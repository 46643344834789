import { Component, Input, OnInit } from '@angular/core';
import { GenericTabComponent } from 'src/app/estimate/generic-tab.component';
import { AccordionTextService } from 'src/app/_add-in/accordion/accordion-text.service';
import { InputBuilder } from 'src/app/_add-in/data/input.builder';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';
import { FieldData } from "../_add-in/data/field-data";
import { UserStore } from '../_add-in/user/store/user.store';

@Component({
    selector: 'size-tab',
    templateUrl: './size-tab.component.html'
})
export class SizeTabComponent extends GenericTabComponent implements OnInit {
    @Input() public model!: FieldData;
    public cellname!: string;
    constructor(
        public excelStorageService: ExcelStorageService,
        public userStore: UserStore,
        public builder: InputBuilder,
        public headers: AccordionTextService
    ) {
        super(excelStorageService, userStore, builder);
    }
    // Removed the logic to assign the reference to a cell based on the value
    // public ngOnInit(): void {
    //     const fields = this.excelStorageService.getExcelEstimate();
    //     if (fields == null) {
    //         return;
    //     }
    //     Excel.run({ delayForCellEdit: true }, async (context: Excel.RequestContext) => {
    //         const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
    //         const range = activeWorksheet.getRange('A1:z60');
    //         range.load(['address', 'cellCount', 'values', 'text']);
    //         activeWorksheet.load(['name', 'id', 'protection/protected']);
    //         return context.sync().then(() => {
    //             //  const temp=activeWorksheet.name+'!'.length;
    //             const cell = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    //                 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    //             for (let i = 0; i < 60; i++) {
    //                 for (let j = 0; j < 24; j++) {
    //                     if (range.values[i][j] !== '' && this.builder.decile.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.decile.source, reference, this.builder.decile.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.decile.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.sizeGrouping.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.sizeGrouping.source, reference,
    //                             this.builder.sizeGrouping.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.sizeGrouping.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }

    //                     if (range.values[i][j] !== '' && this.builder.decileBreakdown1.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.decileBreakdown1.source, reference,
    //                             this.builder.decileBreakdown1.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.decileBreakdown1.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.decileBreakdown2.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.decileBreakdown2.source, reference,
    //                             this.builder.decileBreakdown2.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.decileBreakdown2.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.marketCapPortfolio.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.marketCapPortfolio.source, reference,
    //                             this.builder.marketCapPortfolio.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.marketCapPortfolio.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.marketCapRegression.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.marketCapRegression.source, reference,
    //                             this.builder.marketCapRegression.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.marketCapRegression.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.bookValueOfEquityPortfolio.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.bookValueOfEquityPortfolio.source, reference,
    //                             this.builder.bookValueOfEquityPortfolio.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.bookValueOfEquityPortfolio.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.bookValueOfEquityRegression.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.bookValueOfEquityRegression.source, reference,
    //                             this.builder.bookValueOfEquityRegression.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.bookValueOfEquityRegression.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgNetIncomePortfolio.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgNetIncomePortfolio.source, reference,
    //                             this.builder.fiveYrAvgNetIncomePortfolio.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgNetIncomePortfolio.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgNetIncomeRegression.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgNetIncomeRegression.source, reference,
    //                             this.builder.fiveYrAvgNetIncomeRegression.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgNetIncomeRegression.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.MVICPortfolio.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.MVICPortfolio.source, reference,
    //                             this.builder.MVICPortfolio.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.MVICPortfolio.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.MVICRegression.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.MVICRegression.source, reference,
    //                             this.builder.MVICRegression.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.MVICRegression.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.totalAssetsPortfolio.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.totalAssetsPortfolio.source, reference,
    //                             this.builder.totalAssetsPortfolio.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.totalAssetsPortfolio.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.totalAssetsRegression.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.totalAssetsRegression.source, reference,
    //                             this.builder.totalAssetsRegression.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.totalAssetsRegression.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgEBITDAPortfolio.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgEBITDAPortfolio.source, reference,
    //                             this.builder.fiveYrAvgEBITDAPortfolio.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgEBITDAPortfolio.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgEBITDARegression.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgEBITDARegression.source, reference,
    //                             this.builder.fiveYrAvgEBITDARegression.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgEBITDARegression.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.salesPortfolio.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.salesPortfolio.source, reference,
    //                             this.builder.salesPortfolio.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.salesPortfolio.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.salesRegression.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.salesRegression.source, reference,
    //                             this.builder.salesRegression.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.salesRegression.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.numberOfEmployeesPortfolio.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.numberOfEmployeesPortfolio.source, reference,
    //                             this.builder.numberOfEmployeesPortfolio.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.numberOfEmployeesPortfolio.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.numberOfEmployeesRegression.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.numberOfEmployeesRegression.source, reference,
    //                             this.builder.numberOfEmployeesRegression.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.numberOfEmployeesRegression.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.marketCapPortfolioLevered.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.marketCapPortfolioLevered.source, reference,
    //                             this.builder.marketCapPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.marketCapPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.marketCapRegressionLevered.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.marketCapRegressionLevered.source, reference,
    //                             this.builder.marketCapRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.marketCapRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.bookValueOfEquityPortfolioLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.bookValueOfEquityPortfolioLevered.source, reference,
    //                             this.builder.bookValueOfEquityPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.bookValueOfEquityPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.bookValueOfEquityRegressionLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.bookValueOfEquityRegressionLevered.source, reference,
    //                             this.builder.bookValueOfEquityRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.bookValueOfEquityRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgNetIncomePortfolioLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgNetIncomePortfolioLevered.source, reference,
    //                             this.builder.fiveYrAvgNetIncomePortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgNetIncomePortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgNetIncomeRegressionLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgNetIncomeRegressionLevered.source, reference,
    //                             this.builder.fiveYrAvgNetIncomeRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgNetIncomeRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgNetIncomeRegressionLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgNetIncomeRegressionLevered.source, reference,
    //                             this.builder.fiveYrAvgNetIncomeRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgNetIncomeRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.MVICPortfolioLevered.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.MVICPortfolioLevered.source, reference,
    //                             this.builder.MVICPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.MVICPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.MVICRegressionLevered.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.MVICRegressionLevered.source, reference,
    //                             this.builder.MVICRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.MVICRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.totalAssetsPortfolioLevered.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.totalAssetsPortfolioLevered.source, reference,
    //                             this.builder.totalAssetsPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.totalAssetsPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.totalAssetsRegressionLevered.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.totalAssetsRegressionLevered.source, reference,
    //                             this.builder.totalAssetsRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.totalAssetsRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgEBITDAPortfolioLevered.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgEBITDAPortfolioLevered.source, reference,
    //                             this.builder.fiveYrAvgEBITDAPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgEBITDAPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgEBITDARegressionLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgEBITDARegressionLevered.source, reference,
    //                             this.builder.fiveYrAvgEBITDARegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgEBITDARegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.salesPortfolioLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.salesPortfolioLevered.source, reference,
    //                             this.builder.salesPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.salesPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.salesRegressionLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.salesRegressionLevered.source, reference,
    //                             this.builder.salesRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.salesRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.numberOfEmployeesPortfolioLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.numberOfEmployeesPortfolioLevered.source, reference,
    //                             this.builder.numberOfEmployeesPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.numberOfEmployeesPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.numberOfEmployeesRegressionLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.numberOfEmployeesRegressionLevered.source, reference,
    //                             this.builder.numberOfEmployeesRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.numberOfEmployeesRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.marketCapPortfolioUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.marketCapPortfolioUnlevered.source, reference,
    //                             this.builder.marketCapPortfolioUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.marketCapPortfolioUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.marketCapRegressionUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.marketCapRegressionUnlevered.source, reference,
    //                             this.builder.marketCapRegressionUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.marketCapRegressionUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.bookValueOfEquityPortfolioUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.bookValueOfEquityPortfolioUnlevered.source, reference,
    //                             this.builder.bookValueOfEquityPortfolioUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.bookValueOfEquityPortfolioUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.bookValueOfEquityRegressionUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.bookValueOfEquityRegressionUnlevered.source, reference,
    //                             this.builder.bookValueOfEquityRegressionUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.bookValueOfEquityRegressionUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgNetIncomePortfolioUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgNetIncomePortfolioUnlevered.source, reference,
    //                             this.builder.fiveYrAvgNetIncomePortfolioUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgNetIncomePortfolioUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgNetIncomeRegressionUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgNetIncomeRegressionUnlevered.source, reference,
    //                             this.builder.fiveYrAvgNetIncomeRegressionUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgNetIncomeRegressionUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.MVICPortfolioUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.MVICPortfolioUnlevered.source, reference,
    //                             this.builder.MVICPortfolioUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.MVICPortfolioUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.MVICRegressionUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.MVICRegressionUnlevered.source, reference,
    //                             this.builder.MVICRegressionUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.MVICRegressionUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.totalAssetsPortfolioUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.totalAssetsPortfolioUnlevered.source,
    //                             reference, this.builder.totalAssetsPortfolioUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.totalAssetsPortfolioUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.totalAssetsRegressionUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.totalAssetsRegressionUnlevered.source,
    //                             reference, this.builder.totalAssetsRegressionUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.totalAssetsRegressionUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgEBITDAPortfolioUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgEBITDAPortfolioUnlevered.source,
    //                             reference, this.builder.fiveYrAvgEBITDAPortfolioUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgEBITDAPortfolioUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgEBITDARegressionUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgEBITDARegressionUnlevered.source,
    //                             reference, this.builder.fiveYrAvgEBITDARegressionUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgEBITDARegressionUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.salesPortfolioUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.salesPortfolioUnlevered.source, reference,
    //                             this.builder.salesPortfolioUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.salesPortfolioUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.salesRegressionUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.salesRegressionUnlevered.source, reference,
    //                             this.builder.salesRegressionUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.salesRegressionUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.numberOfEmployeesPortfolioUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.numberOfEmployeesPortfolioUnlevered.source,
    //                             reference, this.builder.numberOfEmployeesPortfolioUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.numberOfEmployeesPortfolioUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.numberOfEmployeesRegressionUnlevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.numberOfEmployeesRegressionUnlevered.source,
    //                             reference, this.builder.numberOfEmployeesRegressionUnlevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.numberOfEmployeesRegressionUnlevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.debtToEquity.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.debtToEquity.source, reference,
    //                             this.builder.debtToEquity.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.debtToEquity.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.marketCapPortfolioRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.marketCapPortfolioRelevered.source,
    //                             reference, this.builder.marketCapPortfolioRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.marketCapPortfolioRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.marketCapRegressionRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.marketCapRegressionRelevered.source,
    //                             reference, this.builder.marketCapRegressionRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.marketCapRegressionRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.bookValueOfEquityPortfolioRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.bookValueOfEquityPortfolioRelevered.source,
    //                             reference, this.builder.bookValueOfEquityPortfolioRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.bookValueOfEquityPortfolioRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.bookValueOfEquityRegressionRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.bookValueOfEquityRegressionRelevered.source,
    //                             reference, this.builder.bookValueOfEquityRegressionRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.bookValueOfEquityRegressionRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgNetIncomePortfolioRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgNetIncomePortfolioRelevered.source,
    //                             reference, this.builder.fiveYrAvgNetIncomePortfolioRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgNetIncomePortfolioRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgNetIncomeRegressionRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgNetIncomeRegressionRelevered.source,
    //                             reference, this.builder.fiveYrAvgNetIncomeRegressionRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgNetIncomeRegressionRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.MVICPortfolioRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.MVICPortfolioRelevered.source, reference,
    //                             this.builder.MVICPortfolioRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.MVICPortfolioRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.MVICRegressionRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.MVICRegressionRelevered.source, reference,
    //                             this.builder.MVICRegressionRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.MVICRegressionRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.totalAssetsPortfolioRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.totalAssetsPortfolioRelevered.source,
    //                             reference, this.builder.totalAssetsPortfolioRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.totalAssetsPortfolioRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.totalAssetsRegressionRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.totalAssetsRegressionRelevered.source,
    //                             reference, this.builder.totalAssetsRegressionRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.totalAssetsRegressionRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgEBITDAPortfolioRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgEBITDAPortfolioRelevered.source,
    //                             reference, this.builder.fiveYrAvgEBITDAPortfolioRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgEBITDAPortfolioRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.fiveYrAvgEBITDARegressionRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.fiveYrAvgEBITDARegressionRelevered.source,
    //                             reference, this.builder.fiveYrAvgEBITDARegressionRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.fiveYrAvgEBITDARegressionRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.salesPortfolioRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.salesPortfolioRelevered.source, reference,
    //                             this.builder.salesPortfolioRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.salesPortfolioRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.salesRegressionRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.salesRegressionRelevered.source, reference,
    //                             this.builder.salesRegressionRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.salesRegressionRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.numberOfEmployeesPortfolioRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.numberOfEmployeesPortfolioRelevered.source,
    //                             reference, this.builder.numberOfEmployeesPortfolioRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.numberOfEmployeesPortfolioRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.numberOfEmployeesRegressionRelevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.numberOfEmployeesRegressionRelevered.source,
    //                             reference, this.builder.numberOfEmployeesRegressionRelevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.numberOfEmployeesRegressionRelevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.operatingMargin.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.operatingMargin.source, reference,
    //                             this.builder.operatingMargin.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.operatingMargin.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.operatingMarginPortfolioLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.operatingMarginPortfolioLevered.source,
    //                             reference, this.builder.operatingMarginPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.operatingMarginPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.operatingMarginRegressionLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.operatingMarginRegressionLevered.source,
    //                             reference, this.builder.operatingMarginRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.operatingMarginRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.CVOfOperatingMargin.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.CVOfOperatingMargin.source, reference,
    //                             this.builder.CVOfOperatingMargin.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.CVOfOperatingMargin.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.CVOfOperatingMarginPortfolioLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.CVOfOperatingMarginPortfolioLevered.source,
    //                             reference, this.builder.CVOfOperatingMarginPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.CVOfOperatingMarginPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.CVOfOperatingMarginRegressionLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.CVOfOperatingMarginRegressionLevered.source,
    //                             reference, this.builder.CVOfOperatingMarginRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.CVOfOperatingMarginRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.CVOfReturnOnEquity.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.CVOfReturnOnEquity.source, reference,
    //                             this.builder.CVOfReturnOnEquity.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.CVOfReturnOnEquity.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.CVOfReturnOnEquityPortfolioLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.CVOfReturnOnEquityPortfolioLevered.source,
    //                             reference, this.builder.CVOfReturnOnEquityPortfolioLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.CVOfReturnOnEquityPortfolioLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.CVOfReturnOnEquityRegressionLevered.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.CVOfReturnOnEquityRegressionLevered.source,
    //                             reference, this.builder.CVOfReturnOnEquityRegressionLevered.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.CVOfReturnOnEquityRegressionLevered.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.ZScore.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.ZScore.source, reference,
    //                             this.builder.ZScore.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.ZScore.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.HFRSizePremium.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.HFRSizePremium.source, reference,
    //                             this.builder.HFRSizePremium.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.HFRSizePremium.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.HFRRiskPremiumOverTheRiskFreeRate.decimalValue ===
    //                         range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.HFRRiskPremiumOverTheRiskFreeRate.source,
    //                             reference, this.builder.HFRRiskPremiumOverTheRiskFreeRate.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.HFRRiskPremiumOverTheRiskFreeRate.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }

    //                 }
    //             }
    //             return true;
    //         });
    //     }).then(() => {
    //     }).catch(this.excelStorageService.handleExcelErrors);
    // }
}
