import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserType } from './user/enum/user-type';
import { UserStore } from './user/store/user.store';
import { AccessType } from './user/enum/access-type';

@Injectable()
export class IsSubscriberGuard implements CanActivate {
    constructor(
        private router: Router,
        private userStore: UserStore
    ) { }

    // TODO: Why does this only work with a promise and not an Observable?
    // see: https://stackoverflow.com/questions/41131476/emptyerror-no-elements-in-sequence
    public canActivate(): Promise<boolean> {

        return new Promise((resolve) => {


            this.userStore.access.onceDefined((access: AccessType) => {
                if (access === 'Authorized') {
                    resolve(true);
                } else {
                    this.userStore.lastLoginType.once((lastLoginType?: UserType) => {
                        if (lastLoginType === UserType.Employee) {
                            this.router.navigate(['employee']);
                        } else {
                            this.router.navigate(['customer']);
                        }
                        resolve(false);
                    });
                }
            });
            // if (this.userStore.access === 'Authorized') {
            //     resolve(true);
            // } else {
            //     this.userStore.lastLoginType.once((lastLoginType?: UserType) => {
            //         if (lastLoginType === UserType.Employee) {
            //             this.router.navigate(['employee']);
            //         } else {
            //             this.router.navigate(['landing']);
            //         }
            //         resolve(false);
            //     });
            // }
        });
    }
}
