import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationManager, ModalManager, NetworkManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { B2CSettings, JwtToken } from '@concurrency/authentication';
import '@concurrency/core';
import { takeUntil } from 'rxjs/operators';
import { PrimeManager } from './_add-in/modal/pmodal.manager';
import { UserStore } from './_add-in/user/store/user.store';
import { AuthService } from '@auth0/auth0-angular';

@Component({ selector: 'app-root', templateUrl: './app.component.html' })
export class AppComponent extends SubscriberEntity implements OnInit {

    isAuthenticated: boolean = false;
    profile: string = "";

    constructor(
        public spinner: Spinner,
        private modalManager: ModalManager,
        private primeManager: PrimeManager,
        private userStore: UserStore,
        private networkManager: NetworkManager,
        private settings: B2CSettings,
        private router: Router,
        viewContainerRef: ViewContainerRef,
        public auth: AuthService) {
        super();
        this.primeManager.setHost(viewContainerRef);
        // this.authenticationManager.error
        //     .pipe(takeUntil(this.destroyed))
        //     .whileDefined(() => {
        //         this.spinner.end();
        //         this.modalManager
        //             .error('Your session has expired. You will be taken to the login page.')
        //             .subscribe(() => { this.userStore.logoutCustomerOrEmployee(); localStorage.clear(); sessionStorage.clear(); });
        //     });

        this.networkManager.error
            .pipe(takeUntil(this.destroyed))
            .whileDefined(() => {
                this.spinner.end();
                this.modalManager
                    .error('There was an unexpected error processing your request.')
                    .subscribe(() => this.networkManager.acknowledgeErrors());
            });

       // this.authenticationManager.initialize();
    }

    public async ngOnInit(): Promise<void> {

        this.auth.isAuthenticated$.subscribe((sucess) => {
            this.isAuthenticated = sucess;
            
            if (!sucess) 
                this.router.navigate(['landing']);          
        });

        this.auth.user$.subscribe(
            (profile) => {
                (this.profile = JSON.stringify(profile, null, 2))

                if (this.isAuthenticated) {
                    this.userStore.findUser();
                    this.auth.idTokenClaims$.subscribe((claim) => {
                        console.log(claim);
                    })
                }

            }

        );

        console.log(this.profile);

        // const raw = AppComponent.storage.getItem('ccy.b2c.id_token');
        // const token = raw ? new JwtToken(raw) : undefined;
        // if ((token != null && token.isExpiring(this.settings.durationMinutes) === true)) {
        //     await this.userStore.logoutCustomerOrEmployee();
        //     this.spinner.end();
        // }
        // this.userStore.inititializeAuthentication();
        this.scrollToTopOnRoute();
    }

    private scrollToTopOnRoute(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    private static get storage(): Storage {
        if (navigator.userAgent.indexOf('Edge') > -1) { // TODO: This should not be necessary and using localStorage is not wise.
            return localStorage;
        } else {
            return sessionStorage;
        }
    }
}
