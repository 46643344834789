import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserStore } from '../_add-in/user/store/user.store';
import { BehaviorSubject, observable } from 'rxjs';
import { AccessType } from '../_add-in/user/enum/access-type';

@Component({
    templateUrl: './subscription-redirect.component.html'
})
export class SubscriptionRedirectComponent {
    constructor(
        private userStore: UserStore,
        private router: Router
    ) { }

    public goToLandingPage(): void {
         this.userStore.access = new BehaviorSubject<AccessType | undefined>(undefined);
        this.router.navigate(['']);
        this.userStore.logoutCustomerOrEmployee();
    }
}
