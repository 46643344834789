<div class="common-input-container">
    <div class="common-input-line">
        <div class="common-input-name">
            <span *ngIf="model.context &&(model.source !=='industry0' && model.source !=='industry1' && model.source !=='industry2' && model.source !=='industry3' && model.source !=='industry4'); else defaultName">
                <span [innerHTML]="model.context"></span>
            </span>
            <ng-template #defaultName>
                <span [innerHTML]="model.config.help.title"></span>
            </ng-template>
            <span *ngIf="!model.config?.hideInfoIcon">
                <span *ngIf="model.config.edit; else infoBtn">
                    <button (click)="openDialog()" class="information-btn edit-button" title="Edit">
                        <i class="fas fa-edit"></i>
                    </button>
                </span>
                <ng-template #infoBtn>
                    <button (click)="openDialog()" class="information-btn" title="More information">
                        <i class="ms-Icon ms-Icon--Info information"></i>
                    </button>
                </ng-template>
            </span>
        </div>
        <div class="common-input-content">
            <ng-content></ng-content>
        </div>
        <common-input-reference [(model)]="model"></common-input-reference>
    </div>
    <div class="common-input-error" *ngIf="hasError()">{{getErrorText()}}</div>
</div>