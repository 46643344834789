import { Component, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FieldData } from "../../data/field-data";
import { FieldService } from "../../data/field.service";
import { InputBuilder } from "../../data/input.builder";
import { DialogConfiguration } from "../../dialog/dialog-configuration.model";
import { ExcelStorageService } from "../../excel/excel-storage.service";
import { CommonDialogInputComponent } from "../dialog/common-dialog-input.component";
import {
    CommonInputConfig,
    CommonUserInputComponent,
} from "./common-user-input.component";

@Component({
    selector: "input-container",
    templateUrl: "./input-container.component.html",
})
export class InputContainerComponent
    extends CommonUserInputComponent
    implements OnChanges
{
    public id: string = Math.random().toString(36).substring(2);

    @Input() public config: CommonInputConfig = {};
    @Input() public invalidValue = "";
    // @Input() public hideInfoIcon = false;

    constructor(
        public dialog: MatDialog,
        private inputBuilder: InputBuilder,
        private fieldService: FieldService,
        private excelStorageService: ExcelStorageService
    ) {
        super(excelStorageService);
    }

    public getErrorText(): string {
        if (this.model.config == null) {
            throw Error(`Expected a configuration to be defined by the model`);
        }

        if (this.model.config.error == null) {
            throw Error(
                `Expected an error message to be defined by the configuration`
            );
        }

        return this.model.config.error.text.replace("{}", this.invalidValue);
    }

    public hasError(): boolean {
        if (this.model.config == null || this.model.config.error == null) {
            return false;
        }

        return this.invalidValue
            ? true
            : false || this.model.config.error.alwaysShow;
    }

    public openDialog(): void {
        // Removed the logic to assign the reference to a cell based on the value
        // Excel.run({ delayForCellEdit: true }, async (context: Excel.RequestContext) => {
        //     const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
        //     const range = activeWorksheet.getRange('A1:z60');
        //     range.load(['address', 'cellCount', 'values', 'text']);
        //     activeWorksheet.load(['name', 'id', 'protection/protected']);
        //     return context.sync().then(() => {
        //          const temp=activeWorksheet.name+'!'.length;
        //         const length = this.model.relatedInputs.length;
        //         const cell = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
        //             'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        //         for (let i = 0; i < 60; i++) {
        //             for (let j = 0; j < 24; j++) {
        //                 for (let k = 0; k < length; k++) {
        //                     if (this.model.relatedInputs[k].value === range.values[i][j] && range.values[i][j] !== '') {
        //                         const temp = i + 1;
        //                         this.cellname = cell[j] + temp;
        //                         const sheetname = activeWorksheet.name;
        //                         const reference = sheetname + '!' + this.cellname;
        //                         this.model.relatedInputs[k].reference;
        //                     }
        //                 }
        //             }
        //         }
        //         return true;
        //     });
        // }).then(() => {
        // }).catch(this.excelStorageService.handleExcelErrors);
        const fields = this.excel.getExcelEstimate();
        const model = fields
            ? fields[this.model.source]
                ? fields[this.model.source]
                : this.model
            : this.model;
        const dialogRef = this.dialog.open(CommonDialogInputComponent, {
            data: { model },
        });
        dialogRef.afterClosed().subscribe((result: DialogConfiguration) => {
            result.model.relatedInputs.forEach((x) => {
                const keyFound = Object.keys(this.inputBuilder).filter(
                    (item) => item === x.source
                )[0];
                const inputFound: FieldData = this.inputBuilder[keyFound];
                if (inputFound.value !== x.value) {
                    inputFound.value = x.value;
                    this.excelStorageService.saveToEstimate(inputFound);
                    this.fieldService.updateModel(x, this.excelStorageService);
                }
            });
        });
    }
}
