import { SubscriptionData } from 'src/app/_add-in/user/model/subscription.model';
import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { Category } from '../enums/category';
import { MetricCategory } from '../models/metric-category.model';
import { MetricRow } from '../models/metric-row.model';
import { MetricTable } from '../models/metric-table.model';
import { MetricValues } from '../models/metric-values.model';
import { TearSheet } from '../tear-sheet.metrics';
import { TearSheetUtil } from './tear-sheet.util';

export class MetricsUtil {

    public static processMetricMapping(
        data: IndustryMetric[],
        codeId: string,
        dataAsOf: Date,
        subscriptionData?: SubscriptionData,
        region?: string
    ): MetricRow[] {
        const metricRows: MetricRow[] = [];
        this.changeLayout(codeId, dataAsOf, region);

        TearSheet(subscriptionData).forEach((row) => {
            const metricCategories: MetricCategory[] = [];

            row.categories.forEach((cat) => {
                if (cat.category === Category.CapitalStructure) {

                } else {
                    const result: MetricCategory = {
                        metricCategoryId: cat.category,
                        metricCategoryName: cat.categoryName,
                        showCompositeLabels: cat.showCompositeLabels,
                        // showCompositeLabels: this.showCompositeLabels(cat.category, changeLayout, cat.showCompositeLabels),
                        metrics: [],
                        chart: null,
                        position: cat.position
                        // position: this.getPosition(cat.category, changeLayout, cat.position)
                    };
                    cat.metricGroups.forEach((y) => {
                        const filteredData = data.filter((z) => y.metrics.includes(z.MetricId));

                        if (filteredData.length > 0) {
                            const metricValue: MetricValues = {
                                name: y.metricGroupName,
                                tables: this.convertMetricValues(filteredData),
                                trendsOverTimeColumn: y.trendsOverTimeColumn ? y.trendsOverTimeColumn : false,
                                trendsOverTimeMetricTitle: y.trendsOverTimeMetricTitle ? y.trendsOverTimeMetricTitle : false
                            };
                            result.metrics.push(metricValue);
                        }
                    });
                    metricCategories.push(result);
                }
            });

            const metricRow: MetricRow = {
                categories: this.sortCategories(metricCategories)
            };
            if (metricRow.categories.length > 1) {
                if (metricRow.categories[0].metrics.length === 0) {
                    metricRow.categories[1].showCompositeLabels = true;
                }
            }
            metricRows.push(metricRow);
        });

        return metricRows;
    }

    private static convertMetricValues(industryData: IndustryMetric[]): MetricTable[] {
        const metricTables: MetricTable[] = [];

        industryData.forEach((x) => {
            const isBeta = TearSheetUtil.isBeta(x);

            const value: MetricTable = {
                metricId: x.MetricId,
                metricName: x.MetricName,
                metricCategory: x.MetricCategory,
                columns: TearSheetUtil.getColumnNames(x),
                medianLatest: TearSheetUtil.roundTearSheetValue(x.MedianLatest, isBeta),
                medianAverage: TearSheetUtil.roundTearSheetValue(x.MedianAverage, isBeta),
                sicCompositeLatest: TearSheetUtil.roundTearSheetValue(x.SICCompositeLatest, isBeta),
                sicCompositeAvergae: TearSheetUtil.roundTearSheetValue(x.SICCompositeAverage, isBeta),
                largeCompositeLatest: TearSheetUtil.roundTearSheetValue(x.LargeCompositeLatest, isBeta),
                largeCompositeAverage: TearSheetUtil.roundTearSheetValue(x.LargeCompositeAverage, isBeta),
                smallCompositeLatest: TearSheetUtil.roundTearSheetValue(x.SmallCompositeLatest, isBeta),
                smallCompositeAverage: TearSheetUtil.roundTearSheetValue(x.SmallCompositeAverage, isBeta),
                highFinancialRiskLatest: TearSheetUtil.roundTearSheetValue(x.HighFinancialRiskLatest, isBeta),
                highFinancialRiskAverage: TearSheetUtil.roundTearSheetValue(x.HighFinancialRiskAverage, isBeta),
                showAverageValues: TearSheetUtil.showAverageValues(x)
            };

            metricTables.push(value);
        });

        return metricTables;
    }

    public static changeLayout(industryCode: string, dataAsOf: Date, region: string | undefined): boolean {
        const usStartDate = new Date(2017, 2, 31);
        const intlStartDate = new Date(2018, 8, 30);
        const targetDate = new Date(dataAsOf);

        if (region && region !== 'United States') {
            return this.isIndustryWithSpecificCodes(industryCode, targetDate, intlStartDate);
        }

        return this.isIndustryWithSpecificCodes(industryCode, targetDate, usStartDate);
    }

    private static isIndustryWithSpecificCodes(industryCode: string, targetDate: Date, startDate: Date): boolean {
        return industryCode[0] === '6' && targetDate >= startDate ||
            industryCode[0] === '4' && targetDate >= startDate;
    }

    private static sortCategories(categories: MetricCategory[]): MetricCategory[] {
        return categories.sort((a, b) => {
            return (a.position > b.position) ? 1 : -1;
        });
    }
}
