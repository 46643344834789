// Components
// Angular Material
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// Concurrency
import { ConcurrencyModule } from "@concurrency/angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CustomFormsModule } from "ng2-validation";
import { CommonTabFooterComponent } from "./footer/common-tab-footer.component";
import { CommonTabHeaderComponent } from "./header/common-tab-header.component";
import { CommonComboboxComponent } from "./inputs/common-combobox.component";
import { CommonDatepickerComponent } from "./inputs/common-datepicker.component";
import { CommonInputReferenceComponent } from "./inputs/common-input-reference.component";
import { CommonIntlComboboxComponent } from "./inputs/common-intlcombobox.component";
import { CommonOutputComponent } from "./inputs/common-output.component";
import { CommonRadioButtonComponent } from "./inputs/common-radio-button.component";
import { CommonTypeaheadComponent } from "./inputs/common-typeahead.component";
import { InputContainerComponent } from "./inputs/input-container.component";
import { FormatDateStructPipe } from "./pipes/format-date-struct.pipe";
import { FormatDatePipe } from "./pipes/format-date.pipe";
import { FormatTableDataPipe } from "./pipes/format-table-data.pipe";
import { CommonTableComponent } from "./table/common-table.component";

const declarationsAndExports = [
    CommonComboboxComponent,
    CommonIntlComboboxComponent,
    CommonDatepickerComponent,
    CommonInputReferenceComponent,
    CommonOutputComponent,
    CommonRadioButtonComponent,
    CommonTabFooterComponent,
    CommonTabHeaderComponent,
    CommonTableComponent,
    CommonTypeaheadComponent,
    InputContainerComponent,
    FormatDateStructPipe,
    FormatDatePipe,
    FormatTableDataPipe,
];

@NgModule({
    declarations: [...declarationsAndExports],
    exports: [...declarationsAndExports],
    imports: [
        FormsModule,
        NgbModule,
        BrowserModule,
        BrowserAnimationsModule,
        CustomFormsModule,
        ConcurrencyModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatTableModule,
    ],
})
export class AddInCommonModule {}
