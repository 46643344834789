import { NgModule } from '@angular/core';
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatTabsModule } from "@angular/material/tabs";
import { DialogModule } from 'primeng/dialog';
import { CommonModules } from '../_config/common-modules';
import { IntlCostOfCapitalComponent } from './intl-cost-of-capital.component';

@NgModule({
  declarations: [
    IntlCostOfCapitalComponent
  ],
  imports: [
    ...CommonModules,
        MatExpansionModule,
        MatIconModule,
        MatTabsModule,
        MatDividerModule,
        MatListModule,
        MatDialogModule,
        DialogModule
  ]
})
export class IntlCostOfCapitalModule { }
