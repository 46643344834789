<p-dialog [(visible)]="display" [modal]="true" [responsive]="true"
    [style]="{width: '100%', minWidth: '200px', margin: 'auto'}" [maximizable]="true" [baseZIndex]="10000"
    [header]="dialogHeader">

    <div class="typeahead-container">
        <div class="common-input-name common-input-name-stack">
            Industry
        </div>
        <input type="text" name="typeahead" [ngModel]="searchTerm" (ngModelChange)="onTypeAheadChange($event)"
            class="common-input" placeholder="Search By Industry Code or Sector Name" />
    </div>

    <table *ngIf="industries.length !== 0; else noIndustriesMsgBlock">
        <thead>
            <tr>
                <th>
                    <span>Industry Code</span>
                </th>
                <th class="width-80-percentage">
                    <span>Sector</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="filteredIndustries.length>0 && moduleName==='Benchmarking'; else takeIndustries">
                <tr *ngFor="let industry of filteredIndustries">
                    <td>{{trendsOverTimeService.createIndustryFilterLabel(industry.SicIndustryCode,
                        industry.GicIndustryCode)}}</td>
                    <td class="width-80-percentage">{{industry.Sector}}</td>
                </tr>
            </ng-container>
            <ng-template #takeIndustries>
                <tr *ngFor="let industry of industries">
                    <td>{{trendsOverTimeService.createIndustryFilterLabel(industry.SicIndustryCode,
                        industry.GicIndustryCode)}}</td>
                    <td class="width-80-percentage">{{industry.Sector}}</td>
                </tr>
            </ng-template>

        </tbody>
    </table>
    <ng-template #noIndustriesMsgBlock>
        <div class="no-company-msg-block">
            No Industries were found that have a region of {{region}}, currency of {{currency}} and valuation date of
            {{valuationDate}}
        </div>
    </ng-template>
    <div class="text-muted">Source: Kroll Cost of Capital Navigator (<a href="https://dpcostofcapital.com"
            target="_blank" rel="noopener noreferrer">dpcostofcapital.com</a>)</div>
</p-dialog>