import { Component, Input } from '@angular/core';
import { FieldData } from 'src/app/_add-in/data/field-data';
import { FieldService } from 'src/app/_add-in/data/field.service';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';

@Component({
    selector: 'common-radio-button',
    templateUrl: './common-radio-button.component.html'
})
export class CommonRadioButtonComponent {
    @Input() public model!: FieldData;

    public constructor(private excelStorageService: ExcelStorageService, private fieldService: FieldService) { }

    public onCompanyTypeChange(companyType: FieldData): void {
        this.fieldService.updateModel(companyType, this.excelStorageService);
    }
}
