import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RprErp } from 'src/app/_add-in/data/rpr-erp';
import { DecileData } from 'src/app/_api/responses/decile.response';
import { Suggestion } from 'src/app/_api/responses/suggestion.response';
import { environment } from 'src/environments/environment';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class AddInClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public readSuggestions(targetDate: string, codeId: number, industryCode: number): Observable<Suggestion[]> {
        return this.post(`AddIn/suggestions?targetDate=${targetDate}&codeId=${codeId}&industryCode=${industryCode}`, {});
    }

    public readHistoricErp(valuationDate: string): Observable<RprErp> {
        return this.post(`AddIn/historicErp?valuationDate=${valuationDate}`, {});
    }

    public readCrsp(valuationDate: string, marketCap: number): Observable<DecileData> {
        return this.get(`Measure/TargetDecile?dataAsOf=${valuationDate}&marketCap=${marketCap}`);
    }
}
