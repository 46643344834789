export enum Metric {
    ReturnOnAssets = 1,
    ReturnOnEquity = 2,
    DividendYield = 3,
    CurrentRatio = 4,
    OperatingMargin = 5,
    RawOlsLevered = 6,
    BlumeAdjustedLevered = 7,
    PeerGroupLevered = 8,
    VasicekAdjustedLevered = 9,
    SumLevered = 10,
    DownsideLevered = 11,
    RawOlsUnlevered = 12,
    BlumeAdjustedUnlevered = 13,
    PeerGroupUnlevered = 14,
    VasicekAdjustedUnlevered = 15,
    SumUnlevered = 16,
    DownsideUnlevered = 17,
    DebtMvEquity = 18,
    DebtTotalCapital = 19,
    LongTermEps = 20,
    PriceSales = 21,
    PriceEarnings = 22,
    MarketBook = 23,
    EvSales = 24,
    EvEbitda = 25,
    Capm = 26,
    OneStageDcf = 27,
    ThreeStageFactor = 28,
    CostOfDebt = 29,
    WaccCapm = 30,
    WaccOneStageDcf = 31,
    WaccThreeStageFactor = 32,
    ThreeFactorModelBeta = 33,
    ThreeFactorModelSmbPremium = 34,
    ThreeFactorModelHmlPremium = 35,
    CrspCapm = 36,
    CrspBuildUp = 37,
    RprCapm = 38,
    RprBuildUp = 39,
    ThreeStageDcf = 40,
    WaccCrspCapm = 41,
    WaccCrspBuildUp = 42,
    WaccRprCapm = 43,
    WaccRprBuildUp = 44,
    WaccThreeStageDcf = 45,
    FiveFactorModelBeta = 46,
    FiveFactorModelSmbPremium = 47,
    FiveFactorModelHmlPremium = 48,
    FiveFactorModelRmwPremium = 49,
    FiveFactorModelCmaPramium = 50,
    FiveStageFactor = 51,
    WaccFiveStageFactor = 52,
    PriceTbv = 53,
    PriceEbt = 54,
    OneYearIndustry = 55,
    ThreeYearIndustry = 56,
    FiveYearIndustry = 57,
    OneYearSp = 58,
    ThreeYearSp = 59,
    FiveYearSp = 60,
    SICComposite = 61,
    EnterpriseValuation = 62,
    CostOfEquityCapital = 63,
    WaccSicComposite = 64,
    OneYearMSCIEur = 73,
    ThreeYearMSCIEur = 74,
    FiveYearMSCIEur = 75,
    OneYearMSCIWorld = 76,
    ThreeYearMSCIWorld = 77,
    FiveYearMSCIWorld = 78
}
