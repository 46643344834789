export enum Menu {
    Inputs = 1,
    RfErpData = 2,
    SizeRiskPremia = 3,
    Betas = 4,
    DataSummary = 5,
    BenchMarkingInputs = 6,
    IntlBenchMarkingInputs = 7,
    IntlCoC = 8
}
