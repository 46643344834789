import { Injectable } from "@angular/core";
import { Spinner, ngbDateStructToString } from "@concurrency/angular";
import { Workbook } from "@progress/kendo-angular-excel-export";
import { FormatTableDataPipe } from "src/app/_add-in/common/pipes/format-table-data.pipe";
import { InputBuilder } from "src/app/_add-in/data/input.builder";
import { ExcelStorageService } from "src/app/_add-in/excel/excel-storage.service";
import { CostofEquityClient } from "src/app/_api/clients/costofEquity.client";
import { IntlCOCInvesteeExcelAddinDetails } from "src/app/_api/responses/country-credit-rating.response";
import { IntlCOCExcelFields } from "../../exports/intl-coc-type";
import { IntlCOCExcelExport } from "../../exports/util/intl-coc-export.util";

@Injectable()
export class IntlCOCSheetService{
    private _intlSheetData: IntlCOCExcelFields[] = [];

    constructor(
        public excelStorageService: ExcelStorageService ,
        private intlCOCExportutil: IntlCOCExcelExport,
        private inputBuilder: InputBuilder,
        private spinner: Spinner, 
        private costOfEquityClient: CostofEquityClient
    ) {
    }
    public intlCOCInvestDetails: IntlCOCInvesteeExcelAddinDetails[] = [];
    public intlCOCSheet: string  = 'KrollCountryRiskExport';

    public async generateIntlCOCExcelExport(fields: IntlCOCExcelFields){
        this.spinner.begin();
        const intlCOCAddInSheet = this.intlCOCExportutil.saveWorksheet(fields, this.intlCOCInvestDetails);
        
       
        if(intlCOCAddInSheet){
        let lstexcelExport: Workbook[] = [];
        const workbook = new Workbook();
        workbook.sheets=[
            {
                
                name: 'KrollCountryRiskExport',
                
                columns:[
                    { width: 270 },
                    { width: 1 },
                    { width: 150 },
                    { width: 200 },
                    { width: 15 },
                    { width: 150 },
                    { width: 150 },
                    { width: 150 },
                    { width: 150 }

                ], 
                rows:[
                    ...intlCOCAddInSheet
                ]
            }
        ]
            const workSheet = workbook.sheets.find((x) => x.name === 'KrollCountryRiskExport');
            console.log(workSheet);
            lstexcelExport.push(workbook);
            await this.excelStorageService.exportMultipleIntlExcel(lstexcelExport);
            
            this.spinner.end();
        }
    }
    public async generateIntlCOCKrollTemplate(){
        const krollTemplateSheet = this.intlCOCExportutil.savekrollTemplateSheet();
        let lstexcelExport: Workbook[] = [];
        const workbook = new Workbook();
        workbook.sheets=[
            {
                
                name: 'KrollWACCTemplate',
                
                columns:[
                    { width: 270 },
                    { width: 250 },
                    { width: 20 },
                    { width: 200 },
                    { width: 20 },
                    { width: 200 },
                    { width: 20 },
                    { width: 150 },

                ], 
                rows:[
                    ...krollTemplateSheet
                ]
            }
        ]
            const workSheet = workbook.sheets.find((x) => x.name === 'KrollWACCTemplate');
            console.log(workSheet);
            // if(workSheet){
            //     workSheet.rows.foreach((x))
            // }
            lstexcelExport.push(workbook);
            await this.excelStorageService.exportMultipleIntlExcelTemplate(lstexcelExport);
            
            this.spinner.end();
        }
        
    
    public async save(){
        this.intlCOCInvestDetails = [];
        const fields: IntlCOCExcelFields ={
            EquityRiskValues: this.excelStorageService.listIntlErpClients,
            RiskFreeRates: this.excelStorageService.listIntlRfClients,
            LeveredBetas: this.excelStorageService.listIntlBetas
        }
        const requestCountry = this.excelStorageService.investorCountries.filter((x) => x.CountryName.toLowerCase() === this.inputBuilder.intlInvestorCountry.value.toLowerCase());
        const requestDate = ngbDateStructToString(this.inputBuilder.intlCOCValuationDate.value).split('T')[0];
        const requestCountryId = requestCountry[0].CountryId;
        this.spinner.begin()
        this.costOfEquityClient.readIntlCOCExcelAddinDetails(requestCountryId, requestDate).
        onceDefined(async (investeeDetails: IntlCOCInvesteeExcelAddinDetails[]) =>{
            if(investeeDetails.length > 0){
                this.intlCOCInvestDetails = investeeDetails;
                this.intlCOCInvestDetails.forEach((x) =>{
                    x.InvesteeContry,
                    x.Region,
                    x.MSIClassification = x.MSIClassification !== null ? x.MSIClassification : '',
                    x.InflationRate = x.InflationRate !== null ? parseFloat(x.InflationRate).toFixed(2).toString() : 'N/A',
                    x.IntlTaxRate = x.IntlTaxRate != null ? parseFloat(x.IntlTaxRate).toFixed(2).toString() : 'N/A',
                    x.CysmDp = x.CysmDp != null ? parseFloat(x.CysmDp).toFixed(2).toString() : 'N/A',
                    x.CcrCrp = x.CcrCrp != null ? parseFloat(x.CcrCrp).toFixed(2).toString() : 'N/A',
                    x.RvComplex = x.RvComplex != null ? parseFloat(x.RvComplex).toFixed(1).toString() : 'N/A'
                });
            }
            this.spinner.end();
        await this.generateIntlCOCExcelExport(fields);

        });
        console.log(this.intlCOCInvestDetails); 
    }
    public saveKrollTemplate(){
        this.generateIntlCOCKrollTemplate();
    }

}