<div class="tab-rf">
    <div class="common-inputs-content">
        <common-tab-header [headerText]="headers.getAccordionText('rfErp')"></common-tab-header>
        <div class="tab-value-input current-inputs">
            <h5>
                Current Inputs
            </h5>
            <mat-divider></mat-divider>
            <common-datepicker [config]="builder.valuationDate.config" [(model)]="builder.valuationDate">
            </common-datepicker>
        </div>
        <mat-accordion class="excel-input-tab" multi="true">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            Risk-free Rates
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <common-output [(model)]="builder.spot10YearTreasury"></common-output>
                    <common-output [(model)]="builder.spot20YearTreasury"></common-output>
                    <common-output [(model)]="builder.spot30YearTreasury"></common-output>
                    <common-output [(model)]="builder.normalizedRiskFreeRate"></common-output>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="excel-input-tab" multi="true">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            Equity Risk Premia
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <common-output [(model)]="builder.DNPRecommended"></common-output>
                    <common-output [(model)]="builder.historicalLongTerm"></common-output>
                    <common-output [(model)]="builder.supplySideLongTerm"></common-output>
                    <common-output [(model)]="builder.riskPremiumReport"></common-output>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="excel-input-tab" multi="true">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            Equity Risk Premium Adjustment based on:
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <common-tab-header [headerText]="headers.getAccordionText('erpAdjustment')"></common-tab-header>
                    <mat-divider></mat-divider>
                    <div class="input-wrapper">
                        <common-output [(model)]="builder.DNPRecommendedAdjusted"></common-output>
                        <common-output [(model)]="builder.historicalLongTermAdjusted"></common-output>
                        <common-output [(model)]="builder.supplySideLongTermAdjusted"></common-output>
                        <common-output [(model)]="builder.riskPremiumReportAdjusted"></common-output>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <common-tab-footer></common-tab-footer>
    </div>
</div>