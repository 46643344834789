import { Component, OnInit } from '@angular/core';
import { BetasTabComponent } from '../../app/estimate/betas-tab.component';
import { AccordionTextService } from '../_add-in/accordion/accordion-text.service';
import { InputBuilder } from '../_add-in/data/input.builder';
import { ExcelStorageService } from '../_add-in/excel/excel-storage.service';
import { Menu } from '../_add-in/menu/menu';
import { UserStore } from '../_add-in/user/store/user.store';
import { GenericTabComponent } from '../estimate/generic-tab.component';
import { RfTabComponent } from '../estimate/rf-tab.component';
import { SizeTabComponent } from '../estimate/size-tab.component';
import { InputsTabComponent } from './inputs-tab.component';

@Component({
    selector: 'data-summary-tab',
    templateUrl: './data-summary-tab.component.html'
})
export class DataSummaryTabComponent extends GenericTabComponent implements OnInit {
    public riskFreeRateMenu = Menu.RfErpData;
    public sizePremiaMenu = Menu.SizeRiskPremia;
    public betaMenu = Menu.Betas;
    public inputsMenu = Menu.Inputs;

    constructor(
        public excelStorageService: ExcelStorageService,
        public userStore: UserStore,
        public builder: InputBuilder,
        public headers: AccordionTextService,
        private betasTabComponent: BetasTabComponent,
        private sizetab: SizeTabComponent,
        private rftab: RfTabComponent,
        private inputTab: InputsTabComponent

    ) {
        super(excelStorageService, userStore, builder);
    }
    public ngOnInit(): void {
        this.betasTabComponent.ngOnInit();
        this.sizetab.ngOnInit();
        this.rftab.ngOnInit();
        this.inputTab.ngOnInit();

    }
    public onExportToExcel(): void {
        this.excelStorageService.exportDataSummary();
    }

}
