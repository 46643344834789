import { EnvironmentConfiguration } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { NgxLoggerLevel } from 'ngx-logger';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentConfiguration = {
    production: false,
    name: 'dev',
    urls: {
        navigatorApi: 'https://costofcapital-dev-api.duffandphelps.com',
        fastSpring: 'https://krollcostofcapital.onfastspring.com',
        contentManagement: 'https://vasdc8grscocqa.blob.core.windows.net/files/content-management',
        krollLogo: 'https://excel-dev.kroll.com/assets/images/kroll.png'
    },
    authentication: {
        aad: {
            client: '16a2837d-55fd-43fe-87e8-0d95e0acd866',
            tenant: '781802be-916f-42df-a204-78a2b3144934',
            endpoints: [
                'https://duffandphelps.onmicrosoft.com/db6aeb94-c157-4c59-8eca-16d1b05ada37',
                'bb2ca938-052f-4556-9b3c-1afbeaf50fbe'
            ],
            durationMinutes: 30
        },
        b2c: {
            provider: 'b2clogin.com',
            workflow: Util.isWindowIFrame() || Util.isWindowPopup() ? 'Popup' : 'Redirect',
            client: 'db839c3e-2577-4275-8f39-ae933884b7cc',
            tenant: 'devusdpb2c',
            scopes: ['https://devusdpb2c.onmicrosoft.com/api/read'],
            policies: {
                signup: 'B2C_1_signup',
                signin: 'B2C_1_signin',
                signinSignup: 'B2C_1_signup_signin',
                forgotPassword: 'B2C_1_reset_password'
            }
        }
    },
    applicationInsights: {
        name: 'dev-us-coc-wa-01',
        key: 'd90417b2-d70f-49ef-8d6c-e6ca08212943'
    },
    loggerLevel: NgxLoggerLevel.TRACE,
    auth0: {
        domain: 'pearl-dev.eu.auth0.com',
        clientId: 'B0Xz7nyJek8pldiGZC6oXLF1nCRIWsiZ',

        authorizationParams: {
            redirect_uri: window.location.origin,
            scope: 'openid profile email',
            audience: '9a868438-b2be-4507-802a-c45af33f73f4'
        },
        httpInterceptor: {
            allowedList: [
                'https://costofcapital-dev-api.duffandphelps.com/api*',
                'https://costofcapital-dev-api.duffandphelps.com/files*',
                'https://cdn.pendo.io/agent/static/'
            ],
        }
    }
};
