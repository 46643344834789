<div class="common-table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Linked Cell column -->
        <ng-container matColumnDef="linkedCell">
            <th mat-header-cell *matHeaderCellDef>Linked Cell</th>
            <td mat-cell *matCellDef="let element">
                <span class="common-table-element-reference" (click)="onFocusToCell(element)">
                    {{element.reference}}
                </span>
            </td>
        </ng-container>

        <!-- Study column -->
        <ng-container matColumnDef="study">
            <th mat-header-cell *matHeaderCellDef>Study</th>
            <td mat-cell *matCellDef="let element">{{element.study}}</td>
        </ng-container>

        <!-- Size/Risk Measure column -->
        <ng-container matColumnDef="sizeRiskMeasure">
            <th mat-header-cell *matHeaderCellDef>Size/Risk Measure</th>
            <td mat-cell *matCellDef="let element">{{element.measure}}</td>
        </ng-container>

        <!-- Data Point column -->
        <ng-container matColumnDef="dataPoint">
            <th mat-header-cell *matHeaderCellDef>Data Point</th>
            <td mat-cell *matCellDef="let element"><span [innerHTML]="element.dataPoint"></span></td>
        </ng-container>

        <!-- Value column -->
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let element">{{isTypeDatePicker(element)?(convertValue(element)|
                date:'longDate'):element.value}}
            </td>
        </ng-container>

        <!-- Data As Of column-->
        <ng-container matColumnDef="dataAsOf">
            <th mat-header-cell *matHeaderCellDef>Data As Of</th>
            <td mat-cell *matCellDef="let element">{{element.dataAsOf}}</td>
        </ng-container>

        <!-- Breakpoints column -->
        <ng-container matColumnDef="breakpoints">
            <th mat-header-cell *matHeaderCellDef>Breakpoints</th>
            <td mat-cell *matCellDef="let element">{{element.breakpoints}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>