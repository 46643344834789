import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { MenuItem } from '../_add-in/menu/menu-item.model';
import { MenuUtility } from '../_add-in/menu/menu.utility';
import { UserStore } from '../_add-in/user/store/user.store';
import { SubscriptionType } from '../_api/responses/user.response';

@Component({
    selector: 'app-benchmarking',
    templateUrl: './benchmarking.component.html'
})
export class BenchmarkingComponent extends SubscriberEntity implements OnInit, OnDestroy {
    public isTrial = false;
    public menuItems: MenuItem[] = MenuUtility.benchmarkingMenu;
    public currentRoute: string;

    constructor(private userStore: UserStore) {
        super();
        this.currentRoute = this.getCurrentRouteName();
    }

    public ngOnInit(): void {
        this.userStore.user.onceDefined((profile) => {
            if (profile == null) {
                return;
            }
            this.isTrial = profile.Subscriptions[0].Type === SubscriptionType.UsiTrial;
        });
    }

    public logoutUser(): void {
        this.userStore.logoutCustomerOrEmployee();
    }

    public getCurrentRouteName(): string {
        const currentRoute = this.menuItems.find((x) => x.route === this.userStore.getCurrentRoute());

        if (!currentRoute) {
            return 'banchmarking';
        }

        return currentRoute.name;
    }

}
