export interface Suggestion {
    Name: string;
    Source: string;
    Value: number;
    SelectionType: string; // api.SelectionType
    DataAsOf: string;
    SuggestionType: SuggestionType;
}

export enum SuggestionType {
    RecommendedRiskFreeRate = 'RecommendedRiskFreeRate',
    Spot20YieldRiskFreeRate = 'Spot20YieldRiskFreeRate',
    RecommendedEquityRiskPremium = 'RecommendedEquityRiskPremium',
    SupplySideEquityRiskPremium = 'SupplySideEquityRiskPremium',
    HistoricalEquityRiskPremium = 'HistoricalEquityRiskPremium',
    FullInformationBeta = 'FullInformationBeta',
    VasicekAdjustedBeta = 'VasicekAdjustedBeta',
    MedianSumLeveredBeta = 'MedianSumLeveredBeta',
    HighFinancialRiskSumLeveredBeta = 'HighFinancialRiskSumLeveredBeta',
    Spot10YieldRiskFreeRate = 'Spot10YieldRiskFreeRate',
    Spot30YieldRiskFreeRate = 'Spot30YieldRiskFreeRate'
}
