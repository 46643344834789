
import * as moment from 'moment';
import * as help from 'src/app/_add-in/help/help-text';
import { CommonDate } from '../../common/date-struct';
import { CommonInputConfig } from '../../common/inputs/common-user-input.component';
import { Help } from '../../help/help.model';
import { ProductType } from '../enum/product-type';
import { SubscriptionLevel } from '../enum/subscription-level';
import { SubscriptionData } from '../model/subscription.model';
import { SubscriptionUtil } from './subscription.util';

export class UserUtil {
    public static getValuationDateInputConfig(
        date: CommonDate,
        productType: ProductType,
        minimumDate: number,
        subscriptionDetails: SubscriptionData[],
        helpText?: Help
    ): CommonInputConfig {
        const subscriptionData = subscriptionDetails.find((x) => x.productType === productType);
        date.asDate();
        return {
            name: 'Valuation Date',
            readonly: true,
            required: true,
            minimumValue: subscriptionData
                ? SubscriptionUtil.getMinimumDate(minimumDate, subscriptionData.level, subscriptionData.productType)
                : undefined,
            maximumValue: subscriptionData ? subscriptionData.maximumDate : undefined,
            help: helpText ? helpText : help.valuationDate
        };
    }
    public static getIntValuationDateInputConfig(
        date: CommonDate,
        productType: ProductType,
        minimumDate: number,
        subscriptionDetails: SubscriptionData[],
        helpText?: Help
    ): CommonInputConfig {
        const subscriptionData = subscriptionDetails.find((x) => x.productType === productType);
        date.asDate();
        return {
            name: 'Valuation Date',
            readonly: true,
            required: true,
            minimumValue: subscriptionData
                ? SubscriptionUtil.getMinimumDate(minimumDate, subscriptionData.level, subscriptionData.productType)
                : undefined,
            maximumValue: subscriptionData ? subscriptionData.maximumDate : undefined,
            help: helpText ? helpText : help.IntlCOCValuationDate
        };
    }

    public static getSubscriptionMinimumValuationDate(
        productType: ProductType,
        subscriptionLevel: SubscriptionLevel,
        minimumDate: number,
        subscriptionDetails: SubscriptionData[]
    ): moment.Moment {
        const subscriptionData = subscriptionDetails.find(
            (x) => x.level === subscriptionLevel && x.productType === productType
        );

        if (subscriptionData == null) {
            return moment();
        }

        return SubscriptionUtil.getMinimumDate(minimumDate, subscriptionData.level, subscriptionData.productType).asMoment();
    }
}
