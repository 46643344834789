import { Injectable } from '@angular/core';
import { Util } from '@concurrency/core';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Industry } from 'src/app/_api/responses/us-industry.response';

@Injectable()
export class BenchmarkingQueryBuilderService {
    public industries: Industry[] = [];
    public area: string | null = null;
    public currencyCode: string | null = null;
    public noResults = false;

    public IndustriesZoneFilterTypeahead = (text: Observable<string>, moduleName: string, displayAllResults?: boolean) => {
        const termMapper = (term: string) => {
            if (term === '') {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            let results = this.baseFilterTypeAhead(this.industries, cleanTerm);

            if (moduleName === 'USCOC') {
                results = this.filterCOCIndustries(results);
            } else {
                results = this.filterIndustriesBasedOnGeographic(results);
            }

            if (displayAllResults) {
                results = results.slice(0, results.length);
            } else {
                results = results.slice(0, 10);
            }

            this.noResults = !results.length ? true : false;

            return Util.sortBy(results, (x) => {
                const code = (!x.GicIndustryCode) ? x.SicIndustryCode : x.GicIndustryCode;
                parseInt(code, 10);
            });
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }

    public filterCOCIndustries(industries: Industry[]): Industry[] {
        return industries.filter(() => {
            return this.industries;
        }).sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.SicIndustryCode) ||
            parseFloat(a.GicIndustryCode) - parseFloat(b.GicIndustryCode))
            .sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.GicIndustryCode));
    }

    public filterIndustriesBasedOnGeographic(industries: Industry[]): Industry[] {
        return industries.filter((filteredIndustry) => {
            const matchesZone = this.MatchesZone(filteredIndustry);
            const matchesUSZone = this.MatchesUSZoneNullValues(filteredIndustry) || this.MatchesUSZone(filteredIndustry);

            return matchesZone || matchesUSZone;
        }).sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.SicIndustryCode) ||
            parseFloat(a.GicIndustryCode) - parseFloat(b.GicIndustryCode))
            .sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.GicIndustryCode));
    }

    private MatchesZone(industry: Industry): boolean {
        return industry.Area !== null && industry.CurrencyCode !== null &&
            industry.Area === this.area && industry.CurrencyCode === this.currencyCode;
    }

    private MatchesUSZoneNullValues(industry: Industry): boolean {
        return industry.CurrencyCode === null && industry.Area === null &&
            this.currencyCode === 'USD' && this.area === 'United States';
    }

    private MatchesUSZone(industry: Industry): boolean {
        return industry.CurrencyCode === 'USD' && industry.Area === 'United States' &&
            this.currencyCode === 'USD' && this.area === 'United States';
    }

    private baseFilterTypeAhead(industries: Industry[], cleanTerm: string): Industry[] {
        return industries.filter((industryValue) => {
            const industryCode = (!industryValue.GicIndustryCode) ? industryValue.SicIndustryCode : industryValue.GicIndustryCode;
            const displayName = `${industryCode} - ${industryValue.Sector}`.toLowerCase();
            const matchesDisplayName = displayName.includes(cleanTerm);
            const hasDash = cleanTerm.includes('-');
            const matchesCode = industryCode.startsWith(cleanTerm);
            const matchesName = industryValue.Sector.toLowerCase().includes(cleanTerm);

            return matchesCode || matchesName || (hasDash && matchesDisplayName);
        });
    }


    public  isSic(targetDate: string, region: string): boolean {
        return region === 'United States' &&
            new Date(targetDate).getTime() < new Date('09/30/2020').getTime();
    }
}
