import { Injectable } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CommonDate } from '../_add-in/common/date-struct';
import { CommonInputConfig } from '../_add-in/common/inputs/common-user-input.component';
import { FieldData } from '../_add-in/data/field-data';
import { SuggestedInput } from '../_add-in/data/suggested-input.model';
import { valuationDate } from '../_add-in/help/help-text';
import { ProductType } from '../_add-in/user/enum/product-type';
import { SubscriptionLevel } from '../_add-in/user/enum/subscription-level';
import { SubscriptionData } from '../_add-in/user/model/subscription.model';
import { UserUtil } from '../_add-in/user/util/user.util';

@Injectable()
export class BenchmarkingService extends SubscriberEntity {
    public constructor(

    ) {
        super();
    }

    public updateCurrencyOptions(currencyOptions: SuggestedInput[], region: string,
                                 iniSubData: SubscriptionData | undefined,
                                 usiSubData: SubscriptionData | undefined): SuggestedInput[] {
        currencyOptions = currencyOptions.map((currencyOption) => {
            return {
                ...currencyOption,
                disabledText: region === 'United States'
                    ? this.disabledTextBasedOnValue(currencyOption.value, 'USD', '- Currency Unavailable')
                    : this.disabledTextBasedOnSubscription(iniSubData, usiSubData, currencyOption.value, 'USD', true)
            };
        });
        return currencyOptions;
    }
    public disabledTextBasedOnValue(item: string, USValue: string, disabledText: string): string | undefined {
        if (item !== USValue) {
            return disabledText;
        }
        return undefined;
    }

    public disabledTextBasedOnSubscription(iniSubData: SubscriptionData | undefined,
                                           usiSubData: SubscriptionData | undefined,
                                           item: string, USValue: string, currency: boolean): string | undefined {
        if (!iniSubData && item !== USValue) {
            return '- An International subscription is required for this item.';
        }

        if (iniSubData && !usiSubData && item === USValue && currency !== true) {
            return '- A US subscription is required for this item.';
        }

        return undefined;
    }
    public getValuationDateConfig(productType: ProductType, minimumDate: number, subscriptionDetails: SubscriptionData[]): CommonInputConfig {
        const defaultValuationDate = moment().toISOString();
        const config =
            UserUtil.getValuationDateInputConfig(
                CommonDate.fromString(defaultValuationDate),
                productType,
                minimumDate,
                subscriptionDetails,
                valuationDate
            );
        return config;
    }
    public setToDefaultOptions(regionComboOptions: SuggestedInput[], currencyOptions: SuggestedInput[],
                               iniSubData: SubscriptionData | undefined,
                               usiSubData: SubscriptionData | undefined): any {
        regionComboOptions = regionComboOptions.map((regionOption) => {
            return {
                ...regionOption,
                disabledText: this.disabledTextBasedOnSubscription(iniSubData, usiSubData, regionOption.value, 'United States', false)
            };
        });

        currencyOptions = currencyOptions.map((currencyOption) => {
            return {
                ...currencyOption,
                disabledText: this.disabledTextBasedOnSubscription(iniSubData, usiSubData, currencyOption.value, 'USD', true)
            };
        });
        return { regionComboOptions, currencyOptions };
    }
    public restrictRegionBasedOnValidationDate(
        iniSubData: SubscriptionData | undefined,
        usiSubData: SubscriptionData | undefined,
        regionComboOptions: SuggestedInput[],
        subscriptionDetails: SubscriptionData[],
        valuationDateData: FieldData,
        minimumDate: number
    ): SuggestedInput[] {
        regionComboOptions = this.restrictUSRegionBasedOnValidationDate(
            usiSubData,
            regionComboOptions,
            subscriptionDetails,
            valuationDateData,
            minimumDate);
        regionComboOptions = this.restrictInternationalRegionBasedOnValidationDate(
            iniSubData,
            regionComboOptions,
            subscriptionDetails,
            valuationDateData,
            minimumDate);
        regionComboOptions = this.restrictBothRegionBasedOnValidationDate(
            iniSubData,
            usiSubData,
            regionComboOptions,
            subscriptionDetails,
            valuationDateData,
            minimumDate);
        return regionComboOptions;
    }
    public restrictUSRegionBasedOnValidationDate(usiSubData: SubscriptionData | undefined,
                                                 regionComboOptions: SuggestedInput[],
                                                 subscriptionDetails: SubscriptionData[],
                                                 valuationDateFieldData: FieldData,
                                                 minimumDate: number): SuggestedInput[] {
        if (usiSubData) {
            const config = this.getValuationDateConfig(usiSubData.productType, minimumDate, subscriptionDetails);
            const validation = this.isInBetweenDate(config.minimumValue as NgbDateStruct, config.maximumValue as NgbDateStruct, valuationDateFieldData);
            if (!validation) {
                regionComboOptions = regionComboOptions.map((regionOption: any) => {
                    return {
                        ...regionOption,
                        disabledText: (regionOption.value === 'United States') ? ' - Unavailable by Valuation Date' : undefined
                    };
                });
                return regionComboOptions;
            }
        }
        return regionComboOptions;
    }
    public restrictInternationalRegionBasedOnValidationDate(iniSubData: SubscriptionData | undefined,
                                                            regionComboOptions: SuggestedInput[],
                                                            subscriptionDetails: SubscriptionData[],
                                                            valuationDateFieldData: FieldData,
                                                            minimumDate: number): SuggestedInput[] {
        if (iniSubData) {
            const config = this.getValuationDateConfig(iniSubData.productType, minimumDate, subscriptionDetails);
            const validation = this.isInBetweenDate(config.minimumValue as NgbDateStruct, config.maximumValue as NgbDateStruct, valuationDateFieldData);
            if (!validation) {
                regionComboOptions = regionComboOptions.map((regionOption: any) => {
                    return {
                        ...regionOption,
                        disabledText: (regionOption.value !== 'United States') ? ' - Unavailable by Valuation Date' : undefined
                    };
                });
                return regionComboOptions;
            }
        }
        return regionComboOptions;
    }
    public restrictBothRegionBasedOnValidationDate(
        iniSubData: SubscriptionData | undefined,
        usiSubData: SubscriptionData | undefined,
        regionComboOptions: SuggestedInput[],
        subscriptionDetails: SubscriptionData[],
        valuationDateFieldData: FieldData,
        minimumDate: number
    ): SuggestedInput[] {
        if (usiSubData && iniSubData) {
            const configMod2 = this.getValuationDateConfig(usiSubData.productType, minimumDate, subscriptionDetails);
            const configMod4 = this.getValuationDateConfig(iniSubData.productType, minimumDate, subscriptionDetails);

            const validation = (!(this.isInBetweenDate(configMod2.minimumValue as NgbDateStruct, configMod2.maximumValue as NgbDateStruct, valuationDateFieldData))
                && (!this.isInBetweenDate(configMod4.minimumValue as NgbDateStruct, configMod4.maximumValue as NgbDateStruct, valuationDateFieldData)));
            if (validation) {
                regionComboOptions = regionComboOptions.map((regionOption: any) => {
                    return {
                        ...regionOption,
                        disabledText: ' - Unavailable by Valuation Date'
                    };
                });
                return regionComboOptions;
            }
        }
        return regionComboOptions;
    }
    public setErrorMessage(iniSubData: SubscriptionData | undefined, usiSubData: SubscriptionData | undefined, isMod4: boolean | undefined, region: string): string {
        let errorMessage = '';
        if (iniSubData && usiSubData) {
            if ((usiSubData.level === SubscriptionLevel.Pro && iniSubData.level === SubscriptionLevel.Basic)
                || (usiSubData.level === SubscriptionLevel.Enterprise && iniSubData.level === SubscriptionLevel.Basic)) {
                errorMessage = 'Only dates of <strong>12/31/2019</strong> and forward are available to International Industry Benchmarking Basic Subscribers';
            } else if ((usiSubData.level === SubscriptionLevel.Basic && iniSubData.level === SubscriptionLevel.Pro)
                || (usiSubData.level === SubscriptionLevel.Basic && iniSubData.level === SubscriptionLevel.Enterprise)) {
                errorMessage = 'Only dates of <strong>12/31/2019</strong> and forward are available to US Industry Benchmarking Basic Subscribers';
            } else if ((usiSubData.level === SubscriptionLevel.Trial && iniSubData.level === SubscriptionLevel.Pro)
                || (usiSubData.level === SubscriptionLevel.Trial && iniSubData.level === SubscriptionLevel.Enterprise)
                || (usiSubData.level === SubscriptionLevel.Trial && iniSubData.level === SubscriptionLevel.Basic)) {
                errorMessage = 'Only dates of <strong> 03/31/2016 - 07/31/2016</strong> are available to US Industry Benchmarking Trial Subscribers';
            } else if ((usiSubData.level === SubscriptionLevel.Pro && iniSubData.level === SubscriptionLevel.Trial)
                || (usiSubData.level === SubscriptionLevel.Enterprise && iniSubData.level === SubscriptionLevel.Trial)) {
                errorMessage = 'Only dates of <strong> 03/31/2016 - 07/31/2016</strong> are available to International Industry Benchmarking Trial Subscribers';
            } else if (usiSubData.level === SubscriptionLevel.Basic && iniSubData.level === SubscriptionLevel.Trial
                && region && region === 'United States') {
                errorMessage = 'Only dates of <strong>12/31/19</strong>  and forward are available to US Industry Benchmarking Basic Subscribers.';
            } else if (usiSubData.level === SubscriptionLevel.Basic && iniSubData.level === SubscriptionLevel.Trial
                && region && region !== 'United States') {
                errorMessage = 'Only dates of <strong> 03/31/2016 - 07/31/2016</strong> are available to International Industry Benchmarking Trial Subscribers';
            } else if ((usiSubData.level === SubscriptionLevel.Pro || usiSubData.level === SubscriptionLevel.Enterprise)
                && (iniSubData.level === SubscriptionLevel.Pro || iniSubData.level === SubscriptionLevel.Enterprise)) {
                errorMessage = 'Valuation dates prior to <strong> 03/31/2015</strong> are limited to US only options.';
            }
        } else if (!isMod4 && !iniSubData) {
            errorMessage = 'Only  available to International Industry Benchmarking Subscribers';
        } else if (isMod4 && usiSubData) {
            if (usiSubData.level === SubscriptionLevel.Basic) {
                errorMessage = 'Only dates of <strong>12/31/2019</strong> and forward are available to US Industry Benchmarking Basic Subscribers';
            }
        } else if (!isMod4 && usiSubData) {
            if (usiSubData.level === SubscriptionLevel.Basic) {
                errorMessage = 'Only dates of <strong>12/31/2019</strong> and forward are available to Basic Subscribers';
            }
        }
        return errorMessage;

    }
    public isInBetweenDate(minDate: NgbDateStruct, maxDate: NgbDateStruct, valuationDateFieldData: FieldData): boolean {
        const start = (CommonDate.fromStruct(minDate)).asDate().setHours(0, 0, 0, 0);
        const end = CommonDate.fromStruct(maxDate).asDate().setHours(0, 0, 0, 0);
        let compare = new Date().setHours(0, 0, 0, 0);
        if (valuationDateFieldData && valuationDateFieldData.value) {
            const value = valuationDateFieldData.value;
            compare = new CommonDate(value.year, value.month, value.day).asDate().setHours(0, 0, 0, 0);
        }
        if (compare >= start && compare <= end) {
            return true;
        } else {
            return false;
        }
    }
}
