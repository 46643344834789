<div class="inputs-tab">
    <div class="common-inputs-content">
        <common-tab-header [headerText]="headers.getAccordionText('generalInputs')"></common-tab-header>
        <mat-accordion class="excel-input-tab">
            <mat-expansion-panel [@.disabled]="true" class="common-overflow-visible">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            General Inputs
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <common-tab-header [headerText]="headers.getAccordionText('generalInputsAccordion')"></common-tab-header>
                    <mat-divider></mat-divider>
                    <div class="input-wrapper">
                        <common-datepicker [config]="builder.valuationDate.config" [(model)]="builder.valuationDate">
                        </common-datepicker>
                        <common-output [(model)]="builder.homeCountry"></common-output>
                        <common-output [(model)]="builder.investeeCountry"></common-output>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="excel-input-tab">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            Size Inputs
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <common-tab-header [headerText]="headers.getAccordionText('sizeInputs')"></common-tab-header>
                    <mat-divider></mat-divider>
                    <div class="input-wrapper">
                        <common-output [(model)]="builder.marketCap"></common-output>
                        <common-output [(model)]="builder.bookValueOfEquity"></common-output>
                        <common-output [(model)]="builder.fiveYrAvgNetIncome"></common-output>
                        <common-output [(model)]="builder.MVIC"></common-output>
                        <common-output [(model)]="builder.totalAssets"></common-output>
                        <common-output [(model)]="builder.fiveYrAvgEBITDA"></common-output>
                        <common-output [(model)]="builder.sales"></common-output>
                        <common-output [(model)]="builder.numberOfEmployees"></common-output>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="excel-input-tab">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            Risk Study Inputs
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <common-tab-header [headerText]="headers.getAccordionText('riskStudyInputs')"></common-tab-header>
                    <mat-divider></mat-divider>
                    <common-tab-header [headerText]="headers.getAccordionText('operatingMargin')"></common-tab-header>
                    <mat-divider></mat-divider>
                    <div class="input-wrapper">
                        <div class="risk-study-input-title">
                            <strong>
                                Net Sales
                            </strong>
                        </div>
                        <div class="input-wrapper">
                            <common-output [(model)]="builder.netSalesYear0" [hideInfoIcon]="true"></common-output>
                            <common-output [(model)]="builder.netSalesYear1" [hideInfoIcon]="true"></common-output>
                            <common-output [(model)]="builder.netSalesYear2" [hideInfoIcon]="true"></common-output>
                            <common-output [(model)]="builder.netSalesYear3" [hideInfoIcon]="true"></common-output>
                            <common-output [(model)]="builder.netSalesYear4" [hideInfoIcon]="true"></common-output>
                        </div>
                        <div class="risk-study-input-title">
                            <strong>
                                Operating Income
                            </strong>
                        </div>
                        <div class="input-wrapper">
                            <common-output [(model)]="builder.operatingIncomeYear0" [hideInfoIcon]="true">
                            </common-output>
                            <common-output [(model)]="builder.operatingIncomeYear1" [hideInfoIcon]="true">
                            </common-output>
                            <common-output [(model)]="builder.operatingIncomeYear2" [hideInfoIcon]="true">
                            </common-output>
                            <common-output [(model)]="builder.operatingIncomeYear3" [hideInfoIcon]="true">
                            </common-output>
                            <common-output [(model)]="builder.operatingIncomeYear4" [hideInfoIcon]="true">
                            </common-output>
                        </div>
                        <div class="input-wrapper">
                            <common-output [(model)]="builder.operatingMargin"></common-output>
                            <common-output [(model)]="builder.CVOfOperatingMargin"></common-output>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <common-tab-header [headerText]="headers.getAccordionText('returnOnEquity')"></common-tab-header>
                    <mat-divider></mat-divider>
                    <div class="input-wrapper">
                        <div class="risk-study-input-title">
                            <strong>
                                Net Income
                            </strong>
                        </div>
                        <div class="input-wrapper">
                            <common-output [(model)]="builder.netIncomeYear0" [hideInfoIcon]="true"></common-output>
                            <common-output [(model)]="builder.netIncomeYear1" [hideInfoIcon]="true"></common-output>
                            <common-output [(model)]="builder.netIncomeYear2" [hideInfoIcon]="true"></common-output>
                            <common-output [(model)]="builder.netIncomeYear3" [hideInfoIcon]="true"></common-output>
                            <common-output [(model)]="builder.netIncomeYear4" [hideInfoIcon]="true"></common-output>
                        </div>
                        <div class="risk-study-input-title">
                            <strong>
                                Book Value of Equity
                            </strong>
                        </div>
                        <div class="input-wrapper">
                            <common-output [(model)]="builder.bookValueOfEquityYear0" [hideInfoIcon]="true">
                            </common-output>
                            <common-output [(model)]="builder.bookValueOfEquityYear1" [hideInfoIcon]="true">
                            </common-output>
                            <common-output [(model)]="builder.bookValueOfEquityYear2" [hideInfoIcon]="true">
                            </common-output>
                            <common-output [(model)]="builder.bookValueOfEquityYear3" [hideInfoIcon]="true">
                            </common-output>
                            <common-output [(model)]="builder.bookValueOfEquityYear4" [hideInfoIcon]="true">
                            </common-output>
                        </div>
                        <div class="input-wrapper">
                            <common-output [(model)]="builder.returnOnEquity" [hideInfoIcon]="true"></common-output>
                            <common-output [(model)]="builder.CVOfReturnOnEquity"></common-output>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="excel-input-tab">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            High Financial Risk (HFR) Study
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <common-tab-header [headerText]="headers.getAccordionText('zScore')"></common-tab-header>
                    <mat-divider></mat-divider>
                    <div class="input-wrapper">
                        <common-output [(model)]="builder.marketCap"></common-output>
                        <common-output [(model)]="builder.bookValueOfEquity"></common-output>
                        <common-output [(model)]="builder.totalAssets"></common-output>
                        <common-output [(model)]="builder.currentYearEBIT"></common-output>
                        <common-output [(model)]="builder.sales"></common-output>
                        <common-output [(model)]="builder.currentAssets"></common-output>
                        <common-output [(model)]="builder.currentLiabilities"></common-output>
                        <common-output [(model)]="builder.retainedEarnings"></common-output>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="z-score-component">
                        <div class="company-type">
                            <div>Company Type</div>
                            <common-radio-button [(model)]="builder.serviceCompanyType"></common-radio-button>
                            <common-radio-button [(model)]="builder.manufacturingCompanyType"></common-radio-button>
                        </div>
                        <common-output [(model)]="builder.ZScore"></common-output>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>