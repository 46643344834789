// Modules
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { NgModule } from "@angular/core";
import {
    MatDialogModule,
    MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { AuthenticationManager, ConcurrencyModule, NgbDateParserFactory } from "@concurrency/angular";
import { AADSettings, B2CSettings } from "@concurrency/authentication";
import { SignalRModule } from "@henkkelder/ng2-signalr";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { LoggerModule } from "ngx-logger";
import { AppComponent } from "src/app/app.component";
import { EstimateModule } from "src/app/estimate/estimate.module";
import { HomeModule } from "src/app/home/home.module";
import { CommonDialogInfoComponent } from "src/app/_add-in/common/dialog/common-dialog-info.component";
import { CommonDialogInputComponent } from "src/app/_add-in/common/dialog/common-dialog-input.component";
import {
    aadSettings,
    b2cSettings,
} from "src/app/_config/authentication.configuration";
import { CommonModules } from "src/app/_config/common-modules";
import { routeConfig } from "src/app/_config/route.configuration";
import { signalRConfig } from "src/app/_config/signalr.configuration";
import { environment } from "src/environments/environment";
import { BenchmarkingModule } from "./benchmarking/benchmarking.module";
import { IntlCostOfCapitalModule } from "./intl-cost-of-capital/intl-cost-of-capital.module";
import { ValidateSubscriptionComponent } from "./home/validate-subscription.component";
import { ExpirationModalComponent } from "./home/_modals/expiration-modal.component";
import { ApiClients } from "./_config/api-clients";
import { CompanyListNoIndustryModalComponent } from "./home/_modals/company-list-no-industry-modal.component";
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from "@angular/common/http";


@NgModule({
    declarations: [
        AppComponent,
        CommonDialogInfoComponent,
        CommonDialogInputComponent,
        ExpirationModalComponent,
        CompanyListNoIndustryModalComponent,
        ValidateSubscriptionComponent,
    ],
    imports: [
        RouterModule.forRoot(routeConfig, { useHash: true }),
        SignalRModule.forRoot(signalRConfig),
        LoggerModule.forRoot({ level: environment.loggerLevel }),
        ConcurrencyModule.forRoot(environment),
        HomeModule,
        BenchmarkingModule,
        EstimateModule,
        IntlCostOfCapitalModule,
        MatDialogModule,
        ...CommonModules,
        AuthModule.forRoot(environment.auth0),
    ],
    providers: [
        ...ApiClients,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        // TODO: Properly define this somewhere else
        {
            provide: NgbDateParserFormatter,
            useFactory: NgbDateParserFactory,
        },
        {
            provide: AADSettings,
            useValue: aadSettings,
        },
        {
            provide: B2CSettings,
            useValue: b2cSettings,
        },
        {
            provide: AuthenticationManager,
            deps: [AADSettings, B2CSettings],
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: false,
            },
        },
        { provide: HTTP_INTERCEPTORS, 
          useClass: AuthHttpInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
