import { Injectable } from '@angular/core';
import { BetaCustomContext } from 'src/app/_add-in/beta/beta-custom-context';
import { ValueType } from 'src/app/_add-in/data/enums/value-type';
import { FieldData } from 'src/app/_add-in/data/field-data';
import { FieldService } from 'src/app/_add-in/data/field.service';
import { InputBuilder } from 'src/app/_add-in/data/input.builder';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';

@Injectable()
export class BetaService {

    constructor(
        private fieldService: FieldService,
        private excelStorageService: ExcelStorageService,
        private inputBuilder: InputBuilder
    ) { }

    public getIRPArgument(valueType: ValueType, betaGroup: number): FieldData {
        const key = Object.keys(this.inputBuilder).filter(
            (x) => this.inputBuilder[x].betaGroup === betaGroup && this.inputBuilder[x].valueType === valueType
        );

        return this.inputBuilder[key.toString()];
    }

    public updateIndustryRiskPremium(isReset: boolean, betaGroup: number): void {
        const industryRiskPremium: FieldData = this.getIRPArgument(ValueType.IndustryRiskPremium, betaGroup);

        if (isReset) {
            this.fieldService.resetInput(industryRiskPremium, this.excelStorageService);
        } else {
            const beta: FieldData = this.getIRPArgument(ValueType.Beta, betaGroup);
            const erp: FieldData = this.getIRPArgument(ValueType.EquityRiskPremium, betaGroup);
            this.fieldService.updateIndustryRiskPremium(beta, erp, industryRiskPremium, this.excelStorageService);
        }
    }

    public bindToCell(model: FieldData): void {
        this.excelStorageService.setCellValue(model);
    }

    public bindIndustry(model: FieldData): void{
        this.excelStorageService.getCellValue(model);
    }

    public saveModel(model: FieldData, value: any, source: string, dataPoint: string, dataAsOf: string): void {
        model.value = value;

        if (model.valueType === ValueType.Beta) {
            model.context = source ? source : BetaCustomContext.BetaContext;
            model.dataPoint = dataPoint ? dataPoint : BetaCustomContext.BetaContext;
            model.dataAsOf = dataAsOf ? dataAsOf : BetaCustomContext.NotApplicable;
        }

        if (model.valueType === ValueType.EquityRiskPremium) {
            model.context = source ? source : BetaCustomContext.ERPContext;
        }

        this.excelStorageService.saveToEstimate(model);
    }

}
