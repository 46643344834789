
export interface BetaCompanyData {
    DataAsOf: string;
    Companies: BetaCompany[];
}

export interface BetaCompany {
    Name: string;
    SalesUsd: number;
    SalesEur: number;
    SalesGbp: number;
    AssetsUsd: number;
    AssetsEur: number;
    AssetsGbp: number;
    Type: BetaCompanyType;
}
export class BetaCompanyTypeName {
    public static readonly default = 'Vasicek/Sum Beta';
    public static readonly fullInformation = 'Full-information Beta';
    public static readonly highFinancialRisk = 'HFR Sum Beta';
}

export enum BetaCompanyType {
    Default = 'Default',
    fullInformation = 'FullInformation',
    HighFinancialRisk = 'HighFinancialRisk'
}

export interface BetaCompanyListItem {
    companyType: BetaCompanyType;
    companyTypeName: BetaCompanyTypeName;
}

export interface FechedCompanyData {
    Data: BetaCompanyData;
    CompanyType: BetaCompanyType;
}
export class BetaCompanyList {
    public static readonly betaCompanyList: BetaCompanyListItem[] = [
        {
            companyType: BetaCompanyType.fullInformation,
            companyTypeName: BetaCompanyTypeName.fullInformation
        },
        {
            companyType: BetaCompanyType.Default,
            companyTypeName: BetaCompanyTypeName.default
        },
        {
            companyType: BetaCompanyType.HighFinancialRisk,
            companyTypeName: BetaCompanyTypeName.highFinancialRisk
        }
    ];
    public static FindBetaCompanyType(type: BetaCompanyType): BetaCompanyTypeName {
        const item: BetaCompanyListItem | undefined = BetaCompanyList.betaCompanyList.find((x) => x.companyType === type);

        if (item == null) {
            return BetaCompanyTypeName.default;
        }

        return item.companyTypeName;
    }
}
