import { Component, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CommonUserInputComponent } from "src/app/_add-in/common/inputs/common-user-input.component";
import { FieldType } from "src/app/_add-in/data/enums/field-type";
import { FieldService } from "src/app/_add-in/data/field.service";
import { ExcelStorageService } from "src/app/_add-in/excel/excel-storage.service";
import { FieldData } from "../../data/field-data";

@Component({
    selector: "common-output",
    templateUrl: "./common-output.component.html",
})
export class CommonOutputComponent
    extends CommonUserInputComponent
    implements OnChanges
{
    constructor(
        public dialog: MatDialog,
        private fieldService: FieldService,
        private excelStorageService: ExcelStorageService
    ) {
        super(excelStorageService);
    }
    @Input() public hideInfoIcon?: boolean;
    @Input() public model!: FieldData;
    public fieldTypeInput = FieldType.Input;
    public fieldTypeOutput = FieldType.Output;
    public exrange: any;
    public reference: any;
    public worksheet: any;

    public onImportCellValue(): void {
        this.excelStorageService.getCellValue(this.model);
    }

    public onBindToCell(): void {
        this.excelStorageService.setCellValue(this.model);
    }

    public debounce(): void {
        if (this.model.fieldType === FieldType.Input) {
            if (this.invalidValue == null && this.model.value) {
                this.fieldService.updateModel(
                    this.model,
                    this.excelStorageService
                );
            } else {
                this.fieldService.resetRelatedOutputs(
                    this.model,
                    this.excelStorageService
                );
            }
        }
    }
    public ngOnChanges(): void {
        if (this.model.reference === "") {
            if (this.model.config) {
                this.model.config.disabled = false;
            }
        }
    }
}
