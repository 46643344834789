import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ConcurrencyAuthenticator } from '@concurrency/authentication';
import '@concurrency/core';
import { AppInsights } from 'applicationinsights-js';
import { aadSettings, b2cSettings } from 'src/app/_config/authentication.configuration';
import { AppInjector } from 'src/app/app-injector';
import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';
const windowIdentity = ConcurrencyAuthenticator.initializeWindow(b2cSettings, aadSettings);

if (windowIdentity === 'MainWindow') {
    Office.onReady(() => {
        if (Office.context.platform !== Office.PlatformType.OfficeOnline) {
            if (window.location.toString().indexOf('_host_Info') > 0) {
                setStorage();
            }
        } else if (Office.context.platform === Office.PlatformType.OfficeOnline) {
            if (window.location.toString() === `${window.location.origin}/?et=#benchmarking`
                || window.location.toString() === `${window.location.origin}/?et=#intlCoC`
                || window.location.toString() === `${window.location.origin}/?et=#intlBenchmarking`
                || window.location.toString() === `${window.location.origin}/?et=#employee/benchmarking`
                || window.location.toString() === `${window.location.origin}/?et=#employee/intlCoC`
                || window.location.toString() === `${window.location.origin}/?et=#employee/intlBenchmarking`
                || `${window.location.origin}/?et=` === window.location.toString()) {
                setStorage();
            }
        }

        bootstrapApp();
    });
} else {
    // TODO: Display progress indicator and/or Timeout after some duration
}
function setStorage(): void {
    sessionStorage.setItem('navigateLocation', window.location.toString());
}
function bootstrapApp(): void {
    enableProdMode();

    if (environment.applicationInsights && window.location.origin !== 'https://localhost:3000') {
        if (AppInsights.downloadAndSetup) {
            AppInsights.downloadAndSetup({ instrumentationKey: environment.applicationInsights.key });
        } else {
            AppInsights.config.instrumentationKey = environment.applicationInsights.key;
        }
    }

    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then((moduleRef) => {
            AppInjector.setInjector(moduleRef.injector);
        })
        .catch((err) => {
            console.error(err);
        });
}
