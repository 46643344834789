<div class="landing-component">
    <div class="nav-main">
        <div class="logo">
            <a href="/">
                <img src="assets/images/kroll-logo.png" alt="Kroll" />
            </a>
        </div>
        <div>
            <h4 class="nav-heading">
                Cost of Capital Navigator - Excel Add-in
            </h4>
            <p class="description">
                This powerful new tool enables Cost of Capital Navigator users to import Kroll cost of capital
                data directly into their own Excel spreadsheets and templates.
            </p>
        </div>
        <div class="login-btn">
            <button class="login-button" (click)="loginUser()">Login</button>
        </div>

        <div class="nav-description">
            <div>
                Access the Cost of Capital Navigator – Excel Add-in when you
            </div>
            <div>
                <a class="purchase-link" target="_blank" href="https://kroll.com/costofcapitalnavigator">subscribe to the Cost of
                    Capital Navigator.
                </a>
            </div>
        </div>
        <div class="nav-description">
            <div class="nav-description">
                <div>
                    <a class="purchase-link" target="_blank"
                        href="https://vasdc8grscoc.blob.core.windows.net/files/Navigator_Add-in_TechFAQ.pdf">
                        Get Support
                    </a>
                    <span>|</span>
                    <a class="nav-footer-link" target="_blank" href="http://www.kroll.com">
                        About Kroll
                    </a>
                </div>
            </div>
            <div class="nav-footer">
                <div class="nav-footer-section">
                    <div>
                        <a class="nav-footer-link" target="_blank" href="https://www.kroll.com/en/terms-of-use">
                            Legal
                        </a>
                        <span>|</span>
                        <a class="nav-footer-link" target="_blank" href="https://www.kroll.com/en/privacy-policy">
                            Privacy
                        </a>
                        <span>|</span>
                        <a class="nav-footer-link" target="_blank" href="https://www.kroll.com/en/cookies-policy">
                            Cookies
                        </a>
                        <span>|</span>
                        <a class="nav-footer-link" target="_blank" href="https://www.kroll.com/en/disclosure">
                            Disclosures
                        </a>
                        <span>|</span>
                        <a class="nav-footer-link" target="_blank"
                            href="https://vasdc8grscoc.blob.core.windows.net/files/terms.v042020.html">
                            Terms &amp; Conditions
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>