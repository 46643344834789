import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { MenuItem } from 'src/app/_add-in/menu/menu-item.model';
import { MenuUtility } from 'src/app/_add-in/menu/menu.utility';

import { UserStore } from '../_add-in/user/store/user.store';
import { SubscriptionType } from '../_api/responses/user.response';

@Component({
    templateUrl: './home.component.html'
})
export class HomeComponent extends SubscriberEntity implements OnInit {
    @ViewChild('nav') public input!: ElementRef;
    public isTrial = false;
    public mainMenu: MenuItem[] = [];
    public menuItems: MenuItem[] = MenuUtility.menu;
    public intlCOCMenuItems: MenuItem[] = MenuUtility.intlCoCMenu;
    public benchMarkingMenuItems: MenuItem[] = MenuUtility.benchmarkingMenu;
    public currentRoute: string;
    public firstModule: string | undefined;

    constructor(private userStore: UserStore) {
        super();
        this.currentRoute = this.getCurrentRouteName();
    }

    public closeNav(): void {
        if (this.input.nativeElement.getAttribute('aria-expanded') === 'true') {
            this.input.nativeElement.click();
        }
    }

    public ngOnInit(): void {
        this.userStore.user.onceDefined((profile) => {
            if (profile == null) {
                return;
            }
            this.isTrial = (profile.Subscriptions[0].Type === SubscriptionType.CocTrial
                || profile.Subscriptions[0].Type === SubscriptionType.UsiTrial
                || profile.Subscriptions[0].Type === SubscriptionType.IneTrial);
        });
    }

    public logoutUser(): void {
        this.userStore.logoutCustomerOrEmployee();
    }

    public getCurrentRouteName(): string {
        this.firstModule = undefined;
        const currentRoute = this.menuItems.find((x) => x.route === this.userStore.getCurrentRoute());
        const intlCOCCurrentRoute = this.intlCOCMenuItems.find((x) => x.route === this.userStore.getCurrentRoute());
        const benchMarkingCurrentRoute = this.benchMarkingMenuItems.find((x) => x.route === this.userStore.getCurrentRoute());
         
        if(currentRoute){
            this.mainMenu = this.menuItems;
        return currentRoute.name;
        }
        if(intlCOCCurrentRoute){
            this.mainMenu = this.intlCOCMenuItems;
            return intlCOCCurrentRoute.name;
        }
        if(benchMarkingCurrentRoute){
            this.mainMenu = this.benchMarkingMenuItems;
            return benchMarkingCurrentRoute.name;
        }
        return ''
    }

    public onNavigate(route: string): void {
        this.currentRoute = route;
        this.closeNav();
    }

}
