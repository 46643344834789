import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { AuthenticationManager, Spinner } from "@concurrency/angular";
import { Util } from "@concurrency/core";
import { NGXLogger } from "ngx-logger";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { UserClient } from "src/app/_api/clients/user.client";
import { ValuesClient } from "src/app/_api/clients/values.client";
import {
    UserActivityLogType,
    UserActivityRequest,
    UserResponse,
} from "src/app/_api/responses/user.response";
import { environment } from "src/environments/environment";
import { AccessType } from "../enum/access-type";
import { UserType } from "../enum/user-type";
import { User } from "../model/user.model";

declare let pendo: any;

@Injectable()
export class UserStore {
    private _access = new BehaviorSubject<AccessType | undefined>(undefined);
    private _user = new BehaviorSubject<UserResponse | undefined>(undefined);

    private _lastLoginType = new BehaviorSubject<UserType | undefined>(
        undefined
    );
    public access=this._access.asObservable();
    public isuserVerfied!:boolean|undefined
    public user = this._user
        .asObservable()
        .pipe(map((x) => (x == null ? undefined : new User(x))));

    public lastLoginType = this._lastLoginType.asObservable();

    constructor(
        private logger: NGXLogger,
        private router: Router,
        private authenticationManager: AuthenticationManager,
        
        private spinner: Spinner,
        private userProfileClient: UserClient,
        public dialog: MatDialog,
        public valuesClient: ValuesClient,
        public auth: AuthService
    ) {
        // TODO: We dont actually need to reload the whole page
    }

    // public inititializeAuthentication(): void {
    //     if (sessionStorage.getItem("ccy.b2c.id_token") === null) {
    //         sessionStorage.setItem("userActivityFlag", "0");
    //     }
    //     this.authenticationManager.authentication.whileDefined(
    //         (authentication) => {
    //             this.findUser(authentication);
    //             if (authentication.previous === AuthenticationType.AAD) {
    //                 this._lastLoginType.next(UserType.Employee);
    //             } else if (authentication.previous === AuthenticationType.B2C) {
    //                 this._lastLoginType.next(UserType.Commercial);
    //             }
    //         }
    //     );
    // }

    public findUser(): void {
        //this.logger.info(`User Authentication is: ${authentication.current}`);

            const profileRequest = this.userProfileClient.read();
            // this.spinner.while(profileRequest);
            this.spinner.begin();

            profileRequest.onceDefined((profile) => {
                this.isuserVerfied=profile.email_verified;

               
                    this.spinner.end();
                    // pendo.initialize({
                    //     visitor: {
                    //         id: profile.Email,
                    //         roles: profile.Roles,
                    //         featureNames:
                    //             profile.FeatureName.length > 0
                    //                 ? profile.FeatureName
                    //                 : [],
                    //         featuresExpiration:
                    //             profile.FeaturesWithExpiration.length > 0
                    //                 ? profile.FeaturesWithExpiration.map(
                    //                       (x) => x.Expiration
                    //                   )
                    //                 : [],
                    //         userLimit:
                    //             profile.FeaturesWithExpiration?.length > 0
                    //                 ? profile.FeaturesWithExpiration.map(
                    //                       (x) => x.UserLimit
                    //                   )
                    //                 : [],
                    //     },
                    //     account: {
                    //         id: profile.CustomerName,
                    //         partner: profile.PartnerName,
                    //         country:
                    //             profile.Country
                    //                 ? profile.Country
                    //                 : "",
                    //     },
                    // });

                    this._user.next(profile);
                    // var token = this.auth.getAccessTokenSilently()
                    // token.onceDefined((tkn) => {
                    //     this.notificationService.start(tkn, profile.Email);
                    // })
        
                    if ((sessionStorage.getItem('userActivityFlag') === '0')) {
                        sessionStorage.setItem('userActivityFlag', '1');
                        const activity: UserActivityRequest = {
                            Id: null,
                            userActivityType: UserActivityLogType.SignIn
                        };
                        this.userProfileClient.userActivityLog(activity).onceDefined((x) => {
                            sessionStorage.setItem('userActivityId', x);
                        });
                    }
                    const isAuthorized = profile.Subscriptions[0] != null || profile.Subscriptions[0] === 'None';
                    this._access.next(isAuthorized ? AccessType.Authorized : AccessType.Authenticated);
                    //  this.router.navigate(['']);
                    this.reDirectModuleURL();


                
            });

            //     this.authenticationManager.getToken();
            //     if (sessionStorage.getItem("userActivityFlag") === "0") {
            //         sessionStorage.setItem("userActivityFlag", "1");
            //         this.userProfileClient.userActivityLog(
            //             UserActivityLogType.SignIn
            //         );
            //     }
            //     this._user.next(profile);
            //     const isAuthorized =
            //         profile.Subscriptions[0] != null &&
            //         new Date(profile.Subscriptions[0].End) > new Date();
            //     this.access = isAuthorized
            //         ? AccessType.Authorized
            //         : AccessType.Authenticated;
            //     this.reDirectModuleURL();
            // });


        //     this.logger.info(`User Authentication is: ${authentication.current}`);

        // if (authentication.current === AuthenticationType.None) {
        //     this._access.next(AccessType.Anonymous);
        // } else {
        //     const profileRequest = this.userProfileClient.read();
        //     this.spinner.while(profileRequest);

        //     profileRequest.onceDefined((profile) => {
        //         if (profile.CustomerName) {
        //             pendo.initialize({
        //                 visitor: {
        //                     id: profile.Email,
        //                     roles: profile.Roles,
        //                     featureNames:
        //                         profile.FeatureName.length > 0
        //                             ? profile.FeatureName
        //                             : [],
        //                     featuresExpiration:
        //                         profile.FeaturesWithExpiration.length > 0
        //                             ? profile.FeaturesWithExpiration.map(
        //                                   (x) => x.Expiration
        //                               )
        //                             : [],
        //                     userLimit:
        //                         profile.FeaturesWithExpiration.length > 0
        //                             ? profile.FeaturesWithExpiration.map(
        //                                   (x) => x.UserLimit
        //                               )
        //                             : [],
        //                 },
        //                 account: {
        //                     id: profile.CustomerName,
        //                     partner: profile.PartnerName,
        //                     country:
        //                         profile.Country.length > 0
        //                             ? profile.Country[0]
        //                             : "",
        //                 },
        //             });
        //         }

        //         this.authenticationManager.getToken();
        //         if (sessionStorage.getItem("userActivityFlag") === "0") {
        //             sessionStorage.setItem("userActivityFlag", "1");
        //             this.userProfileClient.userActivityLog(
        //                 UserActivityLogType.SignIn
        //             );
        //         }
        //         this._user.next(profile);
        //         const isAuthorized =
        //             profile.Subscriptions[0] != null &&
        //             new Date(profile.Subscriptions[0].End) > new Date();
        //         this.access = isAuthorized
        //             ? AccessType.Authorized
        //             : AccessType.Authenticated;
        //         this.reDirectModuleURL();
        //     });
        // }
        
    }

    public loginEmployee(): void {
        // this.authenticationManager.loginAAD();
        
        this.auth.loginWithRedirect({authorizationParams:{showSignup: 'no',}});
    }

    public signupCustomer(email?: string): void {
        if (email == null) {
            return;
        }

        // this.authenticationManager.signupB2C({ email });
        this.auth.loginWithRedirect({authorizationParams:{showSignup: 'no',}});
    }

    public loginCustomer(): void {
        // this.authenticationManager.loginB2C();
        this.auth.loginWithRedirect({authorizationParams:{showSignup: 'no',}});
    }

    public async logoutCustomerOrEmployee(): Promise<void> {
        // this.spinner.begin();
        // if (sessionStorage.getItem("userActivityFlag") === "1") {
        //     this.userProfileClient
        //         .userActivityLog(UserActivityLogType.SignOut)
        //         .onceDefined(() => {
        //             this.logout();
        //         });
        // } else {
        //     this.logout();
        // }
        // this.logout();

        this.spinner.begin();
        // this.auth.logout();
        if ((sessionStorage.getItem('userActivityFlag') === '1')) {
            const activity: UserActivityRequest = {
                Id: null,
                userActivityType: UserActivityLogType.SignOut
            };
            if (sessionStorage.getItem('userActivityId')) {
                activity.Id = sessionStorage.getItem('userActivityId');
            }
            this.userProfileClient.userActivityLog(activity).onceDefined(() => {
                // this.notificationService.stop();
                // this.authenticationManager.forget();
                //this.authenticationManager.logoutB2C();
                const logout = this.auth.logout();
                this.spinner.while(logout);
                window.location.reload();

                // this.router.navigate(['']);
                // this.auth.loginWithRedirect();
            }, () => {
                this.spinner.end(); 
            });
        } else {
            // this.notificationService.stop();
            // this.authenticationManager.forget();
            // this.authenticationManager.logoutB2C();

            let currentHost=window.location.origin+'/#/landing';
            let landingPage = `https://${environment.auth0.domain}/v2/logout?client_id=${encodeURIComponent(environment.auth0.clientId)}&returnTo=${encodeURIComponent(
            currentHost)}`;
            window.location.href=landingPage;


  

            // const logout = this.auth.logout({re(url) {
            //     window.location.replace(landurl)
            // },})
            // const logout = this.auth.logout();
            // this.spinner.while(logout);
             //window.location.reload();
            //this.auth.loginWithRedirect();
            // this.auth.loginWithRedirect();
        }
    }
    // private logout(): void {
    //     this.authenticationManager.forget();
    //     this.authenticationManager.logoutB2C().subscribe(() => {
    //         setTimeout(() => {
    //             window.location.reload();
    //             this.spinner.end();
    //         }, 1000);
    //     });
    // }
    public getCurrentRoute(): string {
        return this.router.url;
    }

    public reDirectModuleURL(): void {
        this.user.once((user) => {
            if (!(user == null || user.hasExpired() === true)) {  
                this.router.navigate(["/validate-subscription"]);
            } else {
                const navigateLocation =
                    sessionStorage.getItem("navigateLocation");
                console.log(navigateLocation);
                if (!Util.isNullOrEmpty(navigateLocation)) {
                    if (
                        navigateLocation &&
                        navigateLocation.search("benchmarking") >= 0
                    ) {
                        this.router.navigate(["/benchmarking"]);
                    } else if (
                        navigateLocation &&
                        navigateLocation.search("intlCoC") >= 0
                    ) {
                        this.router.navigate(["/intlCoC"]);
                    } else if (
                        navigateLocation &&
                        navigateLocation.search("intlBenchmarking") >= 0
                    ) {
                        this.router.navigate(["/intlBenchmarking"]);
                    } else {
                        this.router.navigate([""]);
                    }
                } else {
                    this.router.navigate([""]);
                }
            }
        });
    }
}