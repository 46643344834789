import { AddInClient } from '../_api/clients/addin.client';
import { CostofEquityClient } from '../_api/clients/costofEquity.client';
import { CountryClient } from '../_api/clients/country.client';
import { CurrencyClient } from '../_api/clients/currency.client';
import { IndustryClient } from '../_api/clients/industry.client';
import { IntlBetaClient } from '../_api/clients/intlBeta.client';
import { IntlErpClient } from '../_api/clients/intlErp.client';
import { IntlIndustryClient } from '../_api/clients/intlIndustry.client';
import { IntlRfClient } from '../_api/clients/intlRf.client';
import { RegionClient } from '../_api/clients/region.client';
import { RiskClient } from '../_api/clients/risk.client';
import { USCompanyClient } from '../_api/clients/uscompany.client';
import { UserClient } from '../_api/clients/user.client';
import { USIndustryClient } from '../_api/clients/usindustry.client';
import { ValuesClient } from '../_api/clients/values.client';

export const ApiClients = [
    AddInClient,
    RiskClient,
    USIndustryClient,
    UserClient,
    RegionClient,
    CurrencyClient,
    IndustryClient,
    ValuesClient,
    USCompanyClient,
    CountryClient,
    IntlIndustryClient,
    IntlErpClient,
    IntlRfClient,
    IntlBetaClient,
    CostofEquityClient
];
