<div>
    <div class="common-inputs-content">
        <mat-accordion class="excel-input-tab">
            <mat-expansion-panel [@.disabled]="true" class="common-overflow-visible">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            INPUTS
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content benchmarking">
                    <div>
                        <common-tab-header [headerText]="header"></common-tab-header>
                        <mat-divider></mat-divider>
                    </div>
                    <div>
                        <div>
                            <common-datepicker [config]="builder.benchMarkingValuationDate.config"
                                [(model)]="builder.benchMarkingValuationDate"
                                (modelChange)="reflectDate(builder.benchMarkingValuationDate.value)">
                            </common-datepicker>
                        </div>

                        <div>
                            <div *ngIf="industryService.noResults" class="common-input-error benchmarking-error">
                                No results found under valuation date <span
                                    class="bold">{{(builder.benchMarkingValuationDate.value|
                                    formatDateStruct)}}</span>
                                for region <span class="bold">{{builder.benchMarkingRegion.value}}</span> and
                                currency
                                <span class="bold">{{builder.benchMarkingCurrency.value}}</span>

                                <div class="benchmarking-margin-top">
                                    Please select a valid valuation date for this region/currency.
                                </div>
                            </div>
                            <div *ngIf="errorMessages.length>0" class="common-input-error benchmarking-error">
                                <div *ngFor="let error of errorMessages">
                                    <span [innerHTML]="error"> </span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div>
                        <div class="common-input-name common-input-name-stack" style="margin-top: 12px;">Selected Industries:
        
                            <span style="display: inline;">
                                <label class="singleDisplay" *ngFor="let sectedIndustry of lstSelectedIndustries let i=index">
                                    {{trendsOverTimeService.createIndustryFilterLabel(sectedIndustry.SicIndustryCode,
                                    sectedIndustry.GicIndustryCode)}} - {{sectedIndustry.Sector}}<br>
                                    {{sectedIndustry.Area}} - {{sectedIndustry.CurrencyCode}}
                                    &nbsp;
                                    <span aria-hidden="true" id="sectedIndustry.CodeId"
                                    (click)="removeIndustry(i,false)"><b class="remove-btn">×</b></span>
                                </label>
                            </span>
                           
    
                            <span calss="col-6 row p-1 text-wrap">
                                <i (click)="((!builder.benchMarkingValuationDate.value)||lstSelectedIndustries.length==5)?invalidClick():SelectIndustries()"
                                    class="ms-Icon ms-Icon--CirclePlus plus"
                                    [ngClass]="{'plusgray':((!builder.benchMarkingValuationDate.value)||lstSelectedIndustries.length==5),'plus':builder.benchMarkingValuationDate.value}"></i>
                            </span>
                        </div>
                        
                    </div>
          

                    
                    <!-- <div *ngIf="_showScenario | async">
                        <div class="scenario">
                            <div>
                                <common-combobox [(model)]="builder.benchMarkingRegion"
                                    [comboboxData]="regionComboOptions"
                                    (modelChange)="handleRegionChange(builder.benchMarkingRegion)">
                                </common-combobox>
                            </div>
                            <div>
                                <common-combobox [(model)]="builder.benchMarkingCurrency"
                                    [comboboxData]="currencyOptions" (modelChange)="validateIndustryInput()">
                                </common-combobox>
                            </div>
                            <div>
                                <common-typeahead [(model)]="builder.benchMarkingIndustry"
                                    [typeahead]="industryService.searchBenchmarkingIndustries"
                                    [typeaheadFormatter]="industryService.industryTypeaheadFormatter"
                                    typeaheadValue="{{typeHeadIndustryCode()}}" (focus)="industryInputFocus()">
                                </common-typeahead>
                            </div>
                        </div>
                        <div class="view-list">
                            <a (click)="showCompanies()"> View Company List</a><span> | </span><a
                                (click)="showIndustries()">View Industry List
                            </a>
                        </div>
                    </div> -->

                    <p-dialog [(visible)]="isIndustryDisplay" [modal]="true" [responsive]="true"
                        [style]="{width: '96%', minWidth: '200px', margin: 'auto',height:'93%'}" [maximizable]="true"
                        [baseZIndex]="10000" [header]="'Select an Industry (up to 5)'" (onHide)="close('close')">
                        <div class="paddingIndustry">
                            <!-- <div class="scenario"> -->


                            <div class="row Industryies" style="margin-top:-20px ;min-height: 56px;">

                                <div class="drpdownscntainer fstdrpdown">
                                    <common-combobox  [(model)]="builder.benchMarkingRegion"
                                        [comboboxData]="regionComboOptions"
                                        (modelChange)="handleRegionChange(builder.benchMarkingRegion)">
                                    </common-combobox>
                                </div>
                                <div class="drpdownscntainer secondrop">
                                    <common-combobox [(model)]="builder.benchMarkingCurrency"
                                        [comboboxData]="currencyOptions" (modelChange)="validateIndustryInput()">
                                    </common-combobox>
                                </div>

                            </div>

                            <div class="searchrow row">


                                <input type="text" [(ngModel)]="searchValue"  name="typeahead" class="common-input serach-input"
                                    placeholder="Search By Industry Code or Sector Name" (ngModelChange)="onIndustrySearch($event)" />


                            </div>

                            <div class="selectIndustries row">
                                <label class="labelIndustry">Industry Selection:</label>
                                <button class="singleDisplay indulablels i col-3" *ngFor="let userSelection of lstSelectedIndustries let i=index">
                                    {{trendsOverTimeService.createIndustryFilterLabel(userSelection.SicIndustryCode,
                                    userSelection.GicIndustryCode)}} - {{userSelection.Sector}}<br>
                                    {{userSelection.Area}} - {{userSelection.CurrencyCode}}
                                    &nbsp;
                                    <span aria-hidden="true" (click)="removeIndustry(i,true)"
                                        id="userSelection.CodeId"><b class="remove-btn">×</b></span>
                                </button>
                            </div>

                            <!-- <div class="row mt-2"> -->


                                <div *ngIf="lstIndustries.length > 0" class="row mt-2 Industriescls">
                                    <table class="table table-striped">
                                        <tbody>
                                            <tr *ngFor="let industry of lstIndustries">
                                                <td>
                                                    <input type="checkbox" class="checkmark"
                                                        (change)="checkCheckBoxvalue($event,industry)"
                                                        id="{{industry.CodeId}}"
                                                        [checked]="industry.isSelected"
                                                        [(ngModel)]="industry.isSelected"
                                                        [disabled]="industry.isDisabled">

                                                     

                                                    &nbsp;&nbsp;&nbsp;
                                                    {{trendsOverTimeService.createIndustryFilterLabel(industry.SicIndustryCode,
                                                    industry.GicIndustryCode)}} - {{industry.Sector}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>



                            <!-- </div> -->

                            <!-- <div> -->
                                


                                <!-- <button type="submit" class="buttonPosition" [disabled]="isDisableSubmit()"
                                    (click)="addIndustries(userSelectedIndustries)">
                                    Add Industries
                                </button> -->
                                <p-footer class="row btnfooter">
                                    <div>
                                    <!-- <button type="submit" class="buttonPosition" [disabled]="isDisableSubmit()"
                                        (click)="addIndustries(userSelectedIndustries)">
                                        Add Industries
                                    </button> -->
        
                                    <button [disabled]="lstSelectedIndustries.length==0" class="mlauto btnstyle" (click)="addIndustries()">
                                        Add Industries
                                    </button>
                                   
        
                                    <label class="labelPosition btnstyle mlauto"><b>{{lstIndustries.length}}  Results </b>
                                    </label>
                                </div>
                                </p-footer>
                            <!-- </div> -->



                        
                    </p-dialog>

                    <!-- <button (click)="SelectIndustries()">Add</button> -->

                    <div>
                        <div class="export-to-excel">
                            <button (click)="onExportToExcel()" [disabled]="lstSelectedIndustries.length==0">
                                Export to Excel
                            </button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>