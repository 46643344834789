export enum InputName {
    ValuationDate = 'valuationDate',
    HomeCountry = 'homeCountry',
    InvesteeCountry = 'investeeCountry',
    MarketValueOfCommonEquity = 'marketCap',
    BookValueOfEquity = 'bookValueOfEquity',
    FiveYearAverageNetIncome = 'fiveYrAvgNetIncome',
    MarketValueOfInvestedCapital = 'MVIC',
    TotalAssets = 'totalAssets',
    FiveYearAverageEBITDA = 'fiveYrAvgEBITDA',
    Sales = 'sales',
    NumberOfEmployees = 'numberOfEmployees',
    DebtToEquity = 'debtToEquity',
    NetSalesYear0 = 'netSalesYear0',
    NetSalesYear1 = 'netSalesYear1',
    NetSalesYear2 = 'netSalesYear2',
    NetSalesYear3 = 'netSalesYear3',
    NetSalesYear4 = 'netSalesYear4',
    OperatingIncomeYear0 = 'operatingIncomeYear0',
    OperatingIncomeYear1 = 'operatingIncomeYear1',
    OperatingIncomeYear2 = 'operatingIncomeYear2',
    OperatingIncomeYear3 = 'operatingIncomeYear3',
    OperatingIncomeYear4 = 'operatingIncomeYear4',
    OperatingMargin = 'operatingMargin',
    CVOfOperatingMargin = 'CVOfOperatingMargin',
    NetIncomeYear0 = 'netIncomeYear0',
    NetIncomeYear1 = 'netIncomeYear1',
    NetIncomeYear2 = 'netIncomeYear2',
    NetIncomeYear3 = 'netIncomeYear3',
    NetIncomeYear4 = 'netIncomeYear4',
    BookValueOfEquityYear0 = 'bookValueOfEquityYear0',
    BookValueOfEquityYear1 = 'bookValueOfEquityYear1',
    BookValueOfEquityYear2 = 'bookValueOfEquityYear2',
    BookValueOfEquityYear3 = 'bookValueOfEquityYear3',
    BookValueOfEquityYear4 = 'bookValueOfEquityYear4',
    ReturnOnEquity = 'returnOnEquity',
    CVOfReturnOnEquity = 'CVOfReturnOnEquity',
    CurrentYearEBIT = 'currentYearEBIT',
    CurrentAssets = 'currentAssets',
    CurrentLiabilities = 'currentLiabilities',
    RetainedEarnings = 'retainedEarnings',
    ZScore = 'ZScore',
    Spot20YearTreasury = 'spot20YearTreasury',
    Spot10YearTreasury = 'spot10YearTreasury',
    Spot30YearTreasury = 'spot30YearTreasury',
    NormalizedRiskFreeRate = 'normalizedRiskFreeRate',
    ErpDnPRecommended = 'DNPRecommended',
    ErpHistoricalLongTerm = 'historicalLongTerm',
    ErpSupplySideLongTerm = 'supplySideLongTerm',
    ErpRiskPremiumReport = 'riskPremiumReport',
    ErpAdjustmentDnPRecommended = 'DNPRecommendedAdjusted',
    ErpAdjustmentHistoricalLongTerm = 'historicalLongTermAdjusted',
    ErpAdjustmentSupplySideLongTerm = 'supplySideLongTermAdjusted',
    ErpAdjustmentRiskPremiumReport = 'riskPremiumReportAdjusted',
    Decile = 'decile',
    SizeGrouping = 'sizeGrouping',
    DecileBreakdown1 = 'decileBreakdown1',
    DecileBreakdown2 = 'decileBreakdown2',
    MarketValueOfCommonEquityPortfolio = 'marketCapPortfolio',
    BookValueOfEquityPortfolio = 'bookValueOfEquityPortfolio',
    FiveYearAverageNetIncomePortfolio = 'fiveYrAvgNetIncomePortfolio',
    MarketValueOfInvestedCapitalPortfolio = 'MVICPortfolio',
    MarketValueOfCommonEquityRegression = 'marketCapRegression',
    BookValueOfEquityRegression = 'bookValueOfEquityRegression',
    FiveYearAverageNetIncomeRegression = 'fiveYrAvgNetIncomeRegression',
    MarketValueOfInvestedCapitalRegression = 'MVICRegression',
    TotalAssetsPortfolio = 'totalAssetsPortfolio',
    FiveYearAverageEBITDAPortfolio = 'fiveYrAvgEBITDAPortfolio',
    SalesPortfolio = 'salesPortfolio',
    NumberOfEmployeesPortfolio = 'numberOfEmployeesPortfolio',
    TotalAssetsRegression = 'totalAssetsRegression',
    FiveYearAverageEBITDARegression = 'fiveYrAvgEBITDARegression',
    SalesRegression = 'salesRegression',
    NumberOfEmployeesRegression = 'numberOfEmployeesRegression',
    MarketValueOfCommonEquityPortfolioLevered = 'marketCapPortfolioLevered',
    BookValueOfEquityPortfolioLevered = 'bookValueOfEquityPortfolioLevered',
    FiveYearAverageNetIncomePortfolioLevered = 'fiveYrAvgNetIncomePortfolioLevered',
    MarketValueOfInvestedCapitalPortfolioLevered = 'MVICPortfolioLevered',
    MarketValueOfCommonEquityRegressionLevered = 'marketCapRegressionLevered',
    BookValueOfEquityRegressionLevered = 'bookValueOfEquityRegressionLevered',
    FiveYearAverageNetIncomeRegressionLevered = 'fiveYrAvgNetIncomeRegressionLevered',
    MarketValueOfInvestedCapitalRegressionLevered = 'MVICRegressionLevered',
    TotalAssetsPortfolioLevered = 'totalAssetsPortfolioLevered',
    FiveYearAverageEBITDAPortfolioLevered = 'fiveYrAvgEBITDAPortfolioLevered',
    SalesPortfolioLevered = 'salesPortfolioLevered',
    NumberOfEmployeesPortfolioLevered = 'numberOfEmployeesPortfolioLevered',
    TotalAssetsRegressionLevered = 'totalAssetsRegressionLevered',
    FiveYearAverageEBITDARegressionLevered = 'fiveYrAvgEBITDARegressionLevered',
    SalesRegressionLevered = 'salesRegressionLevered',
    NumberOfEmployeesRegressionLevered = 'numberOfEmployeesRegressionLevered',
    MarketValueOfCommonEquityPortfolioUnlevered = 'marketCapPortfolioUnlevered',
    MarketValueOfCommonEquityRegressionUnlevered = 'marketCapRegressionUnlevered',
    BookValueOfEquityPortfolioUnlevered = 'bookValueOfEquityPortfolioUnlevered',
    BookValueOfEquityRegressionUnlevered = 'bookValueOfEquityRegressionUnlevered',
    FiveYearAverageNetIncomePortfolioUnlevered = 'fiveYrAvgNetIncomePortfolioUnlevered',
    FiveYearAverageNetIncomeRegressionUnlevered = 'fiveYrAvgNetIncomeRegressionUnlevered',
    MarketValueOfInvestedCapitalPortfolioUnlevered = 'MVICPortfolioUnlevered',
    MarketValueOfInvestedCapitalRegressionUnlevered = 'MVICRegressionUnlevered',
    TotalAssetsPortfolioUnlevered = 'totalAssetsPortfolioUnlevered',
    TotalAssetsRegressionUnlevered = 'totalAssetsRegressionUnlevered',
    FiveYearAverageEBITDAPortfolioUnlevered = 'fiveYrAvgEBITDAPortfolioUnlevered',
    FiveYearAverageEBITDARegressionUnlevered = 'fiveYrAvgEBITDARegressionUnlevered',
    SalesPortfolioUnlevered = 'salesPortfolioUnlevered',
    SalesRegressionUnlevered = 'salesRegressionUnlevered',
    NumberOfEmployeesPortfolioUnlevered = 'numberOfEmployeesPortfolioUnlevered',
    NumberOfEmployeesRegressionUnlevered = 'numberOfEmployeesRegressionUnlevered',
    MarketValueOfCommonEquityPortfolioRelevered = 'marketCapPortfolioRelevered',
    BookValueOfEquityPortfolioRelevered = 'bookValueOfEquityPortfolioRelevered',
    FiveYearAverageNetIncomePortfolioRelevered = 'fiveYrAvgNetIncomePortfolioRelevered',
    MarketValueOfInvestedCapitalPortfolioRelevered = 'MVICPortfolioRelevered',
    TotalAssetsPortfolioRelevered = 'totalAssetsPortfolioRelevered',
    FiveYearAverageEBITDAPortfolioRelevered = 'fiveYrAvgEBITDAPortfolioRelevered',
    SalesPortfolioRelevered = 'salesPortfolioRelevered',
    NumberOfEmployeesPortfolioRelevered = 'numberOfEmployeesPortfolioRelevered',
    MarketValueOfCommonEquityRegressionRelevered = 'marketCapRegressionRelevered',
    BookValueOfEquityRegressionRelevered = 'bookValueOfEquityRegressionRelevered',
    FiveYearAverageNetIncomeRegressionRelevered = 'fiveYrAvgNetIncomeRegressionRelevered',
    MarketValueOfInvestedCapitalRegressionRelevered = 'MVICRegressionRelevered',
    TotalAssetsRegressionRelevered = 'totalAssetsRegressionRelevered',
    FiveYearAverageEBITDARegressionRelevered = 'fiveYrAvgEBITDARegressionRelevered',
    SalesRegressionRelevered = 'salesRegressionRelevered',
    NumberOfEmployeesRegressionRelevered = 'numberOfEmployeesRegressionRelevered',
    OperatingMarginPortfolioLevered = 'operatingMarginPortfolioLevered',
    CVOfOperatingMarginPortfolioLevered = 'CVOfOperatingMarginPortfolioLevered',
    OperatingMarginRegressionLevered = 'operatingMarginRegressionLevered',
    CVOfOperatingMarginRegressionLevered = 'CVOfOperatingMarginRegressionLevered',
    CVOfReturnOnEquityPortfolioLevered = 'CVOfReturnOnEquityPortfolioLevered',
    CVOfReturnOnEquityRegressionLevered = 'CVOfReturnOnEquityRegressionLevered',
    HFRSizePremium = 'HFRSizePremium',
    HFRRiskPremiumOverTheRiskFreeRate = 'HFRRiskPremiumOverTheRiskFreeRate',
    Industry0 = 'industry0',
    Industry1 = 'industry1',
    Industry2 = 'industry2',
    Industry3 = 'industry3',
    Industry4 = 'industry4',
    Beta0 = 'beta0',
    Beta1 = 'beta1',
    Beta2 = 'beta2',
    Beta3 = 'beta3',
    Beta4 = 'beta4',
    EquityRiskPremium0 = 'equityRiskPremium0',
    EquityRiskPremium1 = 'equityRiskPremium1',
    EquityRiskPremium2 = 'equityRiskPremium2',
    EquityRiskPremium3 = 'equityRiskPremium3',
    EquityRiskPremium4 = 'equityRiskPremium4',
    IndustryRiskPremium0 = 'industryRiskPremium0',
    IndustryRiskPremium1 = 'industryRiskPremium1',
    IndustryRiskPremium2 = 'industryRiskPremium2',
    IndustryRiskPremium3 = 'industryRiskPremium3',
    IndustryRiskPremium4 = 'industryRiskPremium4',
    BetaSuggestion0 = 'betaSuggestion0',
    BetaSuggestion1 = 'betaSuggestion1',
    BetaSuggestion2 = 'betaSuggestion2',
    BetaSuggestion3 = 'betaSuggestion3',
    BetaSuggestion4 = 'betaSuggestion4',
    USIndustries = 'usIndustries',
    IntlIndustries = 'intlIndustries',
    ERPSelections = 'erpSelections',
    ServiceCompanyType = 'serviceCompanyType',
    ManufacturingCompanyType = 'manufacturingCompanyType',
    BenchMarkingValuationDate = 'benchMarkingValuationDate',
    BenchMarkingRegion = 'benchMarkingRegion',
    BenchMarkingCurrency = 'benchMarkingCurrency',
    BenchMarkingIndustry = 'benchMarkingIndustry',
    BenchMarkingRegions = 'benchMarkingRegions',
    BenchMarkingCurrencies = 'benchMarkingCurrencies',
    BenchMarkingProductType = 'benchMarkingProductType',
    IntlBenchMarkingValuationDate = 'intlBenchMarkingValuationDate',
    IntlBenchMarkingRegion = 'intlBenchMarkingRegion',
    IntlBenchMarkingCurrency = 'intlBenchMarkingCurrency',
    IntlBenchMarkingIndustry = 'intlBenchMarkingIndustry',
    IntlBenchMarkingRegions = 'intlBenchMarkingRegions',
    IntlBenchMarkingCurrencies = 'intlBenchMarkingCurrencies',
    IntlBenchMarkingProductType = 'intlBenchMarkingProductType',
    IntlBenchMarkingIndustries = 'intlBenchMarkingIndustries',
    ISDataSummaryExported = 'isDataSummaryExported',
    FullInformationBeta0 = 'fullInformationBeta0',
    VasicekAdjustedBeta0 = 'vasicekAdjustedBeta0',
    MedianSumLeveredBeta0 = 'medianSumLeveredBeta0',
    HighFinancialRiskSumLeveredBeta0 = 'highFinancialRiskSumLeveredBeta0',
    FullInformationBeta1 = 'fullInformationBeta1',
    VasicekAdjustedBeta1 = 'vasicekAdjustedBeta1',
    MedianSumLeveredBeta1 = 'medianSumLeveredBeta1',
    HighFinancialRiskSumLeveredBeta1 = 'highFinancialRiskSumLeveredBeta1',
    FullInformationBeta2 = 'fullInformationBeta2',
    VasicekAdjustedBeta2 = 'vasicekAdjustedBeta2',
    MedianSumLeveredBeta2 = 'medianSumLeveredBeta2',
    HighFinancialRiskSumLeveredBeta2 = 'highFinancialRiskSumLeveredBeta2',
    FullInformationBeta3 = 'fullInformationBeta3',
    VasicekAdjustedBeta3 = 'vasicekAdjustedBeta3',
    MedianSumLeveredBeta3 = 'medianSumLeveredBeta3',
    HighFinancialRiskSumLeveredBeta3 = 'highFinancialRiskSumLeveredBeta3',
    FullInformationBeta4 = 'fullInformationBeta4',
    VasicekAdjustedBeta4 = 'vasicekAdjustedBeta4',
    MedianSumLeveredBeta4 = 'medianSumLeveredBeta4',
    HighFinancialRiskSumLeveredBeta4 = 'highFinancialRiskSumLeveredBeta4',
    IntlInvestorCountry = 'investorCountry',
    IntlInvestorCurrency = 'intlInvestorCurrency',
    IntlIndustry = 'intlIndustry',
    IntlCOCValuationDate = 'intlCOCValuationDate',

}
