import { Component, OnChanges } from '@angular/core';
import { CommonUserInputComponent } from 'src/app/_add-in/common/inputs/common-user-input.component';
import { FieldType } from 'src/app/_add-in/data/enums/field-type';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';

@Component({
    selector: 'common-input-reference',
    templateUrl: './common-input-reference.component.html'
})
export class CommonInputReferenceComponent extends CommonUserInputComponent implements OnChanges {

    public fieldTypeInput = FieldType.Input;
    public fieldTypeOutput = FieldType.Output;
    public fieldTypeDatePicker = FieldType.DatePicker;
    public fieldTypeAhead = FieldType.TypeAhead;
    public fieldTypeIntlCombobox = FieldType.IntlCombobox;

    constructor(private excelStorageService: ExcelStorageService) {
        super(excelStorageService);
    }
    public onFocusToCell(): void {
        this.excelStorageService.focusToCell(this.model);
    }

    public onRemoveBinding(): void {
        this.excelStorageService.removeBinding(this.model);
    }

}
