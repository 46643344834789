import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { FieldData } from 'src/app/_add-in/data/field-data';
import { Menu } from 'src/app/_add-in/menu/menu';
import { SummaryTableHeaderType } from 'src/app/_add-in/summary/summary-table-header-type';
import { SummaryService } from 'src/app/_add-in/summary/summary.service';

export class SummaryDataSource extends DataSource<FieldData> {

    private dataSubject = new BehaviorSubject<FieldData[]>([]);

    public constructor(private summaryService: SummaryService) {
        super();
    }

    public connect(): Observable<FieldData[]> {
        return this.dataSubject.asObservable();
    }

    public disconnect(): void {
        this.dataSubject.complete();
    }

    public loadData(menu: Menu): void {
        this.summaryService.getData(menu).subscribe((data) => this.dataSubject.next(data));
    }

    public loadTableHeaders(menu: Menu): SummaryTableHeaderType[] {
        return this.summaryService.getTableHeaders(menu);
    }

}
