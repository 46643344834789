<div class="size-tab">
    <div class="common-inputs-content">
        <common-tab-header [headerText]="headers.getAccordionText('sizeRiskPremia')"></common-tab-header>
        <div class="tab-value-input current-inputs">
            <h5>
                Current Inputs
            </h5>
            <mat-divider></mat-divider>
            <common-datepicker [config]="builder.valuationDate.config" [(model)]="builder.valuationDate">
            </common-datepicker>
        </div>
        <mat-accordion class="excel-input-tab" multi="true">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            CRSP Deciles Size Study
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <mat-expansion-panel [@.disabled]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="nested-panel-title-1">
                                    Size Premium
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mat-expansion-panel-content">
                            <common-tab-header [headerText]="headers.getAccordionText('sizePremium')">
                            </common-tab-header>
                            <mat-divider></mat-divider>
                            <div class="input-wrapper">
                                <common-output [(model)]="builder.decile"></common-output>
                                <common-output [(model)]="builder.sizeGrouping"></common-output>
                                <common-output [(model)]="builder.decileBreakdown1"></common-output>
                                <common-output [(model)]="builder.decileBreakdown2"></common-output>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="excel-input-tab" multi="true">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            Risk Premium Report Study
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <mat-expansion-panel [@.disabled]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="nested-panel-title-1">
                                    Size Study
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mat-expansion-panel-content">
                            <mat-expansion-panel [@.disabled]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="nested-panel-title-2">
                                            Size Premium
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-expansion-panel-content">
                                    <common-tab-header [headerText]="headers.getAccordionText('sizePremium')">
                                    </common-tab-header>
                                    <mat-divider></mat-divider>
                                    <div class="input-wrapper">
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Market Value of Common Equity
                                            </div>
                                            <common-output [(model)]="builder.marketCapPortfolio"></common-output>
                                            <common-output [(model)]="builder.marketCapRegression"></common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Book Value of Equity
                                            </div>
                                            <common-output [(model)]="builder.bookValueOfEquityPortfolio">
                                            </common-output>
                                            <common-output [(model)]="builder.bookValueOfEquityRegression">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                5-year Avg Net Income
                                            </div>
                                            <common-output [(model)]="builder.fiveYrAvgNetIncomePortfolio">
                                            </common-output>
                                            <common-output [(model)]="builder.fiveYrAvgNetIncomeRegression">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Market Value of Invested Capital
                                            </div>
                                            <common-output [(model)]="builder.MVICPortfolio"></common-output>
                                            <common-output [(model)]="builder.MVICRegression"></common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Total Assets
                                            </div>
                                            <common-output [(model)]="builder.totalAssetsPortfolio"></common-output>
                                            <common-output [(model)]="builder.totalAssetsRegression"></common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                5-year Avg EBITDA
                                            </div>
                                            <common-output [(model)]="builder.fiveYrAvgEBITDAPortfolio"></common-output>
                                            <common-output [(model)]="builder.fiveYrAvgEBITDARegression">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Sales
                                            </div>
                                            <common-output [(model)]="builder.salesPortfolio"></common-output>
                                            <common-output [(model)]="builder.salesRegression"></common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                # of Employees
                                            </div>
                                            <common-output [(model)]="builder.numberOfEmployeesPortfolio">
                                            </common-output>
                                            <common-output [(model)]="builder.numberOfEmployeesRegression">
                                            </common-output>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel [@.disabled]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="nested-panel-title-2">
                                            Risk Premium Over the Risk-free Rate (Levered)
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-expansion-panel-content">
                                    <common-tab-header
                                        [headerText]="headers.getAccordionText('riskPremiumOverTheRiskFreeRate')">
                                    </common-tab-header>
                                    <mat-divider></mat-divider>
                                    <div class="input-wrapper">
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Market Value of Common Equity
                                            </div>
                                            <common-output [(model)]="builder.marketCapPortfolioLevered">
                                            </common-output>
                                            <common-output [(model)]="builder.marketCapRegressionLevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Book Value of Equity
                                            </div>
                                            <common-output [(model)]="builder.bookValueOfEquityPortfolioLevered">
                                            </common-output>
                                            <common-output [(model)]="builder.bookValueOfEquityRegressionLevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                5-year Avg Net Income
                                            </div>
                                            <common-output [(model)]="builder.fiveYrAvgNetIncomePortfolioLevered">
                                            </common-output>
                                            <common-output [(model)]="builder.fiveYrAvgNetIncomeRegressionLevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Market Value of Invested Capital
                                            </div>
                                            <common-output [(model)]="builder.MVICPortfolioLevered"></common-output>
                                            <common-output [(model)]="builder.MVICRegressionLevered"></common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Total Assets
                                            </div>
                                            <common-output [(model)]="builder.totalAssetsPortfolioLevered">
                                            </common-output>
                                            <common-output [(model)]="builder.totalAssetsRegressionLevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                5-year Avg EBITDA
                                            </div>
                                            <common-output [(model)]="builder.fiveYrAvgEBITDAPortfolioLevered">
                                            </common-output>
                                            <common-output [(model)]="builder.fiveYrAvgEBITDARegressionLevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Sales
                                            </div>
                                            <common-output [(model)]="builder.salesPortfolioLevered"></common-output>
                                            <common-output [(model)]="builder.salesRegressionLevered"></common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                # of Employees
                                            </div>
                                            <common-output [(model)]="builder.numberOfEmployeesPortfolioLevered">
                                            </common-output>
                                            <common-output [(model)]="builder.numberOfEmployeesRegressionLevered">
                                            </common-output>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel [@.disabled]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="nested-panel-title-2">
                                            Risk Premium Over the Risk-free Rate (Unlevered)
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-expansion-panel-content">
                                    <common-tab-header
                                        [headerText]="headers.getAccordionText('riskPremiumOverTheRiskFreeRate')">
                                    </common-tab-header>
                                    <mat-divider></mat-divider>
                                    <div class="input-wrapper">
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Market Value of Common Equity
                                            </div>
                                            <common-output [(model)]="builder.marketCapPortfolioUnlevered">
                                            </common-output>
                                            <common-output [(model)]="builder.marketCapRegressionUnlevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Book Value of Equity
                                            </div>
                                            <common-output [(model)]="builder.bookValueOfEquityPortfolioUnlevered">
                                            </common-output>
                                            <common-output [(model)]="builder.bookValueOfEquityRegressionUnlevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                5-year Avg Net Income
                                            </div>
                                            <common-output [(model)]="builder.fiveYrAvgNetIncomePortfolioUnlevered">
                                            </common-output>
                                            <common-output [(model)]="builder.fiveYrAvgNetIncomeRegressionUnlevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Market Value of Invested Capital
                                            </div>
                                            <common-output [(model)]="builder.MVICPortfolioUnlevered"></common-output>
                                            <common-output [(model)]="builder.MVICRegressionUnlevered"></common-output>

                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Total Assets
                                            </div>
                                            <common-output [(model)]="builder.totalAssetsPortfolioUnlevered">
                                            </common-output>
                                            <common-output [(model)]="builder.totalAssetsRegressionUnlevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                5-year Avg EBITDA
                                            </div>
                                            <common-output [(model)]="builder.fiveYrAvgEBITDAPortfolioUnlevered">
                                            </common-output>
                                            <common-output [(model)]="builder.fiveYrAvgEBITDARegressionUnlevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Sales
                                            </div>
                                            <common-output [(model)]="builder.salesPortfolioUnlevered"></common-output>
                                            <common-output [(model)]="builder.salesRegressionUnlevered"></common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                # of Employees
                                            </div>
                                            <common-output [(model)]="builder.numberOfEmployeesPortfolioUnlevered">
                                            </common-output>
                                            <common-output [(model)]="builder.numberOfEmployeesRegressionUnlevered">
                                            </common-output>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel [@.disabled]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="nested-panel-title-2">
                                            Risk Premium Over the Risk-free Rate (Relevered)
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-expansion-panel-content">
                                    <common-tab-header
                                        [headerText]="headers.getAccordionText('riskPremiumOverTheRiskFreeRate')">
                                    </common-tab-header>
                                    <mat-divider></mat-divider>
                                    <div class="input-wrapper">
                                        <common-output [(model)]="builder.debtToEquity"></common-output>
                                    </div>
                                    <mat-divider></mat-divider>
                                    <div class="input-wrapper">
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Market Value of Common Equity
                                            </div>
                                            <common-output [(model)]="builder.marketCapPortfolioRelevered">
                                            </common-output>
                                            <common-output [(model)]="builder.marketCapRegressionRelevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Book Value of Equity
                                            </div>
                                            <common-output [(model)]="builder.bookValueOfEquityPortfolioRelevered">
                                            </common-output>
                                            <common-output [(model)]="builder.bookValueOfEquityRegressionRelevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                5-year Avg Net Income
                                            </div>
                                            <common-output [(model)]="builder.fiveYrAvgNetIncomePortfolioRelevered">
                                            </common-output>
                                            <common-output [(model)]="builder.fiveYrAvgNetIncomeRegressionRelevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Market Value of Invested Capital
                                            </div>
                                            <common-output [(model)]="builder.MVICPortfolioRelevered"></common-output>
                                            <common-output [(model)]="builder.MVICRegressionRelevered"></common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Total Assets
                                            </div>
                                            <common-output [(model)]="builder.totalAssetsPortfolioRelevered">
                                            </common-output>
                                            <common-output [(model)]="builder.totalAssetsRegressionRelevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                5-year Avg EBITDA
                                            </div>
                                            <common-output [(model)]="builder.fiveYrAvgEBITDAPortfolioRelevered">
                                            </common-output>
                                            <common-output [(model)]="builder.fiveYrAvgEBITDARegressionRelevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                Sales
                                            </div>
                                            <common-output [(model)]="builder.salesPortfolioRelevered"></common-output>
                                            <common-output [(model)]="builder.salesRegressionRelevered"></common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <div class="rpr-category">
                                                # of Employees
                                            </div>
                                            <common-output [(model)]="builder.numberOfEmployeesPortfolioRelevered">
                                            </common-output>
                                            <common-output [(model)]="builder.numberOfEmployeesRegressionRelevered">
                                            </common-output>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel [@.disabled]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="nested-panel-title-1">
                                    Risk Study
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mat-expansion-panel-content">
                            <mat-expansion-panel [@.disabled]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="nested-panel-title-2">
                                            Risk Premium Over the Risk-free Rate (Levered)
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-expansion-panel-content">
                                    <common-tab-header
                                        [headerText]="headers.getAccordionText('riskPremiumOverTheRiskFreeRate')">
                                    </common-tab-header>
                                    <mat-divider></mat-divider>
                                    <div class="input-wrapper">
                                        <div class="common-output-container">
                                            <common-output [(model)]="builder.operatingMargin"></common-output>
                                            <common-output [(model)]="builder.operatingMarginPortfolioLevered">
                                            </common-output>
                                            <common-output [(model)]="builder.operatingMarginRegressionLevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <common-output [(model)]="builder.CVOfOperatingMargin"></common-output>
                                            <common-output [(model)]="builder.CVOfOperatingMarginPortfolioLevered">
                                            </common-output>
                                            <common-output [(model)]="builder.CVOfOperatingMarginRegressionLevered">
                                            </common-output>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="common-output-container">
                                            <common-output [(model)]="builder.CVOfReturnOnEquity"></common-output>
                                            <common-output [(model)]="builder.CVOfReturnOnEquityPortfolioLevered">
                                            </common-output>
                                            <common-output [(model)]="builder.CVOfReturnOnEquityRegressionLevered">
                                            </common-output>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="excel-input-tab">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            High-Financial Risk Study
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <div class="z-score-component">
                        <div class="company-type">
                            <div>Company Type</div>
                            <common-radio-button [(model)]="builder.serviceCompanyType"></common-radio-button>
                            <common-radio-button [(model)]="builder.manufacturingCompanyType"></common-radio-button>
                        </div>
                        <common-output [(model)]="builder.ZScore"></common-output>
                    </div>
                    <mat-divider></mat-divider>
                    <common-output [(model)]="builder.HFRSizePremium"></common-output>
                    <common-output [(model)]="builder.HFRRiskPremiumOverTheRiskFreeRate"></common-output>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>