import { Routes } from '@angular/router';
import { IsSubscriberGuard } from 'src/app/_add-in/is-subscriber.guard';
import { BetasTabComponent } from 'src/app/estimate/betas-tab.component';
import { DataSummaryTabComponent } from 'src/app/estimate/data-summary-tab.component';
import { InputsTabComponent } from 'src/app/estimate/inputs-tab.component';
import { RfTabComponent } from 'src/app/estimate/rf-tab.component';
import { SizeTabComponent } from 'src/app/estimate/size-tab.component';
import { EmployeeLoginComponent } from 'src/app/home/employee-login.component';
import { HomeComponent } from 'src/app/home/home.component';
import { LandingComponent } from 'src/app/home/landing.component';
import { SubscriptionRedirectComponent } from 'src/app/home/subscription-redirect.component';
import { IsUserHaveIniSubscriptionGuard } from '../_add-in/Is-user-have-ini-subscription.guard';
import { IsUserHaveUsiSubscriptionGuard } from '../_add-in/Is-user-have-usi-subscription.guard';
import { IsUserHaveCocSubscriptionGuard } from '../_add-in/is-user-have-coc-subscription.guard';
import { IsUserHaveIneSubscriptionGuard } from '../_add-in/is-user-have-ine-subscription.guard';
import { BenchmarkingComponent } from '../benchmarking/benchmarking.component';
import { IndustryInputsComponent } from '../benchmarking/components/industry-inputs.component';
import { IntlIndustryInputsComponent } from '../benchmarking/components/intl-industry-inputs.component';
import { ValidateSubscriptionComponent } from '../home/validate-subscription.component';
import { IntlCostOfCapitalComponent } from '../intl-cost-of-capital/intl-cost-of-capital.component';

/* Routing */
export const routeConfig: Routes = [
    { path: 'landing', component: LandingComponent },
    { path: 'employee', component: EmployeeLoginComponent },
    { path: 'subscription', component: SubscriptionRedirectComponent },
    { path: 'validate-subscription', component: ValidateSubscriptionComponent },

    {
        path: '', component: HomeComponent, canActivate: [IsSubscriberGuard], children: [

            {
                path: 'home', component: HomeComponent, canActivate: [IsUserHaveCocSubscriptionGuard], children: [
                    { path: 'inputs-tab', component: InputsTabComponent },
                    { path: 'rf-tab', component: RfTabComponent },
                    { path: 'size-tab', component: SizeTabComponent },
                    { path: 'betas-tab', component: BetasTabComponent },
                    { path: 'data-summary-tab', component: DataSummaryTabComponent },
                    { path: '**', redirectTo: 'inputs-tab' }
                ]
            },
            {
                path: 'benchmarking', component: BenchmarkingComponent, canActivate: [IsUserHaveUsiSubscriptionGuard], children: [
                    { path: '', component: IndustryInputsComponent },
                    { path: '**', redirectTo: '' }
                ]
            },
            {
                path: 'intlCoC', component: IntlCostOfCapitalComponent, canActivate: [IsUserHaveIneSubscriptionGuard], 
                // children: [
                //     { path: '', component: IndustryInputsComponent },
                //     { path: '**', redirectTo: '' }
                // ]
            },
            {
                path: 'intlBenchmarking', component: BenchmarkingComponent, canActivate: [IsUserHaveIniSubscriptionGuard], children: [
                    { path: '', component: IntlIndustryInputsComponent },
                    { path: '**', redirectTo: '' }
                ]
            },
            { path: '**', redirectTo: 'home' }
        ]
    },

    { path: '**', redirectTo: '' }
];
