import { Injectable } from '@angular/core';
import { Workbook, WorkbookSheetRow } from '@progress/kendo-ooxml';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';
import { IntlCompanyData } from 'src/app/_api/responses/intl-company.response';
import { BenchmarkingUtil } from 'src/app/benchmarking/benchmarking.util';
import { IndustryTearSheet } from '../../_api/responses/industry-tear-sheet.response';
import { UsCompanyData } from '../../_api/responses/us-company.response';
import { TearSheetService } from '../tear-sheet/data/tear-sheet.service';
import { MetricRow } from '../tear-sheet/models/metric-row.model';
import { StatisticsCategory } from '../tear-sheet/models/statistics-category.model';
import { MetricsUtil } from '../tear-sheet/util/metrics.util';
import { StatisticsUtil } from '../tear-sheet/util/statistics.util';
import { TearSheetExcelExportUtil } from './util/tearsheet-excel.util';
import { Category } from '../tear-sheet/enums/category';
import { IndustryAssumptions } from 'src/app/_api/responses/IndustryAssumption';

@Injectable()
export class BenchmarkingExcelExportService {
    public statisticsCategories: StatisticsCategory[] = [];
    public metricRows: MetricRow[] = [];
    public industryAssumptions!: IndustryAssumptions;
    public currency!: string

    constructor(
        private tearSheetService: TearSheetService,
        public excelStorageService: ExcelStorageService
    ) { }

    public columnWidth = 125; // width of each cell in grid system

    public save(modName:string): void {
        this.saveWorksheet(modName);
    }

    // public saveUSIndustry(): void {
    //     this.saveUSWorksheet();
    // }

    private saveUSWorksheet(): void {
        let lstexcelExport: Workbook[] = [];
        const tearsheetIndex = this.tearSheetService.tearSheetIndex;
        const tearSheetData = this.tearSheetService.getTearSheetData();

        const USCompanies = tearSheetData[tearsheetIndex].Companies as UsCompanyData;

        const excelExport = this.getUSWorksheet(tearSheetData[tearsheetIndex], USCompanies);
        lstexcelExport.push(excelExport)
         this.excelStorageService.exportMultipleExcel(lstexcelExport);
    }

    private saveWorksheet(modName:string): void {
        // const tearsheetIndex = this.tearSheetService.tearSheetIndex;
        const tearSheetData = this.tearSheetService.getTearSheetData();
        let excelExport: Workbook[] = [];


        tearSheetData.forEach(item=>{

        const isSic = BenchmarkingUtil.isSic(item.DataAsOf.toString(), item.Region);

        if (isSic) {
            const USCompanies = item.Companies as UsCompanyData;

           let SicExcelData = this.getUSWorksheet(
                item,
                USCompanies
            );
            excelExport.push(SicExcelData)
        } else {
            const intlCompanies = item.Companies as IntlCompanyData;

            let CmpExcelData = this.getIntlWorksheet(
                item,
                intlCompanies
            );

            excelExport.push(CmpExcelData) 
                     
        }

        });

        excelExport.push(this.getAboutSheet(modName));
   
        this.excelStorageService.exportMultipleExcel(excelExport);
        // component.save(excelExport);
    }

    private getUSWorksheet(data: IndustryTearSheet, companyData: UsCompanyData): Workbook {
        let isFinancial = false;
        this.statisticsCategories = StatisticsUtil.processStatisticsMapping(data.Metrics);
        //let sectionLeft: WorkbookSheetRow[];
        this.statisticsCategories = StatisticsUtil.processStatisticsMapping(data.Metrics);
        if (data.SicCode != null) {
            this.metricRows = MetricsUtil.processMetricMapping(data.Metrics, data.SicCode, data.DataAsOf);
            isFinancial = MetricsUtil.changeLayout(data.SicCode, data.DataAsOf, data.Region);
            //sectionLeft = TearSheetExcelExportUtil.getIndustryData(data, data.SicCode, 'SIC');
        } else {
            this.metricRows = MetricsUtil.processMetricMapping(data.Metrics, data.GicCode, data.DataAsOf);
            //sectionLeft = TearSheetExcelExportUtil.getIndustryData(data, data.GicCode, 'GICS');
        }

        const sectionRight: WorkbookSheetRow[] = this.getIndustryStatistics();

        const workbook = new Workbook();
        const DateAsof = new Date(data.DataAsOf);
        const month = ('0' + (DateAsof.getUTCMonth() + 1)).slice(-2);
        const year = DateAsof.getUTCFullYear();
        const shortYear = year.toString().slice(-2);
        workbook.sheets = [{
            name: 'US_' + data.Currency + '_SIC' + data.SicCode + '_' + month + '_' + shortYear,
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                ...TearSheetExcelExportUtil.getHeader(data),
                //...TearSheetExcelExportUtil.concatenateRows(sectionLeft, sectionRight),
                ...sectionRight,
                ...this.getIndustryMetrics(),
                ...TearSheetExcelExportUtil.getIndustryAssumptionSheet(data.IndustryAssumptions, data.Currency, data.DataAsOf, data.Region, isFinancial)
                // ...TearSheetExcelExportUtil.getFooter(data.Region)
            ]
        }, {
            name: 'US_' + data.Currency + '_SIC' + data.SicCode + '_Co' + '_' + month + '_' + shortYear,
            columns: [{ width: this.columnWidth * 2 }].concat(Array(3).fill({ width: this.columnWidth })),
            rows: [
                ...TearSheetExcelExportUtil.getHeader(data, true),
                ...data.SicCode != null ? TearSheetExcelExportUtil.getUSCompanyData('SIC', data.SicCode, companyData,data.Currency)
                    : TearSheetExcelExportUtil.getUSCompanyData('GICS', data.GicCode, companyData),
              //  ...TearSheetExcelExportUtil.getFooter(data.Region)
            ]
        }];

        return workbook;
    }

    private getIntlWorksheet(
        data: IndustryTearSheet,
        companyData: IntlCompanyData
    ): Workbook {
        this.statisticsCategories = StatisticsUtil.processStatisticsMapping(data.Metrics);
        this.metricRows = MetricsUtil.processMetricMapping(data.Metrics, data.GicCode, data.DataAsOf);

        const sectionLeft: WorkbookSheetRow[] = TearSheetExcelExportUtil.getIndustryData(data, data.GicCode, 'GICS');
        const sectionRight: WorkbookSheetRow[] = this.getIndustryStatistics();
        const isFinancial = MetricsUtil.changeLayout(data.GicCode, data.DataAsOf, data.Region);
        let regionCode: any;
        if (data.Region === 'United States') {
            regionCode = 'US';
        } else if (data.Region === 'European Union') {
            regionCode = 'EU';
        } else if (data.Region === 'United Kingdom') {
            regionCode = 'UK';
        } else if (data.Region === 'Eurozone') {
            regionCode = 'EZone';
        } else {
            regionCode = 'World';
        }
        const workbook = new Workbook();
        const DateAsof = new Date(data.DataAsOf);
        const month = ('0' + (DateAsof.getUTCMonth() + 1)).slice(-2);
        const year = DateAsof.getUTCFullYear();
        const shortYear = year.toString().slice(-2);
        workbook.sheets = [{
            name: regionCode + '_' + data.Currency + '_GICS' + data.GicCode + '_' + month + '_' + shortYear,
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                ...TearSheetExcelExportUtil.getHeader(data),
                //...TearSheetExcelExportUtil.concatenateRows(sectionLeft, sectionRight),
                ...sectionRight,
                ...this.getIndustryMetrics(),
                ...TearSheetExcelExportUtil.getIndustryAssumptionSheet(data.IndustryAssumptions, data.Currency, data.DataAsOf, data.Region, isFinancial)
                // ...TearSheetExcelExportUtil.getFooter(data.Region)
            ]
        }, {
            name: regionCode + '_' + data.Currency + '_GICS' + data.GicCode + '_Co' + '_' + month + '_' + shortYear,
            columns: [{ width: this.columnWidth * 2 }].concat(Array(3).fill({ width: this.columnWidth })),
            rows: [
                ...TearSheetExcelExportUtil.getHeader(data),
                ...TearSheetExcelExportUtil.getIntlCompanyData(data.GicCode, companyData, data.Currency),
                // ...TearSheetExcelExportUtil.getFooter(data.Region)
            ]
        }];

        return workbook;
    }

    private getIndustryMetrics(): WorkbookSheetRow[] {
        let rowData: WorkbookSheetRow[] = [];
        let rowCurrentData: WorkbookSheetRow[] = [];
        const rowDataSet: WorkbookSheetRow[][] = [];

        let rowResults: WorkbookSheetRow[] = [];
        let isFamaFrench3factor = true;
        const updatedMetricData = this.mapDataforNewExcelExport();

        updatedMetricData.forEach((row) => {
            row.categories.forEach((cat) => {
                if (cat.metrics.length >= 1) {
                    rowData =
                        TearSheetExcelExportUtil.getMetricRowCategories(cat);
                    if (cat.showCompositeLabels) {
                        rowCurrentData.push({ cells: [{}] }); //Add Row after every Catogery Section

                        if (rowData.length) {
                            rowDataSet.push(rowCurrentData);
                        }
                        rowCurrentData = rowData;
                    } else {
                        rowCurrentData =
                            TearSheetExcelExportUtil.concatenateRows(
                                rowCurrentData,
                                rowData
                            );
                    }
                    if (cat.metricCategoryId === Category.ProfitabilityRatio || cat.metricCategoryId === Category.EquityValuationMultiples || cat.metricCategoryId === Category.LeverageRatios) {
                        rowCurrentData.forEach(d => {
                            d.cells?.push({
                                value: ''

                            })
                        })
                    }
                    if (cat.metricCategoryId === Category.BetasUnlevered) {
                        let firRowCells = rowCurrentData[0].cells;
                        if (firRowCells) {
                            let firstCell = firRowCells[0];
                            firstCell.value = 'Betas (Levered and Unlevered)'
                            firstCell.colSpan = rowCurrentData[0].cells?.reduce((accumulator, currentValue) => currentValue.colSpan ? currentValue.colSpan + accumulator : 0, 0)
                            rowCurrentData[0] = {
                                cells: [firstCell]
                            }
                        }
                    }
                }
            });
        });

        rowDataSet.push(rowCurrentData);
        rowDataSet.forEach((x: WorkbookSheetRow[]) => rowResults = rowResults.concat(x));

        return rowResults;

    }

    private mapDataforNewExcelExport(): MetricRow[] {


        const tempRowData: MetricRow[] = []

        this.metricRows.forEach(d => {
            if (d.categories.find(d => d.metricCategoryId === Category.ReturnRatios)) {
                tempRowData.push(d);
                const tempCatoger = tempRowData[tempRowData.length - 1];
                let liquidityRatioMteric = tempCatoger.categories.find(d => d.metricCategoryId === Category.LiquidityRatio);
                if (liquidityRatioMteric) {
                    liquidityRatioMteric.showCompositeLabels = true;
                    liquidityRatioMteric.metricCategoryName = 'Liquidity Ratio and Profitability Ratios (%)';
                }
                let profitabilityRatio = tempCatoger.categories.find(d => d.metricCategoryId === Category.ProfitabilityRatio);
                if (profitabilityRatio) {
                    profitabilityRatio.metricCategoryName = ''
                }


            } else if (d.categories.find(d => d.metricCategoryId === Category.FamaFrenchThreeFactor || d.metricCategoryId === Category.FamaFrenchFiveFactor)) {
                tempRowData.push(d);
                let farmaFrenchData = tempRowData[tempRowData.length - 1];
                farmaFrenchData.categories[1].showCompositeLabels = true;

            }
            else {
                tempRowData.push(d);
            }
            return tempRowData;
        })

        return tempRowData;;
    }

    private getIndustryStatistics(): WorkbookSheetRow[] {
        let rowSetResult: WorkbookSheetRow[] = [];

        this.statisticsCategories.forEach((x) => {
            const titleRow = TearSheetExcelExportUtil.getStatisticsCategoryTitle(x.statisticsCategoryName);
            const groupRow = TearSheetExcelExportUtil.getIndustryStatisticsCategoryGroups(x);
            rowSetResult = rowSetResult.concat(titleRow);
            rowSetResult = rowSetResult.concat(groupRow);
        });

        return rowSetResult;
    }


    private getAboutSheet(ModuleName:string):Workbook{

        const workbook = new Workbook();
        const DateAsof = new Date();
        const month = ('0' + (DateAsof.getUTCMonth() + 1)).slice(-2);
        const year = DateAsof.getUTCFullYear();
        const shortYear = year.toString().slice(-2);
        workbook.sheets = [{
            name: 'About',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                ...TearSheetExcelExportUtil.getAboutHeaders(ModuleName),
                ...TearSheetExcelExportUtil.getAboutDataType(ModuleName),
                ...TearSheetExcelExportUtil.getFooterData(ModuleName)
            ]
        }];

        return workbook;
    }

}
