import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatTableData' })
export class FormatTableDataPipe implements PipeTransform {
    public transform(value: number | null): string {
        if (value === null) {
            return '-';
        }

        return value.toFixed(2);
    }
}
