export class Metrics {
    public static readonly ReturnOnAssets = 'Return on Assets';
    public static readonly ReturnOnEquity = 'Return on Equity';
    public static readonly DividendYield = 'Dividend Yield';
    public static readonly CurrentRatio = 'Current Ratio';
    public static readonly OperatingMargin = 'Operating Margin';
    public static readonly LongTermEps = 'Long-term EPS';
    public static readonly RawOls = 'Raw (OLS)';
    public static readonly BlumeAdjusted = 'Blume Adjusted';
    public static readonly PeerGroup = 'Peer Group';
    public static readonly VasicekAdjusted = 'Vasicek Adjusted';
    public static readonly Sum = 'Sum';
    public static readonly Downside = 'Downside';
    public static readonly PriceSales = 'Price/Sales';
    public static readonly PriceEarnings = 'Price/Earnings';    
    public static readonly PriceTbv = 'Price/TBV';
    public static readonly PriceEbt = 'Price/EBT';
    public static readonly MarketBook = 'Market/Book';
    public static readonly EvSales = 'EV/Sales';
    public static readonly EvEbitda = 'EV/EBITDA';
    public static readonly EnterpriseValuation = 'Enterprise Valuation';
    public static readonly FamaFrenchComponents = 'Fama-French (F-F) Components';
    public static readonly DebtMvEquity = 'Debt/MV Equity';
    public static readonly DebtTotalCapital = 'Debt/Total Capital';
    public static readonly CostOfDebt = 'Cost of Debt';
    public static readonly SicComposite = 'SIC Composite';
    public static readonly CrspDeciles = 'CRSP Deciles';
    public static readonly RiskPremiumReport = 'Risk Premium Report';
    public static readonly DiscountedCashFlow = 'Discounted Cash Flow';
    public static readonly StageCashFlow = 'Stage Cash Flow';
    public static readonly FamaFrench = 'Fama-French';
    public static readonly CostOfEquityCapital = 'Cost of Equity Capital';
    public static readonly Wacc = 'WACC';
}
