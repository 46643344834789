<modal [title]="titleString" [selection]="{}" submitButtonText="Continue" [hasCancelButton]="false">
    <form name="acceptForm">
        <div class="">
            <p *ngIf="user">Your subscription expired on {{expirationDate}}.</p>
            <p>Continue to Subscription Options or <a (click)="signout()" class="link">Signout</a> instead.</p>
        </div>
    </form>
</modal>
<!-- <h1 mat-dialog-title [innerHTML]="data.model.titleString"></h1>
<div mat-dialog-content>
    <form name="acceptForm">
        <div class="">
            <p *ngIf="data.model.user">Your subscription expired on {{data.model.expirationDate}}.</p>
            <p>Continue to Subscription Options or <a (click)="signout()" class="link">Signout</a> instead.</p>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button class="btn-dialog" (click)="onClose()">Close</button>
</div> -->