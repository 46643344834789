<nav class="navbar fixed-top navbar-expand-lg navbar-dark" (clickOutside)="closeNav()">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
        aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation" #nav>
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-toggler">
        {{currentRoute}}
    </div>
    <div class="collapse navbar-collapse" id="navbarToggler">
        <ul  class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li *ngFor="let item of mainMenu" class="nav-item">
                <a class="nav-link" [routerLink]="item.route" routerLinkActive="nav-item-active"
                    (click)="onNavigate(item.name)">
                    {{item.name}}
                </a>
            </li>
        </ul>
        <button class="btn btn-info my-2 my-sm-0" (click)="logoutUser()">Logout</button>
    </div>
</nav>
<router-outlet></router-outlet>