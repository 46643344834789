<input-container [(model)]="model" [invalidValue]="invalidValue">
    <div class="common-output">
        <form #form="ngForm" *ngIf="model != null">
            <!-- <input *ngIf="model.source ==='industry0' || model.source ==='industry1'|| model.source ==='industry2' || model.source ==='industry3' || model.source ==='industry4'; else otherThanIndustry" 
                name="textinput" class="common-input" autocomplete="off"
                [attr.disabled]="model.config.disabled ? true : null" [(ngModel)]="model.context"
                (isValid)="emitChange($event)" [form]="form" [gte]="model.config.minimumValue"
                [lte]="model.config.maximumValue" (debounce)="debounce()" delay="450" [pattern]="model.config.pattern"
                [mask]="model.config.mask" #input />
            <ng-template #otherThanIndustry>
                <input name="textinput" class="common-input" autocomplete="off"
                   [attr.disabled]="model.config.disabled ? true : null" [(ngModel)]="model.value"
                   (isValid)="emitChange($event)" [form]="form" [gte]="model.config.minimumValue"
                   [lte]="model.config.maximumValue" (debounce)="debounce()" delay="450" [pattern]="model.config.pattern"
                   [mask]="model.config.mask" #input />
            </ng-template> -->
            <input name="textinput" class="common-input" autocomplete="off"
                [attr.disabled]="model.config.disabled ? true : null" [(ngModel)]="model.value"
                (isValid)="emitChange($event)" [form]="form" [gte]="model.config.minimumValue"
                [lte]="model.config.maximumValue" (debounce)="debounce()" delay="450" [pattern]="model.config.pattern"
                [mask]="model.config.mask" #input />

            <span *ngIf="model.fieldType == fieldTypeInput || model.fieldType == fieldTypeOutput">
                <button *ngIf="model.fieldType == fieldTypeInput" (click)="onImportCellValue()" class="link-to-cell"
                    title="Link the selected cell in your model to this field">
                    <i class="fas fa-link"></i>
                </button>
                <button *ngIf="model.fieldType == fieldTypeOutput" (click)="onBindToCell()" class="link-to-cell"
                    title="Link this field to the selected cell in your model">
                    <i class="fas fa-external-link-alt"></i>
                </button>
            </span>
        </form>
    </div>
</input-container>