<input-container [(model)]="model" [invalidValue]="invalidValue">
        <div class="common-combobox" (clickOutside)="isDropdownActive=false">
        <form #form="ngForm" *ngIf="model != null">
            <input name="typeahead" class="common-input" type="text" autocomplete="off"
                [attr.placeHolder]="model.config.placeholder"
                [attr.readonly]="model.config.readonly ? true : null"
                [attr.disabled]="model.config.disabled ? true : null"
                [attr.required]="model.config.required ? true : null" [(ngModel)]="model.value"
                (ngModelChange)="clearSource()" (isValid)="emitChange($event)" [form]="form"
                [gte]="model.config.minimumValue" [lte]="model.config.maximumValue" (debounce)="debounce()"
                [pattern]="model.config.pattern" [mask]="model.config.mask" #input />
            <span *ngIf="model.fieldType == fieldTypeOutput">
                <button (click)="onBindToCell()" class="link-to-cell" title="Link this field to a cell in your model">
                    <i class="fas fa-external-link-alt"></i>
                </button>
            </span>
            <span *ngIf="model.fieldType === fieldTypeCombobox">
                <button (click)="selectIndustrty(model.value)" class="link-to-cell" title="Link the selected cell in your model to this field">
                    <i class="fas fa-link"></i>
                </button>
            </span>
        </form>
        <ng-container *ngIf="comboboxData != null && comboboxData.length > 0">
            <i class="common-combobox-icon" (click)="isDropdownActive=!isDropdownActive"></i>
            <div class="common-combobox-list" *ngIf="isDropdownActive">
                <ng-container *ngFor="let item of comboboxData">
                    <div class="common-combobox-list-item" *ngIf="item.disabledText == null"
                        (click)="selectItem(item); isDropdownActive=false">
                        {{item.name}}
                    </div>
                    <div class="common-combobox-list-item disabled" *ngIf="item.disabledText">
                        <div>{{item.name}}</div>
                        <div class="disabled-text">{{item.disabledText}}</div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</input-container>