<div class="common-radio-button">
    <input
        class="company-type-radio-button"
        type="radio"
        name="companyTypeInput"
        [value]="model.source"
        [id]="model.source"
        [checked]="model?.config?.checked"
        (change)="onCompanyTypeChange(model)"
    />
    <label [for]="model.source">{{ model?.config?.help?.title }}</label>
</div>
