export enum FieldType {
    Input = 1,
    Output = 2,
    Calculation = 3,
    DatePicker = 4,
    TypeAhead = 5,
    ComboBox = 6,
    RadioButton = 7,
    Suggestion = 8,
    IntlCombobox = 9
}
