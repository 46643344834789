import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ProductType } from './user/enum/product-type';
import { UserStore } from './user/store/user.store';

@Injectable()
export class IsUserHaveIniSubscriptionGuard implements CanActivate {
    constructor(
        private router: Router,
        private userStore: UserStore
    ) { }

    public canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            this.userStore.user.onceDefined((user) => {
                if (user) {
                    const iniSubData = user.subscriptionDetails.find((sub) => sub.productType === ProductType.Ini);
                    if (iniSubData) {
                        if (iniSubData.expiration.asDate() > new Date()) {
                            resolve(true);
                        } else {

                            resolve(false);
                            this.router.navigate(['subscription']);
                        }

                    } else {
                        resolve(false);
                        this.router.navigate(['subscription']);
                    }
                } else {

                    resolve(false);
                    this.router.navigate(['landing']);
                }
            });
        });
    }
}
