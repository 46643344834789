import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserActivityLogType, UserActivityRequest, UserResponse } from '../responses/user.response';
import { Auth0ApiClient } from '../api.client'

@Injectable()
export class UserClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(): Observable<UserResponse> {
        return this.get(`UserProfile`);
    }

    public acceptTerms(marketingOptIn: boolean): Observable<UserResponse> {
        return this.post(`UserProfile/AcceptTerms?marketingOptIn=${marketingOptIn}&version=042020`, {});
    }
    public userActivityLog(userActivity: UserActivityRequest): Observable<any> {
        return this.post(`UserProfile/UserActivityLog?userActivityType=${userActivity}`, {});
    }
}
