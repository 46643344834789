import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogConfiguration } from "src/app/_add-in/dialog/dialog-configuration.model";

@Component({
    selector: "common-dialog-input",
    templateUrl: "./common-dialog-input.component.html",
})
export class CommonDialogInputComponent {
    constructor(
        public dialogRef: MatDialogRef<CommonDialogInputComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogConfiguration
    ) {}

    public onClose(): void {
        this.dialogRef.close(this.data);
    }
}
