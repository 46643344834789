import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Auth0ApiClient } from "../api.client";
import { IntlData } from "../responses/intl-data.response";

export class IntlBetaClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(countryId: number, gicId: number, currencyId: number, dataAsOf: string): Observable<IntlData[]> {
        return this.get(`IntlBeta?countryId=${countryId}&gicId=${gicId}&currencyId=${currencyId}&dataAsOf=${dataAsOf}`);
    }
    public intlRead(gicId: number, currencyId: number, dataAsOf: string): Observable<IntlData[]> {
        return this.get(`IntlBeta/AddIn?gicId=${gicId}&currencyId=${currencyId}&dataAsOf=${dataAsOf}`);
    }
}