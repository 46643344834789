import { Injectable } from "@angular/core";
import { Spinner } from "@concurrency/angular";
import { Util } from "@concurrency/core";
import { WorkbookSheetRow } from "@progress/kendo-angular-excel-export";
import * as moment from "moment";
import { CommonDate } from "src/app/_add-in/common/date-struct";
import { InputBuilder } from "src/app/_add-in/data/input.builder";
import { ExcelStorageService } from "src/app/_add-in/excel/excel-storage.service";
import { CostofEquityClient } from "src/app/_api/clients/costofEquity.client";
import { IntlCOCInvesteeExcelAddinDetails } from "src/app/_api/responses/country-credit-rating.response";
import { IntlData } from "src/app/_api/responses/intl-data.response";
import { IntlCOCExcelFields } from "../intl-coc-type";

@Injectable()
export class IntlCOCExcelExport{
    public columnWidth = 10;       // width of each cell in grid system
    public krollblue = '#14487F'; 
    public green = '#14487F'; 
    public erpDropdownLabels: string[] = [];
    public rfRateDropdownLabels: string[] = [];
    public betaDropdownLabels: string[] = [];

     constructor(
        public excelStorageService: ExcelStorageService,
        public inputBuilder: InputBuilder,
        private spinner: Spinner,
        private costOfEquityClient: CostofEquityClient,
    ) { }
    // public intlCOCInvestDetails: IntlCOCInvesteeExcelAddinDetails[] = [];
    public saveWorksheet(fields: IntlCOCExcelFields, intlCOCInvestDetails: IntlCOCInvesteeExcelAddinDetails[]): WorkbookSheetRow[]{
        return [
        ...this.getBasicDetails(intlCOCInvestDetails),
        ...this.getErpDetails(fields.EquityRiskValues),
        ...this.getRiskFreeRateRows(fields.RiskFreeRates),
        ...this.getBetasRows(fields.LeveredBetas),
        ...this.getInvesteeHeaderRows(intlCOCInvestDetails),
        ...this.getInvesteeRows(intlCOCInvestDetails),
        ...this.getFooterDetails()
        
       ];
    }
    public savekrollTemplateSheet(){
        return[
        ...this.getTemplateBasicDetails(),
        ...this.getTemplateInputs(),
        ...this.getWACCInputs(),
        ...this.getIntlFisherEffectInputs(),
        ...this.getCOECalculations(),
        ...this.getCODCapitalCalculations(),
        ...this.getCurrencyTransactions(),
        ...this.getWACCClaculations(),
        ...this.getCOCConclusions()
        ];
    }
    public getDropdownLabels(): string[]{
        return[
            ...this.erpDropdownLabels,
            ...this.rfRateDropdownLabels,
            ...this.betaDropdownLabels
        ]

    }


    public getBasicDetails(basicFields: IntlCOCInvesteeExcelAddinDetails[]):WorkbookSheetRow[]{
        const crrDataAsOf = moment(basicFields[0].CRDataAsOf.toString()).format('LL');

        const datString = CommonDate.fromStruct(this.inputBuilder.intlCOCValuationDate.value).asString();
        const date: string = moment(datString).format('MMMM DD, YYYY');

       let rows: WorkbookSheetRow[] = [
        {
            cells: [
                {
                    value: 'Investor Country',
                    // colSpan: this.columnWidth,
                    color: this.krollblue,
                    bold: true,
                    fontSize: 12

                },
                {
                    value: 'Country Risk Premia as of'
                },
                

                {
                    value: 'Valuation Date',
                    // colSpan: 1,
                    color: this.krollblue,
                    bold: true,
                    fontSize: 12


                },

                   {
                    value: 'Currency',
                    // colSpan: 1,
                    color: this.krollblue,
                    bold: true,
                    fontSize: 12
                },
                {
                    
                }
            ]
        },
        
        {
         cells:[
            {
                value: this.inputBuilder.intlInvestorCountry.value? this.inputBuilder.intlInvestorCountry.value: 'N/A',
                fontSize: 12
            },
            {
                value: crrDataAsOf
            },
            {
                value: this.inputBuilder.intlCOCValuationDate.value? date: 'N/A',
                fontSize: 12

            },
            {
                value: this.inputBuilder.intlInvestorCurrency.value? this.inputBuilder.intlInvestorCurrency.value: 'N/A',
                fontSize: 12

            }
         ]
        },
        {
            cells:[{
                value: ''
            }]
        },
          {
            cells:[{
                value: ''
            }]
        }
    ];
        return rows;
    }
    public getErpDetails(erpFfields: IntlData[]): WorkbookSheetRow[]{
        const investorCountry = this.inputBuilder.intlInvestorCountry.value;
        const investorCurrency = this.inputBuilder.intlInvestorCurrency.value;
        this.erpDropdownLabels = [];
        let erpRows: WorkbookSheetRow[] = [];
        let erpRow: WorkbookSheetRow;
        let erpHeaders: WorkbookSheetRow;
        erpHeaders = {
            cells: [
                {
                    value: `Equity Risk Premia (ERP) for ${investorCountry}`,
                    // colSpan: this.columnWidth,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true
                },
                {
                    value: ''
                },

                {
                    value: `Value (${investorCurrency})`,
                    // colSpan: 1,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true


                },

                   {
                    value: 'Data as of',
                    // colSpan: 1,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true


                },
                {
                    value: ''
                },
                {
                    value: 'ERP to Use in WACC Template',
                    // colSpan: 1,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true


                }
            ]
        }
        erpRows.push(erpHeaders);
        for(let row = 0; row<= 4; row++){
            if(erpFfields[row]?.Label){

            
            erpRow =    {
            cells:[
                {
                    value: erpFfields[row].Label,
                    fontSize: 10

                },
                {
                    value: ''
                },
                {
                    textAlign: 'left',
                    value: erpFfields[row].Value? Util.roundToFixed(erpFfields[row].Value, 2) + '%' : '-',
                    fontSize: 10,
                },
                {
                    value: erpFfields[row].DataAsOf? moment(erpFfields[row].DataAsOf.toString()).format('MMMM DD, YYYY') : 'N/A',
                    fontSize: 10
                }
            ]
        }
        erpRows.push(erpRow);
        this.erpDropdownLabels.push(erpFfields[row]?.Label);
    }else{
            erpRow =    {
            cells:[
                {
                    value: ''
                }
            ]
        }

        erpRows.push(erpRow);
    }
        }
        this.excelStorageService.loadCOCExcelERPLabels(this.erpDropdownLabels);
        return erpRows;


    }

    public removeTimeStampFromDate(date: string): string {
        if (date && date.includes('T')) {
            return date.split('T')[0];
        }
        return date;
    }
    
    public getRiskFreeRateRows(riskFreeFields: IntlData[]){
        const investorCountry = this.inputBuilder.intlInvestorCountry.value;
        const investorCurrency = this.inputBuilder.intlInvestorCurrency.value;
        this.rfRateDropdownLabels = [];
     let rfRows: WorkbookSheetRow[] = [];
        let rfRow: WorkbookSheetRow;
        let rfHeaders: WorkbookSheetRow;
        rfHeaders = {
            cells:[
               {
                    value: `Risk-free Rates for ${investorCountry}`,
                    // colSpan: this.columnWidth,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true
                },
                {
                    value: ''
                },

                {
                    value: `Value (${investorCurrency})`,
                    // colSpan: 1,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true


                },

                   {
                    value: 'Data as of',
                    // colSpan: 1,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true


                },
                {
                    value: ''
                },
                {
                    value: 'Risk-free Rate to Use in WACC Template',
                    // colSpan: 1,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true


                }
            ]
        }
        rfRows.push(rfHeaders);
        for(let row= 0; row <= 5; row++){
            if(riskFreeFields[row]?.Label){
                rfRow = {
                   cells:[
                {
                    value: riskFreeFields[row].Label? riskFreeFields[row].Label : 'N/A',
                    fontSize: 10

                },
                {
                    value: ''
                },
                {
                    value:  riskFreeFields[row].Value ? Util.roundToFixed(riskFreeFields[row].Value, 2) + '%' : '-',
                    fontSize: 10
                },
                {
                    value: riskFreeFields[row].DataAsOf ? moment(riskFreeFields[row].DataAsOf.toString()).format('MMMM DD, YYYY'): '-',
                    fontSize: 10
                }
                
            ] 
                }
                rfRows.push(rfRow);
                this.rfRateDropdownLabels.push(riskFreeFields[row]?.Label)
            }else{
                rfRow =    {
            cells:[
                {
                    value: ''
                }
            ]
        }

        rfRows.push(rfRow);
            }
        }
        this.excelStorageService.loadCOCExcelRfRateLabels(this.rfRateDropdownLabels);
       return rfRows;

    }
    
    public getBetasRows(betaFields: IntlData[]){
        const investorCurrency = this.inputBuilder.intlInvestorCurrency.value;

        this.betaDropdownLabels = [];
        let betaRows: WorkbookSheetRow[] = [];
        let betaRow: WorkbookSheetRow;
        let betaHeaders: WorkbookSheetRow;
        betaHeaders = {
           cells:[
               {
                    value: 'Levered Betas',
                    // colSpan: this.columnWidth,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true
                },
                {
                    value: ''
                },

                {
                    value: `Value (${investorCurrency})`,
                    // colSpan: 1,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true
                },

                   {
                    value: 'Data as of',
                    // colSpan: 1,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true


                },
                {
                    value: ''
                },
                {
                    value: 'Levered Beta to Use in WACC Template',
                    // colSpan: 1,
                    color: this.krollblue,
                    fontSize: 10,
                    bold: true


                }
                
            ]
        }
        betaRows.push(betaHeaders);
        for(let row=0; row<=8; row++){
            if(betaFields[row]?.Label){
               betaRow = {
                cells:[
                {
                    value: betaFields[row].Label? betaFields[row].Label : 'N/A',
                    fontSize: 10

                },
                {
                    value: ''
                },
                {
                    value: betaFields[row].Value? Util.roundToFixed(betaFields[row].Value, 2) : '-',
                    fontSize: 10,
                    textAlign: 'left'

                },
                {
                    value: betaFields[row].DataAsOf ? moment(betaFields[row].DataAsOf.toString()).format('MMMM DD, YYYY') : 'N/A',
                    fontSize: 10,
                    textAlign: 'left'
                }
            ]
               } 
        this.betaDropdownLabels.push(betaFields[row]?.Label);
        betaRows.push(betaRow);
            }else{
                betaRow = {
            cells:[{
                value: ''
            }]
        }
        betaRows.push(betaRow);
            }
        }
        this.excelStorageService.loadCOCExcelBetaLabels(this.betaDropdownLabels);
        return betaRows;

    }
    public getInvesteeHeaderRows(headerDetails: IntlCOCInvesteeExcelAddinDetails[]): WorkbookSheetRow[]{
        this.spinner.begin();
        const MSCIDataAsOf = moment(headerDetails[0].MSICDataAsOf).format('LL');
        const infDataAsOf = moment(headerDetails[0].InflationDataAsOf).format('LL');
        const crrDataAsOf = moment(headerDetails[0].CRDataAsOf.toString()).format('LL');
        const investorCountry = this.inputBuilder.intlInvestorCountry.value;
        const investorCurrency = this.inputBuilder.intlInvestorCurrency.value;
        const inflationLabel = headerDetails[0].InflationLabel;
        const startIndex = inflationLabel.indexOf('Expected');  
        const inflationLabelHeader = startIndex !== -1?  `Long Term ${inflationLabel.substring(startIndex)}`: null;

        const index = headerDetails[0].IntlTaxRateLabel.search(/\d/);
        const taxRate=headerDetails[0].IntlTaxRateLabel.substring(index); 
        const taxRateHeader = taxRate != null? taxRate : 'N/A';
        let rows: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: `Data as of ${MSCIDataAsOf}`,
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: `Data as of ${infDataAsOf}`,
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: `Country Risk Premia and RV Factors from a ${investorCountry} investor perspective as of ${crrDataAsOf}. Denominated in ${investorCurrency}`,
                        fontSize: 10,
                        colSpan: 3,
                        wrap: true,
                        italic: true
                    }
                ]
            },
            {
                cells: [
                    {
                    value: 'Investee Country',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10
                    },
                    {
                    value: 'Local Currency',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10
                    },
                    {
                    value: 'Region',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10
                    },
                    {
                    value: 'MSCI Market Classification',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10,
                    wrap: true
                    },
                    {
                        value: ''
                    },
                    {
                    value: inflationLabelHeader != null ? inflationLabelHeader: 'N/A',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10,
                    wrap: true
                    },
                    {
                    value: taxRateHeader? taxRateHeader: 'N/A',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10,
                    wrap: true
                    },
                    {
                    value: 'Country Yield Spread Model CRP',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10,
                    wrap: true
                    },
                    {
                    value: 'Country Credit Rating Model (CCR) CRP',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10,
                    wrap: true
                    },
                    {
                    value: 'Relative Volatility Model RV Factor',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10,
                    wrap: true
                    }
                ]
            }
        ];
        this.spinner.end();
        return rows;
        

    }
    // public getRiskFreeRates(fi)
    public getInvesteeRows(investeeDetails: IntlCOCInvesteeExcelAddinDetails[]): WorkbookSheetRow[]{
       this.spinner.begin();
        let rows: WorkbookSheetRow[] = [];
       investeeDetails.forEach((item) =>{
        let row: WorkbookSheetRow = {
            cells: [
            {
                value: item.InvesteeContry,
                textAlign: 'left'
            },
            {
                value: item.Currency,
                textAlign: 'left'
            },
            {
                value: item.Region,
                textAlign: 'left'

            },
            {
                value: item.MSIClassification,
                textAlign: 'left'

            },
            {
                value: ''
            },
            {
                value: item.InflationRate !== 'N/A'? item.InflationRate + '%': 'N/A',
                textAlign: 'left'

            },
            {
                value: item.IntlTaxRate !== 'N/A' ? item.IntlTaxRate+ '%' : 'N/A',
                textAlign: 'left'

            },
            {
                value: item.CysmDp !== 'N/A' ? item.CysmDp + '%': 'N/A',
                textAlign: 'left'

            },
            {
                value: item.CcrCrp !== 'N/A' ? item.CcrCrp + '%': 'N/A',
                textAlign: 'left'

            },
            {
                value: item.RvComplex !== 'N/A' ? parseFloat(item.RvComplex) : 'N/A',
                textAlign: 'left',
                format: '0.0'
            
            },
            ]
        }
        rows.push(row);

       });
       if(investeeDetails.length < 193){
        for(let i=investeeDetails.length; i<193; i++){
            let emptyRows: WorkbookSheetRow ={
                cells: [
                    {
                        value: ''
                    }
                ]
            }
            rows.push(emptyRows);
        }
        }
       
       this.spinner.end();

       return rows;
        
    }

    public getFooterDetails(){
        this.spinner.begin();
       let rows: WorkbookSheetRow[] = [
           {
               cells: [
                   {
                       value: "",
                   },
               ],
           },
           {
               cells: [
                   {
                       value: "Source",
                       // colSpan: 1,
                       color: this.krollblue,
                       fontSize: 10,
                       bold: true,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: "Kroll Cost of Capital Navigator: International Cost of Capital Dataset",
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Exported on: ${moment(
                           new Date().toString()
                       ).format("LL")}`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: "",
                   },
               ],
           },
           {
               cells: [
                   {
                       value: "Data Sources",
                       // colSpan: 1,
                       color: this.krollblue,
                       fontSize: 10,
                       bold: true,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Data Sources used with permission. All rights reserved. Calculations performed by Kroll, LLC.`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: "",
                   },
               ],
           },
           {
               cells: [
                   {
                       value: "Data Type",
                       color: this.krollblue,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: "Underlying Data Source(s):",
                       color: this.krollblue,
                       fontSize: 10,
                       bold: true,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Kroll Normalized Risk-free Rate`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Kroll Research. For more information, please visit the Cost of Capital Resource Center (kroll.com/cost-of-capital-resource-center).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Australia Spot Risk-free Rate`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Reserve Bank of Australia (www.rba.gov.au)`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Canada Spot Risk-free Rate`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Bank of Canada (www.bankofcanada.ca).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Germany Spot Risk-free Rate`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Deutsche Bundesbank (bundesbank.de)`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Japan Spot Risk-free Rate`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Bank of Japan (www.mof.go.jp)`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Switzerland Spot Risk-free Rate`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Swiss National Bank (data.snb.ch)`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `U.K. Spot Risk-free Rate`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Bank of England (bankofengland.co.uk)`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `U.S. Spot Risk-free Rate`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Federal Reserve (federalreserve.gov)`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Kroll Recommended ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Kroll Research. For more information, please visit the Cost of Capital Resource Center (kroll.com/cost-of-capital-resource-center).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Australia Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) Reserve Bank of Australia (www.rba.gov.au).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Austria Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) European Central Bank (sdw.ecb.europa.eu).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Belgium Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) European Central Bank (sdw.ecb.europa.eu).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Canada Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) Bank of Canada (www.bankofcanada.ca).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `France Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) European Central Bank (sdw.ecb.europa.eu).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Germany Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) European Central Bank (sdw.ecb.europa.eu).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Ireland Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) European Central Bank (sdw.ecb.europa.eu).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Italy Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) European Central Bank (sdw.ecb.europa.eu).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Japan Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) Organization for Economic Co-operation and Development.`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Netherlands Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) European Central Bank (sdw.ecb.europa.eu).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `New Zealand Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) Reserve Bank of New Zealand (www.rbnz.govt.nz).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `South Africa Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) Organization for Economic Co-operation and Development.`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Spain Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) European Central Bank (sdw.ecb.europa.eu).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Switzerland Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) Swiss National Bank (data.snb.ch).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `U.K. Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) International Monetary Fund (data.imf.org). (iii) Bank of England (bankofengland.co.uk).`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `U.S. Historical ERP`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) Kroll Research (iii) U.S. Department of the Treasury (iv) U.S. Treasury Yield Curves `,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Australia Historical ERP (1900−Present)`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Dr. Steven Bishop`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Country Risk Premium`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) MSCI Inc. (ii) S&P Global Market Intelligence (iii) Bloomberg (iv) Institutional Investor (v) Euromoney (vi) BMI a Fitch Solutions Company.`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Industry-level Betas`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `(i) S&P Global Market Intelligence (ii) MSCI Inc.`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Long-term Inflation Expectations`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `S&P Global Market Intelligence LLC`,
                       fontSize: 10,
                   },
               ],
           },
           {
               cells: [
                   {
                       value: `Statutory Tax Rates`,
                       fontSize: 10,
                       bold: true,
                   },
                   {
                       value: `Tax Foundation (taxfoundation.org)`,
                       fontSize: 10,
                   },
               ],
           },
       ];
       this.spinner.end();
       return rows;

    }

    public getTemplateBasicDetails():WorkbookSheetRow[]{
       let rows: WorkbookSheetRow[] = [
        {
            cells: [
                {
                    value: 'Investor Country',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10,

                },
                {
                    value: 'Investee Country (select from dropdown)',
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10
                },
                {
                    value: '',

                },
                {
                    colSpan: 3,
                    fontSize: 10
                }
            ]
        },       
        {
         cells:[
            {
                value: '=KrollCountryRiskExport!A2',
                fontSize: 10
            },
            {
                value: ''
            },
            {

            }
         ]
        },
        {
            cells:[{
                 value: 'Valuation Date',
                    // colSpan: this.columnWidth,
                    color: this.krollblue,
                    bold: true,
                    fontSize: 10
            }]
        },
          {
            cells:[
                {
                value: '=TEXT(KrollCountryRiskExport!C2,"mmmm dd, yyyy")',
                fontSize: 10
            }
            ]
        },
        {
            cells:[
                {
               value: 'Inputs',
                    colSpan: 6,
                    bold: true,
                    fontSize: 10,
            },
            ]
        },
        {
                cells:[{
                    value: ''
                }]
            },
    ];
        return rows;
    }

    public getTemplateInputs(): WorkbookSheetRow[]{
        let rows: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: 'Cost of Equity Capital Inputs',
                        fontSize: 10,
                        colSpan: 6
                    }
                ]
            },
            {
                cells:[{
                    value: ''
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            },
            {
                cells:[
                {
                    value: 'Cost of Equity Inputs Country',
                    fontSize: 10
                },
                {
                    value: "=KrollCountryRiskExport!A2",
                    fontSize: 10
                }
            ]
            },
            {
                cells:[
                    {
                        value: 'Required Input',
                        fontSize: 8
                       
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '',
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Risk-free Rate (Rf)',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(KrollCountryRiskExport!F12="","Select Input on KrollCountryRiskExport",VLOOKUP(KrollCountryRiskExport!F12,KrollWACCTemplate!$K$10:$O$13,2,FALSE)),KrollCountryRiskExport!F12)',
                        fontSize: 10
                    }
                    
                ]
            },
            {
                cells:[
                    {
                        value: 'Required Input',
                        fontSize: 8
                      
                    },
                    {
                        value: '=IFERROR(IF(OR(ISNUMBER(B13),B13=""),"","Data as of: "&TEXT(INDEX($M$10:$M$13,MATCH(B13,$L$10:$L$13,0)),"mm/dd/yyyy")),"")',
                        fontSize: 8
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '',
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Levered (Equity) Beta (BETA)',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(KrollCountryRiskExport!F19="","Select Input on KrollCountryRiskExport",VLOOKUP(KrollCountryRiskExport!F19,KrollWACCTemplate!$K$27:$L$34,2,FALSE)),KrollCountryRiskExport!F19)',
                        fontSize: 10
                    }
                    
                ]
            },
            {
                cells:[
                    {
                        value: 'Required Input',
                        fontSize: 8
                        
                    },
                    {
                        value: '=IFERROR(IF(OR(ISNUMBER(B16),B16=""),"","Data as of: "&TEXT(INDEX($M$27:$M$35,MATCH(B16,$L$27:$L$35,0)),"MM/DD/YYYY")),"")',
                        fontSize: 8
                    }
                ]
            },
             {
                cells:[
                    {
                        value: '',
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Equity Risk Premium (ERP)',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(KrollCountryRiskExport!F6="","Select Input on KrollCountryRiskExport",VLOOKUP(KrollCountryRiskExport!F6,KrollWACCTemplate!$K$15:$L$18,2,FALSE)),KrollCountryRiskExport!F6)',
                        fontSize: 10
                    }
                    
                ]
            },
            {
                cells:[
                    {
                        value: 'Required Input',
                        fontSize: 8,
                     
                    },
                    {
                        value: '=IFERROR(IF(OR(ISNUMBER(B19),B19=""),"","Data as of: "&TEXT(INDEX($M$15:$M$18,MATCH(B19,$L$15:$L$18,0)),"mm/dd/yyyy")),"")',
                        fontSize: 8,
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '',
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Size Premium (RPs)',
                        fontSize: 10,
                    }
                    
                ]
            },
            {
                cells:[
                    {
                        value: 'Optional Input - Type in custom value',
                        fontSize: 8,
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '',
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Company Specific Risk Premium (ALPHA)',
                        fontSize: 10,
                    }
                    
                ]
            },
            {
                cells:[
                    {
                        value: 'Optional Input -Type in custom value',
                        fontSize: 8,
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '',
                    }
                ]
            }

        ];
        return rows;
    }

    public getWACCInputs(): WorkbookSheetRow[]{
        let rows: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: 'Cost of Debt and WACC Inputs',
                        fontSize: 10,
                        colSpan: 6
                    }
                ]
            },
            {
               cells:[
                {
                    value: ''
                }
               ] 
            },
            {
                cells:[
                    {
                        value: 'Cost of Debt Country (Select from Dropdown)',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[{
                    value: 'Required Input - Select from dropdown',
                    fontSize: 8
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            },
            {
                cells:[{
                    value: '="Cost of Debt (Pre-Tax) in "&IFERROR(INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(D30,KrollCountryRiskExport!$A$30:$A$222,0)),"(select cost of debt input country)")',
                    fontSize: 10
                }]
            },
            {
                cells:[{
                    value: 'Required Input - Type in Custom Value',
                    fontSize: 8
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            },
            {
                cells:[{
                    value: '="Corporate Income Tax Rate ("&$B$2&")"',
                    fontSize: 10
                }]
            },
            {
                cells:[{
                    value: 'Required Input - Select from dropdown or type in custom value',
                    fontSize: 8
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            },
            {
                cells:[{
                    value: 'Debt-to-Total Capital Ratio',
                    fontSize: 10
                }]
            },
            {
                cells:[{
                    value: 'Required Input - Type in Custom Value',
                    fontSize: 8
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            }

        ];
        return rows;
    }
    public getIntlFisherEffectInputs(): WorkbookSheetRow[]{
        let rows: WorkbookSheetRow[] = [
            {
            cells:[{
                value: 'International Fisher Effect Inputs',
                colSpan: 6,
                fontSize: 10
            }]
            },
            {
                cells:[{
                    value: '=IFERROR(IF(B10="","",IF(AND(B10<>"",D30="",J3=L3),"Your Cost of Equity results are denominated in "&J6&". If you\'d like to complete a full WACC estimate, provide cost of debt inputs. Otherwise, proceed to the next step.",IF(B10="","",IF(AND(B10<>"",D30=""),"Your cost of equity results are denominated in "&J6&". If you would also like to see your cost of equity denominated in "&IF(J6=J3,L3,J3)&", provide inflation inputs below so the international Fisher Effect can be applied",IF(J3=L3,"Your Cost of Equity results and your Cost of Debt results are both denominated in "&J6&". Proceed to the next step.",IF(J6=L6,"Your Cost of Equity results and your Cost of Debt results are both denominated in "&IF(J6=J3,J3,L3)&". If you only want to see your WACC conclusion denominated in "&J6&", proceed to the next step. If you would also like to see your WACC denominated in "&IF(J6=J3,L3,J3)&", enter inflation inputs below so the International Fisher Effect can be applied.","An International Fisher Effect translation is necessary because your Cost of Equity Results and Cost of Debt results are denominated in different currencies. Please provide inflation inputs below so the the International Fisher Effect can be applied.")))))),"")',
                    colSpan: 2,
                    fontSize: 10,
                    color: 'Red',
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            },
            {
                cells:[{
                    value: '="Long-Term Expected Inflation - "&B10',
                    fontSize: 10
                }]
            },
            {
                cells:[{
                    value: 'Select from dropdown or type in custom value',
                    fontSize: 8
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            },
            {
                cells:[{
                    value: '="Long-Term Expected Inflation - "&IF(B10="","",IF(B10=A2,B2,A2))',
                    fontSize: 10
                }]
            },
            {
                cells:[{
                    value: 'Select from dropdown or type in custom value',
                    fontSize: 8
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            }
        ];
        return rows;
    }
    public getCOECalculations(): WorkbookSheetRow[]{
        let rows: WorkbookSheetRow[] = [
            {
                cells:[{
                    value: 'Cost of Equity Capital Calculations',
                    fontSize: 10,
                    colSpan: 6
                }]
            },
            {
                cells:[{
                    value:''
                }]
            },
            {
                cells:[
                    {
                    value:''
                    },
                    {
                    value:'=IF($B$10=$B$2,"Single Country Cost of Capital", "Country Yield Spread Model")',
                    fontSize: 10
                    },
                    {
                    value: ''
                    },
                    {
                    value: '=IF($B$10=$B$2,"", "Country Credit Rating Model")',
                    fontSize: 10
                    },
                    {
                    value: ''    
                    },
                    {
                    value: '=IF($B$10=$B$2,"", "Relative Volatility Model")',
                    fontSize: 10
                    }
            ]
            },
            {
                cells:[
                    {
                    value: 'Model Availability',
                    fontSize: 10 
                    },
                    {
                    value: '=IF($B$55="Single Country Cost of Capital","Available",IF(B$65="N/A","Not Available","Available"))',
                    fontSize: 10
                    },
                    {
                    value: ''
                    },
                    {
                    value: '=IF(D$55="","",IF(D$65="N/A","Not Available","Available"))',
                    fontSize: 10
                    },
                    {
                    value: ''
                    },
                    {
                    value:'=IF(F$55="","",IF(F$65="N/A","Not Available","Available"))',
                    fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'CAPM Equation',
                        fontSize: 10
                    },
                    {
                        value: '=IF(B56="Not Available","N/A",IF(B55="Single Country Cost of Capital","Ke = Rf + Beta x ERP + RPs + ALPHA","Ke = Rf + Beta x ERP + CRP + RPs + ALPHA"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(D56="","",IF(D56="Not Available","N/A","Ke = Rf + Beta x ERP + CRP + RPs + ALPHA"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(F56="","N/A",IF(F56="Not Available","N/A","Ke = Rf + Beta x ERP x RV + RPs + ALPHA"))',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value:''
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Risk-free Rate (Rf)',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),ISNUMBER(B13)),$B$13,IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),INDEX($J$10:$J$13,MATCH($B13,$L$10:$L$13,0)),"N/A")),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(D$65),ISNUMBER(B13)),$B$13,IF(ISNUMBER(D$65),INDEX($J$10:$J$13,MATCH($B13,$L$10:$L$13,0)),"N/A")),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(F$65),ISNUMBER(B13)),$B$13,IF(ISNUMBER(F$65),INDEX($J$10:$J$13,MATCH($B13,$L$10:$L$13,0)),"N/A")),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    }
                ]
            },



            {
                cells:[
                    {
                        value: 'Levered (Equity) Beta (BETA)',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),ISNUMBER(B16)),$B$16,IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),INDEX($J$27:$J$32,MATCH(B16,$L$27:$L$32,0)),"N/A")),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(D$65),ISNUMBER(B16)),$B$16,IF(ISNUMBER(D$65),INDEX($J$27:$J$32,MATCH(B16,$L$27:$L$32,0)),"N/A")),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(F$65),ISNUMBER(B16)),$B$16,IF(ISNUMBER(F$65),INDEX($J$27:$J$32,MATCH(B16,$L$27:$L$32,0)),"N/A")),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '\'x',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'x',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'x',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Equity Risk Premium (ERP)',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),ISNUMBER(B19)),$B$19,IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),INDEX($J$15:$J$18,MATCH(B19,$L$15:$L$18,0)),"N/A")),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(D$65),ISNUMBER(B19)),$B$19,IF(ISNUMBER(D$65),INDEX($J$15:$J$18,MATCH(B19,$L$15:$L$18,0)),"N/A")),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(F$65),ISNUMBER(B19)),$B$19,IF(ISNUMBER(F$65),INDEX($J$15:$J$18,MATCH(B19,$L$15:$L$18,0)),"N/A")),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'x',
                        fontSize: 10
                    }
                ]
            },

            {
                cells:[
                    {
                        value: 'Country Risk Premium (CRP) and/or Relative Volatility (RV) Factor',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF($B$10=$B$2,"CRP Not Needed",INDEX(KrollCountryRiskExport!$H$30:$H$300,MATCH($B$2,KrollCountryRiskExport!$A$30:$A$300,0))),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF($B$10=$B$2,"N/A",INDEX(KrollCountryRiskExport!$I$30:$I$300,MATCH($B$2,KrollCountryRiskExport!$A$30:$A$300,0))),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF($B$10=$B$2,"N/A",INDEX(KrollCountryRiskExport!$J$30:$J$300,MATCH($B$2,KrollCountryRiskExport!$A$30:$A$300,0))),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=IF(OR(ISNUMBER(B65),ISNUMBER(D65),ISNUMBER(F65)),"Data as of: "&TEXT(KrollCountryRiskExport!$B$2,"mm/dd/yyyy"),"")',
                        fontSize: 8
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Size Premium (RPs)',
                        fontSize: 10
                    },
                    {
                        value: '=IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),$B22,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(ISNUMBER(D$65),$B22,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(ISNUMBER(F$65),$B22,"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    }
                ]
            },

            {
                cells:[
                    {
                        value: 'Company Specific Risk Premium (ALPHA)',
                        fontSize: 10
                    },
                    {
                        value: '=IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),$B25,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(ISNUMBER(D$65),$B25,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(ISNUMBER(F$65),$B25,"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '=IFERROR("Cost of Equity Capital (Ke) in "&INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(B10,KrollCountryRiskExport!$A$30:$A$222,0)),"Cost of Equity Capital (Ke) (Required Inputs Not Provided)")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=IFERROR(B59+SUBSTITUTE(B$65,"CRP Not Needed","0")*1+(B61*B63)+B67+B69,"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(D59+D65+(D61*D63)+D67+D69,"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(F59+F65*(F61*F63)+F67+F69,"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    }
                ]
            },
            {
                cells:[{
                    value:''
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            }

        ];
        return rows;
    }
    public getCODCapitalCalculations(): WorkbookSheetRow[]{
        let rows: WorkbookSheetRow[] = [
            {
                cells:[{
                    value: 'Cost of Debt Capital Calculations',
                    fontSize: 10,
                    colSpan: 6,
                    color: 'White'
                }]
            },
            {
                cells:[{
                    value:''
                }]
            },
            {
                cells:[
                    {
                    value:''
                    },
                    {
                    value:'=IF($B$10=$B$2,"Single Country Cost of Capital", "Country Yield Spread Model")',
                    fontSize: 10
                    },
                    {
                    value: ''
                    },
                    {
                    value: '=IF($B$10=$B$2,"", "Country Credit Rating Model")',
                    fontSize: 10
                    },
                    {
                    value: ''    
                    },
                    {
                    value: '=IF($B$10=$B$2,"", "Relative Volatility Model")',
                    fontSize: 10
                    }
            ]
            },
            {
                cells:[
                    {
                    value: 'Model Availability',
                    fontSize: 10 
                    },
                    {
                    value: '=IF($B$55="Single Country Cost of Capital","Available",IF(B$65="N/A","Not Available","Available"))',
                    fontSize: 10
                    },
                    {
                    value: ''
                    },
                    {
                    value: '=IF(D$55="","",IF(D$65="N/A","Not Available","Available"))',
                    fontSize: 10
                    },
                    {
                    value: ''
                    },
                    {
                    value:'=IF(F$55="","",IF(F$65="N/A","Not Available","Available"))',
                    fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Cost of Debt Calculation',
                        fontSize: 10
                    },
                    {
                        value: '=IF(B77="Not Available","N/A",IF(B76="Single Country Cost of Capital","Kd (after-tax) = Kd (pre-tax) x ( 1-t )","Kd (after-tax) = ( Kd (pre-tax) + CRP ) x ( 1-t )"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(D77="","N/A",IF(D77="Not Available","N/A","Kd (after-tax) = ( Kd (pre-tax) + CRP ) x ( 1-t )"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(F77="","N/A",IF(F77="Not Available","N/A","Kd (after-tax) = ( Kd (pre-tax) + ( RV x ERP - ERP ) ) x ( 1-t )"))',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value:''
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=A33',
                        fontSize: 10
                    },
                    {
                        value: '=IF(AND(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),ISNUMBER($B$33)),$B33,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(D$65),ISNUMBER($B$33)),$B33,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(F$65),ISNUMBER($B$33)),$B33,"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    }
                ]
            },



            {
                cells:[
                    {
                        value: 'Country Risk Premium (CRP) and/or Relative Volatility (RV) Factor',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(B80="N/A","N/A",IF($D$30=$B$2,"CRP Not Needed",B$65)),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(D80="N/A","N/A",IF($D$30=$B$2,"CRP Not Needed",D$65)),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(F80="N/A","N/A",IF($D$30=$B$2,"CRP Not Needed",F$65*F63-F63)),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=IF(OR(ISNUMBER(B82),ISNUMBER(D82),ISNUMBER(F82)),"Data as of: "&TEXT(KrollCountryRiskExport!$B$2,"mm/dd/yyyy"),"")',
                        fontSize: 8
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Pre-tax Cost of Debt (Kd (pre-tax))',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR((B80+SUBSTITUTE(B82,"CRP Not Needed",0)),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR((D80+SUBSTITUTE(D82,"CRP Not Needed",0)),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR((F80+SUBSTITUTE(F82,"CRP Not Needed",0)),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '\'x',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'x',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'x',
                        fontSize: 10
                    }
                ]
            },

            {
                cells:[
                    {
                        value: 'Tax Shield (1-t)',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),ISNUMBER(D36)),1-$D$36,IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),1-INDEX($J$40,MATCH(D36,$L$40,0)),"N/A")),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(D$65),ISNUMBER(D36)),1-$D$36,IF(ISNUMBER(D$65),1-INDEX($J$40,MATCH(D36,$L$40,0)),"N/A")),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(AND(ISNUMBER(F$65),ISNUMBER(D36)),1-$D$36,IF(ISNUMBER(F$65),1-INDEX($J$40,MATCH(D36,$L$40,0)),"N/A")),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '=IFERROR(IF(AND(B89="N/A",D89="N/A"),"Cost of Debt (Required Inputs Not Provided)","Cost of Debt in "&INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(D30,KrollCountryRiskExport!$A$30:$A$222,0))&" - After-Tax & Country Risk (Kd (after-tax))"),"Cost of Debt (Required Inputs Not Provided)")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=IFERROR((B80+SUBSTITUTE(B82,"CRP Not Needed",0))*(B87),"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR((D80+SUBSTITUTE(D82,"CRP Not Needed",0))*(D87),"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR((F80+SUBSTITUTE(F82,"CRP Not Needed",0))*(F87),"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    }
                ]
            },
            {
                cells:[{
                    value:''
                }]
            },
            {
                cells:[{
                    value: ''
                }]
            }
        ];
        return rows;
    }
    public getCurrencyTransactions(): WorkbookSheetRow[]{
        let rows: WorkbookSheetRow[] = [
            {
                cells:[
                    {
                        value: 'Currency Translations - International Fisher Effect',
                        fontSize: 10,
                        colSpan: 6
                    }
                ] 
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=IF(B10="","",IF(AND(B10<>"",D30=""),"Your cost of equity results are denominated in "&J6&". The section below translates them into "&IF(J6=J3,L3,J3)&" (only applicable if inflation inputs were provided above).",IF(J3=L3,"Your Cost of Equity results and your Cost of Debt results are both denominated in "&J6&". Proceed to the next section.",IF(AND(J6=L6,D46<>"",D49<>""),"Your Cost of Equity results and your Cost of Debt results are both denominated in "&J6&". The section below translates your cost of capital results into "&INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(IF(B10=A2,B2,A2),KrollCountryRiskExport!$A$30:$A$222,0))&".",IF(J6=L6,"Your Cost of Equity results and your Cost of Debt results are both denominated in "&J6&". If you only want to see your WACC conclusion denominated in "&J6&", proceed to the next section. If you would also like to see your WACC denominated in "&L3&", enter inflation inputs in the inputs section above so the International Fisher Effect can be applied.","An International Fisher Effect translation is necessary because your Cost of Equity Results and Cost of Debt results are denominated in different currencies."&" If you have not already done so, please provide inflation inputs above so the International Fisher Effect can be applied. Cost of Capital results will be displayed in both the home country currency and in the investee country currency.")))))',
                        fontSize: 10,
                        colSpan: 6
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    },
                    {
                        value: '=IF($B$10=$B$2,"Single Country Cost of Capital", "Country Yield Spread Model")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF($B$10=$B$2,"", "Country Credit Rating Model")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF($B$10=$B$2,"", "Relative Volatility Model")',
                        fontSize: 10
                    }
                ]
            },  
            {
                cells: [
                    {
                        value: 'Model Availability',
                        fontSize: 10
                    },
                    {
                        value: '=IF($B$55="Single Country Cost of Capital","Available",IF(B$65="N/A","Not Available","Available"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(D$55="","",IF(D$65="N/A","Not Available","Available"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(F$55="","",IF(F$65="N/A","Not Available","Available"))',
                        fontSize: 10
                    }
                ]
            },    
            {
                cells:[
                    {
                    value: 'Fisher Effect Equation (Ke)',
                    fontSize: 10 
                    },
                    {
                    value: '=IFERROR(IF(B$97="Not Available","N/A",IF($A$105="","N/A","Ke (in "&INDEX($J$3:$L$3,MATCH(FALSE,$J$6=$J$3:$L$3,0))&") = (1+Ke (in "&INDEX($J$3:$L$3,MATCH(TRUE,$J$6=$J$3:$L$3,0))&")) x (1 + inflation ("&INDEX($J$3:$L$3,MATCH(FALSE,$J$6=$J$3:$L$3,0))&")) / (1 + inflation ("&INDEX($J$3:$L$3,MATCH(TRUE,$J$6=$J$3:$L$3,0))&")) - 1")),"N/A")',
                    fontSize: 8
                    },
                    {
                    value: ''
                    },
                    {
                    value: '=IFERROR(IF(D$97="Not Available","N/A",IF($A$105="","N/A","Ke (in "&INDEX($J$3:$L$3,MATCH(FALSE,$J$6=$J$3:$L$3,0))&") = (1+Ke (in "&INDEX($J$3:$L$3,MATCH(TRUE,$J$6=$J$3:$L$3,0))&")) x (1 + inflation ("&INDEX($J$3:$L$3,MATCH(FALSE,$J$6=$J$3:$L$3,0))&")) / (1 + inflation ("&INDEX($J$3:$L$3,MATCH(TRUE,$J$6=$J$3:$L$3,0))&")) - 1")),"N/A")',
                    fontSize: 8
                    },
                    {
                    value: ''
                    },
                    {
                    value:'=IFERROR(IF(F$97="Not Available","N/A",IF($A$105="","N/A","Ke (in "&INDEX($J$3:$L$3,MATCH(FALSE,$J$6=$J$3:$L$3,0))&") = (1+Ke (in "&INDEX($J$3:$L$3,MATCH(TRUE,$J$6=$J$3:$L$3,0))&")) x (1 + inflation ("&INDEX($J$3:$L$3,MATCH(FALSE,$J$6=$J$3:$L$3,0))&")) / (1 + inflation ("&INDEX($J$3:$L$3,MATCH(TRUE,$J$6=$J$3:$L$3,0))&")) - 1")),"N/A")',
                    fontSize: 8
                    }
                ]
            },

            {
                cells:[
                    {
                    value: 'Fisher Effect Equation (Kd)',
                    fontSize: 10 
                    },
                    {
                    value: '=IFERROR(IF(B$97="Not Available","N/A",IF($A$105="","N/A","Kd (in "&INDEX($J$3:$L$3,MATCH(FALSE,$L$6=$J$3:$L$3,0))&") = (1+Kd (in "&INDEX($J$3:$L$3,MATCH(TRUE,$L$6=$J$3:$L$3,0))&")) x (1 + inflation ("&INDEX($J$3:$L$3,MATCH(FALSE,$L$6=$J$3:$L$3,0))&")) / (1 + inflation ("&INDEX($J$3:$L$3,MATCH(TRUE,$L$6=$J$3:$L$3,0))&")) - 1")),"N/A")',
                    fontSize: 8
                    },
                    {
                    value: ''
                    },
                    {
                    value: '=IFERROR(IF(D$97="Not Available","N/A",IF($A$105="","N/A","Kd (in "&INDEX($J$3:$L$3,MATCH(FALSE,$L$6=$J$3:$L$3,0))&") = (1+Kd (in "&INDEX($J$3:$L$3,MATCH(TRUE,$L$6=$J$3:$L$3,0))&")) x (1 + inflation ("&INDEX($J$3:$L$3,MATCH(FALSE,$L$6=$J$3:$L$3,0))&")) / (1 + inflation ("&INDEX($J$3:$L$3,MATCH(TRUE,$L$6=$J$3:$L$3,0))&")) - 1")),"N/A")',
                    fontSize: 8
                    },
                    {
                    value: ''
                    },
                    {
                    value:'=IFERROR(IF(F$97="Not Available","N/A",IF($A$105="","N/A","Kd (in "&INDEX($J$3:$L$3,MATCH(FALSE,$L$6=$J$3:$L$3,0))&") = (1+Kd (in "&INDEX($J$3:$L$3,MATCH(TRUE,$L$6=$J$3:$L$3,0))&")) x (1 + inflation ("&INDEX($J$3:$L$3,MATCH(FALSE,$L$6=$J$3:$L$3,0))&")) / (1 + inflation ("&INDEX($J$3:$L$3,MATCH(TRUE,$L$6=$J$3:$L$3,0))&")) - 1")),"N/A")',
                    fontSize: 8
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=A71',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=B71',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=D71',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=F71',
                        fontSize: 10,
                        color: this.krollblue
                    }
                ]
            },
            {
                cells: [
                    {
                        value: ''
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '=A89',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=B89',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=D89',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=F89',
                        fontSize: 10,
                        color: this.krollblue
                    }
                ]
                },
                {
                    cells: [{
                        value: ''
                    }]
                },
                {
                    cells: [
                        {

                        value: '=IFERROR(IF(AND(B105="",D105=""),"",IF(J3=L3,"",IF(AND(D46="",D30=""),"",A46))),"")',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF($D46="","",IF(AND(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),ISNUMBER($D46)),$D46,IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),L43,"N/A"))),"")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF($D46="","",IF(AND(ISNUMBER(D$65),ISNUMBER($D46)),$D46,IF(ISNUMBER(D$65),$L43,"N/A"))),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF($D46="","",IF(AND(ISNUMBER(F$65),ISNUMBER($D46)),$D46,IF(ISNUMBER(F$65),$L43,"N/A"))),"N/A")',
                        fontSize: 10
                    }
                        
                    ]
                },
                {
                    cells: [{
                        value: ''
                    }]
                },
                {
                    cells: [
                        {

                        value: '=IFERROR(IF(AND(B107="",D107=""),"",IF(J3=L3,"",IF(AND(D49="",D30=""),"",A49))),"")',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(D49="","",IF(AND(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),ISNUMBER(D49)),$D$49,IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),L44,"N/A"))),"")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(D49="","",IF(AND(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),ISNUMBER(D49)),$D$49,IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),L44,"N/A"))),"")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF($D49="","",IF(AND(ISNUMBER(F$65),ISNUMBER($D49)),$D49,IF(ISNUMBER(F$65),$L44,"N/A"))),"N/A")',
                        fontSize: 10
                    }
                        
                    ]
                },
                {
                    cells: [
                        {
                            value: ''
                        }
                    ]
                },
                {
                cells:[
                    {
                        value: '=IF(A105="","",IF(AND(J6=L6,D49=""),"","Cost of Equity Capital (Ke) in "&INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(IF(B10=A2,B2,A2),KrollCountryRiskExport!$A$30:$A$222,0))))',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=IF(A109="","",IF(AND(ISNUMBER(B$71),ISNUMBER($B$105),ISNUMBER($B$107)),(1+B$71)*(1+B$107)/(1+B$105)-1,IF(AND(ISNUMBER(B71),J6<>L6),"Enter Inflation","N/A")))',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF($A109="","",IF(AND(ISNUMBER(D$71),ISNUMBER($D$105),ISNUMBER($D$107)),(1+D$71)*(1+D$107)/(1+D$105)-1,IF(AND(ISNUMBER(D71),J6<>L6),"Enter Inflation","N/A")))',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF($A109="","",IF(AND(ISNUMBER(F$71),ISNUMBER($D$105),ISNUMBER($D$107)),(1+F$71)*(1+F$107)/(1+F$105)-1,IF(AND(ISNUMBER(F71),M6<>N6),"Enter Inflation","N/A")))',
                        fontSize: 10,
                        color: this.krollblue
                    }
                ]
            },
            {
                cells: [
                    {
                        value: ''
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '=IF(AND(B103="N/A",D103="N/A",A109<>""),A103,IF(A109="","",IF(A89="","","Cost of Debt (Kd) in "&INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(IF(D30=A2,B2,A2),KrollCountryRiskExport!$A$30:$A$222,0))&" - After-Tax & Country Risk (Kd (after-tax))")))',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=IFERROR(IF(B109="","",IF(B103="N/A","N/A",IF($D$30=$B$10,(1+B$89)*(1+B$107)/(1+B$105)-1,(1+B$89)*(1+B$105)/(1+B$107)-1))),"Enter Inflation")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(D109="","",IF(D103="N/A","N/A",IF($D$30=$B$10,(1+D$89)*(1+D$107)/(1+D$105)-1,(1+D$89)*(1+D$105)/(1+D$107)-1))),"Enter Inflation")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(F109="","",IF(F103="N/A","N/A",IF($D$30=$B$10,(1+F$89)*(1+F$107)/(1+F$105)-1,(1+F$89)*(1+F$105)/(1+F$107)-1))),"Enter Inflation")',
                        fontSize: 10,
                        color: this.krollblue
                    }
                ]
                },
                {
                    cells: [{
                        value: ''
                    }]
                },
                {
                    cells: [{
                        value: ''
                    }]
                }
                

                    
                
            
            
            
            
        ];
        return rows;
    }
    public getWACCClaculations(): WorkbookSheetRow[]{
        let rows: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: 'Weighted Average Cost of Capital Calculations',
                        fontSize: 10,
                        colSpan: 6
                    }
                ]
            },
            {
                cells: [
                    {
                        value: ''
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '=IF(AND(B76="Country Yield Spread Model",B77="Available",ISNUMBER(B82)),"Apply only the Country Yield Spread Model Cost of Debt to my WACC","")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '=IF(A116="","","Optional - Select \'Yes\' from Dropdown to apply")',
                        fontSize: 8
                    },
                    {
                        value: '=IF($B$10=$B$2,"Single Country Cost of Capital", "Country Yield Spread Model")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF($B$10=$B$2,"", "Country Credit Rating Model")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF($B$10=$B$2,"", "Relative Volatility Model")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Model Availability',
                        fontSize: 10
                    },
                    {
                        value: '=IF($B$55="Single Country Cost of Capital","Available",IF(B$65="N/A","Not Available","Available"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(D$55="","",IF(D$65="N/A","Not Available","Available"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(F$55="","",IF(F$65="N/A","Not Available","Available"))',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'WACC Calculation',
                        fontSize: 10
                    },
                    {
                        value: '=IF(B118="Not Available","N/A","WACC =  Ke x We + Kd (after tax) x Wd")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(D118="","N/A","WACC =  Ke x We + Kd (after tax) x Wd")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(F118="","N/A","WACC =  Ke x We + Kd (after tax) x Wd")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=A71',
                        fontSize: 10
                    },
                    {
                        value: '=B71',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=D71',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=F71',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value:''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Equity-to-Total Capial Ratio',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1),1-$B127,"N/A"),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(ISNUMBER(D$65),1-D127,"N/A"),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF(ISNUMBER(F$65),1-F127,"N/A"),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value:''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=IF(AND(B89="N/A",D89="N/A"),A89,IF($L$6=$J$6,A89,A111))',
                        fontSize: 10
                    },
                    {
                        value: '=IF($L$6=$J$6,B89,IF(B111="","N/A",B111))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(B116="Yes",A116<>""),$B125,IF($L$6=$J$6,D89,IF(D111="","N/A",D111)))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(B116="Yes",A116<>""),$B125,IF($L$6=$J$6,F89,IF(F111="","N/A",F111)))',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value:''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: 'Debt-to-Total Capital Ratio',
                        fontSize: 10
                    },
                    {
                        value: '=IF(AND(ISNUMBER($B39),ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1)),$B39,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(D$65),ISNUMBER($B39)),$B39,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(F$65),ISNUMBER($B39)),$B39,"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value:''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=IFERROR("WACC in "&INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(B10,KrollCountryRiskExport!$A$30:$A$222,0)),"WACC (Required Inputs Not Provided)")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=IFERROR(B127*B125+B123*B121,"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(D127*D125+D123*D121,"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(F127*F125+F123*F121,"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            },

            {
                cells:[
                    {
                        value: '=A109',
                        fontSize: 10
                    },
                    {
                        value: '=B109',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=D109',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=F109',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value:''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    }
                ]
            },


            {
                cells:[
                    {
                        value: 'Equity-to-Total Capial Ratio',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(IF(AND(A134<>"",ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1)),1-$B127,"N/A"),"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(D123),$A$134<>""),D123,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(F123),$A$134<>""),F123,"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value:''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'+',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=IF($L$6=$J$6,A111,A89)',
                        fontSize: 10
                    },
                    {
                        value: '=IF($L$6=$J$6,B111,B89)',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(B116="Yes",A116<>""),$B136,IF($L$6=$J$6,D111,D89))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(B116="Yes",A116<>"",F111<>"N/A"),$B136,IF($L$6=$J$6,F111,F89))',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value:''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'X',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=IF(A136="","","Debt-to-Total Capital Ratio")',
                        fontSize: 10
                    },
                    {
                        value: '=IF(AND(A138<>"",ISNUMBER(SUBSTITUTE(B$65,"CRP Not Needed","0")*1)),$B127,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(D127),$A$138<>""),D127,"N/A")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(F127),$A$138<>""),F127,"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value:''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '\'=',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: '=IF(OR(A109="",A129=""),"","WACC in "&INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(IF(B10=A2,B2,A2),KrollCountryRiskExport!$A$30:$A$222,0)))',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=IFERROR(IF($A140="","",B138*B136+B134*B132),"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF($A140="","",D138*D136+D134*D132),"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IFERROR(IF($A140="","",F138*F136+F134*F132),"N/A")',
                        fontSize: 10,
                        color: this.krollblue
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            },

        ];
        return rows;
    }
    public getCOCConclusions(): WorkbookSheetRow[]{
        let rows: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: 'Cost of Capital Conclusions',
                        fontSize: 10,
                        colSpan: 6
                    }
                ]
            },
            {
                cells: [
                    {
                        value: ''
                    }
                ]
            },
            {
                cells: [
                    {
                        value: ''
                    },
                    {
                        value: '=IF($B$10=$B$2,"Single Country Cost of Capital", "Country Yield Spread Model")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF($B$10=$B$2,"", "Country Credit Rating Model")',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF($B$10=$B$2,"", "Relative Volatility Model")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Model Availability',
                        fontSize: 10
                    },
                    {
                        value: '=IF($B$55="Single Country Cost of Capital","Available",IF(B$65="N/A","Not Available","Available"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(D$55="","",IF(D$65="N/A","Not Available","Available"))',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(F$55="","",IF(F$65="N/A","Not Available","Available"))',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '=IF(AND(ISNUMBER(B71),B89="N/A"),"Weights Assigned to each Cost of Equity Indication","Weights Assigned to each WACC Indication")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '(Weights of available models must add up to 100%)',
                        fontSize: 8,
                        color: this.krollblue
                    }
                ]
            },
            {
                cells: [
                    {
                        value: ''
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '="Indicated "&IF(AND(ISNUMBER(B71),B89="N/A"),A71,A129)&":"',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=IF(AND(ISNUMBER(B71),B89="N/A"),B71,B129)',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(D71),D89="N/A"),D71,D129)',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(F71),F89="N/A"),F71,F129)',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: ''
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Weighted Average',
                        fontSize: 10
                    },
                    {
                        value: '=IF(SUMIF($B$146:$F$146,"Available",$B$147:$F$147)=0,"Enter Weights",IF(SUMIF($B$146:$F$146,"Available",$B$147:$F$147)<>100%,"Available Model Weights must add up to 100%",SUMPRODUCT(B150:F150,$B$147:$F$147)))',
                        fontSize: 10,
                       
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Simple Average',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(AVERAGE(B150:F150),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Median',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(MEDIAN(B150:F150),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '=IF(AND(ISNUMBER(B71),B89="N/A"),"Concluded Cost of Equity","Concluded WACC")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            },

            {
                cells: [
                    {
                        value: '=IF(A140="","","Indicated "&IF(AND(ISNUMBER(B109),B111="N/A"),A109,A140)&":")',
                        fontSize: 10,
                        color: this.krollblue
                    },
                    {
                        value: '=IF(AND(ISNUMBER(B109),B111="N/A"),B109,B140)',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(D109),D111="N/A"),D109,D140)',
                        fontSize: 10
                    },
                    {
                        value: ''
                    },
                    {
                        value: '=IF(AND(ISNUMBER(F109),F111="N/A"),F109,F140)',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: ''
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Weighted Average',
                        fontSize: 10
                    },
                    {
                        value: '=IF(SUMIF($B$146:$F$146,"Available",$B$147:$F$147)=0,"Enter Weights",IF(SUMIF($B$146:$F$146,"Available",$B$147:$F$147)<>100%,"Available Model Weights must add up to 100%",SUMPRODUCT(B157:F157,$B$147:$F$147)))',
                        fontSize: 10,
                       
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Simple Average',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(AVERAGE(B157:F157),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Median',
                        fontSize: 10
                    },
                    {
                        value: '=IFERROR(MEDIAN(B157:F157),"N/A")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells: [
                    {
                        value: '=IF(AND(ISNUMBER(B109),B111="N/A"),"Concluded Cost of Equity","Concluded WACC")',
                        fontSize: 10
                    }
                ]
            },
            {
                cells:[
                    {
                        value: ''
                    }
                ]
            }
        ];
        return rows;
    }
}