import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { CommonDate, DateStruct } from '../date-struct';

@Pipe({ name: 'formatDateStruct' })
export class FormatDateStructPipe implements PipeTransform {
    public transform(value: DateStruct): string {
        return moment(CommonDate.fromStruct(value).asString()).format('MM/DD/YY');
    }
}
