import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { List } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { Dialog } from 'primeng/dialog';
import { IntlCompany, IntlCompanyData } from 'src/app/_api/responses/intl-company.response';
import { CompanyType } from 'src/app/_api/responses/us-company.response';
import { BenchmarkingUtil } from '../../benchmarking.util';
import { CompanyList } from '../../data/company-list';
import { CompanyListItem } from '../../data/company-list-item.model';
import { CompanyTypeName } from '../../data/companyTypeName';
import { AssetsTypes, CurrencyTypes, SalesTypes } from './enums/intl-company-list-enums';

@Component({
    templateUrl: './intl-company-list.component.html',
    styleUrls: ['./intl-company-list.component.scss']
})
export class IntlCompanyListComponent implements OnInit {
    public moment = moment;
    public Util = Util;
    public display = true;
    public sicCode!: string;
    public gicCode!: string;
    public region!: string;
    public currency!: string;
    public companies!: IntlCompanyData;
    public companyList!: List<IntlCompany>;
    public companyType: CompanyType = CompanyType.Default;
    public companyListHeaders: CompanyListItem[] = CompanyList.companyList;
    public companyTypeName: CompanyTypeName = CompanyTypeName.default;
    public dialogHeader!: string;

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
    ) { }

    public ngOnInit(): void {
        if (this.companies.Companies.length) {
            this.companyList = new List(this.companies.Companies, (data) => {
                if (this.companyType === CompanyType.Default) {
                    return data.Type !== CompanyType.HighFinancialRisk;
                } else {
                    return data.Type === this.companyType;
                }
            });
        }

        this.setCompanyType(CompanyType.Default);
        this.setDialogHeader();
    }

    public setCompanyType(type: CompanyType): void {
        this.companyType = type;
        this.companyTypeName = CompanyList.find(type);

        if (this.companyList && this.companyList.all.length) {
            this.companyList.filter('-'); // NOTE: Need to pass a non-null non-empty string or it won't filter
        }

        this.sortBySalesField(this.currency);
        this.sortBy('Name');
    }

    private setDialogHeader(): void {
        this.dialogHeader = BenchmarkingUtil.isSic(this.companies.DataAsOf, this.region)
            ? `Company List - SIC ${this.sicCode} Data as of ${moment(this.companies.DataAsOf).format('MM/DD/YYYY')}`
            : `Company List - GICS ${this.gicCode} Data as of ${moment(this.companies.DataAsOf).format('MM/DD/YYYY')}`;
    }

    public sortBySalesField(currency: string): void {
        const currencyToLower = currency.toLowerCase();

        if (currencyToLower === CurrencyTypes.USD) {
            this.sortBy(SalesTypes.USD);
        } else if (currencyToLower === CurrencyTypes.EUR) {
            this.sortBy(SalesTypes.EUR);
        } else if (currencyToLower === CurrencyTypes.GBP) {
            this.sortBy(SalesTypes.GBP);
        }
    }

    public sortByAssetsField(currency: string): void {
        const currencyToLower = currency.toLowerCase();

        if (currencyToLower === CurrencyTypes.USD) {
            this.sortBy(AssetsTypes.USD);
        } else if (currencyToLower === CurrencyTypes.EUR) {
            this.sortBy(AssetsTypes.EUR);
        } else if (currencyToLower === CurrencyTypes.GBP) {
            this.sortBy(AssetsTypes.GBP);
        }
    }

    public sortBy(field: string): void {
        this.companyList.sort(field);
    }
}
