// tslint:disable:max-line-length
export class Notices {
    public static readonly NoAnalysis = `There is currently no U.S. Industry Benchmarking analysis as of this valuation date or for the industry selected. Only industry full-information betas are available in Step 2: Cost of Capital Equations.`;
    public static readonly NoIndustrySelected = 'You must first select one or more industries on Step 1: General Inputs tab for the U.S. Industry Benchmarking tear sheets to be available.';
    public static readonly IndustryNotInListChecked = `You have selected My industry is not in the list above - to view a US Industry Benchmarking Analysis you need to uncheck the box in Step 1 and add one (1) or more SICs.`;
    public static readonly DateOutOfRange = `The earliest date for data available in the U.S. Industry Benchmarking tearsheet is March 31, 2014.`;

    public static PurchaseModuel2(url: string, region: string): string {
        return `You currently own the US Cost of Capital Navigator (Module 1). If you would like to purchase the ${region} Industry Benchmark tool (Module 2), <a href="${url}" target="_blank">click here</a>.`;
    }
}
