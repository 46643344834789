import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatTabsModule } from "@angular/material/tabs";
import { DialogModule } from "primeng/dialog";
import { CommonModules } from "../_config/common-modules";
import { BenchmarkingComponent } from "./benchmarking.component";
import { BenchmarkingService } from "./benchmarking.service";
import { IndustryInputsComponent } from "./components/industry-inputs.component";
import { IntlIndustryInputsComponent } from "./components/intl-industry-inputs.component";
import { ViewIndustriesComponent } from "./components/view-industries/view-industries.component";
import { BenchmarkingQueryBuilderService } from "./data/benchmarking-query-builder.service";
import { BenchmarkingExcelExportService } from "./exports/benchmarkingexcel-export.service";
import { IntlCOCExcelExport } from "./exports/util/intl-coc-export.util";
import { CompanyListNoIndustryModalComponent } from "./modals/company-list-no-industry-modal.component";
import { IndustryListNoDetailsSelectedComponent } from "./modals/industry-list-no-details-selected.component";
import { IntlCompanyListComponent } from "./modals/intl-company-list/intl-company-list.component";
import { IntlCOCSheetService } from "./tear-sheet/data/intl-costofcapital-sheet.service";
import { TearSheetService } from "./tear-sheet/data/tear-sheet.service";
import { TrendsOverTimeService } from "./tear-sheet/data/trends-over-time.service";

@NgModule({
    declarations: [
        BenchmarkingComponent,
        IndustryInputsComponent,
        ViewIndustriesComponent,
        IntlCompanyListComponent,
        CompanyListNoIndustryModalComponent,
        IndustryListNoDetailsSelectedComponent,
        IntlIndustryInputsComponent,
    ],
    imports: [
        ...CommonModules,
        MatExpansionModule,
        MatIconModule,
        MatTabsModule,
        MatDividerModule,
        MatListModule,
        MatDialogModule,
        DialogModule,
    ],
    providers: [
        BenchmarkingExcelExportService,
        TearSheetService,
        TrendsOverTimeService,
        BenchmarkingQueryBuilderService,
        BenchmarkingService,
        IntlCOCSheetService,
        IntlCOCExcelExport
    ],
})
export class BenchmarkingModule {}
