import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from "src/environments/environment";
import { Auth0ApiClient } from "../api.client";
import { Country } from '../responses/country.response';

@Injectable()
export class CountryClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public investors(): Observable<Country[]> {
        return this.get(`Country/Investors`);
    }
}