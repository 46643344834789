<script src="../../assets/scripts/office-js/office.js"></script>
<nav class="fixed-top navbar-expand-lg navbar-dark">
    <!-- <span></span> -->
    <div class="navbar-toggler float-container display-flex">
        <span class="nav-title">{{currentRoute}}</span>
        <div class="navbarlogout float-child">
            <button class="btn btn-info " (click)="logoutUser()">Logout</button>
        </div>
    </div>
</nav>
<div class="common-inputs-content intlIndustrydialog">
    <div class="intlRowHeader">
        <common-tab-header [headerText]="header"></common-tab-header>
        </div>
        <div class="intlRow">

        <div class="drpdownscntainer fstdrpdown">
            <common-datepicker [config]="builder.intlCOCValuationDate.config" [(model)]="builder.intlCOCValuationDate"
                (modelChange)="updateValuationDate(builder.intlCOCValuationDate.value)">
            </common-datepicker>
        </div>

        <div class="drpdownscntainer secondrop"> 
        <common-intlcombobox [config]="excelStorageService.inputBuilder.intlInvestorCountry.config" 
        [(model)]="excelStorageService.inputBuilder.intlInvestorCountry" 
        [comboboxData]="excelStorageService.investorCountriesComboOptions" 
        (modelChange)="excelStorageService.reflectInvestor(excelStorageService.inputBuilder.intlInvestorCountry)">
         </common-intlcombobox>   
        </div>

        </div>
        <div class="intlMat">
        <mat-divider></mat-divider>

        </div>
        <div style="margin-top: '8px';">Industry Betas (Optional)</div>
        <div class="IntlRow">
        
            <!-- <div class="drpdownscntainer fstdrpdown intlCurrency">
                <common-combobox [config]="excelStorageService.inputBuilder.intlInvestorCurrency.config" [(model)]="excelStorageService.inputBuilder.intlInvestorCurrency"
                    [comboboxData]="excelStorageService.investorCurrencyComboOptions" (modelChange)="excelStorageService.getIntlIndustries(excelStorageService.inputBuilder.intlInvestorCurrency)">
                </common-combobox>
            </div>
     -->
            <!-- <div class="drpdownscntainer secondrop">
                <common-combobox [config]="builder.intlIndustry.config" [(model)]="builder.intlIndustry"    
                    [comboboxData]="intlIndustryComboOptions">
                </common-combobox>
        
        
            </div> -->
        
        </div>
        <div>
            <div class="" style="margin-top: 12px;">Selected Industry:
        
                <span style="display: inline;">
                    <label class="singleDisplay" *ngFor="let sectedIndustry of excelStorageService.lstSelectedIndustries let i=index">
                        {{'GICS' + ' ' + sectedIndustry.GicCode + ' ' + '-' + ' ' + sectedIndustry.GicDescription}}
                        &nbsp;
                        <span aria-hidden="true" id="sectedIndustry.CodeId" (click)="excelStorageService.removeIndustry(i,false)"><b
                                class="remove-btn">×</b></span>
                    </label>
                </span>
        
        
                <span calss="col-6 row p-1 text-wrap">
                    <i (click)="excelStorageService.SelectIndustries()" class="ms-Icon ms-Icon--CirclePlus plus"
                        [ngClass]="{'plusgray':((!builder.intlCOCValuationDate.value) || (!excelStorageService.inputBuilder.intlInvestorCountry.value) || (excelStorageService.lstFinalSelectedIndustry?.length > 0)),'plus':((builder.intlCOCValuationDate.value) &&(excelStorageService.intlIndustryOptions.length > 0) && (excelStorageService.lstFinalSelectedIndustry?.length === 0)) }"></i>
                </span>
            </div>
        
        </div>
        <div *ngIf="excelStorageService.isIndustryNotAvailable" class="industry-not-available">
            <div>
                <i class="ms-Icon ms-Icon--Info  alert-not-available"></i>
            </div>
            <div>
                Industry betas are not available for this scenario. Industry betas are available for the following
                Investor Countries: (i) the United States (in USD), (ii) the United Kingdom (in GBP),
                and (iii) all countries on the Euro (EUR).
            </div>
        </div>
        <p-dialog [(visible)]="excelStorageService.isIndustryDisplay" [modal]="true" [responsive]="true"
            [style]="{width: '96%', minWidth: '200px', margin: 'auto',height:'93%'}" [maximizable]="true" [baseZIndex]="10000"
            [header]="'Select an Industry (only 1)'" (onHide)="excelStorageService.close('close')">
            <div class="paddingIndustry">
                <!-- <div class="scenario"> -->
        
                <div class="searchIntlRow row">
        
        
                    <input type="text" [(ngModel)]="searchValue" name="typeahead" class="common-input search-intlInput"
                        placeholder="Search By Industry Code or Industry Description" (ngModelChange)="excelStorageService.onIndustrySearch($event)" />       
                </div>
        
                <div class="selectIntlIndustries row">
                    <label class="labelIndustry">Industry Selection:</label>
                    <button class="singleDisplay indulablels i col-3"
                        *ngFor="let userSelection of excelStorageService.lstSelectedIndustries let i=index">
                         {{'GICS' + ' ' + userSelection.GicCode + ' ' + '-' + ' ' + userSelection.GicDescription}}
                        &nbsp;
                        <span aria-hidden="true" (click)="excelStorageService.removeIndustry(i,true)" id="userSelection.CodeId"><b
                                class="remove-btn">×</b></span>
                    </button>
                </div>
        
                <!-- <div class="row mt-2"> -->
        
        
                <div *ngIf="excelStorageService.lstIndustries.length > 0" class="row mt-2 Industriescls">
                    <table class="table table-striped">
                        <tbody>
                            <tr *ngFor="let industry of excelStorageService.lstIndustries">
                                <td>
                                    <input type="checkbox" class="checkmark" (change)="excelStorageService.checkCheckBoxvalue($event,industry)"
                                        id="{{industry.GicId}}" [checked]="industry.isSelected"
                                        [(ngModel)]="industry.isSelected" [disabled]="industry.isDisabled">
        
        
        
                                    &nbsp;&nbsp;&nbsp;
                                    {{'GICS' + ' ' + industry.GicCode + ' ' + '-' + ' ' + industry.GicDescription}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        
            </div>
            <p-footer class="row btnfooter">
                <div>
        
                    <button [disabled]="excelStorageService.lstSelectedIndustries.length===0" class="mlauto btnstyle" (click)="excelStorageService.addIndustries()">
                        Add Industries
                    </button>
                </div>
            </p-footer>
            <!-- </div> -->
        
        
        
        
        </p-dialog>
        <div  class="intlButton">
            <span class="drpdownscntainer fstdrpdownbutton">
                <button 
                [disabled]="!excelStorageService.isRefreshDataEnable"
                (click)="onExportToExcel()">
                    Export/Refresh Data
                </button>
            </span>
            <span class="drpdownscntainer secondropbutton">
                <button 
                [disabled]="!excelStorageService.isExportTemplateEnable"
                (click)="onExportToExcelTemplate()">
                    Export WACC Template
                </button>
            </span>
        </div>
</div>
