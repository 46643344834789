<p-dialog [(visible)]="display" [modal]="true" [responsive]="true"
    [style]="{width: '100%', minWidth: '200px', margin: 'auto'}" [maximizable]="true" [baseZIndex]="10000"
    [header]="dialogHeader">
    <select (change)="setCompanyType($event.target.value)" title="Company List">
        <option *ngFor="let header of companyListHeaders" [value]="header.companyType">{{header.companyTypeName}}
        </option>
    </select>
    <table *ngIf="companyList && companyList.filtered.length !== 0; else noCompanyMsgBlock">
        <thead>
            <tr>
                <th class="width-40-percentage" (click)="sortBy('Name')">
                    <span>Company Name</span>
                    <span *ngIf="companyList.sortField === 'Name'">
                        <span *ngIf="companyList.sortDirection==='up'">
                            <i class="fa fa-caret-up sort-arrow"></i>
                        </span>
                        <span *ngIf="companyList.sortDirection==='down'">
                            <i class="fa fa-caret-down sort-arrow"></i>
                        </span>
                    </span>
                </th>
                <th (click)="sortBySalesField(currency)">
                    <!-- <span>Sales (in $mm)</span> -->
                    <span>Sales ([{{currency}}] in millions)</span>
                    <span>
                        <span *ngIf="companyList.sortDirection==='up'">
                            <i class="fa fa-caret-up sort-arrow"></i>
                        </span>
                        <span *ngIf="companyList.sortDirection==='down'">
                            <i class="fa fa-caret-down sort-arrow"></i>
                        </span>
                    </span>
                </th>
                <th (click)="sortByAssetsField(currency)">
                    <!-- <span>Assets (in $mm)</span> -->
                    <span>Assets ([{{currency}}] in millions)</span>
                    <span>
                        <span *ngIf="companyList.sortDirection==='up'">
                            <i class="fa fa-caret-up sort-arrow"></i>
                        </span>
                        <span *ngIf="companyList.sortDirection==='down'">
                            <i class="fa fa-caret-down sort-arrow"></i>
                        </span>
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>

            <tr *ngFor="let company of companyList.filtered">
                <td>{{company.Name}}</td>
                <td *ngIf="company.Sales" class="right">{{Util.round(company.Sales, 0) | number}}</td>
                <td *ngIf="company.SalesUsd" class="right">{{Util.round(company.SalesUsd, 0) | number}}</td>
                <td *ngIf="company.SalesEur" class="right">{{Util.round(company.SalesEur, 0) | number}}</td>
                <td *ngIf="company.SalesGbp" class="right">{{Util.round(company.SalesGbp, 0) | number}}</td>
                <td *ngIf="company.Assets" class="right">{{Util.round(company.Assets, 0) | number}}</td>
                <td *ngIf="company.AssetsUsd" class="right">{{Util.round(company.AssetsUsd, 0) | number}}</td>
                <td *ngIf="company.AssetsEur" class="right">{{Util.round(company.AssetsEur, 0) | number}}</td>
                <td *ngIf="company.AssetsGbp" class="right">{{Util.round(company.AssetsGbp, 0) | number}}</td>
            </tr>
        </tbody>
    </table>
    <ng-template #noCompanyMsgBlock>
        <div class="no-company-msg-block">
            {{companyTypeName}} analysis was not calculated for this industry under the valuation date.
        </div>
    </ng-template>
    <div class="text-muted">Source: Kroll Cost of Capital Navigator (<a href="https://dpcostofcapital.com"
            target="_blank" rel="noopener noreferrer">dpcostofcapital.com</a>)</div>
</p-dialog>