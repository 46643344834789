import { Injectable } from '@angular/core';
import { Menu } from '../menu/menu';
import { DataPointModel } from './summary-datapoints.model';

@Injectable()
export class DataPoint {
    [index: string]: DataPointModel;

    public valuationDate: DataPointModel = {
        title: `Valuation Date`,
        tabName: Menu.Inputs
    };
    public industry: DataPointModel = {
        title: `Industry`,
        tabName: Menu.Inputs
    };
    public homeCountry: DataPointModel = {
        title: `Home Country`,
        tabName: Menu.Inputs
    };
    public investeeCountry: DataPointModel = {
        title: `Investee Country`,
        tabName: Menu.Inputs
    };
    public marketCap: DataPointModel = {
        title: `Market Value of Common Equity ($mm)`,
        tabName: Menu.Inputs
    };
    public bookValueOfEquity: DataPointModel = {
        title: `Book Value of Equity ($mm)`,
        tabName: Menu.Inputs
    };
    public fiveYrAvgNetIncome: DataPointModel = {
        title: `5-Year Average Net Income ($mm)`,
        tabName: Menu.Inputs
    };
    public MVIC: DataPointModel = {
        title: `Market Value of Invested Capital ($mm)`,
        tabName: Menu.Inputs
    };
    public totalAssets: DataPointModel = {
        title: `Total Assets ($mm)`,
        tabName: Menu.Inputs
    };
    public fiveYrAvgEBITDA: DataPointModel = {
        title: `5-Year Average EBITDA ($mm)`,
        tabName: Menu.Inputs
    };
    public sales: DataPointModel = {
        title: `Sales ($mm)`,
        tabName: Menu.Inputs
    };
    public numberOfEmployees: DataPointModel = {
        title: `# of Employees`,
        tabName: Menu.Inputs
    };
    public debtToEquity: DataPointModel = {
        title: `Debt to Equity`,
        tabName: Menu.Inputs
    };
    public netSalesYear0: DataPointModel = {
        title: `Net Sales (Y-0) ($mm)`,
        tabName: Menu.Inputs
    };
    public netSalesYear1: DataPointModel = {
        title: `Net Sales (Y-1) ($mm)`,
        tabName: Menu.Inputs
    };
    public netSalesYear2: DataPointModel = {
        title: `Net Sales (Y-2) ($mm)`,
        tabName: Menu.Inputs
    };
    public netSalesYear3: DataPointModel = {
        title: `Net Sales (Y-3) ($mm)`,
        tabName: Menu.Inputs
    };
    public netSalesYear4: DataPointModel = {
        title: `Net Sales (Y-4) ($mm)`,
        tabName: Menu.Inputs
    };
    public operatingIncomeYear0: DataPointModel = {
        title: `Operating Income (Y-0) ($mm)`,
        tabName: Menu.Inputs
    };
    public operatingIncomeYear1: DataPointModel = {
        title: `Operating Income (Y-1) ($mm)`,
        tabName: Menu.Inputs
    };
    public operatingIncomeYear2: DataPointModel = {
        title: `Operating Income (Y-2) ($mm)`,
        tabName: Menu.Inputs
    };
    public operatingIncomeYear3: DataPointModel = {
        title: `Operating Income (Y-3) ($mm)`,
        tabName: Menu.Inputs
    };
    public operatingIncomeYear4: DataPointModel = {
        title: `Operating Income (Y-4) ($mm)`,
        tabName: Menu.Inputs
    };
    public operatingMargin: DataPointModel = {
        title: `Operating Margin`,
        tabName: Menu.Inputs

    };
    public CVOfOperatingMargin: DataPointModel = {
        title: `CV of Operating Margin`,
        tabName: Menu.Inputs

    };
    public netIncomeYear0: DataPointModel = {
        title: `Net Income Year (Y-0) ($mm)`,
        tabName: Menu.Inputs
    };
    public netIncomeYear1: DataPointModel = {
        title: `Net Income Year (Y-1) ($mm)`,
        tabName: Menu.Inputs
    };
    public netIncomeYear2: DataPointModel = {
        title: `Net Income Year (Y-2) ($mm)`,
        tabName: Menu.Inputs
    };
    public netIncomeYear3: DataPointModel = {
        title: `Net Income Year (Y-3) ($mm)`,
        tabName: Menu.Inputs
    };
    public netIncomeYear4: DataPointModel = {
        title: `Net Income Year (Y-4) ($mm)`,
        tabName: Menu.Inputs
    };
    public bookValueOfEquityYear0: DataPointModel = {
        title: `Book Value of Equity (Y-0) ($mm)`,
        tabName: Menu.Inputs
    };
    public bookValueOfEquityYear1: DataPointModel = {
        title: `Book Value of Equity (Y-1) ($mm)`,
        tabName: Menu.Inputs
    };
    public bookValueOfEquityYear2: DataPointModel = {
        title: `Book Value of Equity (Y-2) ($mm)`,
        tabName: Menu.Inputs
    };
    public bookValueOfEquityYear3: DataPointModel = {
        title: `Book Value of Equity (Y-3) ($mm)`,
        tabName: Menu.Inputs
    };
    public bookValueOfEquityYear4: DataPointModel = {
        title: `Book Value of Equity (Y-4) ($mm)`,
        tabName: Menu.Inputs
    };
    public returnOnEquity: DataPointModel = {
        title: `Return on Equity`,
        tabName: Menu.Inputs
    };
    public CVOfReturnOnEquity: DataPointModel = {
        title: `CV of Return On Equity`,
        tabName: Menu.Inputs

    };
    public currentYearEBIT: DataPointModel = {
        title: `Current Year EBIT`,
        tabName: Menu.Inputs
    };
    public currentYearSales: DataPointModel = {
        title: `Current Year Sales`,
        tabName: Menu.Inputs
    };
    public currentAssets: DataPointModel = {
        title: `Current Assets`,
        tabName: Menu.Inputs
    };
    public currentLiabilities: DataPointModel = {
        title: `Current Liabilities`,
        tabName: Menu.Inputs
    };
    public retainedEarnings: DataPointModel = {
        title: `Retained Earnings`,
        tabName: Menu.Inputs
    };
    public ZScore: DataPointModel = {
        title: `z-Score`,
        tabName: Menu.Inputs
    };
}
