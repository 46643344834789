import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/_add-in/user/model/user.model';
import { UserStore } from 'src/app/_add-in/user/store/user.store';

@Component({
    selector: 'expiration-modal',
    templateUrl: './expiration-modal.component.html'
})
export class ExpirationModalComponent extends SubscriberEntity implements OnInit {
    public user: User = {} as any;
    public expirationDate?: string;
    public titleString?: string;

    constructor(
        private userStore: UserStore,
        private router: Router
    ) { super(); }

    public ngOnInit(): void {
        // TODO: Obviate takeUntil by using Async pipes and local Observable streams
        this.userStore.user.pipe(takeUntil(this.destroyed)).onceDefined((user) => {
            this.user = user;
            this.expirationDate = moment(user.Subscriptions[0].End).format('MM/DD/YYYY');
            this.titleString = '<div>Notice!</div> Your subscription has ended';
        });
    }

    public signout(): void {
        this.userStore.logoutCustomerOrEmployee();
        this.router.navigate(['landing']);
    }
}
