import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalManager, Spinner, ngbDateStructToString } from '@concurrency/angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccordionTextService } from 'src/app/_add-in/accordion/accordion-text.service';
import { CommonDate, DateStruct } from 'src/app/_add-in/common/date-struct';
import { CommonInputConfig } from 'src/app/_add-in/common/inputs/common-user-input.component';
import { FieldService } from 'src/app/_add-in/data/field.service';
import { InputBuilder } from 'src/app/_add-in/data/input.builder';
import { SuggestedInput } from 'src/app/_add-in/data/suggested-input.model';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';
import { benchMarkingValuationDate } from 'src/app/_add-in/help/help-text';
import { IndustryService } from 'src/app/_add-in/industry/industry.service';
import { PrimeManager } from 'src/app/_add-in/modal/pmodal.manager';
import { ProductType } from 'src/app/_add-in/user/enum/product-type';
import { SubscriptionLevel } from 'src/app/_add-in/user/enum/subscription-level';
import { SubscriptionData } from 'src/app/_add-in/user/model/subscription.model';
import { User } from 'src/app/_add-in/user/model/user.model';
import { UserStore } from 'src/app/_add-in/user/store/user.store';
import { SubscriptionUtil } from 'src/app/_add-in/user/util/subscription.util';
import { UserUtil } from 'src/app/_add-in/user/util/user.util';
import { CurrencyClient } from 'src/app/_api/clients/currency.client';
import { IndustryClient } from 'src/app/_api/clients/industry.client';
import { RegionClient } from 'src/app/_api/clients/region.client';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { GenericTabComponent } from 'src/app/estimate/generic-tab.component';
import { BenchmarkingService } from '../benchmarking.service';
import { BenchmarkingQueryBuilderService } from '../data/benchmarking-query-builder.service';
import { IndustryScenario } from '../data/industry-scenario';
import { BenchmarkingExcelExportService } from '../exports/benchmarkingexcel-export.service';
import { CompanyListNoIndustryModalComponent } from '../modals/company-list-no-industry-modal.component';
import { IndustryListNoDetailsSelectedComponent } from '../modals/industry-list-no-details-selected.component';
import { IntlCompanyListComponent } from '../modals/intl-company-list/intl-company-list.component';
import { TearSheetService } from '../tear-sheet/data/tear-sheet.service';
import { TrendsOverTimeService } from '../tear-sheet/data/trends-over-time.service';

@Component({
    selector: 'app-industry-inputs',
    templateUrl: './industry-inputs.component.html'
})
export class IndustryInputsComponent extends GenericTabComponent implements OnInit {

    public regionStrings = new Array<string>();
    public regionComboOptions: SuggestedInput[] = [];
    public currencyStrings = new Array<string>();
    public currencyOptions: SuggestedInput[] = [];
    public onloadClear = false;
    public _showScenario = new BehaviorSubject<boolean>(false);
    public inputData!: User;
    public errorMessages: SafeHtml[] = [];
    public minimumDate = -1;
    public preserveValuationDate: DateStruct | undefined;
    private subscriptionWithProPlusLevel: SubscriptionData | undefined;
    private iniSubData: SubscriptionData | undefined;
    private usiSubData: SubscriptionData | undefined;
    private _header = '';
    private IsMod4: boolean | undefined = false;
    public isIndustryDisplay: boolean = false;
    public lstIndustries: Industry[] = [];
    public tempIndustries: Industry[] = [];
    public lstSelectedIndustries: Industry[] = [];
    public lstFinalSelectedIndustry: Industry[] = [];
    public maximumNumberOfIndustries = 5
    public searchValue: any;
    public isAddClicked: any;
    private _check: string = '';


    public get header(): string {
        return this._header;
    }
    public set header(value: string) {
        this._header = value;
    }
    constructor(
        public excelStorageService: ExcelStorageService,
        public userStore: UserStore,
        public builder: InputBuilder,
        public spinner: Spinner,
        public headers: AccordionTextService,
        private regionClient: RegionClient,
        private currencyClient: CurrencyClient,
        public fieldService: FieldService,
        public industryService: IndustryService,
        private industryClient: IndustryClient,
        public tearSheetService: TearSheetService,
        public benchmarkingExcelExportService: BenchmarkingExcelExportService,
        private modalManager: ModalManager,
        private primeManager: PrimeManager,
        private benchmarkingService: BenchmarkingService,
        private sanitizer: DomSanitizer,
        public trendsOverTimeService: TrendsOverTimeService,
        private benchmarkingQueryBuilderService: BenchmarkingQueryBuilderService

    ) {

        super(excelStorageService, userStore, builder);
        this.header = headers.getAccordionText('benchmarkingInputs');
    }

    private setValuationDateConfigBasedOnSubscription(config: CommonInputConfig): void {
        if (config.minimumValue && config.maximumValue
            && this.builder.benchMarkingValuationDate.config && this.builder.benchMarkingValuationDate.config.minimumValue
            && this.builder.benchMarkingValuationDate.config.maximumValue) {
            if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)
                <= (CommonDate.fromStruct(this.builder.benchMarkingValuationDate.config.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)
                && CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)
                >= CommonDate.fromStruct(this.builder.benchMarkingValuationDate.config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
                this.builder.benchMarkingValuationDate.config = {
                    ...this.builder.benchMarkingValuationDate.config,
                    ...config
                };
            } else if (CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) >=
                CommonDate.fromStruct(this.builder.benchMarkingValuationDate.config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
                this.builder.benchMarkingValuationDate.config.maximumValue = config.maximumValue;
            } else if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)
                <= (CommonDate.fromStruct(this.builder.benchMarkingValuationDate.config.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)) {
                this.builder.benchMarkingValuationDate.config.minimumValue = config.minimumValue;
            }
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
        try {
            this.spinner.begin()
        combineLatest([
            this.userStore.user,
            this.regionClient.read(),
            // this.currencyClient.readIndustry(),
            this.userStore.valuesClient.getBasicMinimumDate()

        ]).pipe(
            map((x) => ({
                user: x[0],
                regions: x[1],
                // currencyIndustries: x[2],
                values: x[2]
            }))
        ).subscribe((data) => {
            if (data.user) {
                this.IsMod4 = false;
                this.inputData = data.user;
                this.iniSubData = data.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Ini);
                this.usiSubData = data.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Usi);
                this.subscriptionWithProPlusLevel = data.user.subscriptionDetails.find(
                    (sub) => {
                        return sub.level === SubscriptionLevel.Pro ||
                            sub.level === SubscriptionLevel.Enterprise;
                    }
                );
            }
            if (data.values) {
                if (this.iniSubData && !this.usiSubData) {
                    this.minimumDate = data.values.InternationalEstimates;
                }

                if (this.usiSubData && !this.iniSubData) {
                    this.minimumDate = data.values.UsIndustries;
                }

                if (this.usiSubData && this.iniSubData) {
                    this.minimumDate = data.values.InternationalEstimates;
                }
            }

            const region = this.builder.benchMarkingRegion;
            data.regions.forEach((r) => {
                this.regionComboOptions.push({
                    name: r,
                    value: r,
                    source: region.source,
                    dataPoint: '',
                    dataAsOf: ''
                });
            });
            this.spinner.end();
            const currency = this.builder.benchMarkingCurrency;
            const request = this.currencyClient.readIndustry();
            this.spinner.begin();
            request.once((currencyIndustries) => {
                this.spinner.end();
                currencyIndustries.forEach((r) => {
                    this.currencyOptions.push({
                        name: r,
                        value: r,
                        source: currency.source,
                        dataPoint: '',
                        dataAsOf: ''
                    });
                });
            })

            this.builder.benchMarkingCurrencies.value = this.currencyOptions;

            const ngbDate = this.preserveValuationDate = this.builder.benchMarkingValuationDate.value as NgbDateStruct;
            const date = this.builder.benchMarkingValuationDate.value ?
                new CommonDate(ngbDate.year, ngbDate.month, ngbDate.day) :
                (this.usiSubData && this.usiSubData.level === SubscriptionLevel.Trial ?
                    SubscriptionUtil.getMinimumDate(this.minimumDate, this.usiSubData.level, this.usiSubData.productType)
                    : CommonDate.fromDate(new Date()));
            if (!this.builder.benchMarkingValuationDate.value) {
                this.builder.benchMarkingValuationDate.value = date;
            }
            if (data.user && data.user.subscriptionDetails) {
                this.excelStorageService.getuserprofile(data.user);
                const config = UserUtil.getValuationDateInputConfig(date,
                    ProductType.Usi, this.minimumDate, data.user.subscriptionDetails);
                this.builder.benchMarkingValuationDate.config = {
                    readonly: true,
                    required: true,
                    minimumValue: config.minimumValue,
                    maximumValue: config.maximumValue,
                    help: benchMarkingValuationDate,
                    disabled: this.builder.benchMarkingValuationDate.config
                        && this.builder.benchMarkingValuationDate.config.disabled && this.builder.benchMarkingValuationDate.reference !== ""
                        ? this.builder.benchMarkingValuationDate.config.disabled : false
                };
                const options: any = this.benchmarkingService.setToDefaultOptions(this.regionComboOptions, this.currencyOptions, this.iniSubData, this.usiSubData);
                this.regionComboOptions = options.regionComboOptions ? options.regionComboOptions : this.regionComboOptions;
                this.currencyOptions = options.currencyOptions ? options.currencyOptions : this.currencyOptions;
                this.validateValuationDateForAllScenarios();
                this.restrictRegionBasedOnValidationDate();
            }
            if (this.iniSubData && this.iniSubData.level && this.usiSubData && this.usiSubData.level) {
                this.setValuationDateConfigBasedOnSubscription(
                    this.benchmarkingService.getValuationDateConfig(this.usiSubData.productType, this.minimumDate, this.inputData.subscriptionDetails)
                );
                this.setValuationDateConfigBasedOnSubscription(
                    this.benchmarkingService.getValuationDateConfig(this.iniSubData.productType, this.minimumDate, this.inputData.subscriptionDetails)
                );
            }
            if (this.builder.benchMarkingValuationDate.value) {
                this.preserveValuationDate = this.builder.benchMarkingValuationDate.value;
            }
            this.validateIndustryInput();
            this.currencyOptions = this.benchmarkingService.updateCurrencyOptions(
                this.currencyOptions, this.builder.benchMarkingRegion.value,
                this.iniSubData, this.usiSubData);
            this.onloadClear = true;
            this._showScenario.next(true);

        });
    } catch (error) {
            console.log(error);       
    }
    }
    public industryInputFocus(): void {
        if (this.builder.benchMarkingRegion.value && this.builder.benchMarkingCurrency.value && this.builder.benchMarkingValuationDate) {

            const date = ngbDateStructToString(this.builder.benchMarkingValuationDate.value);
            this.builder.benchMarkingIntlIndustries.value = [];
            const request = this.industryClient.read(this.builder.benchMarkingRegion.value, this.builder.benchMarkingCurrency.value, date);
            this.spinner.while(request);
            request.once((industries) => {
                this.builder.benchMarkingIntlIndustries.value = industries;
            });
        }
    }
    public validateIndustryInput(): void {
        if (this.builder.benchMarkingRegion.value && this.builder.benchMarkingCurrency.value && this.builder.benchMarkingValuationDate) {
            this.showIndustries();
            // if (this.builder.benchMarkingIndustry.config) {
            //     if ((this.builder.benchMarkingRegion.value === 'United States' && this.builder.benchMarkingCurrency.value !== 'USD')) {
            //         this.builder.benchMarkingIndustry.config.disabled = true;
            //     } else {
            //         this.builder.benchMarkingIndustry.config.disabled = false;
            //     }
            // }
        }
    }

    public validateValuationDateForAllScenarios(): void {
        if (this.usiSubData && this.iniSubData) {
            this.errorMessages = [];
            let errorMessagesTemp: string[] = [];
            if (this.builder.benchMarkingRegion.value && this.usiSubData && this.usiSubData.level && this.iniSubData && this.iniSubData.level) {
                let subsciption: SubscriptionData | undefined;
                if ((!this.IsMod4 && this.builder.benchMarkingRegion.value !== 'United States') || (this.IsMod4 && this.builder.benchMarkingRegion.value !== 'United States')) {
                    subsciption = this.iniSubData;
                } else if (this.builder.benchMarkingRegion.value === 'United States') {
                    subsciption = this.usiSubData;
                }
                if (subsciption) {
                    const config = this.benchmarkingService.getValuationDateConfig(subsciption.productType, this.minimumDate, this.inputData.subscriptionDetails);
                    if (config.minimumValue && config.maximumValue) {
                        const validation = this.benchmarkingService.isInBetweenDate(
                            config.minimumValue as NgbDateStruct,
                            config.maximumValue as NgbDateStruct,
                            this.builder.benchMarkingValuationDate);
                        if (!validation) {
                            this.builder.benchMarkingCurrency.config = {
                                ...this.builder.benchMarkingCurrency.config,
                                disabled: true,
                                readonly: true
                            };
                            this.builder.benchMarkingIndustry.config = {
                                ...this.builder.benchMarkingIndustry.config,
                                disabled: true
                            };
                            errorMessagesTemp.push(
                                this.benchmarkingService.setErrorMessage(
                                    this.iniSubData,
                                    this.usiSubData,
                                    this.IsMod4,
                                    this.builder.benchMarkingRegion.value));
                        }
                    }
                }
            }

            errorMessagesTemp = errorMessagesTemp.filter((elem: string, index: number, self: string[]) => {
                return index === self.indexOf(elem);
            });
            errorMessagesTemp.forEach((x) => {
                this.errorMessages.push(this.sanitizer.bypassSecurityTrustHtml(x));
            });
        }
    }

    public restrictRegionBasedOnValidationDate(): void {
        this.regionComboOptions = this.benchmarkingService.restrictRegionBasedOnValidationDate(this.iniSubData,
            this.usiSubData,
            this.regionComboOptions,
            this.inputData.subscriptionDetails,
            this.builder.benchMarkingValuationDate,
            this.minimumDate);

    }

    public handleRegionChange(region: any): void {
        if (this.onloadClear) {
            this.onloadClear = false;
            this.builder.benchMarkingIndustry.value = null;
        }
        if (region.value) {
            this.builder.benchMarkingCurrency.value = "";
            this.lstIndustries.length = 0
            this.validateValuationDateForAllScenarios();
            this.validateIndustryInput();
            this.currencyOptions = this.benchmarkingService.updateCurrencyOptions(
                this.currencyOptions, region.value, this.iniSubData, this.usiSubData);
        }

    }

    public reflectDate(valuationDate: DateStruct): void {

        this.resetCountryCurrencyIndu();

        if (this.preserveValuationDate && valuationDate) {
            const options: any = this.benchmarkingService.setToDefaultOptions(this.regionComboOptions, this.currencyOptions, this.iniSubData, this.usiSubData);
            this.regionComboOptions = options.regionComboOptions ? options.regionComboOptions : this.regionComboOptions;
            this.currencyOptions = options.currencyOptions ? options.currencyOptions : this.currencyOptions;
            this.validateValuationDateForAllScenarios();
            this.restrictRegionBasedOnValidationDate();
            const newValuationDate = moment(valuationDate).format('MM/DD/YY');
            const perservedValuationDate = moment(this.preserveValuationDate).format('MM/DD/YY');

            if (newValuationDate !== perservedValuationDate) {
                this.preserveValuationDate = valuationDate;
                this.handleRegionChange(valuationDate);
            }
        } else if (valuationDate) {
            this.preserveValuationDate = valuationDate;
        }
    }
    public typeHeadIndustryCode(): string | undefined {
        const valuationDate = this.builder.benchMarkingValuationDate.value;

        if (valuationDate) {
            const dateUSGICS = new Date('2020-09-30');

            if (new Date(`${valuationDate.year}-${valuationDate.month}-${valuationDate.day}`).getTime() >= dateUSGICS.getTime()) {
                return 'GicIndustryCode';
            } else {
                return 'SicIndustryCode';
            }
        }

        return undefined;
    }
    public onExportToExcel(): void {
        if (!(this.builder.benchMarkingRegion.value && this.builder.benchMarkingCurrency.value && this.builder.benchMarkingValuationDate
            && (this.lstFinalSelectedIndustry.length > 0))) {
            return;
        }

        const date = CommonDate.fromStruct(this.builder.benchMarkingValuationDate.value).asString();
        const mappedScenarioData: IndustryScenario[] = [];
        console.log(this.lstFinalSelectedIndustry);

        this.lstFinalSelectedIndustry.forEach(element => {
            mappedScenarioData.push({
                Region: element.Area,
                Currency: element.CurrencyCode,
                IndustryCode: element.CodeId
            } as IndustryScenario)

        });
        // const mappedScenarioData: IndustryScenario[] = [{
        //     Region: this.builder.benchMarkingRegion.value,
        //     Currency: this.builder.benchMarkingCurrency.value,
        //     IndustryCode: this.builder.benchMarkingIndustry.value.CodeId
        // } as IndustryScenario];

        const request = this.industryClient.create(date, mappedScenarioData);
        this.userStore.user.once((user) => {
            if (user) {
                this.subscriptionWithProPlusLevel = user.subscriptionDetails.find(
                    (sub) => {
                        return sub.level === SubscriptionLevel.Pro ||
                            sub.level === SubscriptionLevel.Enterprise;
                    }
                );
            }

        });

        // this.spinner.while(request);
        this.spinner.begin();
        request.once((benchmarkingData) => {
            this.spinner.end();
            this.tearSheetService.setTearSheetUserSubscription(this.subscriptionWithProPlusLevel);
            benchmarkingData.forEach(data => {
                this.tearSheetService.setTearSheetContent(data);
            })
            if (this.subscriptionWithProPlusLevel) {
                this.tearSheetService.setTrendsOverTimeIndustry([...benchmarkingData]);
            }
            this.tearSheetService.setTearSheetData(benchmarkingData);
            this.benchmarkingExcelExportService.save('UsiBenchmarking');
        });
    }





    public showCompanies(): void {
        if (!(this.builder.benchMarkingIndustry && this.builder.benchMarkingIndustry.value) ||
            !(this.builder.benchMarkingValuationDate && this.builder.benchMarkingValuationDate.value)) {
            this.modalManager.open<void, CompanyListNoIndustryModalComponent>(CompanyListNoIndustryModalComponent, {
                mayDismiss: false
            });

            return;
        }

        const date = CommonDate.fromStruct(this.builder.benchMarkingValuationDate.value).asString();
        const mappedScenarioData: IndustryScenario[] =
            [{
                Region: this.builder.benchMarkingRegion.value,
                Currency: this.builder.benchMarkingCurrency.value,
                IndustryCode: this.builder.benchMarkingIndustry.value ? this.builder.benchMarkingIndustry.value.CodeId : undefined
            }] as IndustryScenario[];

        const request = this.industryClient.create(date, mappedScenarioData);

        this.spinner.while(request);

        request.once((benchmarkingData) => {

            this.tearSheetService.setTearSheetUserSubscription(this.subscriptionWithProPlusLevel);
            this.tearSheetService.setTearSheetContent(benchmarkingData[0]);
            this.tearSheetService.setTearSheetData(benchmarkingData);

            const tearSheetContent = this.tearSheetService.getTearSheetContent();

            this.primeManager.openDialog(IntlCompanyListComponent, {
                companies: tearSheetContent.Companies,
                region: tearSheetContent.Region,
                currency: tearSheetContent.Currency,
                sicCode: tearSheetContent.SicCode,
                gicCode: tearSheetContent.GicCode
            }
            );
        });
    }

    public showIndustries(): void {
        if (!(this.builder.benchMarkingRegion && this.builder.benchMarkingRegion.value)
            || !(this.builder.benchMarkingCurrency && this.builder.benchMarkingCurrency.value)
            || !(this.builder.benchMarkingValuationDate && this.builder.benchMarkingValuationDate.value)) {
            this.modalManager.open<void, IndustryListNoDetailsSelectedComponent>(IndustryListNoDetailsSelectedComponent, {
                mayDismiss: false
            });

            return;
        }

        const date = CommonDate.fromStruct(this.builder.benchMarkingValuationDate.value);
        const request = this.industryClient.read(this.builder.benchMarkingRegion.value,
            this.builder.benchMarkingCurrency.value, date.asString());

        this.spinner.while(request);
        request.once((industries) => {
            this.benchmarkingQueryBuilderService.area = this.builder.benchMarkingRegion.value;
            this.benchmarkingQueryBuilderService.currencyCode = this.builder.benchMarkingCurrency.value;

            if (this.benchmarkingQueryBuilderService.isSic(CommonDate.fromStruct(date).asString(), this.builder.benchMarkingRegion.value ? this.builder.benchMarkingRegion.value : '')) {
                industries.forEach((x) => {
                    x.CurrencyCode = "USD",
                        x.Area = "United States";
                });
            }

            industries =
                this.benchmarkingQueryBuilderService.filterIndustriesBasedOnGeographic(
                    industries
                );
            this.tempIndustries = industries;
            this.lstIndustries = industries;

            
            var selectedIndustries: Industry[] = [];

            if (this.lstFinalSelectedIndustry?.length > 0) {
                this.lstIndustries.forEach(item => {
                    let objexisted = this.lstFinalSelectedIndustry.some(s => this.checkZoneCurencyCode(s, item));
                    if (objexisted) {
                        item.isSelected = true;
                    } else {
                        item.isSelected = false;
                    }

                });

            }

            if (this.lstSelectedIndustries?.length > 0 && this.lstSelectedIndustries?.length > this.lstFinalSelectedIndustry?.length) {
                selectedIndustries = this.lstSelectedIndustries.filter(d => !this.lstFinalSelectedIndustry.find(s => this.checkZoneCurencyCode(s, d)));
            }

            if (selectedIndustries?.length > 0) {
                this.lstIndustries.forEach(item => {
                    let isobjexisted = selectedIndustries.some(s => this.checkZoneCurencyCode(s, item));
                    if (isobjexisted) {
                        item.isSelected = true;
                    } else {
                        if (!this.lstFinalSelectedIndustry.some(k => this.checkZoneCurencyCode(k, item)))
                            item.isSelected = false;
                    }


                    if (this.lstSelectedIndustries.length == this.maximumNumberOfIndustries) {
                        item.isDisabled = true;
                    }

                })
            }


        });
    }



    public checkCheckBoxvalue($event: any, industry: Industry) {
        const isChecked = $event.target.checked;
        if (isChecked && this.lstSelectedIndustries.length < this.maximumNumberOfIndustries) {
            let objIndex = this.lstIndustries.findIndex(s => this.checkZoneCurencyCode(s, industry));
            this.lstIndustries[objIndex].isSelected = true;
            this.lstSelectedIndustries.push(this.lstIndustries[objIndex])
        } else if (!isChecked) {
            let unselectedObj = this.lstIndustries.findIndex(s => this.checkZoneCurencyCode(s, industry));
            this.lstIndustries[unselectedObj].isSelected = false;
            let objSelectIndex = this.lstSelectedIndustries.findIndex(s => this.checkZoneCurencyCode(s, industry));
            this.lstSelectedIndustries.splice(objSelectIndex, 1);
        }

        if (this.lstSelectedIndustries.length === this.maximumNumberOfIndustries) {
            this.lstIndustries.filter(s => !this.lstSelectedIndustries.some(d => this.checkZoneCurencyCode(s, d))).map(s => s.isDisabled = true);
        } else {
            this.lstIndustries.map(d => d.isDisabled = false);
        }


    }

    public addIndustries() {
        this.lstFinalSelectedIndustry = [];
        this._check = 'button';
        this.lstFinalSelectedIndustry.push(...this.lstSelectedIndustries)
        this.isIndustryDisplay = false;
        this.isAddClicked = true;

    }




    public SelectIndustries(): boolean {
        if (this.builder.benchMarkingValuationDate.value) {
            this.isIndustryDisplay = true;
            return true;
        } else {
            return false;
        }

    }





    public removeIndustry(index: number, isModal: boolean) {
        if (this.lstSelectedIndustries.length === this.maximumNumberOfIndustries) {
            this.lstIndustries.map(x => x.isDisabled = false);
        }
        let objCod = this.lstSelectedIndustries[index];
        let objindex = this.lstIndustries.findIndex(s => this.checkZoneCurencyCode(s, objCod));
        if (objindex > -1) {
            this.lstIndustries[objindex].isSelected = false;
        }
        this.lstSelectedIndustries.splice(index, 1);

        if (!isModal) {
            this.lstFinalSelectedIndustry = []
            this.lstFinalSelectedIndustry.push(...this.lstSelectedIndustries);
        }
    }

    public onIndustrySearch(inputValue: any): void {

        if (inputValue !== '') {
            if (!isNaN(Number(inputValue))) {
                this.lstIndustries = this.tempIndustries.filter((s) => s.GicIndustryCode?.startsWith(inputValue, 0));
            } else {
                this.lstIndustries = this.tempIndustries.filter((s) => s.Sector?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1);

            }
        } else {
            this.lstIndustries = this.tempIndustries;
        }

    }

    public invalidClick() {
        return false;
    }

    public close(isClosed: string): void {
        if (this._check === '') {
            if (this.lstFinalSelectedIndustry?.length > 0) {
                this.lstIndustries.map(item => {
                    var objExisted = this.lstFinalSelectedIndustry.find(s => this.checkZoneCurencyCode(s, item));
                    if (objExisted) {
                        item.isSelected = true;
                    } else {
                        item.isSelected = false;
                    }
                });

                if (this.lstSelectedIndustries.length > this.lstFinalSelectedIndustry.length) {
                    this.lstIndustries.map(s => s.isDisabled = false);
                }
                this.lstSelectedIndustries = [];
                this.lstSelectedIndustries.push(...this.lstFinalSelectedIndustry);

            } else {
                this.lstSelectedIndustries.length = 0;
                this.lstIndustries.map(s => { s.isSelected = false; s.isDisabled = false })
            }

            if (this.lstSelectedIndustries.length > this.lstFinalSelectedIndustry.length) {
                this.lstIndustries.map(s => s.isDisabled = false);
            }
        }
        this._check = '';
        this.isIndustryDisplay = false;

    }

    public checkZoneCurencyCode(IndustryobjA: Industry, industryobjB: Industry): boolean {
        return (IndustryobjA.CodeId == industryobjB.CodeId && IndustryobjA.CurrencyCode == industryobjB.CurrencyCode && IndustryobjA.Area == industryobjB.Area);
    }

    public resetCountryCurrencyIndu() {
        this.builder.benchMarkingCurrency.value = ''
        this.builder.benchMarkingRegion.value = ''
        this.lstIndustries.length = 0;
        this.lstSelectedIndustries.length = 0;
        this.lstFinalSelectedIndustry.length = 0;
    }









}
