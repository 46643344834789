<div class="datepicker-container">
    <input-container *ngIf="config" [config]="config" [(model)]="model" [hideInfoIcon]="hideInfoIcon">
        <div class="common-datepicker" (clickOutside)="closeDatepicker(d)">
            <input class="common-input" [attr.readonly]="config.readonly ? true : null"
                [disabled]="disabled||(model.config.disabled ? true : null)"
                [attr.required]="config.required ? true : null" [(ngModel)]="model.value"
                (ngModelChange)="emitChange(true)" [firstDayOfWeek]="7" ngbDatepicker #d="ngbDatepicker"
                (click)="d.toggle()" />
            <span>
                <button (click)="onImportCellValue()" class="link-to-cell"
                    title="Link the selected cell in your model to this field">
                    <i class="fas fa-link"></i>
                </button>
            </span>
        </div>
    </input-container>
</div>