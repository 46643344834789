import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ngbDateStructToString, SubscriberEntity } from "@concurrency/angular";
import { Util } from "@concurrency/core";
import { Observable } from "rxjs";
import { BetaCustomContext } from "src/app/_add-in/beta/beta-custom-context";
import { CalculationsService } from "src/app/_add-in/calculations/calculations.service";
import { CommonDialogInfoComponent } from "src/app/_add-in/common/dialog/common-dialog-info.component";
import { DecileGroup } from "src/app/_add-in/data/decile-group.model";
import { DistressZone } from "src/app/_add-in/data/enums/distress-zone";
import { FieldType } from "src/app/_add-in/data/enums/field-type";
import { InputName } from "src/app/_add-in/data/enums/input-name";
import { Study } from "src/app/_add-in/data/enums/study";
import { ValueType } from "src/app/_add-in/data/enums/value-type";
import { ZScoreContext } from "src/app/_add-in/data/enums/zscore-context";
import { FieldData } from "src/app/_add-in/data/field-data";
import { InputBuilder } from "src/app/_add-in/data/input.builder";
import { RprErp } from "src/app/_add-in/data/rpr-erp";
import * as dt from "src/app/_add-in/dialog/dialog-text";
import { ExcelStorageService } from "src/app/_add-in/excel/excel-storage.service";
import * as help from "src/app/_add-in/help/help-text";
import { RiskService } from "src/app/_add-in/risk/risk.service";
import { AddInClient } from "src/app/_api/clients/addin.client";
import { RiskClient } from "src/app/_api/clients/risk.client";
import { USIndustryClient } from "src/app/_api/clients/usindustry.client";
import { DecileData } from "src/app/_api/responses/decile.response";
import { Industry } from "src/app/_api/responses/industry.response";
import { CompanyType, InputType } from "src/app/_api/responses/input.response";
import { Portfolio } from "src/app/_api/responses/portfolio.response";
import { SelectionType } from "src/app/_api/responses/selection.response";
import {
    Suggestion,
    SuggestionType,
} from "src/app/_api/responses/suggestion.response";
import { ZScore } from "src/app/_api/responses/zscore.response";

@Injectable()
export class FieldService extends SubscriberEntity {
    private isOpenUpdateCrsp = false;
    public constructor(
        private calculationService: CalculationsService,
        private addInService: AddInClient,
        private builder: InputBuilder,
        private industryService: USIndustryClient,
        private dialog: MatDialog,
        private riskClient: RiskClient,
        private riskService: RiskService
    ) {
        super();
    }

    public updateModel(
        model: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        if (model.value != null) {
            switch (model.source) {
                case InputName.ValuationDate:
                    this.updateRfERPData(excelStorageService);
                    if (!this.isOpenUpdateCrsp) {
                        this.isOpenUpdateCrsp = true;
                        const sto = setTimeout(() => {
                            this.updateCrsp(excelStorageService);
                            clearTimeout(sto);
                        }, 5000);
                    }
                    this.updateEstimate(
                        excelStorageService,
                        model.source,
                        model.value,
                        model.inputType
                    );
                    this.updateOperatingMargin(model, excelStorageService);
                    this.updateReturnOnEquity(excelStorageService);
                    this.resetValuationDate(excelStorageService);
                    this.updateIndustries(excelStorageService);
                    break;
                case InputName.Industry0:
                case InputName.Industry1:
                case InputName.Industry2:
                case InputName.Industry3:
                case InputName.Industry4:
                    this.updateBetas(model, excelStorageService);
                    this.resetIndustry(model, excelStorageService);
                    break;
                case InputName.MarketValueOfCommonEquity:
                    this.updateZScore(excelStorageService);
                    if (!this.isOpenUpdateCrsp) {
                        this.isOpenUpdateCrsp = true;
                        const sto = setTimeout(() => {
                            this.updateCrsp(excelStorageService);
                            clearTimeout(sto);
                        }, 5000);
                    }
                    this.updateEstimate(
                        excelStorageService,
                        model.source,
                        model.value,
                        model.inputType
                    );
                    this.updateRelatedOutputs(model, excelStorageService);
                    break;
                case InputName.BookValueOfEquity:
                case InputName.TotalAssets:
                case InputName.Sales:
                    this.updateZScore(excelStorageService);
                    this.updateEstimate(
                        excelStorageService,
                        model.source,
                        model.value,
                        model.inputType
                    );
                    this.updateRelatedOutputs(model, excelStorageService);
                    break;
                case InputName.CurrentYearEBIT:
                case InputName.CurrentAssets:
                case InputName.CurrentLiabilities:
                case InputName.RetainedEarnings:
                    this.updateZScore(excelStorageService);
                    this.updateRelatedOutputs(model, excelStorageService);
                    break;
                case InputName.FiveYearAverageNetIncome:
                case InputName.MarketValueOfInvestedCapital:
                case InputName.FiveYearAverageEBITDA:
                case InputName.NumberOfEmployees:
                case InputName.DebtToEquity:
                    this.updateEstimate(
                        excelStorageService,
                        model.source,
                        model.value,
                        model.inputType
                    );
                    this.updateRelatedOutputs(model, excelStorageService);
                    break;
                case InputName.NetSalesYear0:
                case InputName.NetSalesYear1:
                case InputName.NetSalesYear2:
                case InputName.NetSalesYear3:
                case InputName.NetSalesYear4:
                case InputName.OperatingIncomeYear0:
                case InputName.OperatingIncomeYear1:
                case InputName.OperatingIncomeYear2:
                case InputName.OperatingIncomeYear3:
                case InputName.OperatingIncomeYear4:
                    this.updateOperatingMargin(model, excelStorageService);
                    this.updateRelatedOutputs(model, excelStorageService);
                    break;
                case InputName.NetIncomeYear0:
                case InputName.NetIncomeYear1:
                case InputName.NetIncomeYear2:
                case InputName.NetIncomeYear3:
                case InputName.NetIncomeYear4:
                case InputName.BookValueOfEquityYear0:
                case InputName.BookValueOfEquityYear1:
                case InputName.BookValueOfEquityYear2:
                case InputName.BookValueOfEquityYear3:
                case InputName.BookValueOfEquityYear4:
                    this.updateReturnOnEquity(excelStorageService);
                    this.updateRelatedOutputs(model, excelStorageService);
                    break;
                case InputName.ServiceCompanyType:
                case InputName.ManufacturingCompanyType:
                    this.updateCompanyType(model, excelStorageService);
                    break;
                case InputName.BenchMarkingCurrency:
                case InputName.BenchMarkingRegion:
                case InputName.BenchMarkingValuationDate:
                case InputName.BenchMarkingIndustry:
                case InputName.BenchMarkingProductType:
                    break;
                case InputName.IntlBenchMarkingCurrency:
                case InputName.IntlBenchMarkingRegion:
                case InputName.IntlBenchMarkingValuationDate:
                case InputName.IntlBenchMarkingIndustry:
                case InputName.IntlBenchMarkingProductType:
                    break;
                // this.updateEstimate(excelStorageService, model.source, model.value, model.inputType)
                case InputName.IntlCOCValuationDate:
                    this.IntlValuationDateUpdated(model, excelStorageService);
                    break;
                // case InputName.IntlInvestorCountry:
                //     this.IntlInvestorCountryUpdated(model, excelStorageService);
                default:
                    break;
            }
            excelStorageService.saveToEstimate(model);
        }
    }
    public updateIndustry(
        industry: FieldData,
        excelStorageService: ExcelStorageService,
        indutryExists: boolean
    ): void {
        this.updateBetas(industry, excelStorageService);
        this.resetIndustry(industry, excelStorageService, indutryExists);
        if (industry.value.GicIndustryCode !== null) {
            industry.context =
                "GICS " +
                industry.value.GicIndustryCode +
                " - " +
                industry.value.Sector;
        } else {
            industry.context =
                "SIC " +
                industry.value.SicIndustryCode +
                " - " +
                industry.value.Sector;
        }
        excelStorageService.saveToEstimate(this.builder[industry.source]);
        return;
    }
    private updateRelatedOutputs(
        model: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        this.getRelatedOutputsKeys(model).forEach((key) => {
            const output: FieldData = this.builder[key];
            const relatedInput = output.relatedInputs.find(
                (x) => x.source === model.source
            );
            if (relatedInput == null) {
                throw new Error(
                    `Config error: the related inputs should have been defined in the builder!`
                );
            }
            relatedInput.value = model.value;
            excelStorageService.saveToEstimate(output);
        });
    }

    private updateCompanyType(
        companyType: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        if (companyType === this.builder.serviceCompanyType) {
            this.builder.serviceCompanyType.config.checked = true;
            this.builder.manufacturingCompanyType.config.checked = false;
        }

        if (companyType === this.builder.manufacturingCompanyType) {
            this.builder.serviceCompanyType.config.checked = false;
            this.builder.manufacturingCompanyType.config.checked = true;
        }

        excelStorageService.saveToEstimate(this.builder.serviceCompanyType);
        excelStorageService.saveToEstimate(
            this.builder.manufacturingCompanyType
        );
        this.updateZScore(excelStorageService);
    }

    public updateIndustryRiskPremium(
        beta: FieldData,
        erp: FieldData,
        industryRiskPremia: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        if (beta.value && erp.value) {
            industryRiskPremia.value = `${Util.roundToFixed(
                beta.value * erp.value - erp.value
            )}%`;
            industryRiskPremia.decimalValue = Util.round(
                (beta.value * erp.value - erp.value) / 100,
                4
            );
            if (beta.context && erp.context) {
                industryRiskPremia.context =
                    this.buildIndustryRiskPremiumContext(
                        beta.context,
                        erp.context,
                        erp.value
                    );
                industryRiskPremia.dataPoint =
                    this.buildIndustryRiskPremiumContext(
                        beta.context,
                        erp.context,
                        erp.value
                    );
            }
            if (beta.dataAsOf) {
                industryRiskPremia.dataAsOf =
                    this.riskService.removeTimeStampFromDate(beta.dataAsOf);
            }
        } else {
            industryRiskPremia.value = null;
            industryRiskPremia.decimalValue = 0;
            industryRiskPremia.context = "";
            industryRiskPremia.dataPoint = "";
            industryRiskPremia.dataAsOf = "";
        }
        excelStorageService.saveToEstimate(industryRiskPremia);
    }

    private async resetValuationDate(
        excelStorageService: ExcelStorageService
    ): Promise<void> {
        if (
            (this.builder.industry0.value == null ||
                this.builder.industry0.value == "N/A") &&
            (this.builder.industry1.value == null ||
                this.builder.industry1.value == "N/A") &&
            (this.builder.industry2.value == null ||
                this.builder.industry2.value == "N/A") &&
            (this.builder.industry3.value == null ||
                this.builder.industry3.value == "N/A") &&
            (this.builder.industry4.value == null ||
                this.builder.industry4.value == "N/A") &&
            this.builder.fullInformationBeta0.value == null &&
            this.builder.fullInformationBeta1.value == null &&
            this.builder.fullInformationBeta2.value == null &&
            this.builder.fullInformationBeta3.value == null &&
            this.builder.fullInformationBeta4.value == null &&
            this.builder.vasicekAdjustedBeta0.value == null &&
            this.builder.vasicekAdjustedBeta1.value == null &&
            this.builder.vasicekAdjustedBeta2.value == null &&
            this.builder.vasicekAdjustedBeta3.value == null &&
            this.builder.vasicekAdjustedBeta4.value == null &&
            this.builder.medianSumLeveredBeta0.value == null &&
            this.builder.medianSumLeveredBeta1.value == null &&
            this.builder.medianSumLeveredBeta2.value == null &&
            this.builder.medianSumLeveredBeta3.value == null &&
            this.builder.medianSumLeveredBeta4.value == null &&
            this.builder.highFinancialRiskSumLeveredBeta0.value == null &&
            this.builder.highFinancialRiskSumLeveredBeta1.value == null &&
            this.builder.highFinancialRiskSumLeveredBeta2.value == null &&
            this.builder.highFinancialRiskSumLeveredBeta3.value == null &&
            this.builder.highFinancialRiskSumLeveredBeta4.value == null
        ) {
            return;

            //if(this.builder.valuationDate.value){
            //let date = ngbDateStructToString(this.builder.valuationDate.value);
            // if (date && date.includes('T')) {
            // date = date.split('T')[0];
            // await this.getlistofIndusties(date);
            // }
            // }
        }
        if (
            typeof this.builder.industry0.value === "number" ||
            typeof this.builder.industry1.value === "number" ||
            typeof this.builder.industry2.value === "number" ||
            typeof this.builder.industry3.value === "number" ||
            typeof this.builder.industry4.value === "number"
        ) {
            const tempIndustry0 = this.builder.industry0.value;
            const tempIndustry1 = this.builder.industry1.value;
            const tempIndustry2 = this.builder.industry2.value;
            const tempIndustry3 = this.builder.industry3.value;
            const tempIndustry4 = this.builder.industry4.value;
            let date = ngbDateStructToString(this.builder.valuationDate.value);
            if (date && date.includes("T")) {
                date = date.split("T")[0];
            }
            const request = this.industryService.read(date);
            request.subscribe((param: Industry[]) => {
                this.builder.intlIndustries.value = param;
                // excelStorageService.saveToEstimate(this.builder.intlIndustries);
                if (this.builder.intlIndustries.value) {
                    if (tempIndustry0) {
                        this.builder.industry0.value =
                            excelStorageService.getIndustry(
                                this.builder.intlIndustries.value,
                                date,
                                tempIndustry0.toString(),
                                this.builder.industry0
                            );
                    }
                    if (tempIndustry1) {
                        this.builder.industry1.value =
                            excelStorageService.getIndustry(
                                this.builder.intlIndustries.value,
                                date,
                                tempIndustry1.toString(),
                                this.builder.industry1
                            );
                    }
                    if (tempIndustry2) {
                        this.builder.industry2.value =
                            excelStorageService.getIndustry(
                                this.builder.intlIndustries.value,
                                date,
                                tempIndustry2.toString(),
                                this.builder.industry2
                            );
                    }
                    if (tempIndustry3) {
                        this.builder.industry3.value =
                            excelStorageService.getIndustry(
                                this.builder.intlIndustries.value,
                                date,
                                tempIndustry3.toString(),
                                this.builder.industry3
                            );
                    }
                    if (tempIndustry4) {
                        this.builder.industry4.value =
                            excelStorageService.getIndustry(
                                this.builder.intlIndustries.value,
                                date,
                                tempIndustry4.toString(),
                                this.builder.industry4
                            );
                    }
                }
                this.resetIndustries(excelStorageService);
                // excelStorageService.intiateUpdateDataSummary();
            });
        } else if (
            (this.builder.industry0.value &&
                typeof this.builder.industry0.value !== "string") ||
            (this.builder.industry1.value &&
                typeof this.builder.industry1.value !== "string") ||
            (this.builder.industry2.value &&
                typeof this.builder.industry2.value !== "string") ||
            (this.builder.industry3.value &&
                typeof this.builder.industry3.value !== "string") ||
            (this.builder.industry4.value &&
                typeof this.builder.industry4.value !== "string")
        ) {
            this.resetIndustries(excelStorageService);
        } else {
            const industries: FieldData[] = [
                this.builder.industry0,
                this.builder.industry1,
                this.builder.industry2,
                this.builder.industry3,
                this.builder.industry4,
            ];
            industries
                .filter((x) => x.value)
                .forEach((industry) =>
                    this.getIndustryCode(industry, excelStorageService)
                );
        }
    }

    // private async  getlistofIndusties(valudate:string):Promise<void>{
    //     const request = this.industryService.read(valudate);
    //     await request.subscribe((param: Industry[]) => {this.builder.intlIndustries.value = param;});
    // }

    private getIndustryCode(
        model: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        let date = ngbDateStructToString(this.builder.valuationDate.value);
        if (date && date.includes("T")) {
            date = date.split("T")[0];
        }
        if (typeof model.value === "string" && model.value.includes(" ")) {
            const splitted = model.value.split(" ", 2);
            model.value = excelStorageService.getIndustry(
                this.builder.intlIndustries.value,
                date,
                splitted[1],
                model
            );
        } else {
            excelStorageService.getRangeForIndustries(model);
            model.value = excelStorageService.getIndustry(
                this.builder.intlIndustries.value,
                date,
                model.value,
                model
            );
        }
        if (model.value) {
            this.resetIndustries(excelStorageService);
        }
    }

    private industryExists(industries: Industry[], industry: any): boolean {
        if (
            typeof industry === "string" &&
            industry !== "N/A" &&
            industry !== ""
        ) {
            return true;
        } else {
            if (industry && industry.SicIndustryCode != null) {
                return industries.some((x) => {
                    return x.SicIndustryCode === industry.SicIndustryCode;
                });
            } else if (industry && industry.GicIndustryCode != null) {
                return industries.some((x) => {
                    return x.GicIndustryCode === industry.GicIndustryCode;
                });
            } else {
                return false;
            }
        }
    }
    private resetIndustries(excelStorageService: ExcelStorageService): void {
        let date = ngbDateStructToString(this.builder.valuationDate.value);
        const request = this.industryService.read(date);
        request.subscribe((param: Industry[]) => {
            this.builder.intlIndustries.value = param;
            excelStorageService.saveToEstimate(this.builder.intlIndustries);
            if (
                this.builder.intlIndustries.value &&
                (this.industryExists(
                    this.builder.intlIndustries.value,
                    this.builder.industry0.value
                ) ||
                    this.industryExists(
                        this.builder.intlIndustries.value,
                        this.builder.industry1.value
                    ) ||
                    this.industryExists(
                        this.builder.intlIndustries.value,
                        this.builder.industry2.value
                    ) ||
                    this.industryExists(
                        this.builder.intlIndustries.value,
                        this.builder.industry3.value
                    ) ||
                    this.industryExists(
                        this.builder.intlIndustries.value,
                        this.builder.industry4.value
                    ))
            ) {
                this.resetIRPGroup(excelStorageService, true);
                const fields: any = excelStorageService.getExcelEstimate();
                const industries: string[] = [
                    InputName.Industry0,
                    InputName.Industry1,
                    InputName.Industry2,
                    InputName.Industry3,
                    InputName.Industry4,
                ];
                industries.forEach((x) => {
                    const model: any = excelStorageService.getValueFromSource(
                        x,
                        fields
                    );
                    if (
                        typeof model.value === "string" &&
                        model.value.includes(" ")
                    ) {
                        const splitted = model.value.split(" ", 2);
                        if (date && date.includes("T")) {
                            date = date.split("T")[0];
                        }
                        model.value = excelStorageService.getIndustry(
                            this.builder.intlIndustries.value,
                            date,
                            splitted[1],
                            model
                        );
                    }
                    this.updateBetas(model, excelStorageService);
                });
            } else {
                this.resetIRPGroup(excelStorageService, false);
                this.dialog.open(CommonDialogInfoComponent, {
                    data: dt.valuationDateChanged,
                });
            }
        });
    }
    public resetIndustryvaluestoEmpty(
        model: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        if (model.source === InputName.Industry0) {
            model.context = "N/A";
            this.builder.fullInformationBeta0.value = "N/A";
            this.builder.fullInformationBeta0.dataPoint = "N/A";
            this.builder.fullInformationBeta0.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.fullInformationBeta0
            );
            this.builder.vasicekAdjustedBeta0.value = "N/A";
            this.builder.vasicekAdjustedBeta0.dataPoint = "N/A";
            this.builder.vasicekAdjustedBeta0.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.vasicekAdjustedBeta0
            );
            this.builder.medianSumLeveredBeta0.value = "N/A";
            this.builder.medianSumLeveredBeta0.dataPoint = "N/A";
            this.builder.medianSumLeveredBeta0.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.medianSumLeveredBeta0
            );
            this.builder.highFinancialRiskSumLeveredBeta0.value = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta0.dataPoint = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta0.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.highFinancialRiskSumLeveredBeta0
            );
        }
        if (model.source === InputName.Industry1) {
            model.context = "N/A";
            this.builder.fullInformationBeta1.value = "N/A";
            this.builder.fullInformationBeta1.dataPoint = "N/A";
            this.builder.fullInformationBeta1.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.fullInformationBeta1
            );
            this.builder.vasicekAdjustedBeta1.value = "N/A";
            this.builder.vasicekAdjustedBeta1.dataPoint = "N/A";
            this.builder.vasicekAdjustedBeta1.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.vasicekAdjustedBeta1
            );
            this.builder.medianSumLeveredBeta1.value = "N/A";
            this.builder.medianSumLeveredBeta1.dataPoint = "N/A";
            this.builder.medianSumLeveredBeta1.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.medianSumLeveredBeta1
            );
            this.builder.highFinancialRiskSumLeveredBeta1.value = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta1.dataPoint = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta1.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.highFinancialRiskSumLeveredBeta1
            );
        }
        if (model.source === InputName.Industry2) {
            model.context = "N/A";
            this.builder.fullInformationBeta2.value = "N/A";
            this.builder.fullInformationBeta2.dataPoint = "N/A";
            this.builder.fullInformationBeta2.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.fullInformationBeta2
            );
            this.builder.vasicekAdjustedBeta2.value = "N/A";
            this.builder.vasicekAdjustedBeta2.dataPoint = "N/A";
            this.builder.vasicekAdjustedBeta2.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.vasicekAdjustedBeta2
            );
            this.builder.medianSumLeveredBeta2.value = "N/A";
            this.builder.medianSumLeveredBeta2.dataPoint = "N/A";
            this.builder.medianSumLeveredBeta2.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.medianSumLeveredBeta2
            );
            this.builder.highFinancialRiskSumLeveredBeta2.value = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta2.dataPoint = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta2.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.highFinancialRiskSumLeveredBeta2
            );
        }
        if (model.source === InputName.Industry3) {
            model.context = "N/A";
            this.builder.fullInformationBeta3.value = "N/A";
            this.builder.fullInformationBeta3.dataPoint = "N/A";
            this.builder.fullInformationBeta3.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.fullInformationBeta3
            );
            this.builder.vasicekAdjustedBeta3.value = "N/A";
            this.builder.vasicekAdjustedBeta3.dataPoint = "N/A";
            this.builder.vasicekAdjustedBeta3.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.vasicekAdjustedBeta3
            );
            this.builder.medianSumLeveredBeta3.value = "N/A";
            this.builder.medianSumLeveredBeta3.dataPoint = "N/A";
            this.builder.medianSumLeveredBeta3.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.medianSumLeveredBeta3
            );
            this.builder.highFinancialRiskSumLeveredBeta3.value = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta3.dataPoint = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta3.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.highFinancialRiskSumLeveredBeta3
            );
        }
        if (model.source === InputName.Industry4) {
            model.context = "N/A";
            this.builder.fullInformationBeta4.value = "N/A";
            this.builder.fullInformationBeta4.dataPoint = "N/A";
            this.builder.fullInformationBeta4.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.fullInformationBeta4
            );
            this.builder.vasicekAdjustedBeta4.value = "N/A";
            this.builder.vasicekAdjustedBeta4.dataPoint = "N/A";
            this.builder.vasicekAdjustedBeta4.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.vasicekAdjustedBeta4
            );
            this.builder.medianSumLeveredBeta4.value = "N/A";
            this.builder.medianSumLeveredBeta4.dataPoint = "N/A";
            this.builder.medianSumLeveredBeta4.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.medianSumLeveredBeta4
            );
            this.builder.highFinancialRiskSumLeveredBeta4.value = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta4.dataPoint = "N/A";
            this.builder.highFinancialRiskSumLeveredBeta4.dataAsOf = "N/A";
            excelStorageService.saveToEstimate(
                this.builder.highFinancialRiskSumLeveredBeta4
            );
        }
        excelStorageService.saveToEstimate(model);
    }
    public resetAllvaluestoEmpty(
        excelStorageService: ExcelStorageService
    ): void {
        const outputstoBind = Object.keys(this.builder).filter(
            (x) =>
                this.builder[x].fieldType === FieldType.Output ||
                (this.builder[x].fieldType === FieldType.Suggestion &&
                    this.builder[x].source !==
                        InputName.ISDataSummaryExported) ||
                this.builder[x].valueType === ValueType.Industry
        );

        outputstoBind.forEach((item) => {
            const outputs = Object.assign(this.builder[item]);
            const stoInner = setTimeout(() => {
                if (
                    outputs.source === "industry0" ||
                    "industry1" ||
                    "industry2" ||
                    "industry3" ||
                    "industry4"
                ) {
                    if (outputs.value) {
                        if (
                            outputs.value.GicIndustryCode ||
                            outputs.value.SicIndustryCode
                        ) {
                            outputs.value = outputs.value.GicIndustryCode
                                ? outputs.value.GicIndustryCode
                                : outputs.value.SicIndustryCode;
                            outputs.value = parseInt(outputs.value);
                        } else {
                            outputs.value = "";
                        }
                    } else {
                        outputs.value = "";
                    }
                } else {
                    outputs.value = "";
                }
                outputs.decimalValue = "";
                outputs.dataPoint = "";
                outputs.datapointsource = "";
                outputs.context = "";
                excelStorageService.saveToEstimate(outputs);
                clearTimeout(stoInner);
            }, 2000);
        });

        this.builder.valuationDate.value = "";
        excelStorageService.saveToEstimate(this.builder.valuationDate);
    }

    private resetIndustry(
        industry: FieldData,
        excelStorageService: ExcelStorageService,
        industryExists?: boolean
    ): void {
        const linkedBetaKey = Object.keys(this.builder).filter(
            (x) =>
                industry.betaGroup === this.builder[x].betaGroup &&
                this.builder[x].valueType === ValueType.Beta
        );
        const linkedIndustryRiskPremiumKey = Object.keys(this.builder).filter(
            (x) =>
                industry.betaGroup === this.builder[x].betaGroup &&
                this.builder[x].valueType === ValueType.IndustryRiskPremium
        );
        const linkedBeta: FieldData = this.builder[linkedBetaKey.toString()];
        const linkedIndustryRiskPremium =
            this.builder[linkedIndustryRiskPremiumKey.toString()];
        if (
            !industryExists &&
            linkedBeta.value !== null &&
            linkedBeta.value !== undefined
        ) {
            this.resetInput(linkedBeta, excelStorageService);
            this.resetInput(linkedIndustryRiskPremium, excelStorageService);
            // this.dialog.open(CommonDialogInfoComponent, {
            //     data: dt.industryChanged
            // });
        }
    }

    private resetIRPGroup(
        excelStorageService: ExcelStorageService,
        hasIndustry: boolean
    ): void {
        Object.keys(this.builder)
            .filter(
                (x) =>
                    this.builder[x].valueType === ValueType.Industry ||
                    this.builder[x].valueType ===
                        ValueType.FullInformationBeta ||
                    this.builder[x].valueType ===
                        ValueType.VasicekAdjustedBeta ||
                    this.builder[x].valueType ===
                        ValueType.MedianSumLeveredBeta ||
                    this.builder[x].valueType ===
                        ValueType.HighFinancialRiskBeta

                // this.builder[x].valueType === ValueType.EquityRiskPremium
                // || this.builder[x].valueType === ValueType.IndustryRiskPremium
            )
            .forEach((key) => {
                const model: FieldData = this.builder[key];
                if (
                    hasIndustry &&
                    (key === "industry0" ||
                        key === "industry1" ||
                        key === "industry2" ||
                        key === "industry3" ||
                        key === "industry4")
                ) {
                    model.value = this.builder[key].value;
                    model.context = this.builder[key].context;
                    // } else if (hasIndustry && (key === InputName.EquityRiskPremium0 || InputName.EquityRiskPremium1 || InputName.EquityRiskPremium2 || InputName.EquityRiskPremium3 || InputName.EquityRiskPremium4)) {
                    //     model.value = null;
                    //     model.context = this.builder[key].context;
                } else {
                    model.value = null;
                    model.context = "";
                }
                excelStorageService.saveToEstimate(model);
            });
    }

    // private resetBetaSuggestions(excelStorageService: ExcelStorageService): void {
    //     Object.keys(this.builder).filter((x) =>
    //         this.builder[x].valueType === ValueType.BetaSuggestion
    //         || this.builder[x].valueType === ValueType.Beta).forEach((key) => {
    //             const model: FieldData = this.builder[key];

    //             if (model.valueType === ValueType.BetaSuggestion) {
    //                 model.value = [];
    //                 excelStorageService.saveToEstimate(model);
    //             }

    //         });
    // }

    private updateIndustries(excelStorageService: ExcelStorageService): void {
        if (this.builder.valuationDate.value == null) {
            return;
        }

        const date = ngbDateStructToString(this.builder.valuationDate.value);
        const request = this.industryService.read(date);
        request.onceDefined((param: Industry[]) => {
            this.builder.intlIndustries.value = param;
            excelStorageService.saveToEstimate(this.builder.intlIndustries);
            const industries: FieldData[] = [
                this.builder.industry0,
                this.builder.industry1,
                this.builder.industry2,
                this.builder.industry3,
                this.builder.industry4,
            ];
            industries
                .filter((x) => x.value)
                .forEach((industry) =>
                    this.validateIndustry(industry, excelStorageService)
                );
        });
    }

    private validateIndustry(
        industry: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        if (typeof industry.value === "string") {
            return;
        } else if (
            industry == null ||
            this.builder.intlIndustries.value.find(
                (x: Industry) =>
                    x.SicIndustryCode === industry.value.SicIndustryCode ||
                    x.GicIndustryCode === industry.value.GicIndustryCode
            ) == null
        ) {
            industry.value = null;
            excelStorageService.saveToEstimate(industry);
        }
    }

    private getSelectedCompanyType(): FieldData {
        return this.builder.serviceCompanyType.config.checked
            ? this.builder.serviceCompanyType
            : this.builder.manufacturingCompanyType;
    }

    private updateZScore(excelStorageService: ExcelStorageService): void {
        if (
            this.builder.marketCap.value &&
            this.builder.bookValueOfEquity.value &&
            this.builder.totalAssets.value &&
            this.builder.currentYearEBIT.value &&
            this.builder.sales.value &&
            this.builder.currentAssets.value &&
            this.builder.currentLiabilities.value &&
            this.builder.retainedEarnings.value
        ) {
            const zScore: number = this.riskService.calculateZScore({
                marketValueOfEquity: this.builder.marketCap.value,
                bookValueOfEquity: this.builder.bookValueOfEquity.value,
                totalAssets: this.builder.totalAssets.value,
                currentYearEBIT: this.builder.currentYearEBIT.value,
                currentYearSales: this.builder.sales.value,
                currentAssets: this.builder.currentAssets.value,
                currentLiabilities: this.builder.currentLiabilities.value,
                retainedEarnings: this.builder.retainedEarnings.value,
                companyType: this.getSelectedCompanyType().value,
            });
            this.builder.ZScore.value = Util.roundToFixed(zScore, 2);
            this.builder.ZScore.context = this.getZScoreContext(
                zScore,
                this.getSelectedCompanyType().value
            );
            this.updateHFRStudy(excelStorageService);
        } else {
            this.builder.ZScore.value = null;
            this.builder.ZScore.context = help.ZScore.title;
            this.builder.HFRSizePremium.value = null;
            this.builder.HFRRiskPremiumOverTheRiskFreeRate.value = null;
            excelStorageService.saveToEstimate(this.builder.HFRSizePremium);
            excelStorageService.saveToEstimate(
                this.builder.HFRRiskPremiumOverTheRiskFreeRate
            );
        }
        excelStorageService.saveToEstimate(this.builder.ZScore);
    }

    private getZScoreContext(
        zscore: number,
        companyType: CompanyType
    ): ZScoreContext {
        if (companyType === CompanyType.Service) {
            if (zscore < DistressZone.Service) {
                return ZScoreContext.ServiceDistress;
            } else {
                return ZScoreContext.ServiceNotDistress;
            }
        } else {
            if (zscore < DistressZone.Manufacturing) {
                return ZScoreContext.ManufacturingDistress;
            } else {
                return ZScoreContext.ManufacturingNotDistress;
            }
        }
    }

    private updateBetas(
        industry: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        if (
            this.builder.valuationDate.value == null ||
            industry.value == null ||
            typeof industry.value === "string"
        ) {
            return;
        }

        const date = ngbDateStructToString(this.builder.valuationDate.value);
        const codeId = industry.value.CodeId;
        const industryCode = industry.value.SicIndustryCode
            ? Number(industry.value.SicIndustryCode)
            : Number(industry.value.GicIndustryCode);
        if ((date !== undefined || date !== null) && codeId != null) {
            const request = this.addInService.readSuggestions(
                date,
                codeId,
                industryCode
            );
            request.onceDefined((suggestion: Suggestion[]) => {
                suggestion.forEach((item) => {
                    this.betaprocessSuggestion(
                        industry,
                        item.SuggestionType,
                        item.Value,
                        item.Source,
                        item.DataAsOf,
                        excelStorageService
                    );
                });
                const isFullInformationBetaAvailable = suggestion.find(
                    (x) =>
                        x.SuggestionType === SuggestionType.FullInformationBeta
                );
                const isVasicekAdjustedBetaAvailable = suggestion.find(
                    (x) =>
                        x.SuggestionType === SuggestionType.VasicekAdjustedBeta
                );
                const isMedianLeveredAvailable = suggestion.find(
                    (x) =>
                        x.SuggestionType === SuggestionType.MedianSumLeveredBeta
                );
                const isHFRSumBetaAvailable = suggestion.find(
                    (x) =>
                        x.SuggestionType ===
                        SuggestionType.HighFinancialRiskSumLeveredBeta
                );
                if (!isFullInformationBetaAvailable) {
                    if (industry.source === InputName.Industry0) {
                        this.builder.fullInformationBeta0.value = "N/A";
                        this.builder.fullInformationBeta0.dataAsOf = "N/A";
                        this.builder.fullInformationBeta0.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta0
                        );
                    }
                    if (industry.source === InputName.Industry1) {
                        this.builder.fullInformationBeta1.value = "N/A";
                        this.builder.fullInformationBeta1.dataAsOf = "N/A";
                        this.builder.fullInformationBeta1.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta1
                        );
                    }
                    if (industry.source === InputName.Industry2) {
                        this.builder.fullInformationBeta2.value = "N/A";
                        this.builder.fullInformationBeta2.dataAsOf = "N/A";
                        this.builder.fullInformationBeta2.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta2
                        );
                    }
                    if (industry.source === InputName.Industry3) {
                        this.builder.fullInformationBeta3.value = "N/A";
                        this.builder.fullInformationBeta3.dataAsOf = "N/A";
                        this.builder.fullInformationBeta3.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta3
                        );
                    }
                    if (industry.source === InputName.Industry4) {
                        this.builder.fullInformationBeta4.value = "N/A";
                        this.builder.fullInformationBeta4.dataAsOf = "N/A";
                        this.builder.fullInformationBeta4.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta4
                        );
                    }
                }

                if (!isVasicekAdjustedBetaAvailable) {
                    if (industry.source === InputName.Industry0) {
                        this.builder.vasicekAdjustedBeta0.value = "N/A";
                        this.builder.vasicekAdjustedBeta0.dataAsOf = "N/A";
                        this.builder.vasicekAdjustedBeta0.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta0
                        );
                    }
                    if (industry.source === InputName.Industry1) {
                        this.builder.vasicekAdjustedBeta1.value = "N/A";
                        this.builder.vasicekAdjustedBeta1.dataAsOf = "N/A";
                        this.builder.vasicekAdjustedBeta1.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta1
                        );
                    }
                    if (industry.source === InputName.Industry2) {
                        this.builder.vasicekAdjustedBeta2.value = "N/A";
                        this.builder.vasicekAdjustedBeta2.dataAsOf = "N/A";
                        this.builder.vasicekAdjustedBeta2.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta2
                        );
                    }
                    if (industry.source === InputName.Industry3) {
                        this.builder.vasicekAdjustedBeta3.value = "N/A";
                        this.builder.vasicekAdjustedBeta3.dataAsOf = "N/A";
                        this.builder.vasicekAdjustedBeta3.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta3
                        );
                    }
                    if (industry.source === InputName.Industry4) {
                        this.builder.vasicekAdjustedBeta4.value = "N/A";
                        this.builder.vasicekAdjustedBeta4.dataAsOf = "N/A";
                        this.builder.vasicekAdjustedBeta4.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta4
                        );
                    }
                }
                if (!isMedianLeveredAvailable) {
                    if (industry.source === InputName.Industry0) {
                        this.builder.medianSumLeveredBeta0.value = "N/A";
                        this.builder.medianSumLeveredBeta0.dataAsOf = "N/A";
                        this.builder.medianSumLeveredBeta0.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta0
                        );
                    }
                    if (industry.source === InputName.Industry1) {
                        this.builder.medianSumLeveredBeta1.value = "N/A";
                        this.builder.medianSumLeveredBeta1.dataAsOf = "N/A";
                        this.builder.medianSumLeveredBeta1.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta1
                        );
                    }
                    if (industry.source === InputName.Industry2) {
                        this.builder.medianSumLeveredBeta2.value = "N/A";
                        this.builder.medianSumLeveredBeta2.dataAsOf = "N/A";
                        this.builder.medianSumLeveredBeta2.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta2
                        );
                    }
                    if (industry.source === InputName.Industry3) {
                        this.builder.medianSumLeveredBeta3.value = "N/A";
                        this.builder.medianSumLeveredBeta3.dataAsOf = "N/A";
                        this.builder.medianSumLeveredBeta3.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta3
                        );
                    }
                    if (industry.source === InputName.Industry4) {
                        this.builder.medianSumLeveredBeta4.value = "N/A";
                        this.builder.medianSumLeveredBeta4.dataAsOf = "N/A";
                        this.builder.medianSumLeveredBeta4.dataPoint = "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta4
                        );
                    }
                }
                if (!isHFRSumBetaAvailable) {
                    if (industry.source === InputName.Industry0) {
                        this.builder.highFinancialRiskSumLeveredBeta0.value =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta0.dataAsOf =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta0.dataPoint =
                            "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta0
                        );
                    }
                    if (industry.source === InputName.Industry1) {
                        this.builder.highFinancialRiskSumLeveredBeta1.value =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta1.dataAsOf =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta1.dataPoint =
                            "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta1
                        );
                    }
                    if (industry.source === InputName.Industry2) {
                        this.builder.highFinancialRiskSumLeveredBeta2.value =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta2.dataAsOf =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta2.dataPoint =
                            "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta2
                        );
                    }
                    if (industry.source === InputName.Industry3) {
                        this.builder.highFinancialRiskSumLeveredBeta3.value =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta3.dataAsOf =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta3.dataPoint =
                            "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta3
                        );
                    }
                    if (industry.source === InputName.Industry4) {
                        this.builder.highFinancialRiskSumLeveredBeta4.value =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta4.dataAsOf =
                            "N/A";
                        this.builder.highFinancialRiskSumLeveredBeta4.dataPoint =
                            "N/A";
                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta4
                        );
                    }
                }

                // this.populateEquityRiskPremiumSelections(dataAsOf, excelStorageService);
            });
        }

        // const betaSuggestionKey = Object.keys(this.builder).filter(
        //     (x) => this.builder[x].valueType === ValueType.BetaSuggestion && industry.betaGroup === this.builder[x].betaGroup
        // );

        // const betaSuggestion: FieldData = this.builder[betaSuggestionKey.toString()];
        // request.onceDefined((param: Suggestion[]) => {
        //     betaSuggestion.value = this.populateBetas(param);
        //     excelStorageService.saveToEstimate(betaSuggestion);
        //     if (betaSuggestion.source === 'betaSuggestion0') {
        //         const model: FieldData = this.builder[InputName.Beta0];
        //         this.updateBetaValue(excelStorageService, model, betaSuggestion);
        //     } else if (betaSuggestion.source === 'betaSuggestion1') {
        //         const model: FieldData = this.builder[InputName.Beta1];
        //         this.updateBetaValue(excelStorageService, model, betaSuggestion);
        //     } else if (betaSuggestion.source === 'betaSuggestion2') {
        //         const model: FieldData = this.builder[InputName.Beta2];
        //         this.updateBetaValue(excelStorageService, model, betaSuggestion);
        //     } else if (betaSuggestion.source === 'betaSuggestion3') {
        //         const model: FieldData = this.builder[InputName.Beta3];
        //         this.updateBetaValue(excelStorageService, model, betaSuggestion);
        //     } else if (betaSuggestion.source === 'betaSuggestion4') {
        //         const model: FieldData = this.builder[InputName.Beta4];
        //         this.updateBetaValue(excelStorageService, model, betaSuggestion);
        //     }
        // });
    }
    // private updateBetaValue(excelStorageService: ExcelStorageService, beta: FieldData, betaSuggestion: any): void {
    //     const value = beta.dataPoint ? betaSuggestion.value.filter((x: any) => x.dataPoint === beta.dataPoint) : null;
    //     if (value) {
    //         value.forEach((x: any) => {
    //             beta.value = x.value;
    //             beta.dataAsOf = x.dataAsOf;
    //         });
    //         excelStorageService.saveToEstimate(beta);
    //     }
    //     if (beta.source === InputName.Beta0 && this.builder.equityRiskPremium0.value!=null) {
    //         this.updateIndustryRiskPremium(beta, this.builder.equityRiskPremium0, this.builder.industryRiskPremium0, excelStorageService);
    //     }
    //     if (beta.source === InputName.Beta1 && this.builder.equityRiskPremium1.value!=null) {
    //         this.updateIndustryRiskPremium(beta, this.builder.equityRiskPremium1, this.builder.industryRiskPremium1, excelStorageService);
    //     }
    //     if (beta.source === InputName.Beta2 && this.builder.equityRiskPremium2.value!=null) {
    //         this.updateIndustryRiskPremium(beta, this.builder.equityRiskPremium2, this.builder.industryRiskPremium2, excelStorageService);
    //     }
    //     if (beta.source === InputName.Beta3 && this.builder.equityRiskPremium3.value!=null) {
    //         this.updateIndustryRiskPremium(beta, this.builder.equityRiskPremium3, this.builder.industryRiskPremium3, excelStorageService);
    //     }
    //     if (beta.source === InputName.Beta4 && this.builder.equityRiskPremium4.value!=null) {
    //         this.updateIndustryRiskPremium(beta, this.builder.equityRiskPremium4, this.builder.industryRiskPremium4, excelStorageService);
    //     }
    //     return;
    // }
    // private updateErpValue(excelStorageService: ExcelStorageService, erp: FieldData, erpSelections: any): void {
    //     if (erp.context) {
    //         const selectedErp = erpSelections.filter((x: any) => x.dataPoint === erp.context);
    //         erp.value = selectedErp[0].value;
    //     }
    //     excelStorageService.saveToEstimate(erp);
    // }
    // private populateBetas(param: Suggestion[]): SuggestedInput[] {
    //     const betas: SuggestedInput[] = [];

    //     param.filter(
    //         (x) => x.SelectionType === SelectionType[SelectionType.CrspDecilesBeta]
    //             || x.SelectionType === SelectionType[SelectionType.RiskPremiumBeta]
    //             || x.SelectionType === SelectionType[SelectionType.HighFinancialRiskBeta]
    //     ).forEach((item) => {
    //         const betaOption: SuggestedInput = {
    //             name: item.Name,
    //             source: item.Source,
    //             value: item.Value,
    //             dataPoint: item.Source,
    //             dataAsOf: this.riskService.removeTimeStampFromDate(item.DataAsOf)
    //         };
    //         betas.push(betaOption);
    //     });

    //     return betas;
    // }

    private buildIndustryRiskPremiumContext(
        betaContext: string,
        erpContext: string,
        erpValue: number
    ): string {
        if (betaContext == null) {
            betaContext = BetaCustomContext.BetaContext;
        }

        if (erpContext == null) {
            erpContext = BetaCustomContext.ERPContext;
        }

        return `Industry Risk Premium based on ${betaContext} & ${erpContext} (${erpValue}%)`;
    }

    private updateRfERPData(excelStorageService: ExcelStorageService): void {
        if (this.builder.valuationDate.value == null) {
            return;
        }

        const date = ngbDateStructToString(this.builder.valuationDate.value);
        const erpRequest = this.addInService.readHistoricErp(date);
        if (date !== undefined || date !== null) {
            erpRequest.onceDefined((param: RprErp) => {
                const dataAsOf = this.riskService.removeTimeStampFromDate(
                    param.DataAsOf
                );
                if (date < "2003-12-31") {
                    this.builder.supplySideLongTerm.value = "N/A";
                    this.builder.supplySideLongTerm.numericalValue = 0;
                    const newdate = "N/A";
                    this.builder.supplySideLongTerm.dataAsOf = newdate;
                    excelStorageService.saveToEstimate(
                        this.builder.supplySideLongTerm
                    );
                }
                const suggestionsRequest = this.addInService.readSuggestions(
                    date,
                    0,
                    0
                );
                const erp = Util.round(param.Erp * 100);
                suggestionsRequest.onceDefined((suggestion: Suggestion[]) => {
                    suggestion.forEach((item) => {
                        this.processSuggestion(
                            item.SelectionType,
                            item.SuggestionType,
                            item.Value,
                            item.DataAsOf,
                            excelStorageService
                        );
                        const adjustedErpValue = item.Value - erp;
                        this.processSuggestion(
                            SelectionType.EquityRiskPremiumAdjustment,
                            item.SuggestionType,
                            adjustedErpValue,
                            item.DataAsOf,
                            excelStorageService
                        );
                    });
                    this.builder.riskPremiumReport.value = `${Util.roundToFixed(
                        erp
                    )}%`;
                    this.builder.riskPremiumReport.numericalValue = param.Erp;
                    this.builder.riskPremiumReport.decimalValue = Util.round(
                        erp / 100,
                        4
                    );
                    this.builder.riskPremiumReport.dataAsOf = dataAsOf;
                    excelStorageService.saveToEstimate(
                        this.builder.riskPremiumReport
                    );

                    this.builder.riskPremiumReportAdjusted.value = "0.00%";
                    this.builder.riskPremiumReportAdjusted.decimalValue = 0;
                    this.builder.riskPremiumReportAdjusted.dataAsOf = dataAsOf;
                    excelStorageService.saveToEstimate(
                        this.builder.riskPremiumReportAdjusted
                    );

                    // this.populateEquityRiskPremiumSelections(dataAsOf, excelStorageService);
                    // if (this.builder.equityRiskPremium0.context) {
                    //     this.updateErpValue(excelStorageService, this.builder.equityRiskPremium0, this.builder.erpSelections.value);
                    //     if (this.builder.beta0.value != null) {
                    //         this.updateIndustryRiskPremium(this.builder.beta0, this.builder.equityRiskPremium0, this.builder.industryRiskPremium0, excelStorageService);
                    //     }
                    // }
                    // if (this.builder.equityRiskPremium1.context) {
                    //     this.updateErpValue(excelStorageService, this.builder.equityRiskPremium1, this.builder.erpSelections.value);
                    //     if (this.builder.beta1.value != null) {
                    //         this.updateIndustryRiskPremium(this.builder.beta1, this.builder.equityRiskPremium1, this.builder.industryRiskPremium1, excelStorageService);
                    //     }
                    // }
                    // if (this.builder.equityRiskPremium2.context) {
                    //     this.updateErpValue(excelStorageService, this.builder.equityRiskPremium2, this.builder.erpSelections.value);
                    //     if (this.builder.beta2.value != null) {
                    //         this.updateIndustryRiskPremium(this.builder.beta2, this.builder.equityRiskPremium2, this.builder.industryRiskPremium2, excelStorageService);
                    //     }
                    // }
                    // if (this.builder.equityRiskPremium3.context) {
                    //     this.updateErpValue(excelStorageService, this.builder.equityRiskPremium3, this.builder.erpSelections.value);
                    //     if (this.builder.beta3.value != null) {
                    //         this.updateIndustryRiskPremium(this.builder.beta3, this.builder.equityRiskPremium3, this.builder.industryRiskPremium3, excelStorageService);
                    //     }
                    // }
                    // if (this.builder.equityRiskPremium4.context) {
                    //     this.updateErpValue(excelStorageService, this.builder.equityRiskPremium4, this.builder.erpSelections.value);
                    //     if (this.builder.beta4.value != null) {
                    //         this.updateIndustryRiskPremium(this.builder.beta4, this.builder.equityRiskPremium4, this.builder.industryRiskPremium4, excelStorageService);
                    //     }
                    // }
                });
            });
        }

        // can these 2 requests be chained?
    }

    private processSuggestion(
        selectionType: string,
        suggestionType: SuggestionType,
        value: number,
        dataAsOf: string,
        excelStorageService: ExcelStorageService
    ): void {
        const result = Object.keys(this.builder).filter(
            (x) =>
                this.builder[x].selectionType === selectionType &&
                this.builder[x].suggestionType === suggestionType
        );
        if (result.length > 0) {
            const input: FieldData = this.builder[result[0]];
            input.value = `${Util.roundToFixed(value)}%`;
            input.numericalValue = value;
            input.decimalValue = Util.round(value / 100, 4);
            input.dataAsOf = this.riskService.removeTimeStampFromDate(dataAsOf);

            if (input.config && input.config.help) {
                input.context = input.config.help.title;
                input.dataPoint = input.config.help.title;
            }

            excelStorageService.saveToEstimate(input);
        }
    }

    private betaprocessSuggestion(
        industry: FieldData,
        suggestionType: string,
        value: number,
        datapointsource: string,
        quarterDate: string,
        excelStorageService: ExcelStorageService
    ): void {
        // const result = Object.keys(this.builder).filter(
        //     (x) => this.builder[x].suggestionType === SuggestionType.FullInformationBeta
        //        ||  this.builder[x].suggestionType === SuggestionType.VasicekAdjustedBeta
        //        ||  this.builder[x].suggestionType === SuggestionType.MedianSumLeveredBeta
        //        ||  this.builder[x].suggestionType === SuggestionType.HighFinancialRiskSumLeveredBeta
        // );

        if (
            suggestionType === SuggestionType.FullInformationBeta ||
            suggestionType === SuggestionType.VasicekAdjustedBeta ||
            suggestionType === SuggestionType.MedianSumLeveredBeta ||
            suggestionType === SuggestionType.HighFinancialRiskSumLeveredBeta
        ) {
            const stoInner = setTimeout(() => {
                if (suggestionType === SuggestionType.FullInformationBeta) {
                    if (industry.source === InputName.Industry0) {
                        if (value) {
                            this.builder.fullInformationBeta0.value = value;
                            this.builder.fullInformationBeta0.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.fullInformationBeta0.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.fullInformationBeta0.value = "N/A";
                            this.builder.fullInformationBeta0.dataAsOf = "N/A";
                            this.builder.fullInformationBeta0.dataPoint = "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta0
                        );
                    }
                    if (industry.source === InputName.Industry1) {
                        if (value) {
                            this.builder.fullInformationBeta1.value = value;
                            this.builder.fullInformationBeta1.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.fullInformationBeta1.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.fullInformationBeta1.value = "N/A";
                            this.builder.fullInformationBeta1.dataAsOf = "N/A";
                            this.builder.fullInformationBeta1.dataPoint = "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta1
                        );
                    }
                    if (industry.source === InputName.Industry2) {
                        if (value) {
                            this.builder.fullInformationBeta2.value = value;
                            this.builder.fullInformationBeta2.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.fullInformationBeta2.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.fullInformationBeta2.value = "N/A";
                            this.builder.fullInformationBeta2.dataAsOf = "N/A";
                            this.builder.fullInformationBeta2.dataPoint = "N/A";
                        }
                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta2
                        );
                    }
                    if (industry.source === InputName.Industry3) {
                        if (value) {
                            this.builder.fullInformationBeta3.value = value;
                            this.builder.fullInformationBeta3.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.fullInformationBeta3.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.fullInformationBeta3.value = "N/A";
                            this.builder.fullInformationBeta3.dataAsOf = "N/A";
                            this.builder.fullInformationBeta3.dataPoint = "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta3
                        );
                    }
                    if (industry.source === InputName.Industry4) {
                        if (value) {
                            this.builder.fullInformationBeta4.value = value;
                            this.builder.fullInformationBeta4.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.fullInformationBeta4.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.fullInformationBeta4.value = "N/A";
                            this.builder.fullInformationBeta4.dataAsOf = "N/A";
                            this.builder.fullInformationBeta4.dataPoint = "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.fullInformationBeta4
                        );
                    }
                }

                if (suggestionType === SuggestionType.VasicekAdjustedBeta) {
                    if (industry.source === InputName.Industry0) {
                        if (value) {
                            this.builder.vasicekAdjustedBeta0.value = value;
                            this.builder.vasicekAdjustedBeta0.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.vasicekAdjustedBeta0.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.vasicekAdjustedBeta0.value = "N/A";
                            this.builder.vasicekAdjustedBeta0.dataAsOf = "N/A";
                            this.builder.vasicekAdjustedBeta0.dataPoint = "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta0
                        );
                    }
                    if (industry.source === InputName.Industry1) {
                        if (value) {
                            this.builder.vasicekAdjustedBeta1.value = value;
                            this.builder.vasicekAdjustedBeta1.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.vasicekAdjustedBeta1.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.vasicekAdjustedBeta1.value = "N/A";
                            this.builder.vasicekAdjustedBeta1.dataAsOf = "N/A";
                            this.builder.vasicekAdjustedBeta1.dataPoint = "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta1
                        );
                    }
                    if (industry.source === InputName.Industry2) {
                        if (value) {
                            this.builder.vasicekAdjustedBeta2.value = value;
                            this.builder.vasicekAdjustedBeta2.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.vasicekAdjustedBeta2.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.vasicekAdjustedBeta2.value = "N/A";
                            this.builder.vasicekAdjustedBeta2.dataAsOf = "N/A";
                            this.builder.vasicekAdjustedBeta2.dataPoint = "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta2
                        );
                    }
                    if (industry.source === InputName.Industry3) {
                        if (value) {
                            this.builder.vasicekAdjustedBeta3.value = value;
                            this.builder.vasicekAdjustedBeta3.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.vasicekAdjustedBeta3.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.vasicekAdjustedBeta3.value = "N/A";
                            this.builder.vasicekAdjustedBeta3.dataAsOf = "N/A";
                            this.builder.vasicekAdjustedBeta3.dataPoint = "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta3
                        );
                    }
                    if (industry.source === InputName.Industry4) {
                        if (value) {
                            this.builder.vasicekAdjustedBeta4.value = value;
                            this.builder.vasicekAdjustedBeta4.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.vasicekAdjustedBeta4.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.vasicekAdjustedBeta4.value = "N/A";
                            this.builder.vasicekAdjustedBeta4.dataAsOf = "N/A";
                            this.builder.vasicekAdjustedBeta4.dataPoint = "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.vasicekAdjustedBeta4
                        );
                    }
                }

                if (suggestionType === SuggestionType.MedianSumLeveredBeta) {
                    if (industry.source === InputName.Industry0) {
                        if (value) {
                            this.builder.medianSumLeveredBeta0.value = value;
                            this.builder.medianSumLeveredBeta0.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.medianSumLeveredBeta0.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.medianSumLeveredBeta0.value = "N/A";
                            this.builder.medianSumLeveredBeta0.dataAsOf = "N/A";
                            this.builder.medianSumLeveredBeta0.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta0
                        );
                    }
                    if (industry.source === InputName.Industry1) {
                        if (value) {
                            this.builder.medianSumLeveredBeta1.value = value;
                            this.builder.medianSumLeveredBeta1.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.medianSumLeveredBeta1.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.medianSumLeveredBeta1.value = "N/A";
                            this.builder.medianSumLeveredBeta1.dataAsOf = "N/A";
                            this.builder.medianSumLeveredBeta1.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta1
                        );
                    }
                    if (industry.source === InputName.Industry2) {
                        if (value) {
                            this.builder.medianSumLeveredBeta2.value = value;
                            this.builder.medianSumLeveredBeta2.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.medianSumLeveredBeta2.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.medianSumLeveredBeta2.value = "N/A";
                            this.builder.medianSumLeveredBeta2.dataAsOf = "N/A";
                            this.builder.medianSumLeveredBeta2.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta2
                        );
                    }
                    if (industry.source === InputName.Industry3) {
                        if (value) {
                            this.builder.medianSumLeveredBeta3.value = value;
                            this.builder.medianSumLeveredBeta3.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.medianSumLeveredBeta3.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.medianSumLeveredBeta3.value = "N/A";
                            this.builder.medianSumLeveredBeta3.dataAsOf = "N/A";
                            this.builder.medianSumLeveredBeta3.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta3
                        );
                    }
                    if (industry.source === InputName.Industry4) {
                        if (value) {
                            this.builder.medianSumLeveredBeta4.value = value;
                            this.builder.medianSumLeveredBeta4.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.medianSumLeveredBeta4.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.medianSumLeveredBeta4.value = "N/A";
                            this.builder.medianSumLeveredBeta4.dataAsOf = "N/A";
                            this.builder.medianSumLeveredBeta4.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.medianSumLeveredBeta4
                        );
                    }
                }

                if (
                    suggestionType ===
                    SuggestionType.HighFinancialRiskSumLeveredBeta
                ) {
                    if (industry.source === InputName.Industry0) {
                        if (value) {
                            this.builder.highFinancialRiskSumLeveredBeta0.value =
                                value;
                            this.builder.highFinancialRiskSumLeveredBeta0.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.highFinancialRiskSumLeveredBeta0.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.highFinancialRiskSumLeveredBeta0.value =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta0.dataAsOf =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta0.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta0
                        );
                    }
                    if (industry.source === InputName.Industry1) {
                        if (value) {
                            this.builder.highFinancialRiskSumLeveredBeta1.value =
                                value;
                            this.builder.highFinancialRiskSumLeveredBeta1.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.highFinancialRiskSumLeveredBeta1.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.highFinancialRiskSumLeveredBeta1.value =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta1.dataAsOf =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta1.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta1
                        );
                    }
                    if (industry.source === InputName.Industry2) {
                        if (value) {
                            this.builder.highFinancialRiskSumLeveredBeta2.value =
                                value;
                            this.builder.highFinancialRiskSumLeveredBeta2.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.highFinancialRiskSumLeveredBeta2.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.highFinancialRiskSumLeveredBeta2.value =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta2.dataAsOf =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta2.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta2
                        );
                    }
                    if (industry.source === InputName.Industry3) {
                        if (value) {
                            this.builder.highFinancialRiskSumLeveredBeta3.value =
                                value;
                            this.builder.highFinancialRiskSumLeveredBeta3.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.highFinancialRiskSumLeveredBeta3.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.highFinancialRiskSumLeveredBeta3.value =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta3.dataAsOf =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta3.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta3
                        );
                    }
                    if (industry.source === InputName.Industry4) {
                        if (value) {
                            this.builder.highFinancialRiskSumLeveredBeta4.value =
                                value;
                            this.builder.highFinancialRiskSumLeveredBeta4.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    quarterDate
                                );
                            this.builder.highFinancialRiskSumLeveredBeta4.dataPoint =
                                datapointsource;
                        } else {
                            this.builder.highFinancialRiskSumLeveredBeta4.value =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta4.dataAsOf =
                                "N/A";
                            this.builder.highFinancialRiskSumLeveredBeta4.dataPoint =
                                "N/A";
                        }

                        excelStorageService.saveToEstimate(
                            this.builder.highFinancialRiskSumLeveredBeta4
                        );
                    }
                }
                clearTimeout(stoInner);
            }, 5000);
        }
    }

    // private populateEquityRiskPremiumSelections(dataAsOf: string, excelStorageService: ExcelStorageService): void {
    //     const erpSelections: SuggestedInput[] = [];

    //     Object.keys(this.builder).filter((x) => this.builder[x].selectionType === SelectionType.EquityRiskPremium).forEach((erp) => {
    //         const input: FieldData = this.builder[erp];

    //         if (input.value == null || input.context == null || input.numericalValue == null) {
    //             return;
    //         }

    //         const equityRiskPremiumOption: SuggestedInput = {
    //             name: `${input.value} - ${input.context}`,
    //             source: input.context,
    //             value: input.numericalValue,
    //             dataPoint: input.context,
    //             dataAsOf
    //         };
    //         erpSelections.push(equityRiskPremiumOption);
    //     });

    //     this.builder.erpSelections.value = erpSelections;
    //     excelStorageService.saveToEstimate(this.builder.erpSelections);
    // }

    private getRelatedOutputsKeys(input: FieldData): string[] {
        const outputKeys: string[] = Object.keys(this.builder).filter(
            (x) =>
                (this.builder[x].fieldType === FieldType.Output ||
                    this.builder[x].fieldType === FieldType.Calculation) &&
                this.builder[x].relatedInputs.some(
                    (key: FieldData) => key.source === input.source
                )
        );

        return outputKeys;
    }

    public resetRelatedOutputs(
        input: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        if (
            input.source === InputName.NetSalesYear3 ||
            input.source === InputName.NetSalesYear4 ||
            input.source === InputName.OperatingIncomeYear3 ||
            input.source === InputName.OperatingIncomeYear4
        ) {
            this.updateOperatingMargin(input, excelStorageService);
            return;
        }

        if (
            input.source === InputName.NetIncomeYear3 ||
            input.source === InputName.NetIncomeYear4 ||
            input.source === InputName.BookValueOfEquityYear3 ||
            input.source === InputName.BookValueOfEquityYear4
        ) {
            this.updateReturnOnEquity(excelStorageService);
            return;
        }

        this.getRelatedOutputsKeys(input).forEach((key) =>
            this.resetInput(this.builder[key], excelStorageService)
        );
    }

    public resetInput(
        input: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        if (input.value !== null && input.value !== undefined) {
            input.value = null;
            input.numericalValue = 0;
            input.decimalValue = 0;
            input.context = "";
            input.breakpoints = "";
            input.dataPoint = "";
            input.dataAsOf = "";
            excelStorageService.saveToEstimate(input);
            // if (input.valueType === ValueType.Beta && input.reference) {
            //     excelStorageService.clearRange(input);
            // }
        }
    }

    private updateCrsp(excelStorageService: ExcelStorageService): void {
        const crspInputs = Object.keys(this.builder).filter(
            (x) => this.builder[x].study === Study.CRSPStudy
        );

        if (this.builder.valuationDate.value && this.builder.marketCap.value) {
            const targetDate = ngbDateStructToString(
                this.builder.valuationDate.value
            );
            const request = this.addInService.readCrsp(
                targetDate,
                this.builder.marketCap.value
            );
            request.onceDefined(
                (param: DecileData) => {
                    crspInputs.forEach((key) =>
                        this.resetInput(this.builder[key], excelStorageService)
                    );
                    const deciles: DecileGroup[] = [
                        { name: InputName.Decile, decile: param.Target },
                        {
                            name: InputName.SizeGrouping,
                            decile: param.Grouping,
                        },
                        {
                            name: InputName.DecileBreakdown1,
                            decile: param.PrimarySplit,
                        },
                        {
                            name: InputName.DecileBreakdown2,
                            decile: param.SecondarySplit,
                        },
                    ];
                    crspInputs.forEach((key) => {
                        const input: FieldData = Object.assign(
                            this.builder[key]
                        );
                        const decileValue = deciles.find(
                            (x) => input.source === x.name
                        );
                        if (decileValue == null) {
                            throw new Error(
                                "Config error: the CRSP deciles should have been defined in the builder!"
                            );
                        }
                        if (decileValue.decile) {
                            input.value = `${Util.roundToFixed(
                                decileValue.decile.CapM
                            )}%`;
                            input.decimalValue = Util.round(
                                decileValue.decile.CapM / 100,
                                4
                            );
                            input.context = decileValue.decile.Description;
                            input.breakpoints = `$${decileValue.decile.SmallestCap.toFixed(
                                3
                            )}m-$${decileValue.decile.LargestCap.toFixed(3)}m`;
                            input.dataPoint = `${decileValue.decile.Name} ${input.premium}`;
                            input.dataAsOf =
                                this.riskService.removeTimeStampFromDate(
                                    decileValue.decile.DataAsOf
                                );
                        } else {
                            input.value = "N/A";
                        }
                        this.builder[key] = { ...input };
                        excelStorageService.saveToEstimate(this.builder[key]);
                    });
                    this.isOpenUpdateCrsp = false;
                },
                () => {
                    this.isOpenUpdateCrsp = false;
                }
            );
        } else {
            this.isOpenUpdateCrsp = false;
        }
    }

    private updateHFRStudy(excelStorageService: ExcelStorageService): void {
        if (this.builder.valuationDate.value == null) {
            return;
        }

        const valuationDate = ngbDateStructToString(
            this.builder.valuationDate.value
        );
        const zScoreRequest = this.riskClient.getZscore(valuationDate);
        zScoreRequest.onceDefined((param: ZScore) => {
            const zScore = this.builder.ZScore.value;
            const companyType = this.getSelectedCompanyType().value;
            Object.keys(this.builder)
                .filter(
                    (x) =>
                        this.builder[x].selectionType ===
                            SelectionType.HighFinancialRiskPremiumOverCapM ||
                        this.builder[x].selectionType ===
                            SelectionType.HighFinancialRiskPremiumOverRiskFreeRate
                )
                .forEach((key) => {
                    const input = this.builder[key];
                    const selectionType = input.selectionType;

                    if (selectionType == null) {
                        throw new Error(
                            "Config error: the selection type should have been defined in the builder!"
                        );
                    }

                    const zScoreResult = this.riskService.getZScoreResult(
                        param,
                        zScore,
                        companyType,
                        selectionType
                    );
                    input.value = zScoreResult.value
                        ? `${Util.roundToFixed(zScoreResult.value)}%`
                        : "N/A";
                    input.decimalValue = zScoreResult.value
                        ? Util.round(zScoreResult.value / 100, 4)
                        : 0;
                    input.measure = zScoreResult.measure;
                    input.breakpoints = zScoreResult.breakpoint;
                    input.dataPoint = zScoreResult.dataPoint;
                    input.dataAsOf = this.riskService.removeTimeStampFromDate(
                        zScoreResult.dataAsOf
                    );
                    excelStorageService.saveToEstimate(input);
                });
        });
    }

    private updateOperatingMargin(
        model: FieldData,
        excelStorageService: ExcelStorageService
    ): void {
        Object.keys(this.builder)
            .filter((x) => this.builder[x])
            .forEach((item) => {
                if (this.builder[item].source === model.source) {
                    this.builder[item] = model;
                }
            });
        if (
            this.builder.netSalesYear0.value &&
            this.builder.netSalesYear1.value &&
            this.builder.netSalesYear2.value &&
            this.builder.operatingIncomeYear0.value &&
            this.builder.operatingIncomeYear1.value &&
            this.builder.operatingIncomeYear2.value
        ) {
            const netSales: number[] = [
                this.builder.netSalesYear0.value,
                this.builder.netSalesYear1.value,
                this.builder.netSalesYear2.value,
                this.builder.netSalesYear3.value,
                this.builder.netSalesYear4.value,
            ];
            const operatingIncome: number[] = [
                this.builder.operatingIncomeYear0.value,
                this.builder.operatingIncomeYear1.value,
                this.builder.operatingIncomeYear2.value,
                this.builder.operatingIncomeYear3.value,
                this.builder.operatingIncomeYear4.value,
            ];

            const operatingMargin =
                this.calculationService.calculateOperatingMargin(
                    netSales,
                    operatingIncome
                );
            const CVOfOperatingMargin =
                this.calculationService.calculateCVOfOperatingMargin(
                    netSales,
                    operatingIncome
                );

            this.builder.operatingMargin.value = `${Util.roundToFixed(
                operatingMargin.percentage
            )}%`;
            this.builder.CVOfOperatingMargin.value = `${Util.roundToFixed(
                CVOfOperatingMargin.percentage
            )}%`;

            if (this.builder.valuationDate.value) {
                this.updateEstimate(
                    excelStorageService,
                    this.builder.operatingMargin.source,
                    operatingMargin.decimal,
                    this.builder.operatingMargin.inputType
                );
                this.updateEstimate(
                    excelStorageService,
                    this.builder.CVOfOperatingMargin.source,
                    CVOfOperatingMargin.decimal,
                    this.builder.CVOfOperatingMargin.inputType
                );
            }
        } else {
            this.builder.operatingMargin.value = null;
            this.builder.CVOfOperatingMargin.value = null;
        }

        excelStorageService.saveToEstimate(this.builder.operatingMargin);
        excelStorageService.saveToEstimate(this.builder.CVOfOperatingMargin);
    }

    private updateReturnOnEquity(
        excelStorageService: ExcelStorageService
    ): void {
        if (
            this.builder.netIncomeYear0.value &&
            this.builder.netIncomeYear1.value &&
            this.builder.netIncomeYear2.value &&
            this.builder.bookValueOfEquityYear0.value &&
            this.builder.bookValueOfEquityYear1.value &&
            this.builder.bookValueOfEquityYear2.value
        ) {
            const netIncome: number[] = [
                this.builder.netIncomeYear0.value,
                this.builder.netIncomeYear1.value,
                this.builder.netIncomeYear2.value,
                this.builder.netIncomeYear3.value,
                this.builder.netIncomeYear4.value,
            ];
            const bookValueOfEquity: number[] = [
                this.builder.bookValueOfEquityYear0.value,
                this.builder.bookValueOfEquityYear1.value,
                this.builder.bookValueOfEquityYear2.value,
                this.builder.bookValueOfEquityYear3.value,
                this.builder.bookValueOfEquityYear4.value,
            ];

            const returnOnEquity =
                this.calculationService.calculateAverageReturnOnEquity(
                    netIncome,
                    bookValueOfEquity
                );
            const CVOfReturnOnEquity =
                this.calculationService.calculateCVOfReturnOnEquity(
                    netIncome,
                    bookValueOfEquity
                );

            this.builder.returnOnEquity.value = `${Util.roundToFixed(
                returnOnEquity.percentage
            )}%`;
            this.builder.CVOfReturnOnEquity.value = `${Util.roundToFixed(
                CVOfReturnOnEquity.percentage
            )}%`;

            if (this.builder.valuationDate.value) {
                this.updateEstimate(
                    excelStorageService,
                    this.builder.CVOfReturnOnEquity.source,
                    CVOfReturnOnEquity.decimal,
                    this.builder.CVOfReturnOnEquity.inputType
                );
            }
        } else {
            this.builder.returnOnEquity.value = null;
            this.builder.CVOfReturnOnEquity.value = null;
        }

        excelStorageService.saveToEstimate(this.builder.returnOnEquity);
        excelStorageService.saveToEstimate(this.builder.CVOfReturnOnEquity);
    }

    private updateEstimate(
        excelStorageService: ExcelStorageService,
        source: string,
        value: number,
        inputType: InputType
    ): void {
        if (this.builder.valuationDate.value == null) {
            return;
        }
        const valuationDate = ngbDateStructToString(
            this.builder.valuationDate.value
        );
        const portfolioRequest = this.riskClient.getPortfolios(valuationDate);

        if (source === InputName.DebtToEquity) {
            const inputs: FieldData[] = [
                this.builder.marketCap,
                this.builder.bookValueOfEquity,
                this.builder.fiveYrAvgNetIncome,
                this.builder.MVIC,
                this.builder.totalAssets,
                this.builder.fiveYrAvgEBITDA,
                this.builder.sales,
                this.builder.numberOfEmployees,
            ];
            inputs
                .filter((x) => x.value != null)
                .forEach((obj) =>
                    this.processSizeRequest(
                        portfolioRequest,
                        excelStorageService,
                        obj.value,
                        obj.inputType
                    )
                );
        } else if (source === InputName.ValuationDate) {
            Object.keys(this.builder)
                .filter(
                    (x) =>
                        this.builder[x].fieldType === FieldType.Input &&
                        this.builder[x].value != null
                )
                .forEach((x) =>
                    this.processSizeRequest(
                        portfolioRequest,
                        excelStorageService,
                        this.builder[x].value,
                        this.builder[x].inputType
                    )
                );
        } else {
            this.processSizeRequest(
                portfolioRequest,
                excelStorageService,
                value,
                inputType
            );
        }
    }

    private processSizeRequest(
        request: Observable<Portfolio[]>,
        excelStorageService: ExcelStorageService,
        value: any,
        inputType: InputType
    ): void {
        const debt = this.builder.debtToEquity.value
            ? this.builder.debtToEquity.value
            : 0;
        const historicRprErp = this.builder.riskPremiumReport.numericalValue
            ? this.builder.riskPremiumReport.numericalValue
            : 0;

        request.onceDefined((param: Portfolio[]) => {
            this.riskService
                .getPortfolioResult(
                    param,
                    value,
                    inputType,
                    debt,
                    historicRprErp
                )
                .forEach((obj) => {
                    const results = Object.keys(this.builder).filter(
                        (x) =>
                            this.builder[x].riskParameterType ===
                                obj.riskParameterType &&
                            this.builder[x].inputType === obj.inputType &&
                            this.builder[x].equationType === obj.equationType
                    );
                    if (value === "") {
                        const input: FieldData = this.builder[results[0]];
                        input.value = null;
                        input.decimalValue = 0;
                        input.context = "";
                        input.breakpoints = "";
                        input.dataPoint = "";
                        input.dataAsOf = "";
                        excelStorageService.saveToEstimate(input);
                    } else {
                        if (results.length > 0) {
                            const input: FieldData = this.builder[results[0]];
                            input.value = `${Util.roundToFixed(obj.value)}%`;
                            input.decimalValue = Util.round(obj.value / 100, 4);
                            input.context = obj.description;
                            input.breakpoints = obj.breakpoints;
                            input.dataPoint = obj.dataPoint;
                            input.dataAsOf = obj.dataAsOf;
                            excelStorageService.saveToEstimate(input);
                        }
                    }
                });
        });
    }
    private IntlValuationDateUpdated(model: FieldData, excelStorageService: ExcelStorageService){
        this.builder.intlCOCValuationDate.value = model.value;
        excelStorageService.resetIntlIndustries();        
        excelStorageService.saveToEstimate(model);

    }
    private IntlInvestorCountryUpdated(model: FieldData, excelStorageService: ExcelStorageService){
        this.builder.intlInvestorCountry.value = model.value;
        excelStorageService.reflectInvestor(model);
    }
}
