export enum Category {
    BetasLevered = 1,
    BetasUnlevered = 2,
    CostOfDebt = 3,
    CostOfEquityCapital = 4,
    EnterpriseValuationMultiples = 5,
    EquityValuationMultiples = 6,
    FamaFrenchThreeFactor = 7,
    FamaFrenchFiveFactor = 8,
    GrowthRates = 9,
    Industry = 10,
    LeverageRatios = 11,
    LiquidityRatio = 12,
    ProfitabilityRatio = 13,
    ReturnRatios = 14,
    SPIndex = 15,
    Wacc = 16,
    CapitalStructure = 17
}

export enum CategoryName {
    BetasLevered = 'Betas Levered',
    BetasUnlevered = 'Betas Unlevered',
    CostOfDebt = 'Cost of Debt (%)',
    CostOfEquityCapital = 'Cost of Equity Capital (%)',
    EnterpriseValuationMultiples = 'Enterprise Valuation (EV) Multiples',
    EquityValuationMultiples = 'Equity Valuation Multiples',
    GrowthRates = 'Growth Rates (%)',
    LeverageRatios = 'Leverage Ratios (%)',
    LiquidityRatio = 'Liquidity Ratio',
    ProfitabilityRatio = 'Profitability Ratio (%)',
    ReturnRatios = 'Return Ratios (%)',
    Wacc = 'Weighted Average Cost of Capital (WACC) (%)',
    FamaFrenchFiveFactor = 'Fama-French (F-F) 5-Factor Model',
    WaccCalculations = 'WACC Calculation(s)'
}
