<div class="data-summary-tab">
    <div class="common-inputs-content">
        <common-tab-header [headerText]="headers.getAccordionText('dataSummary')"></common-tab-header>
        <div class="tab-value-input current-inputs">
            <h5>
                Current Inputs
            </h5>
            <mat-divider></mat-divider>
            <common-datepicker [config]="builder.valuationDate.config" [(model)]="builder.valuationDate"
                [hideInfoIcon]="true" [disabled]="true"></common-datepicker>
            <common-output [(model)]="builder.homeCountry" [hideInfoIcon]="true"></common-output>
            <common-output [(model)]="builder.investeeCountry" [hideInfoIcon]="true"></common-output>
        </div>
        <div class="export-to-excel">
            <button (click)="onExportToExcel()">
                Export to Excel
            </button>
        </div>
        <div class="input-wrapper">
            <div class="common-accordion">
                <mat-accordion class="excel-input-tab" multi="true">
                    <mat-expansion-panel [@.disabled]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="nested-panel-title-0">
                                    Inputs
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mat-expansion-panel-content">
                            <common-table [menu]="inputsMenu"></common-table>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="common-accordion">
                <mat-accordion class="excel-input-tab" multi="true">
                    <mat-expansion-panel [@.disabled]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="nested-panel-title-0">
                                    Risk-free Rates &amp; Equity Risk Premia
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mat-expansion-panel-content">
                            <common-table [menu]="riskFreeRateMenu"></common-table>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="common-accordion">
                <mat-accordion class="excel-input-tab" multi="true">
                    <mat-expansion-panel [@.disabled]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="nested-panel-title-0">
                                    Size &amp; Risk Premia
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mat-expansion-panel-content">
                            <common-table [menu]="sizePremiaMenu"></common-table>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="common-accordion">
                <mat-accordion class="excel-input-tab" multi="true">
                    <mat-expansion-panel [@.disabled]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="nested-panel-title-0">
                                    Betas/Industry Risk Premia
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="mat-expansion-panel-content">
                            <common-table [menu]="betaMenu"></common-table>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>