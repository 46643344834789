import { Component, OnInit } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AccordionTextService } from 'src/app/_add-in/accordion/accordion-text.service';
import { InputBuilder } from 'src/app/_add-in/data/input.builder';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';
import { GenericTabComponent } from 'src/app/estimate/generic-tab.component';
import { CommonDate } from '../_add-in/common/date-struct';
import { valuationDate } from '../_add-in/help/help-text';
import { ProductType } from '../_add-in/user/enum/product-type';
import { UserStore } from '../_add-in/user/store/user.store';
import { UserUtil } from '../_add-in/user/util/user.util';
import { MinimumDate } from '../_api/responses/minimum-date.response';

@Component({
    selector: 'inputs-tab',
    templateUrl: './inputs-tab.component.html'
})
export class InputsTabComponent extends GenericTabComponent implements OnInit {

    constructor(
        public excelStorageService: ExcelStorageService,
        public userStore: UserStore,
        public builder: InputBuilder,
        public headers: AccordionTextService,
        private spinner: Spinner
    ) {
        super(excelStorageService, userStore, builder);
        this.userStore.user.onceDefined((profile) => {
            this.excelStorageService.getuserprofile(profile);
            this.spinner.begin();
            this.userStore.valuesClient.getBasicMinimumDate().onceDefined((value: MinimumDate | undefined) => {
                const ngbDate = this.builder.valuationDate.value as NgbDateStruct;
                const date = this.builder.valuationDate.value ?
                    new CommonDate(ngbDate.year, ngbDate.month, ngbDate.day) : CommonDate.fromDate(new Date());
                if (value) {
                    const config = UserUtil.getValuationDateInputConfig(date, ProductType.Coc,
                        value.UsEstimates, profile.subscriptionDetails);
                    this.builder.valuationDate.config = {
                        readonly: true,
                        required: true,
                        minimumValue: config.minimumValue,
                        maximumValue: config.maximumValue,
                        help: valuationDate,
                        disabled: this.builder.valuationDate.config && this.builder.valuationDate.config.disabled && this.builder.valuationDate.reference !== ""
                            ? this.builder.valuationDate.config.disabled : false
                    };
                }

            }, () => { }, () => { this.spinner.end(); });
        });
    }

}
