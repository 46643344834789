import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { List, Spinner } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { Dialog } from 'primeng/dialog';
import { CommonDate } from '../_add-in/common/date-struct';
import { InputBuilder } from '../_add-in/data/input.builder';
import { ExcelStorageService } from '../_add-in/excel/excel-storage.service';
import { UserStore } from '../_add-in/user/store/user.store';
import { USCompanyClient } from '../_api/clients/uscompany.client';
import { EstimateIndustries } from '../_api/responses/estimate-industries.response';
import { BenchmarkingUtil } from '../benchmarking/benchmarking.util';
import { BetaCompany, BetaCompanyData, BetaCompanyList, BetaCompanyListItem, BetaCompanyType, BetaCompanyTypeName, FechedCompanyData } from './beta-company-list.model';
import { GenericTabComponent } from './generic-tab.component';

@Component({
    templateUrl: './beta-company-list.component.html',
    styleUrls: ['./beta-company-list.component.scss']
})
export class BetaCompaniesListComponent extends GenericTabComponent implements OnInit {
    public moment = moment;
    public Util = Util;
    public display = true;
    public region = 'United States';
    public currency = 'USD';
    public companies!: BetaCompanyData;
    public companyList!: List<BetaCompany>;
    public industries!: EstimateIndustries[];
    public companyType: BetaCompanyType = BetaCompanyType.fullInformation;
    public companyListHeaders: BetaCompanyListItem[] = BetaCompanyList.betaCompanyList;
    public companyTypeName: BetaCompanyTypeName = BetaCompanyTypeName.fullInformation;
    public dialogHeader = 'View Beta Company List';
    public industry!: EstimateIndustries;
    public dataAsOf!: string;
    public fachedCompanyDataList: FechedCompanyData[] = [];
    public code!: string;
    public industryCode!: number;

    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        public excelStorageService: ExcelStorageService,
        public userStore: UserStore,
        public builder: InputBuilder,
        private usCompanyClient: USCompanyClient,
        private spinner: Spinner
    ) {
        super(excelStorageService, userStore, builder);
    }

    public ngOnInit(): void {
        if (this.companies.Companies.length) {
            this.fachedCompanyDataList.push({
                Data: this.companies,
                CompanyType: BetaCompanyType.fullInformation
            });
        }
        this.setCompanyType(BetaCompanyType.fullInformation);
        this.setDialogHeader();
        this.code = BenchmarkingUtil.isSic(this.companies.DataAsOf, this.region)
            ? `SIC`
            : `GICS`;
        this.setFullInformationCompanyList();
    }

    public setCompanyType(type: BetaCompanyType): void {
        this.companyType = type;
        this.companyTypeName = BetaCompanyList.FindBetaCompanyType(type);
    }

    private setDialogHeader(): void {
        this.dialogHeader = this.dialogHeader;
    }

    public getFullInformationCompanyList(): void {
        if (!(this.fachedCompanyDataList.filter((x) => x.CompanyType === this.companyType).length > 0)) {
            const date = CommonDate.fromStruct(this.builder.valuationDate.value).asString();
            this.industryCode = this.builder.industry0.value.CodeId;
            const request = this.usCompanyClient.getFibCompanies(date, this.industryCode);
            this.spinner.while(request);
            request.once((data) => {
                this.companies = data as any as BetaCompanyData;
                this.addFachedCompanyData(BetaCompanyType.fullInformation);
                this.setFullInformationCompanyList();
            }, () => {
                this.spinner.end();
            });
        } else {
            const data = this.fachedCompanyDataList.filter((x) => x.CompanyType === this.companyType)[0].Data;
            this.companies = data;
            this.setFullInformationCompanyList();
        }
    }
    public setFullInformationCompanyList(): void {
        this.companyList = new List(this.companies.Companies, (data) => {
            if (this.companyType === BetaCompanyType.fullInformation) {
                return data.Type !== BetaCompanyType.Default;
            } else {
                return data.Type === this.companyType;
            }
        });
        this.resetList();
    }
    public setDefaultCompanyList(): void {
        this.companyList = new List(this.companies.Companies, (data) => {
            if (this.companyType === BetaCompanyType.Default) {
                return data.Type !== BetaCompanyType.HighFinancialRisk;
            } else {
                return data.Type === this.companyType;
            }
        });
        this.resetList();
    }
    public getDefaultCompanyList(): void {
        if (!(this.fachedCompanyDataList.filter((x) => x.CompanyType === BetaCompanyType.Default).length > 0)) {
            const date = CommonDate.fromStruct(this.builder.valuationDate.value).asString();
            this.industryCode = this.builder.industry0.value.CodeId;
            const request = this.usCompanyClient.read(date, this.industryCode);
            this.spinner.while(request);
            request.once((data) => {
            this.companies = data as any as BetaCompanyData;
            this.addFachedCompanyData(BetaCompanyType.Default);
            this.setDefaultCompanyList();
            }, () => {
                this.spinner.end();
            });
        } else {
            const company = this.fachedCompanyDataList.filter((x) =>  x.CompanyType === BetaCompanyType.Default)[0];
            this.companies = company.Data;
            this.setDefaultCompanyList();
        }

    }

    public getDefaultCompanyGICList(): void {
        if (!(this.fachedCompanyDataList.filter((x) =>  x.CompanyType === BetaCompanyType.Default).length > 0)) {
            const date = CommonDate.fromStruct(this.builder.valuationDate.value).asString();
            this.industryCode = this.builder.industry0.value.CodeId;
            const request = this.usCompanyClient.getGICCompanies(date, this.industryCode, this.region, this.currency);
            this.spinner.while(request);
            request.once((data) => {
                this.companies = data as any as BetaCompanyData;
                this.addFachedCompanyData(BetaCompanyType.Default);
                this.setDefaultCompanyList();
            }, () => {
                this.spinner.end();
            });
        } else {
            const company = this.fachedCompanyDataList.filter((x) => x.CompanyType === BetaCompanyType.Default)[0];
            this.companies = company.Data;
            this.setDefaultCompanyList();
        }
    }

    public resetList(): void {
        if (this.companyList && this.companyList.all.length) {
            this.companyList.filter('-');
        }
        this.sortBy('Name');
        this.setDialogHeader();
    }

    public addFachedCompanyData(companyType: BetaCompanyType): void {
        this.fachedCompanyDataList.push({
            Data: this.companies,
            CompanyType: companyType
        });
    }
    public sortBy(field: string): void {
        this.companyList.sort(field);
    }
    public fetchBetaCompanyData(): void {
        if (this.companyType === BetaCompanyType.fullInformation) {
            this.getFullInformationCompanyList();
        } else {
            this.code === `SIC` ? this.getDefaultCompanyList() : this.getDefaultCompanyGICList();
        }
    }
}
