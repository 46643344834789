import { Error } from './error.model';

export class ErrorService {

    public static equityRiskPremium: Error = {
        text: '{}% is an invalid entry for an equity risk premium. Please enter a value between 0% and 10%.',
        alwaysShow: false
    };

    public static beta: Error = {
        text: '{} is an invalid entry for a beta. Please enter a value between 0 and 5.',
        alwaysShow: false
    };

    public static sizeMeasure: Error = {
        text: '{} is an invalid entry for a size measure. Please enter a value greater than 0.',
        alwaysShow: false
    };

    public static debtRatio: Error = {
        text: 'Please enter a value greater than 0.',
        alwaysShow: false
    };
}
