// tslint:disable:max-line-length

import { Help } from './help.model';

export const valuationDate: Help = {
    title: `Valuation Date`,
    text: `The valuation date is the specific point in time as of which the valuation analyst’s opinion of value applies. The valuation date is sometimes referred to as the ‘‘effective date’’ or ‘‘appraisal date’’. The Cost of Capital Navigator pulls the most recent data available as of your valuation date.`
};
export const industry: Help = {
    title: `Industry`,
    text: `<p>For valuation dates prior to September 30, 2020, the U.S. Cost of Capital Module utilizes the Standard Industrial Classification (SIC) code to classify industries. For valuation dates of September 30, 2020 and thereafter, the U.S. Cost of Capital Module utilizes the Global Industry Classification Standard (GICS).</p>
    <p>Users can search for an industry by SIC or GICS Code (depending on the date selected), or by typing in a keyword (e.g., information technology). Users may add additional scenarios to analyze more than one industry at a time.</p>
    <p>If a particular industry is not available, it is because a sufficient number of companies (at least 5) operating in that industry did not pass our screening criteria.</p>
    <p>The SIC is a system for classifying industries by 1-, 2-, 3-, and 4-digit codes (1-digit SIC codes are the broadest, least specific industry categories; 4-digit SIC codes are the narrowest, most specific industry categories). SIC codes can be grouped into progressively more specific industry levels: from division (1-digit SICs, the least specific (i.e., broadest) level), to major group (2-digit SICs), to industry group (3-digit SICs), and finally individual industry (4-digit SICs, the most specific (i.e., narrowest) level). More specific (i.e., narrower) SICs “roll up” into less specific (i.e., broader) SICs. Only SIC descriptions listed on the United States Occupational Safety & Health Administration’s (OSHA) website are included here.</p>
    <p>To learn more, visit: <a href="https://osha.gov/pls/imis/sic_manual.html">https://osha.gov/pls/imis/sic_manual.html</a></p>
    <p>GICS is a four-tiered, hierarchical industry classification system. It consists of 11 "sectors" (2-digit GICS), 24 "industry groups" (4-digit GICS), 69 "industries" (6-digit GICS), and 158 "sub-industries" (8-digit GICS). For more information, visit: <a href="https://www.msci.com">https://www.msci.com</a></p>`
};
export const homeCountry: Help = {
    title: `Home Country`,
    text: `The country in which the investor is based.`
};
export const investeeCountry: Help = {
    title: `Investee Country`,
    text: `The country in which the investment is based.`
};
export const marketCap: Help = {
    title: `Market Value of Common Equity ($mm)`,
    text: `Common stock price times number of common shares outstanding. Also known as the market value of equity.`
};
export const bookValueOfEquity: Help = {
    title: `Book Value of Equity ($mm)`,
    text: `The difference between total assets and total liabilities as they appear on the balance sheet.`
};
export const fiveYrAvgNetIncome: Help = {
    title: `5-Year Average Net Income ($mm)`,
    text: `Revenue minus expenses (depreciation, interest, and taxes).`
};
export const MVIC: Help = {
    title: `Market Value of Invested Capital ($mm)`,
    text: `The market value of equity plus the market value of the debt component of invested capital.`
};
export const totalAssets: Help = {
    title: `Total Assets ($mm)`,
    text: `Total amount of assets owned.`
};
export const fiveYrAvgEBITDA: Help = {
    title: `5-Year Average EBITDA ($mm)`,
    text: `Earnings before interest, taxes, depreciation, and amortization.`
};
export const sales: Help = {
    title: `Sales ($mm)`,
    text: `Gross sales less the deduction of returns, allowances, discounts.`
};
export const numberOfEmployees: Help = {
    title: `# of Employees`,
    text: `Total number of employees at your subject company.`
};
export const debtToEquity: Help = {
    title: `Debt to Equity`,
    text: `Total debt divided by market value of common equity`
};
export const netSalesYear0: Help = {
    title: `Net Sales (Y<sub>0</sub>)`,
    text: ``
};
export const netSalesYear1: Help = {
    title: `Net Sales (Y<sub>-1</sub>)`,
    text: ``
};
export const netSalesYear2: Help = {
    title: `Net Sales (Y<sub>-2</sub>)`,
    text: ``
};
export const netSalesYear3: Help = {
    title: `Net Sales (Y<sub>-3</sub>)`,
    text: ``
};
export const netSalesYear4: Help = {
    title: `Net Sales (Y<sub>-4</sub>)`,
    text: ``
};
export const operatingIncomeYear0: Help = {
    title: `Operating Income (Y<sub>0</sub>)`,
    text: ``
};
export const operatingIncomeYear1: Help = {
    title: `Operating Income (Y<sub>-1</sub>)`,
    text: ``
};
export const operatingIncomeYear2: Help = {
    title: `Operating Income (Y<sub>-2</sub>)`,
    text: ``
};
export const operatingIncomeYear3: Help = {
    title: `Operating Income (Y<sub>-3</sub>)`,
    text: ``
};
export const operatingIncomeYear4: Help = {
    title: `Operating Income (Y<sub>-4</sub>)`,
    text: ``
};
export const operatingMargin: Help = {
    title: `Operating Margin`,
    text: `<p>Average operating margin is the mean of the operating margin (operating income divided by net sales) calculated for each year of data provided. Operating income is defined as sales minus cost of goods sold plus selling, general, and administrative expenses plus depreciation.</p>
    <p>The minimum number of inputs that are required is the most recent 3 years of fiscal data (enter 5 years of data for best results).</p>`
};
export const CVOfOperatingMargin: Help = {
    title: `CV of Operating Margin`,
    text: `<p>The standard deviation of operating margin over the prior five years divided by the average operating margin for the same years. For calculating this coefficient, average operating margin is a simple average of the annual ratios of operating income to sales.</p>
    <p>The minimum number of inputs that are required is the most recent 3 years of fiscal data (enter 5 years of data for best results).</p>`
};
export const netIncomeYear0: Help = {
    title: `Net Income Year (Y<sub>0</sub>)`,
    text: ``
};
export const netIncomeYear1: Help = {
    title: `Net Income Year (Y<sub>-1</sub>)`,
    text: ``
};
export const netIncomeYear2: Help = {
    title: `Net Income Year (Y<sub>-2</sub>)`,
    text: ``
};
export const netIncomeYear3: Help = {
    title: `Net Income Year (Y<sub>-3</sub>)`,
    text: ``
};
export const netIncomeYear4: Help = {
    title: `Net Income Year (Y<sub>-4</sub>)`,
    text: ``
};
export const bookValueOfEquityYear0: Help = {
    title: `Book Value of Equity (Y<sub>0</sub>)`,
    text: ``
};
export const bookValueOfEquityYear1: Help = {
    title: `Book Value of Equity (Y<sub>-1</sub>)`,
    text: ``
};
export const bookValueOfEquityYear2: Help = {
    title: `Book Value of Equity (Y<sub>-2</sub>)`,
    text: ``
};
export const bookValueOfEquityYear3: Help = {
    title: `Book Value of Equity (Y<sub>-3</sub>)`,
    text: ``
};
export const bookValueOfEquityYear4: Help = {
    title: `Book Value of Equity (Y<sub>-4</sub>)`,
    text: ``
};
export const returnOnEquity: Help = {
    title: `Return on Equity`,
    text: `<p>The mean return on equity (net income divided by book value of equity) calculated for each year of data provided.</p>
    <p>The minimum number of inputs that are required is the most recent 3 years of fiscal data (enter 5 years of data for best results).</p>`
};
export const CVOfReturnOnEquity: Help = {
    title: `CV of Return On Equity`,
    text: `<p>The standard deviation of return on book equity for the prior five years divided by the mean return on book equity for the same years. Return on book equity is defined as net income before extraordinary items minus preferred dividends divided by book value of common equity.</p>
    <p>The minimum number of inputs that are required is the most recent 3 years of fiscal data (enter 5 years of data for best results).</p>`
};
export const currentYearEBIT: Help = {
    title: `Current Year EBIT`,
    text: `Earnings before interest and taxes for the current year.`
};
export const currentYearSales: Help = {
    title: `Current Year Sales`,
    text: `Gross sales less the deduction of returns, allowances, discounts for the current year.`
};
export const currentAssets: Help = {
    title: `Current Assets`,
    text: `Cash and assets that are reasonably expected to be converted into cash within one year in the normal course of business.`
};
export const currentLiabilities: Help = {
    title: `Current Liabilities`,
    text: `Debt or obligations that are due within one year.`
};
export const retainedEarnings: Help = {
    title: `Retained Earnings`,
    text: `Earnings retained by the subject company to be reinvested or used to pay off debt.`
};
export const ZScore: Help = {
    title: `z-Score`,
    text: `<p>Altman z-Score is an accounting-data-based method designed to assess financial condition and developed originally for assessing the likelihood of bankruptcy.</p>
    <p>The z-Score is calculated based on the inputs provided above. A z-Score that falls within the Distress Zone suggests that the subject company is at risk of bankruptcy according to the Altman z-Score method.</p>
    <p>Manufacturing (z-Score) < 1.8 (distress zone)</p>
    <p>Service (z-Score) < 1.1 (distress zone)</p>`
};
export const spot20YearTreasury: Help = {
    title: `Spot 20-year Treasury Yield`,
    text: `The current yield on a risk-free government bond (as of the valuation date).`
};
export const spot30YearTreasury: Help = {
    title: `Spot 30-year Treasury Yield`,
    text: `The current yield on a risk-free government bond (as of the valuation date).`
};
export const spot10YearTreasury: Help = {
    title: `Spot 10-year Treasury Yield`,
    text: `The current yield on a risk-free government bond (as of the valuation date).`
};
export const normalizedRiskFreeRate: Help = {
    title: `Kroll Normalized Risk-free Rate`,
    text: `During periods in which risk-free rates appear to be abnormally low due to flight to quality or massive central bank monetary interventions, valuation analysts may want to consider normalizing the risk-free rate. By “normalization” we mean estimating a risk-free rate that more likely reflects the sustainable average return of long-term government bonds.`
};
export const DNPRecommended: Help = {
    title: `Kroll Recommended ERP`,
    text: `During periods in which risk-free rates appear to be abnormally low due to flight to quality or massive central bank monetary interventions, valuation analysts may want to consider normalizing the risk-free rate. By “normalization” we mean estimating a risk-free rate that more likely reflects the sustainable average return of long-term government bonds.`
};
export const historicalLongTerm: Help = {
    title: `Historical Long-term ERP (1926-Present)`,
    text: `The Historical Equity Risk Premium is calculated as the average annual return of the stock market minus the average annual return of the long-term government bond income return series. `
};
export const supplySideLongTerm: Help = {
    title: `Supply-side Long term ERP (1926-Present)`,
    text: `The Supply-side model forecast the equity risk premium using historical data. The analysis uses an earnings model as the basis for their supply-side estimate. The earnings model breaks the historical equity return into four pieces, with only three historically being supplied by companies: inflation, income return, and growth in real earnings per share. The growth in the P/E ratio, the fourth piece, is a reflection of investors’ changing prediction of future earnings growth.`
};
export const riskPremiumReport: Help = {
    title: `Risk Premium Report Historical Long-term ERP (1963-Present)`,
    text: `The equity risk premium is the extra return investors expect as compensation for assuming the additional risk associated with an investment in a diversified portfolio of common stocks, compared to the return they would expect from an investment in risk-free securities.`
};
export const DNPRecommendedAdjusted: Help = {
    title: `Kroll Recommended ERP Adjustment`,
    text: `The ERP Adjustment is needed to account for the difference between the forward-looking ERP as of the valuation date that the analyst has selected to use in his or her cost of equity capital calculations, and the historical (in this case, 1963–present) ERP that was used as a convention in the calculations performed to create the Risk Premium Report exhibits. The ERP Adjustment is necessary when utilizing using a Risk Premium over the Risk-free Rate.`
};
export const historicalLongTermAdjusted: Help = {
    title: `Historical Long-term ERP Adjustment (1926-Present)`,
    text: `The ERP Adjustment is needed to account for the difference between the forward-looking ERP as of the valuation date that the analyst has selected to use in his or her cost of equity capital calculations, and the historical (in this case, 1963–present) ERP that was used as a convention in the calculations performed to create the Risk Premium Report exhibits. The ERP Adjustment is necessary when utilizing using a Risk Premium over the Risk-free Rate.`
};
export const supplySideLongTermAdjusted: Help = {
    title: `Supply-side Long term ERP Adjustment (1926-Present)`,
    text: `The ERP Adjustment is needed to account for the difference between the forward-looking ERP as of the valuation date that the analyst has selected to use in his or her cost of equity capital calculations, and the historical (in this case, 1963–present) ERP that was used as a convention in the calculations performed to create the Risk Premium Report exhibits. The ERP Adjustment is necessary when utilizing using a Risk Premium over the Risk-free Rate.`
};
export const riskPremiumReportAdjusted: Help = {
    title: `Risk Premium Report Historical Long-term ERP Adjustment (1963-Present)`,
    text: `The ERP Adjustment is needed to account for the difference between the forward-looking ERP as of the valuation date that the analyst has selected to use in his or her cost of equity capital calculations, and the historical (in this case, 1963–present) ERP that was used as a convention in the calculations performed to create the Risk Premium Report exhibits. The ERP Adjustment is necessary when utilizing using a Risk Premium over the Risk-free Rate.`
};
export const decile: Help = {
    title: `Decile`,
    text: `The CRSP Deciles Size Study sorts companies into 10 deciles ranked by market cap, with Decile 1 comprising the largest companies and Decile 10 comprising the smallest companies.`
};
export const sizeGrouping: Help = {
    title: `Size Grouping`,
    text: `Mid-cap comprises Deciles 3–5. Low-cap comprises Deciles 6–8. Mirco-cap comprises Deciles 9 and 10.`
};
export const decileBreakdown1: Help = {
    title: `10th Decile Breakdown 1`,
    text: `10A is the top half of Decile 10. 10B is the bottom half of Decile 10. `
};
export const decileBreakdown2: Help = {
    title: `10th Decile Breakdown 2`,
    text: `The 10th Decile can be broken into Quartiles (10w, 10x, 10y, and 10z).`
};
export const marketCapPortfolio: Help = {
    title: `Portfolio`,
    text: `In order to calculate the portfolio for the Market Value of Equity, please provide a market value of equity.`
};
export const bookValueOfEquityPortfolio: Help = {
    title: `Portfolio`,
    text: ``
};
export const fiveYrAvgNetIncomePortfolio: Help = {
    title: `Portfolio`,
    text: ``
};
export const MVICPortfolio: Help = {
    title: `Portfolio`,
    text: ``
};
export const marketCapRegression: Help = {
    title: `Regression Method`,
    text: ``
};
export const bookValueOfEquityRegression: Help = {
    title: `Regression Method`,
    text: ``
};
export const fiveYrAvgNetIncomeRegression: Help = {
    title: `Regression Method`,
    text: ``
};
export const MVICRegression: Help = {
    title: `Regression Method`,
    text: ``
};
export const totalAssetsPortfolio: Help = {
    title: `Portfolio`,
    text: ``
};
export const fiveYrAvgEBITDAPortfolio: Help = {
    title: `Portfolio`,
    text: ``
};
export const salesPortfolio: Help = {
    title: `Portfolio`,
    text: ``
};
export const numberOfEmployeesPortfolio: Help = {
    title: `Portfolio`,
    text: ``
};
export const totalAssetsRegression: Help = {
    title: `Regression Method`,
    text: ``
};
export const fiveYrAvgEBITDARegression: Help = {
    title: `Regression Method`,
    text: ``
};
export const salesRegression: Help = {
    title: `Regression Method`,
    text: ``
};
export const numberOfEmployeesRegression: Help = {
    title: `Regression Method`,
    text: ``
};
export const marketCapPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const bookValueOfEquityPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const fiveYrAvgNetIncomePortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const MVICPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const marketCapRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const bookValueOfEquityRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const fiveYrAvgNetIncomeRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const MVICRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const totalAssetsPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const fiveYrAvgEBITDAPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const salesPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const numberOfEmployeesPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const totalAssetsRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const fiveYrAvgEBITDARegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const salesRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const numberOfEmployeesRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const marketCapPortfolioUnlevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const marketCapRegressionUnlevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const bookValueOfEquityPortfolioUnlevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const bookValueOfEquityRegressionUnlevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const fiveYrAvgNetIncomePortfolioUnlevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const fiveYrAvgNetIncomeRegressionUnlevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const MVICPortfolioUnlevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const MVICRegressionUnlevered: Help = {
    title: `Regression Method`,
    text: ``
};

export const totalAssetsPortfolioUnlevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const totalAssetsRegressionUnlevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const fiveYrAvgEBITDAPortfolioUnlevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const fiveYrAvgEBITDARegressionUnlevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const salesPortfolioUnlevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const salesRegressionUnlevered: Help = {
    title: `Regression Method`,
    text: ``
};

export const numberOfEmployeesPortfolioUnlevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const numberOfEmployeesRegressionUnlevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const marketCapPortfolioRelevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const bookValueOfEquityPortfolioRelevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const fiveYrAvgNetIncomePortfolioRelevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const MVICPortfolioRelevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const totalAssetsPortfolioRelevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const fiveYrAvgEBITDAPortfolioRelevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const salesPortfolioRelevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const numberOfEmployeesPortfolioRelevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const marketCapRegressionRelevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const bookValueOfEquityRegressionRelevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const fiveYrAvgNetIncomeRegressionRelevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const MVICRegressionRelevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const totalAssetsRegressionRelevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const fiveYrAvgEBITDARegressionRelevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const salesRegressionRelevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const numberOfEmployeesRegressionRelevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const operatingMarginPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const CVOfOperatingMarginPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const CVOfReturnOnEquityPortfolioLevered: Help = {
    title: `Portfolio`,
    text: ``
};
export const operatingMarginRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const CVOfOperatingMarginRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const CVOfReturnOnEquityRegressionLevered: Help = {
    title: `Regression Method`,
    text: ``
};
export const HFRSizePremium: Help = {
    title: `HFR Premium Over CAPM`,
    text: ``
};
export const HFRRiskPremiumOverTheRiskFreeRate: Help = {
    title: `HFR Risk Premium Over the Risk-free Rate`,
    text: ``
};
export const fullInformationBeta: Help = {
    title: `Full-information Beta`,
    text: `The Full-information Beta methodology is based on the premise that a business can be thought of as a portfolio of assets. The full-information methodology is designed to capture the impact that the individual segments have upon the overall business beta.`
};
export const vasicekAdjusted: Help = {
    title: `Median Vasicek-adjusted Beta`,
    text: `The Median Vasicek-adjusted Beta is a Raw (OLS) beta adjusted toward the industry average beta, which can increase the statistical quality of the beta. Betas with lower statistical quality are more heavily adjusted to the industry average than those with high statistical quality.`
};
export const sum: Help = {
    title: `Median Sum Beta`,
    text: `The Median Sum Beta is an attempt to capture more fully the lagged effect of co-movement in a company's returns with returns on the market, an effect which is usually more prominent with smaller companies than with larger companies.`
};
export const HFRSumBeta: Help = {
    title: `HFR Sum Beta`,
    text: `The HFR Composite Sum Beta is an attempt to capture more fully the lagged effect of co-movement in a company's returns with returns on the market, an effect which is usually more prominent with smaller companies than with larger companies.`
};
export const beta: Help = {
    title: `Beta`,
    text: `
    <p>The Full-information Beta methodology is based on the premise that a business can be thought of as a portfolio of assets. The full-information methodology is designed to capture the impact that the individual segments have upon the overall business beta.</p>
    <p>The Median Vasicek-adjusted Beta is a Raw (OLS) beta adjusted toward the industry average beta, which can increase the statistical quality of the beta. Betas with lower statistical quality are more heavily adjusted to the industry average than those with high statistical quality.</p>
    <p>The Median Sum Beta (or HFR Composite Sum Beta) is an attempt to capture more fully the lagged effect of co-movement in a company's returns with returns on the market, an effect which is usually more prominent with smaller companies than with larger companies.</p>
    `
};
export const equityRiskPremium: Help = {
    title: `Equity Risk Premium`,
    text: `The equity risk premium is the extra return investors expect as compensation for assuming the additional risk associated with an investment in a diversified portfolio of common stocks, compared to the return they would expect from an investment in risk-free securities.`
};
export const industryRiskPremium: Help = {
    title: `Industry Risk Premium`,
    text: `The industry risk premium is a measure of beta (market) risk scaled around 0. Industry risk premia are appropriately used in “build-up” methods of estimating cost of equity that do not otherwise have a measure of beta. Industry risk premia are not appropriate for use in the CAPM model.`
};
export const serviceCompanyType: Help = {
    title: `Service`,
    text: ``
};
export const manufacturingCompanyType: Help = {
    title: `Manufacturing`,
    text: ``
};
export const benchMarkingValuationDate: Help = {
    title: `Valuation Date`,
    text: ``
};
export const benchMarkingRegion: Help = {
    // title: `Region`,
    text: ` <p>U.S. Industry Benchmarking Module subscribers have access to data for the United States.</p>
    <p>International Industry Benchmarking Module subscribers have access to data for 4 global economic regions: (i) the "World" (based on the MSCI ACWI IMI Index), (ii) the European Union, (iii) the Eurozone, and (iv) the United Kingdom. </p>
    <p>Users may add additional scenarios if they wish to analyze more than one region at a time. </p>
    `
};
export const benchMarkingCurrency: Help = {
    title: ``,
    text: ` <p>U.S. Industry Benchmarking Module analyses are presented only in U.S. Dollars (USD).</p>
    <p>International Industry Benchmarking Module analyses are performed in Euro (EUR), British Pounds (GBP), and U.S. Dollars (USD).</p>
    <p>Users may add additional scenarios if they wish to see their analysis presented in more than one currency.</p>
    `
};
export const benchMarkingIndustry: Help = {
    title: `Industry`,
    text: `<p>For valuation dates prior to September 30, 2020, the U.S. Industry Benchmarking Module utilizes the Standard Industrial Classification (SIC) code to classify industries. For valuation dates of September 30, 2020 and thereafter, the U.S. Industry Benchmarking Module utilizes the Global Industry Classification Standard (GICS).</p>
    <p>The International Industry Benchmarking Module utilizes the Global Industry Classification Standard (GICS) for all valuation dates.</p>
    <p>Users can search for an industry by SIC or GICS Code (depending on the region and date selected), or by typing in a keyword (e.g., information technology). Users may add additional scenarios to analyze more than one industry at a time.</p>
    <p>The U.S. Industry Benchmarking Module provides analysis for 1-, 2- 3, and 4-digit SIC Codes (prior to September 30, 2020) and for 2-, 4-, 6-, and 8-digit GICS (for September 30, 2020 and thereafter). The International Industry Benchmarking Module provides analysis for 2-, 4-, and 6-digit GICS. If a particular industry is not available in either module, it is because a sufficient number of companies (at least 5) operating in that industry did not pass our screening criteria. For more information on our screening criteria, view the "Methodology" file available in the resources section for the applicable module (U.S. or International) in question.</p>
    <p>The SIC is a system for classifying industries by 1-, 2-, 3-, and 4-digit codes (1-digit SIC codes are the broadest, least specific industry categories; 4-digit SIC codes are the narrowest, most specific industry categories). The SIC codes can be grouped into progressively more specific industry levels: from division (1-digit SICs, the least specific (i.e., broadest) level), to major group (2-digit SICs), to industry group (3-digit SICs), and finally individual industry (4-digit SICs, the most specific (i.e., narrowest) level). More specific (i.e., narrower) SICs “roll up” into less specific (i.e., broader) SICs. Only SIC descriptions listed on the United States Occupational Safety & Health Administration’s (OSHA) website are included here.</p>
    <p>To learn more, visit: <a href="https://osha.gov/pls/imis/sic_manual.html">https://osha.gov/pls/imis/sic_manual.html</a></p>
    <p>GICS is a four-tiered, hierarchical industry classification system. It consists of 11 "sectors" (2-digit GICS), 24 "industry groups" (4-digit GICS), 69 "industries" (6-digit GICS), and 158 "sub-industries" (8-digit GICS). For more information, visit: <a href="https://www.msci.com">https://www.msci.com</a></p>`
};
export const benchMarkingProductType: Help = {
    title: `Product type`,
    text: ``
};
export const IntlCOCValuationDate: Help = {
        title: `Valuation Date`,
        text: `<p>Subscribers to the International Cost of Capital Module have access to data going back to December 31, 2013.</P>
               <p>The Cost of Capital Navigator will always pull the most recent data available as of the selected valuation date. Data in the International Cost of Capital Module are updated daily, quarterly, and annually, depending on the data point.</P>`
};
export const InternationalInvestorCountry: Help = {
    title:`Investor Country`,
    text:`<p>The “Investor Country” is the country in which the investor is based. For example, if a U.S. investor is evaluating an investment located in Germany, the “investor country” is the U.S., and the “investee country” is Germany.</p>
                <p>This term is closely related to the concept of “investor perspective”. In the previous example, the German investment is being evaluated from the “perspective” of a United States investor.</p>
                <p>Only one investor country can be selected.</p>`
}
export const InternationalInvestorCurrency: Help = {
    title: `Currency`,
    text: ` <p>U.S. Industry Benchmarking Module analyses are presented only in U.S. Dollars (USD).</p>
    <p>International Industry Benchmarking Module analyses are performed in Euro (EUR), British Pounds (GBP), and U.S. Dollars (USD).</p>
    <p>Users may add additional scenarios if they wish to see their analysis presented in more than one currency.</p>
    `
};
export const InternationalIndustry: Help = {
    title: `Industry`,
    text: `<p>For valuation dates prior to September 30, 2020, the U.S. Industry Benchmarking Module utilizes the Standard Industrial Classification (SIC) code to classify industries. For valuation dates of September 30, 2020 and thereafter, the U.S. Industry Benchmarking Module utilizes the Global Industry Classification Standard (GICS).</p>
    <p>The International Industry Benchmarking Module utilizes the Global Industry Classification Standard (GICS) for all valuation dates.</p>
    <p>Users can search for an industry by SIC or GICS Code (depending on the region and date selected), or by typing in a keyword (e.g., information technology). Users may add additional scenarios to analyze more than one industry at a time.</p>
    <p>The U.S. Industry Benchmarking Module provides analysis for 1-, 2- 3, and 4-digit SIC Codes (prior to September 30, 2020) and for 2-, 4-, 6-, and 8-digit GICS (for September 30, 2020 and thereafter). The International Industry Benchmarking Module provides analysis for 2-, 4-, and 6-digit GICS. If a particular industry is not available in either module, it is because a sufficient number of companies (at least 5) operating in that industry did not pass our screening criteria. For more information on our screening criteria, view the "Methodology" file available in the resources section for the applicable module (U.S. or International) in question.</p>
    <p>The SIC is a system for classifying industries by 1-, 2-, 3-, and 4-digit codes (1-digit SIC codes are the broadest, least specific industry categories; 4-digit SIC codes are the narrowest, most specific industry categories). The SIC codes can be grouped into progressively more specific industry levels: from division (1-digit SICs, the least specific (i.e., broadest) level), to major group (2-digit SICs), to industry group (3-digit SICs), and finally individual industry (4-digit SICs, the most specific (i.e., narrowest) level). More specific (i.e., narrower) SICs “roll up” into less specific (i.e., broader) SICs. Only SIC descriptions listed on the United States Occupational Safety & Health Administration’s (OSHA) website are included here.</p>
    <p>To learn more, visit: <a href="https://osha.gov/pls/imis/sic_manual.html">https://osha.gov/pls/imis/sic_manual.html</a></p>
    <p>GICS is a four-tiered, hierarchical industry classification system. It consists of 11 "sectors" (2-digit GICS), 24 "industry groups" (4-digit GICS), 69 "industries" (6-digit GICS), and 158 "sub-industries" (8-digit GICS). For more information, visit: <a href="https://www.msci.com">https://www.msci.com</a></p>`
};

