import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Auth0ApiClient } from "../api.client";
import { CountryCreditRating, IntlCOCInvesteeExcelAddinDetails } from "../responses/country-credit-rating.response";
import { CountryRiskPremia } from "../responses/country-risk-premia.response";

@Injectable()
export class CostofEquityClient extends Auth0ApiClient{
    protected baseUrl = `${environment.urls.navigatorApi}/api`;
    public readCcr(investor: number, investee: number, dataAsOf: string): Observable<CountryCreditRating> {
        return this.get(
            `CostOfEquity/Ccr?investorCountryId=${investor}&investeeCountryId=${investee}&dataAsOf=${dataAsOf}`
        );
    }
    public readCountryRiskPremia(investor: number, investee: number, dataAsOf: string): Observable<CountryRiskPremia[]> {
        return this.get(`CostOfEquity/CountryRiskPremia?investorCountryId=${investor}&investeeCountryId=${investee}&dataAsOf=${dataAsOf}`);
    }
    public readIntlCOCExcelAddinDetails(investee: number, dataAsOf: string): Observable<IntlCOCInvesteeExcelAddinDetails[]>{
        return this.get(`CostOfEquity/GetIntlCOCAddinExportlist?investeeCountryId=${investee}&dataAsOf=${dataAsOf}`)
    }
}