import { CompanyType } from 'src/app/_api/responses/us-company.response';
import { CompanyListItem } from './company-list-item.model';
import { CompanyTypeName } from './companyTypeName';

export class CompanyList {
    public static readonly companyList: CompanyListItem[] = [
        {
            companyType: CompanyType.Default,
            companyTypeName: CompanyTypeName.default
        },
        {
            companyType: CompanyType.Large,
            companyTypeName: CompanyTypeName.large
        },
        {
            companyType: CompanyType.Small,
            companyTypeName: CompanyTypeName.small
        },
        {
            companyType: CompanyType.HighFinancialRisk,
            companyTypeName: CompanyTypeName.highFinancialRisk
        }
    ];

    public static find(type: CompanyType): CompanyTypeName {
        const item: CompanyListItem | undefined = CompanyList.companyList.find((x) => x.companyType === type);

        if (item == null) {
            return CompanyTypeName.default;
        }

        return item.companyTypeName;
    }
}
