import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";
import { Auth0ApiClient } from "../api.client";
import { Gic } from "../responses/gic.response";

@Injectable()
export class IntlIndustryClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(countryId: number, currencyId: number, dataAsOf: string): Observable<Gic[]> {
        return this.get(`IntlIndustry?countryId=${countryId}&currencyId=${currencyId}&dataAsOf=${dataAsOf}`);
    }
}
