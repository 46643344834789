import { Injectable, Injector, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ProductType } from './user/enum/product-type';
import { UserStore } from './user/store/user.store';

@Injectable()
export class IsUserHaveCocSubscriptionGuard implements CanActivate {
    constructor(
        private router: Router,
        private userStore: UserStore,
        private injector: Injector
    ) { }

    public canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            this.userStore.user.onceDefined((user) => {
                if (user) {
                    const cocSubData = user.subscriptionDetails.find((sub) => sub.productType.toUpperCase() === ProductType.Coc.toUpperCase());
                    if (cocSubData) {
                        if (cocSubData.expiration.asDate() > new Date()) {
                            resolve(true);
                        } else {

                            resolve(false);
                            this.router.navigate(['subscription']);
                        }

                    } else {

                        //\const routerService = this.injector.get(Router);      
                            resolve(false);
                            setTimeout(() => {
                            this.router.navigate(['subscription']);                              
                            }, 300);
                      

                       
                    }
                } else {

                    resolve(false);
                    this.router.navigate(['landing']);
                }
            });
        });
    }
}
