<div class="betas-tab">
    <div class="common-inputs-content">
        <common-tab-header [headerText]="headers.getAccordionText('betas')"></common-tab-header>
        <div class="tab-value-input current-inputs">
            <h5>
                Current Inputs
            </h5>
            <mat-divider></mat-divider>
            <common-datepicker [config]="builder.valuationDate.config" [(model)]="builder.valuationDate">
            </common-datepicker>
        </div>
        <mat-accordion class="excel-input-tab" multi="true">
            <mat-expansion-panel [@.disabled]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="nested-panel-title-0">
                            Levered Betas and Industry Risk Premia
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-content">
                    <common-tab-header [headerText]="headers.getAccordionText('betaPanel')"></common-tab-header>
                    <mat-divider></mat-divider>
                    <div class="combobox-container">
                        <div class="view-link">
                            <span class="usi-industry-link view-industries-link" (click)="showIndustries()">
                                <span>View Industry List</span>
                            </span>
                            <span class="usi-industry-link view-companies-link"
                                (click)="showCompanies(builder.industry0)">
                                <span>View Beta Company List</span>
                            </span>
                        </div>
                        <common-typeahead [(model)]="builder.industry0" [typeahead]="industryService.searchIndustries"
                            [typeaheadFormatter]="industryService.industryTypeaheadFormatter"
                            typeaheadValue="{{determineIndustryCode()}}">
                        </common-typeahead>
                        <!-- <common-output [(model)]="builder.industry0"></common-output> -->
                        <common-output [(model)]="builder.fullInformationBeta0"></common-output>
                        <common-output [(model)]="builder.vasicekAdjustedBeta0"></common-output>
                        <common-output [(model)]="builder.medianSumLeveredBeta0"></common-output>
                        <common-output [(model)]="builder.highFinancialRiskSumLeveredBeta0"></common-output>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="combobox-container">
                        <div class="view-link">
                            <span class="usi-industry-link view-industries-link" (click)="showIndustries()">
                                <span>View Industry List</span>
                            </span>
                            <span class="usi-industry-link view-companies-link"
                                (click)="showCompanies(builder.industry1)">
                                <span>View Beta Company List</span>
                            </span>
                        </div>
                        <common-typeahead [(model)]="builder.industry1" [typeahead]="industryService.searchIndustries"
                        [typeaheadFormatter]="industryService.industryTypeaheadFormatter"
                        typeaheadValue="{{determineIndustryCode()}}">
                    </common-typeahead>
                        <!-- <common-output [(model)]="builder.industry1"></common-output> -->
                        <common-output [(model)]="builder.fullInformationBeta1"></common-output>
                        <common-output [(model)]="builder.vasicekAdjustedBeta1"></common-output>
                        <common-output [(model)]="builder.medianSumLeveredBeta1"></common-output>
                        <common-output [(model)]="builder.highFinancialRiskSumLeveredBeta1"></common-output>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="combobox-container">
                        <div class="view-link">
                            <span class="usi-industry-link view-industries-link" (click)="showIndustries()">
                                <span>View Industry List</span>
                            </span>
                            <span class="usi-industry-link view-companies-link"
                                (click)="showCompanies(builder.industry2)">
                                <span>View Beta Company List</span>
                            </span>
                        </div>
                        <common-typeahead [(model)]="builder.industry2" [typeahead]="industryService.searchIndustries"
                            [typeaheadFormatter]="industryService.industryTypeaheadFormatter"
                            typeaheadValue="{{determineIndustryCode()}}">
                        </common-typeahead>
                        <!-- <common-output [(model)]="builder.industry2"></common-output> -->
                        <common-output [(model)]="builder.fullInformationBeta2"></common-output>
                        <common-output [(model)]="builder.vasicekAdjustedBeta2"></common-output>
                        <common-output [(model)]="builder.medianSumLeveredBeta2"></common-output>
                        <common-output [(model)]="builder.highFinancialRiskSumLeveredBeta2"></common-output>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="combobox-container">
                        <div class="view-link">
                            <span class="usi-industry-link view-industries-link" (click)="showIndustries()">
                                <span>View Industry List</span>
                            </span>
                            <span class="usi-industry-link view-companies-link"
                                (click)="showCompanies(builder.industry3)">
                                <span>View Beta Company List</span>
                            </span>
                        </div>
                        <common-typeahead [(model)]="builder.industry3" [typeahead]="industryService.searchIndustries"
                            [typeaheadFormatter]="industryService.industryTypeaheadFormatter"
                            typeaheadValue="{{determineIndustryCode()}}">
                        </common-typeahead>
                        <!-- <common-output [(model)]="builder.industry3"></common-output> -->
                        <common-output [(model)]="builder.fullInformationBeta3"></common-output>
                        <common-output [(model)]="builder.vasicekAdjustedBeta3"></common-output>
                        <common-output [(model)]="builder.medianSumLeveredBeta3"></common-output>
                        <common-output [(model)]="builder.highFinancialRiskSumLeveredBeta3"></common-output>
                        
                    </div>
                    <mat-divider></mat-divider>
                    <div class="combobox-container">
                        <div class="view-link">
                            <span class="usi-industry-link view-industries-link" (click)="showIndustries()">
                                <span>View Industry List</span>
                            </span>
                            <span class="usi-industry-link view-companies-link"
                                (click)="showCompanies(builder.industry4)">
                                <span>View Beta Company List</span>
                            </span>
                        </div>
                        <common-typeahead [(model)]="builder.industry4" [typeahead]="industryService.searchIndustries"
                            [typeaheadFormatter]="industryService.industryTypeaheadFormatter"
                            typeaheadValue="{{determineIndustryCode()}}">
                        </common-typeahead>
                        <!-- <common-output [(model)]="builder.industry4"></common-output> -->
                        <common-output [(model)]="builder.fullInformationBeta4"></common-output>
                        <common-output [(model)]="builder.vasicekAdjustedBeta4"></common-output>
                        <common-output [(model)]="builder.medianSumLeveredBeta4"></common-output>
                        <common-output [(model)]="builder.highFinancialRiskSumLeveredBeta4"></common-output>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>