<input-container [(model)]="model" [invalidValue]="invalidValue">
    <div class="common-combobox" (clickOutside)="isDropdownActive=false">
        <form #form="ngForm" *ngIf="model != null" [config]="config">
            <input name="typeahead" class="common-input" type="text" autocomplete="off"
                [attr.placeHolder]="model.config.placeholder" [attr.readonly]="model.config.readonly ? true : null"
                [attr.disabled]="model.config.disabled ? true : null"
                [attr.required]="model.config.required ? true : null" [(ngModel)]="model.value"
                (ngModelChange)="emitChange(true)" (isValid)="emitChange($event)" [form]="form"
                [gte]="model.config.minimumValue" [lte]="model.config.maximumValue" (debounce)="debounce()"
                [pattern]="model.config.pattern" [mask]="model.config.mask" #input />
        </form>
        <ng-container *ngIf="comboboxData != null && comboboxData.length > 0"
                    (ngModelChange)="onChange()">
            <i class="common-combobox-icon" (click)="isDropdownActive=!isDropdownActive"></i>
            <div class="common-combobox-list" *ngIf="isDropdownActive">
                <ng-container *ngFor="let item of comboboxData" 
                (ngModelChange)="onChange()"
                >
                    <div class="common-combobox-list-item" *ngIf="item.disabledText == null"
                        (click)="selectItem(item); isDropdownActive=false" 
                        (ngModelChange)="onChange()">
                        {{item.name}}
                    </div>
                    <div class="common-combobox-list-item disabled" *ngIf="item.disabledText"
                    (ngModelChange)="onChange()">
                        <div>{{item.name}}</div>
                        <div class="disabled-text">{{item.disabledText}}</div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</input-container>