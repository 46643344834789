import { HttpClient, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

// type HttpAction<T> = () => Observable<T>;

@Injectable()
export abstract class Auth0ApiClient {
    protected abstract baseUrl: string;
    constructor(protected http: HttpClient, protected auth: AuthService) { }


    protected get<T>(path: string, options?: any): Observable<any> {

        return this.http.get<T>(`${this.baseUrl}/${path}`, options);

    }


    protected post<T>(path: string, body: any, options?: any): Observable<any> {

        return this.http.post<T>(`${this.baseUrl}/${path}`, body, options);

    }


    protected put<T>(path: string, body: any, options?: any): Observable<any> {

        return this.http.put<T>(`${this.baseUrl}/${path}`, body, options);

    }


    protected delete<T>(path: string, options?: any): Observable<any> {

        return this.http.delete<T>(`${this.baseUrl}/${path}`, options);

    }


    protected patch<T>(path: string, body: any, options?: any): Observable<any> {

        return this.http.patch<T>(`${this.baseUrl}/${path}`, body, options);

    }

}



