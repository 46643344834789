import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatTabsModule } from "@angular/material/tabs";
import { DialogModule } from "primeng/dialog";
import { CommonModules } from "src/app/_config/common-modules";
import { BetaCompaniesListComponent } from "./beta-company-list.component";
// Components
import { BetasTabComponent } from "./betas-tab.component";
import { DataSummaryTabComponent } from "./data-summary-tab.component";
import { InputsTabComponent } from "./inputs-tab.component";
import { RfTabComponent } from "./rf-tab.component";
import { SizeTabComponent } from "./size-tab.component";

@NgModule({
    declarations: [
        BetaCompaniesListComponent,
        BetasTabComponent,
        DataSummaryTabComponent,
        InputsTabComponent,
        RfTabComponent,
        SizeTabComponent,
    ],
    imports: [
        ...CommonModules,
        MatExpansionModule,
        MatIconModule,
        MatTabsModule,
        MatDividerModule,
        MatListModule,
        DialogModule,
    ],
})
export class EstimateModule {}
