import { Metric } from '../enums/metric';

export const MetricBeta: Metric[] = [
    Metric.RawOlsLevered,
    Metric.BlumeAdjustedLevered,
    Metric.PeerGroupLevered,
    Metric.VasicekAdjustedLevered,
    Metric.SumLevered,
    Metric.DownsideLevered,
    Metric.RawOlsUnlevered,
    Metric.BlumeAdjustedUnlevered,
    Metric.PeerGroupUnlevered,
    Metric.VasicekAdjustedUnlevered,
    Metric.SumUnlevered,
    Metric.DownsideUnlevered
];
