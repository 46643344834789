import { Injectable } from '@angular/core';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { Premium } from '../data/enums/premium';

@Injectable()
export class PremiumsBuilder {

    private premiums: { [index: string]: Premium } = {
        [EquationType.RprsCapmSizeStudy]: Premium.SizePremium,
        [EquationType.RprsBuildup1Levered]: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        [EquationType.RprsBuildup1Unlevered]: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        [EquationType.RprsBuildup3]: Premium.RiskPremiumOverTheRiskFreeRateLevered
    };

    public getPremiumItem(equationType: EquationType): Premium {
        return this.premiums[equationType];
    }

}
