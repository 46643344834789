// tslint:disable:max-line-length

export const generalInputsText = `Here you can enter in or “link” from your spreadsheet your subject company’s characteristics up front or proceed to the following tabs and to enter the information necessary for the data you seek.`;

export const rfErpText = `Click the blue icon next to the data point you want to export to your workbook.`;

export const sizeRiskPremiaText = `Click the blue icon next to the data point you want to export to your workbook.`;

export const betasText = `Click the icon next to the input field if you would like to map the data to your workbook.`;

export const dataSummaryText = `This tab contains records of all data points exported to your spreadsheet, along with supplementary information for each data point. You can export this information to a worksheet in your workbook using the “Export to Excel” button.`;

export const generalInputsAccordionText = `A valuation date is required to get started.<br/><br/>Note that the home and investee country are locked at the US as only US data is available currently.`;

export const zScoreText = `Enter all measures in order to calculate a z-Score.`;

export const erpAdjustmentText = `The ERP Adjustment is needed to account for the difference between the forward-looking ERP as of the valuation date that the analyst has selected to use in his or her cost of equity capital calculations, and the historical (in this case, 1963–present) ERP that was used as a convention in the calculations performed to create the Risk Premium Report exhibits. The ERP Adjustment is necessary when utilizing using a Risk Premium over the Risk-free Rate.`;

export const sizePremiumText = `The size premium represents the difference between actual historical excess return and the excess return predicted by beta. The “size effect” is based on the empirical observation that companies of smaller size are associated with greater risk and, therefore, have greater cost of capital. All size premia are “beta-adjusted”, meaning that they have been adjusted to remove the portion of excess return that is attributable to beta, leaving only the size effect’s contribution to excess return.`;

export const riskPremiumOverTheRiskFreeRateText = `Represents the difference between the historical (observed) total return of equities over the risk-free rate. Because risk premia over the risk-free rate have an embedded measure of market (i.e., “beta”) risk, these premia are appropriate for use in “build-up” methods that do not already include a measure of market risk, but are not appropriate for use in models (e.g., CAPM) that already have a measure of market risk.`;

export const betaPanelText = `Choose a beta for use in CAPM methods or manually translate beta into an industry risk premium for use in build-up methods..<br/><br/><strong>Industry Risk Premia</strong> are calculated as <strong>Beta * ERP – ERP.</strong>`;

export const operatingMarginText = `<strong>Operating Margin/CV of Operating Margin</strong> - Enter at least the 3 most recent years of data.`;

export const returnOnEquityText = `<strong>Return on Equity/CV of Return on Equity</strong> - Enter at least the 3 most recent years of data.`;

export const sizeInputsText = `The size inputs are required to get the size premia and the risk premia over the risk-free rate.`;

export const riskStudyInputsText = `The risk study inputs are required to get the risk premia over the risk-free rate.`;

export const benchmarkingInputsText = `This module enables you to export industry-level data directly into your excel workbook. Clicking "Export to Excel" will add two worksheets to your workbook: one for the industry tearsheet data and one for the industry company list used in the calculations. Subscribers to the U.S. Industry Benchmarking Module have access to the United States region (in USD). Subscribers to the International Industry Benchmarking Module have access to the World, European Union, Euro Area, and United Kingdom regions (in EUR, GBP, USD). If you subscribe to both modules, you may select any region/currency combination.`;

export const ineBenchmarkingInputsText = `This module enables you to export industry-level data directly into your excel workbook. Clicking "Export to Excel" will add two worksheets to your workbook: one for the industry tearsheet data and one for the industry company list used in the calculations. Subscribers to the U.S. Industry Benchmarking Module have access to the United States region (in USD). Subscribers to the International Industry Benchmarking Module have access to the World, European Union, Euro Area, and United Kingdom regions (in EUR, GBP, USD). If you subscribe to both modules, you may select any region/currency combination.`;

export const ineCoCInputsText=`This module enables you to export International Cost of Capital`;

export const ineCoCHeaderText = `Select a valuation date and investor country and click "Export/Refresh Data" to add country risk data, equity risk premia, risk-free rates, and more to your workbook. If you change the valuation date, investor country, or industry, click "Export/Refresh Data" to refresh the data with the new inputs. Optional: Fill out the industry information to get beta estimates. Values are denominated in the currency of the "Investor Country" and are the most recent data available as of the selected valuation date.`
 
