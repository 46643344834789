import { Menu } from 'src/app/_add-in/menu/menu';
import { MenuItem } from 'src/app/_add-in/menu/menu-item.model';
import * as texts from 'src/app/_add-in/menu/menu-names';
import { MenuRoutes } from 'src/app/_add-in/menu/menu-routes';

export class MenuUtility {

    public static readonly menu: MenuItem[] = [
        {
            id: Menu.Inputs,
            name: texts.inputs,
            route: MenuRoutes.RouteInputs
        },
        {
            id: Menu.RfErpData,
            name: texts.riskFreeRatesAndEquityRiskPremia,
            route: MenuRoutes.RouteRfErp
        },
        {
            id: Menu.SizeRiskPremia,
            name: texts.sizeAndRiskPremia,
            route: MenuRoutes.RouteSizeRiskPremia
        },
        {
            id: Menu.Betas,
            name: texts.betasIndustryRiskPremia,
            route: MenuRoutes.RouteBetas
        },
        {
            id: Menu.DataSummary,
            name: texts.dataSummary,
            route: MenuRoutes.RouteDataSummary
        }
    ];
    public static readonly benchmarkingMenu: MenuItem[] = [
        {
            id: Menu.BenchMarkingInputs,
            name: texts.benchmarking,
            route: MenuRoutes.RouteBenckmarking
        },
        {
            id: Menu.IntlBenchMarkingInputs,
            name: texts.intlBenchmarking,
            route: MenuRoutes.RouteIntlBenchMarking
        }
    ];
    public static readonly intlCoCMenu: MenuItem[] = [
        {
            id: Menu.IntlCoC,
            name: texts.intlCoC,
            route: MenuRoutes.RouteIntlCoC
        }
    ];


}
