import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Portfolio } from '../responses/portfolio.response';
import { ZScore } from '../responses/zscore.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class RiskClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public getPortfolios(targetDate: string): Observable<Portfolio[]> {
        return this.get(`Risk/Portfolios?targetDate=${targetDate}`);
    }

    public getZscore(targetDate: string): Observable<ZScore> {
        return this.get(`Risk/ZScore?targetDate=${targetDate}`);
    }
}
