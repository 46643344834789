import { Component, Input, OnInit } from '@angular/core';
import { AccordionTextService } from 'src/app/_add-in/accordion/accordion-text.service';
import { InputBuilder } from 'src/app/_add-in/data/input.builder';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';
import { GenericTabComponent } from 'src/app/estimate/generic-tab.component';
import { FieldData } from '../_add-in/data/field-data';
import { UserStore } from '../_add-in/user/store/user.store';

@Component({
    selector: 'rf-tab',
    templateUrl: './rf-tab.component.html'
})
export class RfTabComponent extends GenericTabComponent implements OnInit {
    @Input() public model!: FieldData;
    public cellname!: string;
    constructor(
        public excelStorageService: ExcelStorageService,
        public userStore: UserStore,
        public builder: InputBuilder,
        public headers: AccordionTextService
    ) {
        super(excelStorageService, userStore, builder);
    }
    // Removed the logic to assign the reference to a cell based on the value
    // public ngOnInit(): void {
    //     const fields = this.excelStorageService.getExcelEstimate();
    //     if (fields == null) {
    //         return;
    //     }
    //     Excel.run({ delayForCellEdit: true }, async (context: Excel.RequestContext) => {
    //         const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
    //         const range = activeWorksheet.getRange('A1:z60');
    //         range.load(['address', 'cellCount', 'values', 'text']);
    //         activeWorksheet.load(['name', 'id', 'protection/protected']);
    //         return context.sync().then(() => {
    //             //  const temp=activeWorksheet.name+'!'.length;
    //             const cell = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    //                 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    //             for (let i = 0; i < 60; i++) {
    //                 for (let j = 0; j < 24; j++) {
    //                     if (range.values[i][j] !== '' && this.builder.spot20YearTreasury.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.spot20YearTreasury.source, reference,
    //                             this.builder.spot20YearTreasury.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.spot20YearTreasury.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.normalizedRiskFreeRate.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.normalizedRiskFreeRate.source, reference,
    //                             this.builder.normalizedRiskFreeRate.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.normalizedRiskFreeRate.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }

    //                     if (range.values[i][j] !== '' && this.builder.DNPRecommended.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.DNPRecommended.source, reference,
    //                             this.builder.DNPRecommended.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.DNPRecommended.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.historicalLongTerm.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.historicalLongTerm.source, reference,
    //                             this.builder.historicalLongTerm.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.historicalLongTerm.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.supplySideLongTerm.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.supplySideLongTerm.source, reference,
    //                             this.builder.supplySideLongTerm.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.supplySideLongTerm.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.riskPremiumReport.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.riskPremiumReport.source,
    //                             reference, this.builder.riskPremiumReport.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.riskPremiumReport.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.DNPRecommendedAdjusted.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.DNPRecommendedAdjusted.source, reference,
    //                             this.builder.DNPRecommendedAdjusted.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.DNPRecommendedAdjusted.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.historicalLongTermAdjusted.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.historicalLongTermAdjusted.source, reference,
    //                             this.builder.historicalLongTermAdjusted.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.historicalLongTermAdjusted.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.supplySideLongTermAdjusted.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.supplySideLongTermAdjusted.source, reference,
    //                             this.builder.supplySideLongTermAdjusted.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.supplySideLongTermAdjusted.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.riskPremiumReportAdjusted.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.riskPremiumReportAdjusted.source, reference,
    //                             this.builder.riskPremiumReportAdjusted.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.riskPremiumReportAdjusted.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                 }
    //             }
    //             return true;
    //         });
    //     }).then(() => {
    //     }).catch(this.excelStorageService.handleExcelErrors);
    // }

}
