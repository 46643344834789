import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Auth0ApiClient } from "../api.client";
import { IntlData } from "../responses/intl-data.response";

@Injectable()
export class IntlRfClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(countryId: number, dataAsOf: string): Observable<IntlData[]> {
        return this.get(`IntlRf?countryId=${countryId}&dataAsOf=${dataAsOf}`);
    }
}