export interface UsCompanyData {
    DataAsOf: string;
    Companies: UsCompany[];
}

export interface UsCompany {
    Name: string;
    Sales: number;
    Assets: number;
    SalesUsd: number;
    AssetsUsd: number;
    Type: CompanyType;
}

export enum CompanyType {
    Default = 'Default',
    Large = 'Large',
    Small = 'Small',
    HighFinancialRisk = 'HighFinancialRisk'
}
