import { Injectable } from '@angular/core';
import { SummaryTableHeaderType } from 'src/app/_add-in/summary/summary-table-header-type';
import { SummaryTableHeader } from 'src/app/_add-in/summary/summary-table-header.model';
import * as headerText from 'src/app/_add-in/summary/summary-table-header.text';

@Injectable()
export class SummaryTableHeaderService {

    private linkedCell: SummaryTableHeader = {
        type: SummaryTableHeaderType.LinkedCell,
        text: headerText.linkedCellText
    };

    private study: SummaryTableHeader = {
        type: SummaryTableHeaderType.Study,
        text: headerText.studyText
    };

    private sizeRiskMeasure: SummaryTableHeader = {
        type: SummaryTableHeaderType.SizeRiskMeasure,
        text: headerText.sizeRiskMeasureText
    };

    private dataPoint: SummaryTableHeader = {
        type: SummaryTableHeaderType.DataPoint,
        text: headerText.dataPointText
    };

    private value: SummaryTableHeader = {
        type: SummaryTableHeaderType.Value,
        text: headerText.valueText
    };

    private dataAsOf: SummaryTableHeader = {
        type: SummaryTableHeaderType.DataAsOf,
        text: headerText.dataAsOfText
    };

    private breakpoints: SummaryTableHeader = {
        type: SummaryTableHeaderType.Breakpoints,
        text: headerText.breakpointsText
    };

    public getHeaderTitles(): string[] {
        return [
            this.linkedCell.text,
            this.study.text,
            this.sizeRiskMeasure.text,
            this.dataPoint.text,
            this.value.text,
            this.dataAsOf.text,
            this.breakpoints.text
        ];
    }

}
