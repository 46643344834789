import { Component, Input } from '@angular/core';
import { BetaService } from 'src/app/_add-in/beta/beta.service';
import { CommonUserInputComponent } from 'src/app/_add-in/common/inputs/common-user-input.component';
import { FieldType } from 'src/app/_add-in/data/enums/field-type';
import { SuggestedInput } from 'src/app/_add-in/data/suggested-input.model';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';
import { FieldData } from '../../data/field-data';

@Component({
    selector: 'common-combobox',
    templateUrl: './common-combobox.component.html'
})
export class CommonComboboxComponent extends CommonUserInputComponent {
    @Input() public comboboxData: SuggestedInput[] = [];
    @Input() public model!: FieldData;
     
    
    public isDropdownActive = false;
    public fieldTypeOutput = FieldType.Output;
    public fieldTypeCombobox = FieldType.IntlCombobox;
    

    public constructor(private betaService: BetaService, excelStorageService: ExcelStorageService) {
        super(excelStorageService);
    }

    public onBindToCell(): void {
        this.betaService.bindToCell(this.model);
    }

    public selectItem(item: SuggestedInput): void {
        // if (this.model.betaGroup == null) {
        //     return;
        // }

        this.betaService.saveModel(this.model, item.value, item.source, item.dataPoint, item.dataAsOf);
        this.modelChange.emit(this.model);
        this.input.nativeElement.focus();
        // debugger;
        // if (this.model.betaGroup !== null && typeof (this.model.betaGroup) === 'number') {
        //     this.betaService.updateIndustryRiskPremium(false, this.model.betaGroup);
        // }
    }
   

    public clearSource(): void {
        this.model.context = '';
    }

    public debounce(): void {
        if (this.model.betaGroup == null) {
            return;
        }

        // let isReset = true;

        if (this.invalidValue == null && this.model.value) {
            this.betaService.saveModel(this.model, this.model.value, '', '', '');
            // isReset = false;
        }

        // this.betaService.updateIndustryRiskPremium(isReset, this.model.betaGroup);
    }

}
