import { Injectable } from '@angular/core';

@Injectable()
export class TrendsOverTimeService {
    constructor() { }
    public createIndustryFilterLabel(sicId: string | undefined, gicId: string | undefined): string {
        let label = '';

        if (sicId) {
            label = `SIC ${sicId}`;
        } else {
            label = `GICS ${gicId}`;
        }

        return label;
    }

}
