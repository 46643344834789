import { Injectable } from '@angular/core';
import * as text from 'src/app/_add-in/accordion/accordion-text';

@Injectable()
export class AccordionTextService {

    private accordionNames: { [index: string]: string } = {
        generalInputs: text.generalInputsText,
        rfErp: text.rfErpText,
        sizeRiskPremia: text.sizeRiskPremiaText,
        betas: text.betasText,
        dataSummary: text.dataSummaryText,
        generalInputsAccordion: text.generalInputsAccordionText,
        zScore: text.zScoreText,
        erpAdjustment: text.erpAdjustmentText,
        sizePremium: text.sizePremiumText,
        riskPremiumOverTheRiskFreeRate: text.riskPremiumOverTheRiskFreeRateText,
        betaPanel: text.betaPanelText,
        operatingMargin: text.operatingMarginText,
        returnOnEquity: text.returnOnEquityText,
        sizeInputs: text.sizeInputsText,
        riskStudyInputs: text.riskStudyInputsText,
        benchmarkingInputs: text.benchmarkingInputsText,
        ineBenchmarkingInputs: text.ineBenchmarkingInputsText,
        ineCoCInputs:text.ineCoCInputsText,
        ineCOCHeader: text.ineCoCHeaderText
    };

    // TODO: turn this class into a static utility class
    public getAccordionText(key: string): string {
        return this.accordionNames[key];
    }

}
