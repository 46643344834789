export interface Equation {
    ScenarioId: string;
    EquationType: EquationType;
    ImplicationType: ImplicationType;
    Result?: number;
    IsExcluded?: boolean;
    Created: Date;
    Updated: Date;
}

export enum EquationType {
    CrspCapmSizeStudy = 'CrspCapmSizeStudy',
    CrspBuildup = 'CrspBuildup',
    RprsCapmSizeStudy = 'RprsCapmSizeStudy',
    RprsBuildup1Levered = 'RprsBuildup1Levered',
    RprsBuildup1Unlevered = 'RprsBuildup1Unlevered',
    RprsBuildup1Relevered = 'RprsBuildup1Relevered',
    RprsBuildup2 = 'RprsBuildup2',
    RprsBuildup3 = 'RprsBuildup3',
    HfrsCapmHfr = 'HfrsCapmHfr',
    HfrsBuildupHfr = 'HfrsBuildupHfr',
    RprsBuildup3ComparativeRisk = 'RprsBuildup3ComparativeRisk'
}

export enum ImplicationType {
    None = 'None',
    MarketValueOfCommonEquity = 'MarketValueOfCommonEquity',
    BookValueOfEquity = 'BookValueOfEquity',
    FiveYearAverageNetIncome = 'FiveYearAverageNetIncome',
    MarketValueOfInvestedCapital = 'MarketValueOfInvestedCapital',
    TotalAssets = 'TotalAssets',
    FiveYearAverageEbitda = 'FiveYearAverageEbitda',
    NetSales = 'NetSales',
    NumberOfEmployees = 'NumberOfEmployees',
    OperatingMargin = 'OperatingMargin',
    CoefficientOfVariationOfOperatingMargin = 'CoefficientOfVariationOfOperatingMargin',
    CoefficientOfVariationOfReturnOnEquity = 'CoefficientOfVariationOfReturnOnEquity'
}
