import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FieldData } from 'src/app/_add-in/data/field-data';
import { InputBuilder } from 'src/app/_add-in/data/input.builder';
import { Menu } from 'src/app/_add-in/menu/menu';
import { SummaryTableHeaderType } from 'src/app/_add-in/summary/summary-table-header-type';
import { DataPoint } from './summary-datapoints';

@Injectable()
export class SummaryService {

    public constructor(private inputBuilder: InputBuilder, private dataPoint: DataPoint) { }

    public getData(menu: Menu): Observable<FieldData[]> {
        const values: FieldData[] = [];
        this.inputBuilder.industry0.tabName = 1;
        this.inputBuilder.industry1.tabName = 1;
        this.inputBuilder.industry2.tabName = 1;
        this.inputBuilder.industry3.tabName = 1;
        this.inputBuilder.industry4.tabName = 1;
        const keys = Object.keys(this.inputBuilder).filter((x) => this.inputBuilder[x].tabName === menu && this.inputBuilder[x].reference);
        for (const key of keys) {
            if (this.inputBuilder[key].tabName === Menu.Inputs) {
                const value: FieldData = Object.assign(this.inputBuilder[key]);
                if (this.inputBuilder[key].source === 'industry0' || this.inputBuilder[key].source === 'industry1'  || this.inputBuilder[key].source === 'industry2' || this.inputBuilder[key].source === 'industry3'
                || this.inputBuilder[key].source === 'industry4') {
                    value.dataPoint = 'Industry';
                } else {
                value.dataPoint = this.dataPoint[this.inputBuilder[key].source].title;
                }
                values.push(value);
            } else {
                values.push(this.inputBuilder[key]);
            }
        }
        return of(values);
    }

    public getTableHeaders(menu: Menu): SummaryTableHeaderType[] {
        if (menu === Menu.SizeRiskPremia) {
            return [
                SummaryTableHeaderType.LinkedCell,
                SummaryTableHeaderType.Study,
                SummaryTableHeaderType.SizeRiskMeasure,
                SummaryTableHeaderType.DataPoint,
                SummaryTableHeaderType.Value,
                SummaryTableHeaderType.DataAsOf,
                SummaryTableHeaderType.Breakpoints
            ];
        } else if (menu === Menu.Inputs) {
            return [
                SummaryTableHeaderType.LinkedCell,
                SummaryTableHeaderType.DataPoint,
                SummaryTableHeaderType.Value
            ];
        } else {
            return [
                SummaryTableHeaderType.LinkedCell,
                SummaryTableHeaderType.DataPoint,
                SummaryTableHeaderType.Value,
                SummaryTableHeaderType.DataAsOf
            ];
        }
    }

}
