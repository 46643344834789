export enum Measure {
    MarketValueOfCommonEquity = 'Market Value of Common Equity',
    BookValueOfEquity = 'Book Value of Equity',
    FiveYearAvgNetIncome = '5-year Average Net Income',
    MVIC = 'Market Value of Invested Capital',
    TotalAssets = 'Total Assets',
    FiveYearAvgEBITDA = '5-year Average EBITDA',
    Sales = 'Sales',
    NumberOfEmployees = '# of Employees',
    OperatingMargin = 'Operating Margin',
    CVOfOperatingMargin = 'Coefficient of Variation of Operating Margin',
    CVOfReturnOnEquity = 'Coefficient of Variation of Return on Equity',
    ServiceZScore = 'Service z-Score',
    ManufacturingZScore = 'Manufacturing z-Score'
}
