import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FieldData } from 'src/app/_add-in/data/field-data';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';
import { Menu } from 'src/app/_add-in/menu/menu';
import { SummaryTableHeaderType } from 'src/app/_add-in/summary/summary-table-header-type';
import { SummaryDataSource } from 'src/app/_add-in/summary/summary.data.source';
import { SummaryService } from 'src/app/_add-in/summary/summary.service';
import { FieldType } from '../../data/enums/field-type';
import { CommonDate } from '../date-struct';

@Component({
    selector: 'common-table',
    templateUrl: './common-table.component.html'
})
export class CommonTableComponent implements OnInit, OnDestroy {
    public dataSource: SummaryDataSource;
    public displayedColumns: SummaryTableHeaderType[] = [];
    private interval = -1;
    @Input() public menu!: Menu;

    public constructor(private excelStorageService: ExcelStorageService, private summaryService: SummaryService) {
        this.dataSource = new SummaryDataSource(this.summaryService);
    }

    public ngOnInit(): void {
        this.displayedColumns = this.dataSource.loadTableHeaders(this.menu);
        // TODO: can we avoid doing this?
        this.interval = window.setInterval(() => {
            this.dataSource.loadData(this.menu);
        }, 1000);
    }

    public ngOnDestroy(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    public onFocusToCell(element: FieldData): void {
        this.excelStorageService.focusToCell(element);
    }
    public isTypeDatePicker(element: FieldData): boolean {
        if (element.source === 'industry0' || element.source === 'industry1' || element.source === 'industry2' || element.source === 'industry3' || element.source === 'industry4') {
          element.value = element.context;
        }
        return (element.fieldType === FieldType.DatePicker && element.value) ? true : false;
    }
    public convertValue(element: FieldData): any {
        if (element.fieldType === FieldType.DatePicker && element.value) {
            return CommonDate.fromStruct(element.value).asMoment();
        } else {
            return element.value;
        }
    }
}
