<div class="bgcolor">
<h1 mat-dialog-title [innerHTML]="data?.model?.config?.help?.title"></h1>
<div mat-dialog-content>
    <div [innerHTML]="data?.model?.config?.help?.text"></div>
    <div *ngIf="data?.model?.config?.edit" class="dialog-input-section">
        <common-output *ngFor="let relatedInput of data.model.relatedInputs; let i = index;"
            [(model)]="data.model.relatedInputs[i]" [hideInfoIcon]="true"></common-output>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button class="btn-dialog" (click)="onClose()">Close</button>
</div>
</div>