import { Injectable } from '@angular/core';
import { FieldType } from 'src/app/_add-in/data/enums/field-type';
import { InputName } from 'src/app/_add-in/data/enums/input-name';
import { Measure } from 'src/app/_add-in/data/enums/measure';
import { Premium } from 'src/app/_add-in/data/enums/premium';
import { RiskParameterType } from 'src/app/_add-in/data/enums/risk-parameter-type';
import { Study } from 'src/app/_add-in/data/enums/study';
import { ValueType } from 'src/app/_add-in/data/enums/value-type';
import { CompanyTypeData, FieldData } from 'src/app/_add-in/data/field-data';
import { ErrorService } from 'src/app/_add-in/error/error.service';
import * as help from 'src/app/_add-in/help/help-text';
import { Menu } from 'src/app/_add-in/menu/menu';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { CompanyType, InputType } from 'src/app/_api/responses/input.response';
import { SelectionType } from 'src/app/_api/responses/selection.response';
import { SuggestionType } from 'src/app/_api/responses/suggestion.response';

@Injectable()
export class InputBuilder {

    [index: string]: FieldData;

    public valuationDate: FieldData = {
        source: InputName.ValuationDate,
        config: {
            help: help.valuationDate,
            disabled: false
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.DatePicker,
        dataPoint: help.valuationDate.title,
        inputType: InputType.None,
        relatedInputs: []
    };
    public homeCountry: FieldData = {
        value: 'UNITED STATES',
        source: InputName.HomeCountry,
        config: {
            disabled: true,
            help: help.homeCountry
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.TypeAhead,
        inputType: InputType.None,
        relatedInputs: []
    };
    public investeeCountry: FieldData = {
        value: 'UNITED STATES',
        source: InputName.InvesteeCountry,
        config: {
            disabled: true,
            help: help.investeeCountry
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.TypeAhead,
        inputType: InputType.None,
        relatedInputs: []
    };
    public marketCap: FieldData = {
        source: InputName.MarketValueOfCommonEquity,
        config: {
            minimumValue: 0,
            help: help.marketCap,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.marketCap.title,
        inputType: InputType.MarketValueOfCommonEquity,
        relatedInputs: []
    };
    public bookValueOfEquity: FieldData = {
        source: InputName.BookValueOfEquity,
        config: {
            minimumValue: 0,
            help: help.bookValueOfEquity,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.bookValueOfEquity.title,
        inputType: InputType.BookValueOfEquity,
        relatedInputs: []
    };
    public fiveYrAvgNetIncome: FieldData = {
        source: InputName.FiveYearAverageNetIncome,
        config: {
            minimumValue: 0,
            help: help.fiveYrAvgNetIncome,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.fiveYrAvgNetIncome.title,
        inputType: InputType.FiveYearAverageNetIncome,
        relatedInputs: []
    };
    public MVIC: FieldData = {
        source: InputName.MarketValueOfInvestedCapital,
        config: {
            minimumValue: 0,
            help: help.MVIC,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.MVIC.title,
        inputType: InputType.MarketValueOfInvestedCapital,
        relatedInputs: []
    };
    public totalAssets: FieldData = {
        source: InputName.TotalAssets,
        config: {
            minimumValue: 0,
            help: help.totalAssets,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.totalAssets.title,
        inputType: InputType.TotalAssets,
        relatedInputs: []
    };
    public fiveYrAvgEBITDA: FieldData = {
        source: InputName.FiveYearAverageEBITDA,
        config: {
            minimumValue: 0,
            help: help.fiveYrAvgEBITDA,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.fiveYrAvgEBITDA.title,
        inputType: InputType.FiveYearAverageEbitda,
        relatedInputs: []
    };
    public sales: FieldData = {
        source: InputName.Sales,
        config: {
            minimumValue: 0,
            help: help.sales,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.sales.title,
        inputType: InputType.NetSales,
        relatedInputs: []
    };
    public numberOfEmployees: FieldData = {
        source: InputName.NumberOfEmployees,
        config: {
            minimumValue: 0,
            help: help.numberOfEmployees,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.numberOfEmployees.title,
        inputType: InputType.NumberOfEmployees,
        relatedInputs: []
    };
    public debtToEquity: FieldData = {
        source: InputName.DebtToEquity,
        config: {
            minimumValue: 0,
            help: help.debtToEquity,
            error: ErrorService.debtRatio,
            pattern: '(.*?)',
            mask: '{}%'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.debtToEquity.title,
        inputType: InputType.DebtRatioEquity,
        relatedInputs: []
    };
    public netSalesYear0: FieldData = {
        source: InputName.NetSalesYear0,
        config: {
            minimumValue: 0,
            help: help.netSalesYear0,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netIncomeYear0.title,
        inputType: InputType.NetSales,
        relatedInputs: []
    };
    public netSalesYear1: FieldData = {
        source: InputName.NetSalesYear1,
        config: {
            minimumValue: 0,
            help: help.netSalesYear1,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netIncomeYear1.title,
        inputType: InputType.NetSales,
        relatedInputs: []
    };
    public netSalesYear2: FieldData = {
        source: InputName.NetSalesYear2,
        config: {
            minimumValue: 0,
            help: help.netSalesYear2,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netIncomeYear2.title,
        inputType: InputType.NetSales,
        relatedInputs: []
    };
    public netSalesYear3: FieldData = {
        source: InputName.NetSalesYear3,
        config: {
            minimumValue: 0,
            help: help.netSalesYear3,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netIncomeYear3.title,
        inputType: InputType.NetSales,
        relatedInputs: []
    };
    public netSalesYear4: FieldData = {
        source: InputName.NetSalesYear4,
        config: {
            minimumValue: 0,
            help: help.netSalesYear4,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netSalesYear4.title,
        inputType: InputType.NetSales,
        relatedInputs: []
    };
    public operatingIncomeYear0: FieldData = {
        source: InputName.OperatingIncomeYear0,
        config: {
            minimumValue: 0,
            help: help.operatingIncomeYear0,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.operatingIncomeYear0.title,
        inputType: InputType.OperatingIncome,
        relatedInputs: []
    };
    public operatingIncomeYear1: FieldData = {
        source: InputName.OperatingIncomeYear1,
        config: {
            minimumValue: 0,
            help: help.operatingIncomeYear1,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.operatingIncomeYear1.title,
        inputType: InputType.OperatingIncome,
        relatedInputs: []
    };
    public operatingIncomeYear2: FieldData = {
        source: InputName.OperatingIncomeYear2,
        config: {
            minimumValue: 0,
            help: help.operatingIncomeYear2,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.operatingIncomeYear2.title,
        inputType: InputType.OperatingIncome,
        relatedInputs: []
    };
    public operatingIncomeYear3: FieldData = {
        source: InputName.OperatingIncomeYear3,
        config: {
            minimumValue: 0,
            help: help.operatingIncomeYear3,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.operatingIncomeYear3.title,
        inputType: InputType.OperatingIncome,
        relatedInputs: []
    };
    public operatingIncomeYear4: FieldData = {
        source: InputName.OperatingIncomeYear4,
        config: {
            minimumValue: 0,
            help: help.operatingIncomeYear4,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.operatingIncomeYear4.title,
        inputType: InputType.OperatingIncome,
        relatedInputs: []
    };
    public operatingMargin: FieldData = {
        source: InputName.OperatingMargin,
        config: {
            disabled: true,
            help: help.operatingMargin
        },
        relatedInputs: [
            this.netSalesYear0,
            this.netSalesYear1,
            this.netSalesYear2,
            this.netSalesYear3,
            this.netSalesYear4,
            this.operatingIncomeYear0,
            this.operatingIncomeYear1,
            this.operatingIncomeYear2,
            this.operatingIncomeYear3,
            this.operatingIncomeYear4
        ],
        tabName: Menu.Inputs,
        fieldType: FieldType.Calculation,
        dataPoint: help.operatingMargin.title,
        inputType: InputType.OperatingMargin
    };
    public CVOfOperatingMargin: FieldData = {
        source: InputName.CVOfOperatingMargin,
        config: {
            disabled: true,
            help: help.CVOfOperatingMargin
        },
        relatedInputs: [
            this.netSalesYear0,
            this.netSalesYear1,
            this.netSalesYear2,
            this.netSalesYear3,
            this.netSalesYear4,
            this.operatingIncomeYear0,
            this.operatingIncomeYear1,
            this.operatingIncomeYear2,
            this.operatingIncomeYear3,
            this.operatingIncomeYear4
        ],
        tabName: Menu.Inputs,
        fieldType: FieldType.Calculation,
        dataPoint: help.CVOfOperatingMargin.title,
        inputType: InputType.CoefficientOfVariationOfOperatingMargin
    };
    public netIncomeYear0: FieldData = {
        source: InputName.NetIncomeYear0,
        config: {
            minimumValue: 0,
            help: help.netIncomeYear0,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netIncomeYear0.title,
        inputType: InputType.NetIncome,
        relatedInputs: []
    };
    public netIncomeYear1: FieldData = {
        source: InputName.NetIncomeYear1,
        config: {
            minimumValue: 0,
            help: help.netIncomeYear1,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netIncomeYear1.title,
        inputType: InputType.NetIncome,
        relatedInputs: []
    };
    public netIncomeYear2: FieldData = {
        source: InputName.NetIncomeYear2,
        config: {
            minimumValue: 0,
            help: help.netIncomeYear2,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netIncomeYear2.title,
        inputType: InputType.NetIncome,
        relatedInputs: []
    };
    public netIncomeYear3: FieldData = {
        source: InputName.NetIncomeYear3,
        config: {
            minimumValue: 0,
            help: help.netIncomeYear3,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netIncomeYear3.title,
        inputType: InputType.NetIncome,
        relatedInputs: []
    };
    public netIncomeYear4: FieldData = {
        source: InputName.NetIncomeYear4,
        config: {
            minimumValue: 0,
            help: help.netIncomeYear4,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.netIncomeYear4.title,
        inputType: InputType.NetIncome,
        relatedInputs: []
    };
    public bookValueOfEquityYear0: FieldData = {
        source: InputName.BookValueOfEquityYear0,
        config: {
            minimumValue: 0,
            help: help.bookValueOfEquityYear0,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.bookValueOfEquityYear0.title,
        inputType: InputType.BookValueOfEquity,
        relatedInputs: []
    };
    public bookValueOfEquityYear1: FieldData = {
        source: InputName.BookValueOfEquityYear1,
        config: {
            minimumValue: 0,
            help: help.bookValueOfEquityYear1,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.bookValueOfEquityYear1.title,
        inputType: InputType.BookValueOfEquity,
        relatedInputs: []
    };
    public bookValueOfEquityYear2: FieldData = {
        source: InputName.BookValueOfEquityYear2,
        config: {
            minimumValue: 0,
            help: help.bookValueOfEquityYear2,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.bookValueOfEquityYear2.title,
        inputType: InputType.BookValueOfEquity,
        relatedInputs: []
    };
    public bookValueOfEquityYear3: FieldData = {
        source: InputName.BookValueOfEquityYear3,
        config: {
            minimumValue: 0,
            help: help.bookValueOfEquityYear3,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.bookValueOfEquityYear3.title,
        inputType: InputType.BookValueOfEquity,
        relatedInputs: []
    };
    public bookValueOfEquityYear4: FieldData = {
        source: InputName.BookValueOfEquityYear4,
        config: {
            minimumValue: 0,
            help: help.bookValueOfEquityYear4,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.bookValueOfEquityYear4.title,
        inputType: InputType.BookValueOfEquity,
        relatedInputs: []
    };
    public returnOnEquity: FieldData = {
        source: InputName.ReturnOnEquity,
        config: {
            disabled: true,
            help: help.returnOnEquity
        },
        relatedInputs: [
            this.netIncomeYear0,
            this.netIncomeYear1,
            this.netIncomeYear2,
            this.netIncomeYear3,
            this.netIncomeYear4,
            this.bookValueOfEquityYear0,
            this.bookValueOfEquityYear1,
            this.bookValueOfEquityYear2,
            this.bookValueOfEquityYear3,
            this.bookValueOfEquityYear4
        ],
        tabName: Menu.Inputs,
        fieldType: FieldType.Calculation,
        dataPoint: help.returnOnEquity.title,
        inputType: InputType.ReturnOnEquity
    };
    public CVOfReturnOnEquity: FieldData = {
        source: InputName.CVOfReturnOnEquity,
        config: {
            disabled: true,
            help: help.CVOfReturnOnEquity
        },
        relatedInputs: [
            this.netIncomeYear0,
            this.netIncomeYear1,
            this.netIncomeYear2,
            this.netIncomeYear3,
            this.netIncomeYear4,
            this.bookValueOfEquityYear0,
            this.bookValueOfEquityYear1,
            this.bookValueOfEquityYear2,
            this.bookValueOfEquityYear3,
            this.bookValueOfEquityYear4
        ],
        tabName: Menu.Inputs,
        fieldType: FieldType.Calculation,
        dataPoint: help.CVOfReturnOnEquity.title,
        inputType: InputType.CoefficientOfVariationOfReturnOnEquity
    };
    public currentYearEBIT: FieldData = {
        source: InputName.CurrentYearEBIT,
        config: {
            minimumValue: 0,
            help: help.currentYearEBIT,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.currentYearEBIT.title,
        inputType: InputType.CurrentYearEbit,
        relatedInputs: []
    };
    public currentAssets: FieldData = {
        source: InputName.CurrentAssets,
        config: {
            minimumValue: 0,
            help: help.currentAssets,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.currentAssets.title,
        inputType: InputType.CurrentAssets,
        relatedInputs: []
    };
    public currentLiabilities: FieldData = {
        source: InputName.CurrentLiabilities,
        config: {
            minimumValue: 0,
            help: help.currentLiabilities,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.currentLiabilities.title,
        inputType: InputType.CurrentLiabilities,
        relatedInputs: []
    };
    public retainedEarnings: FieldData = {
        source: InputName.RetainedEarnings,
        config: {
            minimumValue: 0,
            help: help.retainedEarnings,
            error: ErrorService.sizeMeasure,
            pattern: '(.*?)'
        },
        tabName: Menu.Inputs,
        fieldType: FieldType.Input,
        dataPoint: help.retainedEarnings.title,
        inputType: InputType.RetainedEarnings,
        relatedInputs: []
    };
    public ZScore: FieldData = {
        source: InputName.ZScore,
        config: {
            disabled: true,
            help: help.ZScore
        },
        relatedInputs: [
            this.marketCap,
            this.bookValueOfEquity,
            this.totalAssets,
            this.currentYearEBIT,
            this.sales,
            this.currentAssets,
            this.currentLiabilities,
            this.retainedEarnings
        ],
        tabName: Menu.Inputs,
        fieldType: FieldType.Calculation,
        dataPoint: help.ZScore.title,
        inputType: InputType.ZScore
    };
    public spot20YearTreasury: FieldData = {
        source: InputName.Spot20YearTreasury,
        config: {
            disabled: true,
            help: help.spot20YearTreasury
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.RiskFreeRate,
        suggestionType: SuggestionType.Spot20YieldRiskFreeRate,
        inputType: InputType.None,
        relatedInputs: []
    };
    public spot10YearTreasury: FieldData = {
        source: InputName.Spot10YearTreasury,
        config: {
            disabled: true,
            help: help.spot10YearTreasury
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.RiskFreeRate,
        suggestionType: SuggestionType.Spot10YieldRiskFreeRate,
        inputType: InputType.None,
        relatedInputs: []
    };
    public spot30YearTreasury: FieldData = {
        source: InputName.Spot30YearTreasury,
        config: {
            disabled: true,
            help: help.spot30YearTreasury
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.RiskFreeRate,
        suggestionType: SuggestionType.Spot30YieldRiskFreeRate,
        inputType: InputType.None,
        relatedInputs: []
    };
    public normalizedRiskFreeRate: FieldData = {
        source: InputName.NormalizedRiskFreeRate,
        config: {
            disabled: true,
            help: help.normalizedRiskFreeRate
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.RiskFreeRate,
        suggestionType: SuggestionType.RecommendedRiskFreeRate,
        inputType: InputType.None,
        relatedInputs: []
    };
    public DNPRecommended: FieldData = {
        source: InputName.ErpDnPRecommended,
        config: {
            disabled: true,
            help: help.DNPRecommended
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.EquityRiskPremium,
        suggestionType: SuggestionType.RecommendedEquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public historicalLongTerm: FieldData = {
        source: InputName.ErpHistoricalLongTerm,
        config: {
            disabled: true,
            help: help.historicalLongTerm
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.EquityRiskPremium,
        suggestionType: SuggestionType.HistoricalEquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public supplySideLongTerm: FieldData = {
        source: InputName.ErpSupplySideLongTerm,
        config: {
            disabled: true,
            help: help.supplySideLongTerm
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.EquityRiskPremium,
        suggestionType: SuggestionType.SupplySideEquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public riskPremiumReport: FieldData = {
        source: InputName.ErpRiskPremiumReport,
        config: {
            disabled: true,
            help: help.riskPremiumReport
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        dataPoint: help.riskPremiumReport.title,
        inputType: InputType.None,
        relatedInputs: []
    };
    public DNPRecommendedAdjusted: FieldData = {
        source: InputName.ErpAdjustmentDnPRecommended,
        config: {
            disabled: true,
            help: help.DNPRecommendedAdjusted
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.EquityRiskPremiumAdjustment,
        suggestionType: SuggestionType.RecommendedEquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public historicalLongTermAdjusted: FieldData = {
        source: InputName.ErpAdjustmentHistoricalLongTerm,
        config: {
            disabled: true,
            help: help.historicalLongTermAdjusted
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.EquityRiskPremiumAdjustment,
        suggestionType: SuggestionType.HistoricalEquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public supplySideLongTermAdjusted: FieldData = {
        source: InputName.ErpAdjustmentSupplySideLongTerm,
        config: {
            disabled: true,
            help: help.supplySideLongTermAdjusted
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        selectionType: SelectionType.EquityRiskPremiumAdjustment,
        suggestionType: SuggestionType.SupplySideEquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public riskPremiumReportAdjusted: FieldData = {
        source: InputName.ErpAdjustmentRiskPremiumReport,
        config: {
            disabled: true,
            help: help.riskPremiumReportAdjusted
        },
        tabName: Menu.RfErpData,
        fieldType: FieldType.Output,
        dataPoint: help.riskPremiumReportAdjusted.title,
        inputType: InputType.None,
        relatedInputs: []
    };
    public decile: FieldData = {
        source: InputName.Decile,
        config: {
            disabled: true,
            help: help.decile,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.CRSPStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.SizePremium,
        inputType: InputType.None
    };
    public sizeGrouping: FieldData = {
        source: InputName.SizeGrouping,
        config: {
            disabled: true,
            help: help.sizeGrouping,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.CRSPStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.SizePremium,
        inputType: InputType.None
    };
    public decileBreakdown1: FieldData = {
        source: InputName.DecileBreakdown1,
        config: {
            disabled: true,
            help: help.decileBreakdown1,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.CRSPStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.SizePremium,
        inputType: InputType.None
    };
    public decileBreakdown2: FieldData = {
        source: InputName.DecileBreakdown2,
        config: {
            disabled: true,
            help: help.decileBreakdown2,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.CRSPStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.SizePremium,
        inputType: InputType.None
    };
    public marketCapPortfolio: FieldData = {
        source: InputName.MarketValueOfCommonEquityPortfolio,
        config: {
            disabled: true,
            help: help.marketCapPortfolio,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.MarketValueOfCommonEquity,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public marketCapRegression: FieldData = {
        source: InputName.MarketValueOfCommonEquityRegression,
        config: {
            disabled: true,
            help: help.marketCapRegression,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.MarketValueOfCommonEquity,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public bookValueOfEquityPortfolio: FieldData = {
        source: InputName.BookValueOfEquityPortfolio,
        config: {
            disabled: true,
            help: help.bookValueOfEquityPortfolio,
            edit: true
        },
        relatedInputs: [this.bookValueOfEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.BookValueOfEquity,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.BookValueOfEquity,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public bookValueOfEquityRegression: FieldData = {
        source: InputName.BookValueOfEquityRegression,
        config: {
            disabled: true,
            help: help.bookValueOfEquityRegression,
            edit: true
        },
        relatedInputs: [this.bookValueOfEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.BookValueOfEquity,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.BookValueOfEquity,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public fiveYrAvgNetIncomePortfolio: FieldData = {
        source: InputName.FiveYearAverageNetIncomePortfolio,
        config: {
            disabled: true,
            help: help.fiveYrAvgNetIncomePortfolio,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgNetIncome],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgNetIncome,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.FiveYearAverageNetIncome,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public fiveYrAvgNetIncomeRegression: FieldData = {
        source: InputName.FiveYearAverageNetIncomeRegression,
        config: {
            disabled: true,
            help: help.fiveYrAvgNetIncomeRegression,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgNetIncome],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgNetIncome,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.FiveYearAverageNetIncome,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public MVICPortfolio: FieldData = {
        source: InputName.MarketValueOfInvestedCapitalPortfolio,
        config: {
            disabled: true,
            help: help.MVICPortfolio,
            edit: true
        },
        relatedInputs: [this.MVIC],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MVIC,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.MarketValueOfInvestedCapital,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public MVICRegression: FieldData = {
        source: InputName.MarketValueOfInvestedCapitalRegression,
        config: {
            disabled: true,
            help: help.MVICRegression,
            edit: true
        },
        relatedInputs: [this.MVIC],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MVIC,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.MarketValueOfInvestedCapital,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public totalAssetsPortfolio: FieldData = {
        source: InputName.TotalAssetsPortfolio,
        config: {
            disabled: true,
            help: help.totalAssetsPortfolio,
            edit: true
        },
        relatedInputs: [this.totalAssets],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.TotalAssets,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.TotalAssets,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public totalAssetsRegression: FieldData = {
        source: InputName.TotalAssetsRegression,
        config: {
            disabled: true,
            help: help.totalAssetsRegression,
            edit: true
        },
        relatedInputs: [this.totalAssets],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.TotalAssets,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.TotalAssets,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public fiveYrAvgEBITDAPortfolio: FieldData = {
        source: InputName.FiveYearAverageEBITDAPortfolio,
        config: {
            disabled: true,
            help: help.fiveYrAvgEBITDAPortfolio,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgEBITDA],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgEBITDA,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.FiveYearAverageEbitda,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public fiveYrAvgEBITDARegression: FieldData = {
        source: InputName.FiveYearAverageEBITDARegression,
        config: {
            disabled: true,
            help: help.fiveYrAvgEBITDARegression,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgEBITDA],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgEBITDA,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.FiveYearAverageEbitda,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public salesPortfolio: FieldData = {
        source: InputName.SalesPortfolio,
        config: {
            disabled: true,
            help: help.salesPortfolio,
            edit: true
        },
        relatedInputs: [this.sales],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.Sales,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.NetSales,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public salesRegression: FieldData = {
        source: InputName.SalesRegression,
        config: {
            disabled: true,
            help: help.salesRegression,
            edit: true
        },
        relatedInputs: [this.sales],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.Sales,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.NetSales,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public numberOfEmployeesPortfolio: FieldData = {
        source: InputName.NumberOfEmployeesPortfolio,
        config: {
            disabled: true,
            help: help.numberOfEmployeesPortfolio,
            edit: true
        },
        relatedInputs: [this.numberOfEmployees],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.NumberOfEmployees,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.NumberOfEmployees,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public numberOfEmployeesRegression: FieldData = {
        source: InputName.NumberOfEmployeesRegression,
        config: {
            disabled: true,
            help: help.numberOfEmployeesRegression,
            edit: true
        },
        relatedInputs: [this.numberOfEmployees],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.NumberOfEmployees,
        premium: Premium.SizePremium,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.NumberOfEmployees,
        selectionType: SelectionType.RiskPremiumOverCapm,
        equationType: EquationType.RprsCapmSizeStudy
    };
    public marketCapPortfolioLevered: FieldData = {
        source: InputName.MarketValueOfCommonEquityPortfolioLevered,
        config: {
            disabled: true,
            help: help.marketCapPortfolioLevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.MarketValueOfCommonEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public marketCapRegressionLevered: FieldData = {
        source: InputName.MarketValueOfCommonEquityRegressionLevered,
        config: {
            disabled: true,
            help: help.marketCapRegressionLevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.MarketValueOfCommonEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public bookValueOfEquityPortfolioLevered: FieldData = {
        source: InputName.BookValueOfEquityPortfolioLevered,
        config: {
            disabled: true,
            help: help.bookValueOfEquityPortfolioLevered,
            edit: true
        },
        relatedInputs: [this.bookValueOfEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.BookValueOfEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.BookValueOfEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public bookValueOfEquityRegressionLevered: FieldData = {
        source: InputName.BookValueOfEquityRegressionLevered,
        config: {
            disabled: true,
            help: help.bookValueOfEquityRegressionLevered,
            edit: true
        },
        relatedInputs: [this.bookValueOfEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.BookValueOfEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.BookValueOfEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public fiveYrAvgNetIncomePortfolioLevered: FieldData = {
        source: InputName.FiveYearAverageNetIncomePortfolioLevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgNetIncomePortfolioLevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgNetIncome],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgNetIncome,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.FiveYearAverageNetIncome,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public fiveYrAvgNetIncomeRegressionLevered: FieldData = {
        source: InputName.FiveYearAverageNetIncomeRegressionLevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgNetIncomeRegressionLevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgNetIncome],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgNetIncome,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.FiveYearAverageNetIncome,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public MVICPortfolioLevered: FieldData = {
        source: InputName.MarketValueOfInvestedCapitalPortfolioLevered,
        config: {
            disabled: true,
            help: help.MVICPortfolioLevered,
            edit: true
        },
        relatedInputs: [this.MVIC],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MVIC,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.MarketValueOfInvestedCapital,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public MVICRegressionLevered: FieldData = {
        source: InputName.MarketValueOfInvestedCapitalRegressionLevered,
        config: {
            disabled: true,
            help: help.MVICRegressionLevered,
            edit: true
        },
        relatedInputs: [this.MVIC],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MVIC,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.MarketValueOfInvestedCapital,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public totalAssetsPortfolioLevered: FieldData = {
        source: InputName.TotalAssetsPortfolioLevered,
        config: {
            disabled: true,
            help: help.totalAssetsPortfolioLevered,
            edit: true
        },
        relatedInputs: [this.totalAssets],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.TotalAssets,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.TotalAssets,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public totalAssetsRegressionLevered: FieldData = {
        source: InputName.TotalAssetsRegressionLevered,
        config: {
            disabled: true,
            help: help.totalAssetsRegressionLevered,
            edit: true
        },
        relatedInputs: [this.totalAssets],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.TotalAssets,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.TotalAssets,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public fiveYrAvgEBITDAPortfolioLevered: FieldData = {
        source: InputName.FiveYearAverageEBITDAPortfolioLevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgEBITDAPortfolioLevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgEBITDA],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgEBITDA,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.FiveYearAverageEbitda,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public fiveYrAvgEBITDARegressionLevered: FieldData = {
        source: InputName.FiveYearAverageEBITDARegressionLevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgEBITDARegressionLevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgEBITDA],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgEBITDA,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.FiveYearAverageEbitda,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public salesPortfolioLevered: FieldData = {
        source: InputName.SalesPortfolioLevered,
        config: {
            disabled: true,
            help: help.salesPortfolioLevered,
            edit: true
        },
        relatedInputs: [this.sales],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.Sales,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.NetSales,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public salesRegressionLevered: FieldData = {
        source: InputName.SalesRegressionLevered,
        config: {
            disabled: true,
            help: help.salesRegressionLevered,
            edit: true
        },
        relatedInputs: [this.sales],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.Sales,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.NetSales,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public numberOfEmployeesPortfolioLevered: FieldData = {
        source: InputName.NumberOfEmployeesPortfolioLevered,
        config: {
            disabled: true,
            help: help.numberOfEmployeesPortfolioLevered,
            edit: true
        },
        relatedInputs: [this.numberOfEmployees],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.NumberOfEmployees,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.NumberOfEmployees,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public numberOfEmployeesRegressionLevered: FieldData = {
        source: InputName.NumberOfEmployeesRegressionLevered,
        config: {
            disabled: true,
            help: help.numberOfEmployeesRegressionLevered,
            edit: true
        },
        relatedInputs: [this.numberOfEmployees],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.NumberOfEmployees,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.NumberOfEmployees,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateLevered,
        equationType: EquationType.RprsBuildup1Levered
    };
    public marketCapPortfolioUnlevered: FieldData = {
        source: InputName.MarketValueOfCommonEquityPortfolioUnlevered,
        config: {
            disabled: true,
            help: help.marketCapPortfolioUnlevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.MarketValueOfCommonEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public marketCapRegressionUnlevered: FieldData = {
        source: InputName.MarketValueOfCommonEquityRegressionUnlevered,
        config: {
            disabled: true,
            help: help.marketCapRegressionUnlevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.MarketValueOfCommonEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public bookValueOfEquityPortfolioUnlevered: FieldData = {
        source: InputName.BookValueOfEquityPortfolioUnlevered,
        config: {
            disabled: true,
            help: help.bookValueOfEquityPortfolioUnlevered,
            edit: true
        },
        relatedInputs: [this.bookValueOfEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.BookValueOfEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.BookValueOfEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public bookValueOfEquityRegressionUnlevered: FieldData = {
        source: InputName.BookValueOfEquityRegressionUnlevered,
        config: {
            disabled: true,
            help: help.bookValueOfEquityRegressionUnlevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.BookValueOfEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.BookValueOfEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public fiveYrAvgNetIncomePortfolioUnlevered: FieldData = {
        source: InputName.FiveYearAverageNetIncomePortfolioUnlevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgNetIncomePortfolioUnlevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgNetIncome],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgNetIncome,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.FiveYearAverageNetIncome,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public fiveYrAvgNetIncomeRegressionUnlevered: FieldData = {
        source: InputName.FiveYearAverageNetIncomeRegressionUnlevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgNetIncomeRegressionUnlevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgNetIncome,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.FiveYearAverageNetIncome,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public MVICPortfolioUnlevered: FieldData = {
        source: InputName.MarketValueOfInvestedCapitalPortfolioUnlevered,
        config: {
            disabled: true,
            help: help.MVICPortfolioUnlevered,
            edit: true
        },
        relatedInputs: [this.MVIC],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MVIC,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.MarketValueOfInvestedCapital,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public MVICRegressionUnlevered: FieldData = {
        source: InputName.MarketValueOfInvestedCapitalRegressionUnlevered,
        config: {
            disabled: true,
            help: help.MVICRegressionUnlevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MVIC,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.MarketValueOfInvestedCapital,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public totalAssetsPortfolioUnlevered: FieldData = {
        source: InputName.TotalAssetsPortfolioUnlevered,
        config: {
            disabled: true,
            help: help.totalAssetsPortfolioUnlevered,
            edit: true
        },
        relatedInputs: [this.totalAssets],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.TotalAssets,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.TotalAssets,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public totalAssetsRegressionUnlevered: FieldData = {
        source: InputName.TotalAssetsRegressionUnlevered,
        config: {
            disabled: true,
            help: help.totalAssetsRegressionUnlevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.TotalAssets,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.TotalAssets,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public fiveYrAvgEBITDAPortfolioUnlevered: FieldData = {
        source: InputName.FiveYearAverageEBITDAPortfolioUnlevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgEBITDAPortfolioUnlevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgEBITDA],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgEBITDA,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.FiveYearAverageEbitda,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public fiveYrAvgEBITDARegressionUnlevered: FieldData = {
        source: InputName.FiveYearAverageEBITDARegressionUnlevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgEBITDARegressionUnlevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgEBITDA,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.FiveYearAverageEbitda,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public salesPortfolioUnlevered: FieldData = {
        source: InputName.SalesPortfolioUnlevered,
        config: {
            disabled: true,
            help: help.salesPortfolioUnlevered,
            edit: true
        },
        relatedInputs: [this.sales],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.Sales,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.NetSales,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public salesRegressionUnlevered: FieldData = {
        source: InputName.SalesRegressionUnlevered,
        config: {
            disabled: true,
            help: help.salesRegressionUnlevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.Sales,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.NetSales,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public numberOfEmployeesPortfolioUnlevered: FieldData = {
        source: InputName.NumberOfEmployeesPortfolioUnlevered,
        config: {
            disabled: true,
            help: help.numberOfEmployeesPortfolioUnlevered,
            edit: true
        },
        relatedInputs: [this.numberOfEmployees],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.NumberOfEmployees,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.NumberOfEmployees,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public numberOfEmployeesRegressionUnlevered: FieldData = {
        source: InputName.NumberOfEmployeesRegressionUnlevered,
        config: {
            disabled: true,
            help: help.numberOfEmployeesRegressionUnlevered,
            edit: true
        },
        relatedInputs: [this.marketCap],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.NumberOfEmployees,
        premium: Premium.RiskPremiumOverTheRiskFreeRateUnlevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.NumberOfEmployees,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateUnlevered,
        equationType: EquationType.RprsBuildup1Unlevered
    };
    public marketCapPortfolioRelevered: FieldData = {
        source: InputName.MarketValueOfCommonEquityPortfolioRelevered,
        config: {
            disabled: true,
            help: help.marketCapPortfolioRelevered,
            edit: true
        },
        relatedInputs: [this.marketCap, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.MarketValueOfCommonEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };

    public marketCapRegressionRelevered: FieldData = {
        source: InputName.MarketValueOfCommonEquityRegressionRelevered,
        config: {
            disabled: true,
            help: help.marketCapRegressionRelevered,
            edit: true
        },
        relatedInputs: [this.marketCap, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MarketValueOfCommonEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.MarketValueOfCommonEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public bookValueOfEquityPortfolioRelevered: FieldData = {
        source: InputName.BookValueOfEquityPortfolioRelevered,
        config: {
            disabled: true,
            help: help.bookValueOfEquityPortfolioRelevered,
            edit: true
        },
        relatedInputs: [this.bookValueOfEquity, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.BookValueOfEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.BookValueOfEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public bookValueOfEquityRegressionRelevered: FieldData = {
        source: InputName.BookValueOfEquityRegressionRelevered,
        config: {
            disabled: true,
            help: help.bookValueOfEquityRegressionRelevered,
            edit: true
        },
        relatedInputs: [this.bookValueOfEquity, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.BookValueOfEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.BookValueOfEquity,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public fiveYrAvgNetIncomePortfolioRelevered: FieldData = {
        source: InputName.FiveYearAverageNetIncomePortfolioRelevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgNetIncomePortfolioRelevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgNetIncome, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgNetIncome,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.FiveYearAverageNetIncome,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public fiveYrAvgNetIncomeRegressionRelevered: FieldData = {
        source: InputName.FiveYearAverageNetIncomeRegressionRelevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgNetIncomeRegressionRelevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgNetIncome, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgNetIncome,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.FiveYearAverageNetIncome,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public MVICPortfolioRelevered: FieldData = {
        source: InputName.MarketValueOfInvestedCapitalPortfolioRelevered,
        config: {
            disabled: true,
            help: help.MVICPortfolioRelevered,
            edit: true
        },
        relatedInputs: [this.MVIC, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MVIC,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.MarketValueOfInvestedCapital,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };

    public MVICRegressionRelevered: FieldData = {
        source: InputName.MarketValueOfInvestedCapitalRegressionRelevered,
        config: {
            disabled: true,
            help: help.MVICRegressionRelevered,
            edit: true
        },
        relatedInputs: [this.MVIC, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.MVIC,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.MarketValueOfInvestedCapital,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public totalAssetsPortfolioRelevered: FieldData = {
        source: InputName.TotalAssetsPortfolioRelevered,
        config: {
            disabled: true,
            help: help.totalAssetsPortfolioRelevered,
            edit: true
        },
        relatedInputs: [this.totalAssets, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.TotalAssets,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.TotalAssets,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public totalAssetsRegressionRelevered: FieldData = {
        source: InputName.TotalAssetsRegressionRelevered,
        config: {
            disabled: true,
            help: help.totalAssetsRegressionRelevered,
            edit: true
        },
        relatedInputs: [this.totalAssets, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.TotalAssets,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.TotalAssets,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public fiveYrAvgEBITDAPortfolioRelevered: FieldData = {
        source: InputName.FiveYearAverageEBITDAPortfolioRelevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgEBITDAPortfolioRelevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgEBITDA, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgEBITDA,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.FiveYearAverageEbitda,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public fiveYrAvgEBITDARegressionRelevered: FieldData = {
        source: InputName.FiveYearAverageEBITDARegressionRelevered,
        config: {
            disabled: true,
            help: help.fiveYrAvgEBITDARegressionRelevered,
            edit: true
        },
        relatedInputs: [this.fiveYrAvgEBITDA, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.FiveYearAvgEBITDA,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.FiveYearAverageEbitda,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public salesPortfolioRelevered: FieldData = {
        source: InputName.SalesPortfolioRelevered,
        config: {
            disabled: true,
            help: help.salesPortfolioRelevered,
            edit: true
        },
        relatedInputs: [this.sales, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.Sales,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.NetSales,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };

    public salesRegressionRelevered: FieldData = {
        source: InputName.SalesRegressionRelevered,
        config: {
            disabled: true,
            help: help.salesRegressionRelevered,
            edit: true
        },
        relatedInputs: [this.sales, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.Sales,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.NetSales,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };
    public numberOfEmployeesPortfolioRelevered: FieldData = {
        source: InputName.NumberOfEmployeesPortfolioRelevered,
        config: {
            disabled: true,
            help: help.numberOfEmployeesPortfolioRelevered,
            edit: true
        },
        relatedInputs: [this.numberOfEmployees, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.NumberOfEmployees,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.NumberOfEmployees,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };

    public numberOfEmployeesRegressionRelevered: FieldData = {
        source: InputName.NumberOfEmployeesRegressionRelevered,
        config: {
            disabled: true,
            help: help.numberOfEmployeesRegressionRelevered,
            edit: true
        },
        relatedInputs: [this.numberOfEmployees, this.debtToEquity],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRSizeStudy,
        measure: Measure.NumberOfEmployees,
        premium: Premium.RiskPremiumOverTheRiskFreeRateRelevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.NumberOfEmployees,
        selectionType: SelectionType.RiskPremiumOverRiskFreeRateRelevered,
        equationType: EquationType.RprsBuildup1Relevered
    };

    public operatingMarginPortfolioLevered: FieldData = {
        source: InputName.OperatingMarginPortfolioLevered,
        config: {
            disabled: true,
            help: help.operatingMarginPortfolioLevered,
            edit: true
        },
        relatedInputs: [
            this.netSalesYear0,
            this.netSalesYear1,
            this.netSalesYear2,
            this.netSalesYear3,
            this.netSalesYear4,
            this.operatingIncomeYear0,
            this.operatingIncomeYear1,
            this.operatingIncomeYear2,
            this.operatingIncomeYear3,
            this.operatingIncomeYear4
        ],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRRiskStudy,
        measure: Measure.OperatingMargin,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.OperatingMargin,
        selectionType: SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate,
        equationType: EquationType.RprsBuildup3
    };
    public CVOfOperatingMarginPortfolioLevered: FieldData = {
        source: InputName.CVOfOperatingMarginPortfolioLevered,
        config: {
            disabled: true,
            help: help.CVOfOperatingMarginPortfolioLevered,
            edit: true
        },
        relatedInputs: [
            this.netSalesYear0,
            this.netSalesYear1,
            this.netSalesYear2,
            this.netSalesYear3,
            this.netSalesYear4,
            this.operatingIncomeYear0,
            this.operatingIncomeYear1,
            this.operatingIncomeYear2,
            this.operatingIncomeYear3,
            this.operatingIncomeYear4
        ],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRRiskStudy,
        measure: Measure.CVOfOperatingMargin,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.CoefficientOfVariationOfOperatingMargin,
        selectionType: SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate,
        equationType: EquationType.RprsBuildup3
    };
    public operatingMarginRegressionLevered: FieldData = {
        source: InputName.OperatingMarginRegressionLevered,
        config: {
            disabled: true,
            help: help.operatingMarginRegressionLevered,
            edit: true
        },
        relatedInputs: [
            this.netSalesYear0,
            this.netSalesYear1,
            this.netSalesYear2,
            this.netSalesYear3,
            this.netSalesYear4,
            this.operatingIncomeYear0,
            this.operatingIncomeYear1,
            this.operatingIncomeYear2,
            this.operatingIncomeYear3,
            this.operatingIncomeYear4
        ],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRRiskStudy,
        measure: Measure.OperatingMargin,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.OperatingMargin,
        selectionType: SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate,
        equationType: EquationType.RprsBuildup3
    };
    public CVOfOperatingMarginRegressionLevered: FieldData = {
        source: InputName.CVOfOperatingMarginRegressionLevered,
        config: {
            disabled: true,
            help: help.CVOfOperatingMarginRegressionLevered,
            edit: true
        },
        relatedInputs: [
            this.netSalesYear0,
            this.netSalesYear1,
            this.netSalesYear2,
            this.netSalesYear3,
            this.netSalesYear4,
            this.operatingIncomeYear0,
            this.operatingIncomeYear1,
            this.operatingIncomeYear2,
            this.operatingIncomeYear3,
            this.operatingIncomeYear4
        ],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRRiskStudy,
        measure: Measure.CVOfOperatingMargin,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.CoefficientOfVariationOfOperatingMargin,
        selectionType: SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate,
        equationType: EquationType.RprsBuildup3
    };
    public CVOfReturnOnEquityPortfolioLevered: FieldData = {
        source: InputName.CVOfReturnOnEquityPortfolioLevered,
        config: {
            disabled: true,
            help: help.CVOfReturnOnEquityPortfolioLevered,
            edit: true
        },
        relatedInputs: [
            this.netIncomeYear0,
            this.netIncomeYear1,
            this.netIncomeYear2,
            this.netIncomeYear3,
            this.netIncomeYear4,
            this.bookValueOfEquityYear0,
            this.bookValueOfEquityYear1,
            this.bookValueOfEquityYear2,
            this.bookValueOfEquityYear3,
            this.bookValueOfEquityYear4
        ],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRRiskStudy,
        measure: Measure.CVOfReturnOnEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Portfolio,
        inputType: InputType.CoefficientOfVariationOfReturnOnEquity,
        selectionType: SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate,
        equationType: EquationType.RprsBuildup3
    };
    public CVOfReturnOnEquityRegressionLevered: FieldData = {
        source: InputName.CVOfReturnOnEquityRegressionLevered,
        config: {
            disabled: true,
            help: help.CVOfReturnOnEquityRegressionLevered,
            edit: true
        },
        relatedInputs: [
            this.netIncomeYear0,
            this.netIncomeYear1,
            this.netIncomeYear2,
            this.netIncomeYear3,
            this.netIncomeYear4,
            this.bookValueOfEquityYear0,
            this.bookValueOfEquityYear1,
            this.bookValueOfEquityYear2,
            this.bookValueOfEquityYear3,
            this.bookValueOfEquityYear4
        ],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.RPRRiskStudy,
        measure: Measure.CVOfReturnOnEquity,
        premium: Premium.RiskPremiumOverTheRiskFreeRateLevered,
        riskParameterType: RiskParameterType.Regression,
        inputType: InputType.CoefficientOfVariationOfReturnOnEquity,
        selectionType: SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate,
        equationType: EquationType.RprsBuildup3
    };
    public HFRSizePremium: FieldData = {
        source: InputName.HFRSizePremium,
        config: {
            disabled: true,
            help: help.HFRSizePremium,
            edit: true
        },
        relatedInputs: [
            this.marketCap,
            this.bookValueOfEquity,
            this.totalAssets,
            this.currentYearEBIT,
            this.sales,
            this.currentAssets,
            this.currentLiabilities,
            this.retainedEarnings
        ],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.HFRStudy,
        measure: Measure.ServiceZScore,
        premium: Premium.SizePremium,
        selectionType: SelectionType.HighFinancialRiskPremiumOverCapM,
        inputType: InputType.None
    };
    public HFRRiskPremiumOverTheRiskFreeRate: FieldData = {
        source: InputName.HFRRiskPremiumOverTheRiskFreeRate,
        config: {
            disabled: true,
            help: help.HFRRiskPremiumOverTheRiskFreeRate,
            edit: true
        },
        relatedInputs: [
            this.marketCap,
            this.bookValueOfEquity,
            this.totalAssets,
            this.currentYearEBIT,
            this.sales,
            this.currentAssets,
            this.currentLiabilities,
            this.retainedEarnings
        ],
        tabName: Menu.SizeRiskPremia,
        fieldType: FieldType.Output,
        study: Study.HFRStudy,
        measure: Measure.ServiceZScore,
        premium: Premium.RiskPremiumOverTheRiskFreeRate,
        selectionType: SelectionType.HighFinancialRiskPremiumOverRiskFreeRate,
        inputType: InputType.None
    };
    // Betas
    public industry0: FieldData = {
        source: InputName.Industry0,
        config: {
            help: help.industry
        },
        tabName: Menu.Betas,
        fieldType: FieldType.TypeAhead,
        betaGroup: 0,
        valueType: ValueType.Industry,
        inputType: InputType.None,
        relatedInputs: []
    };
    public beta0: FieldData = {
        source: InputName.Beta0,
        config: {
            minimumValue: 0,
            maximumValue: 5,
            help: help.beta,
            error: ErrorService.beta,
            pattern: '(.*?)'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        valueType: ValueType.Beta,
        inputType: InputType.None,
        relatedInputs: []
    };
    public equityRiskPremium0: FieldData = {
        source: InputName.EquityRiskPremium0,
        config: {
            minimumValue: 0,
            maximumValue: 10,
            help: help.equityRiskPremium,
            error: ErrorService.equityRiskPremium,
            pattern: '(.*?)',
            mask: '{}%'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.ComboBox,
        betaGroup: 0,
        valueType: ValueType.EquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public industryRiskPremium0: FieldData = {
        source: InputName.IndustryRiskPremium0,
        config: {
            disabled: true,
            help: help.industryRiskPremium
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        valueType: ValueType.IndustryRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public betaSuggestion0: FieldData = {
        source: InputName.BetaSuggestion0,
        fieldType: FieldType.Suggestion,
        value: [],
        betaGroup: 0,
        valueType: ValueType.BetaSuggestion,
        inputType: InputType.None,
        relatedInputs: []
    };
    public industry1: FieldData = {
        source: InputName.Industry1,
        config: {
            help: help.industry
        },
        tabName: Menu.Betas,
        fieldType: FieldType.TypeAhead,
        betaGroup: 1,
        valueType: ValueType.Industry,
        inputType: InputType.None,
        relatedInputs: []
    };
    public beta1: FieldData = {
        source: InputName.Beta1,
        config: {
            minimumValue: 0,
            maximumValue: 5,
            help: help.beta,
            error: ErrorService.beta,
            pattern: '(.*?)'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 1,
        valueType: ValueType.Beta,
        inputType: InputType.None,
        relatedInputs: []
    };
    public equityRiskPremium1: FieldData = {
        source: InputName.EquityRiskPremium1,
        config: {
            minimumValue: 0,
            maximumValue: 10,
            help: help.equityRiskPremium,
            error: ErrorService.equityRiskPremium,
            pattern: '(.*?)',
            mask: '{}%'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.ComboBox,
        betaGroup: 1,
        valueType: ValueType.EquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public industryRiskPremium1: FieldData = {
        source: InputName.IndustryRiskPremium1,
        config: {
            disabled: true,
            help: help.industryRiskPremium
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 1,
        valueType: ValueType.IndustryRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public betaSuggestion1: FieldData = {
        source: InputName.BetaSuggestion1,
        fieldType: FieldType.Suggestion,
        value: [],
        betaGroup: 1,
        valueType: ValueType.BetaSuggestion,
        inputType: InputType.None,
        relatedInputs: []
    };
    public industry2: FieldData = {
        source: InputName.Industry2,
        config: {
            help: help.industry
        },
        tabName: Menu.Betas,
        fieldType: FieldType.TypeAhead,
        betaGroup: 2,
        valueType: ValueType.Industry,
        inputType: InputType.None,
        relatedInputs: []
    };
    public beta2: FieldData = {
        source: InputName.Beta2,
        config: {
            minimumValue: 0,
            maximumValue: 5,
            help: help.beta,
            error: ErrorService.beta,
            pattern: '(.*?)'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 2,
        valueType: ValueType.Beta,
        inputType: InputType.None,
        relatedInputs: []
    };
    public equityRiskPremium2: FieldData = {
        source: InputName.EquityRiskPremium2,
        config: {
            minimumValue: 0,
            maximumValue: 10,
            help: help.equityRiskPremium,
            error: ErrorService.equityRiskPremium,
            pattern: '(.*?)',
            mask: '{}%'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.ComboBox,
        betaGroup: 2,
        valueType: ValueType.EquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public industryRiskPremium2: FieldData = {
        source: InputName.IndustryRiskPremium2,
        config: {
            disabled: true,
            help: help.industryRiskPremium
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 2,
        valueType: ValueType.IndustryRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public betaSuggestion2: FieldData = {
        source: InputName.BetaSuggestion2,
        fieldType: FieldType.Suggestion,
        value: [],
        betaGroup: 2,
        valueType: ValueType.BetaSuggestion,
        inputType: InputType.None,
        relatedInputs: []
    };
    public industry3: FieldData = {
        source: InputName.Industry3,
        config: {
            help: help.industry
        },
        tabName: Menu.Betas,
        fieldType: FieldType.TypeAhead,
        betaGroup: 3,
        valueType: ValueType.Industry,
        inputType: InputType.None,
        relatedInputs: []
    };
    public beta3: FieldData = {
        source: InputName.Beta3,
        config: {
            minimumValue: 0,
            maximumValue: 5,
            help: help.beta,
            error: ErrorService.beta,
            pattern: '(.*?)'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 3,
        valueType: ValueType.Beta,
        inputType: InputType.None,
        relatedInputs: []
    };
    public equityRiskPremium3: FieldData = {
        source: InputName.EquityRiskPremium3,
        config: {
            minimumValue: 0,
            maximumValue: 10,
            help: help.equityRiskPremium,
            error: ErrorService.equityRiskPremium,
            pattern: '(.*?)',
            mask: '{}%'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.ComboBox,
        betaGroup: 3,
        valueType: ValueType.EquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public industryRiskPremium3: FieldData = {
        source: InputName.IndustryRiskPremium3,
        config: {
            disabled: true,
            help: help.industryRiskPremium
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 3,
        valueType: ValueType.IndustryRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public betaSuggestion3: FieldData = {
        source: InputName.BetaSuggestion3,
        fieldType: FieldType.Suggestion,
        value: [],
        betaGroup: 3,
        valueType: ValueType.BetaSuggestion,
        inputType: InputType.None,
        relatedInputs: []
    };
    public industry4: FieldData = {
        source: InputName.Industry4,
        config: {
            help: help.industry
        },
        tabName: Menu.Betas,
        fieldType: FieldType.TypeAhead,
        betaGroup: 4,
        valueType: ValueType.Industry,
        inputType: InputType.None,
        relatedInputs: []
    };
    public beta4: FieldData = {
        source: InputName.Beta4,
        config: {
            minimumValue: 0,
            maximumValue: 5,
            help: help.beta,
            error: ErrorService.beta,
            pattern: '(.*?)'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 4,
        valueType: ValueType.Beta,
        inputType: InputType.None,
        relatedInputs: []
    };
    public equityRiskPremium4: FieldData = {
        source: InputName.EquityRiskPremium4,
        config: {
            minimumValue: 0,
            maximumValue: 10,
            help: help.equityRiskPremium,
            error: ErrorService.equityRiskPremium,
            pattern: '(.*?)',
            mask: '{}%'
        },
        tabName: Menu.Betas,
        fieldType: FieldType.ComboBox,
        betaGroup: 4,
        valueType: ValueType.EquityRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public industryRiskPremium4: FieldData = {
        source: InputName.IndustryRiskPremium4,
        config: {
            disabled: true,
            help: help.industryRiskPremium
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 4,
        valueType: ValueType.IndustryRiskPremium,
        inputType: InputType.None,
        relatedInputs: []
    };
    public betaSuggestion4: FieldData = {
        source: InputName.BetaSuggestion4,
        fieldType: FieldType.Suggestion,
        value: [],
        betaGroup: 4,
        valueType: ValueType.BetaSuggestion,
        inputType: InputType.None,
        relatedInputs: []
    };
    public erpSelections: FieldData = {
        source: InputName.ERPSelections,
        fieldType: FieldType.Suggestion,
        value: [],
        inputType: InputType.None,
        relatedInputs: []
    };
    // Company Types
    public serviceCompanyType: CompanyTypeData = {
        source: InputName.ServiceCompanyType,
        fieldType: FieldType.RadioButton,
        inputType: InputType.CompanyType,
        value: CompanyType.Service,
        config: {
            help: help.serviceCompanyType,
            checked: true
        },
        tabName: Menu.Inputs,
        relatedInputs: []
    };
    public manufacturingCompanyType: CompanyTypeData = {
        source: InputName.ManufacturingCompanyType,
        fieldType: FieldType.RadioButton,
        inputType: InputType.CompanyType,
        value: CompanyType.Manufacturing,
        config: {
            help: help.manufacturingCompanyType,
            checked: false
        },
        tabName: Menu.Inputs,
        relatedInputs: []
    };
    // US Industries
    public usIndustries: FieldData = {
        source: InputName.USIndustries,
        fieldType: FieldType.Suggestion,
        value: [],
        inputType: InputType.None,
        relatedInputs: []
    };

    // Intl Industries
    public intlIndustries: FieldData = {
        source: InputName.IntlIndustries,
        fieldType: FieldType.Suggestion,
        value: [],
        inputType: InputType.None,
        relatedInputs: []
    };

    public benchMarkingValuationDate: FieldData = {
        source: InputName.BenchMarkingValuationDate,
        config: {
            help: help.benchMarkingValuationDate
        },
        tabName: Menu.BenchMarkingInputs,
        fieldType: FieldType.DatePicker,
        inputType: InputType.None,
        relatedInputs: []
    };
    public intlCOCValuationDate: FieldData = {
        source: InputName.IntlCOCValuationDate,
        config: {
            help: help.IntlCOCValuationDate
        },
        tabName: Menu.IntlCoC,
        fieldType: FieldType.DatePicker,
        inputType: InputType.None,
        relatedInputs: []
    };
     
    public benchMarkingRegion: FieldData = {
        source: InputName.BenchMarkingRegion,
        config: {
            help: help.benchMarkingRegion,
            mask: '{}',
            placeholder:'Select a Region',
            hideInfoIcon:true,
        },
        tabName: Menu.BenchMarkingInputs,
        fieldType: FieldType.ComboBox,
        selectionType: SelectionType.BenchmarkingRegion,
        valueType: ValueType.Region,
        inputType: InputType.None,
        relatedInputs: [],
        
    };
    public benchMarkingCurrency: FieldData = {
        source: InputName.BenchMarkingCurrency,
        config: {
            help: help.benchMarkingCurrency,
            mask: '{}',
            placeholder:'Select a Currency',
            hideInfoIcon:true,
        },
        tabName: Menu.BenchMarkingInputs,
        fieldType: FieldType.ComboBox,
        selectionType: SelectionType.BenchmarkingCurrency,
        valueType: ValueType.Currency,
        inputType: InputType.None,
        relatedInputs: []
    };

    public benchMarkingIndustry: FieldData = {
        source: InputName.BenchMarkingIndustry,
        config: {
            help: help.benchMarkingIndustry,
            disabled: true
        },
        tabName: Menu.BenchMarkingInputs,
        fieldType: FieldType.TypeAhead,
        valueType: ValueType.BenchmarkingIndustry,
        inputType: InputType.None,
        relatedInputs: []
    };
    public benchMarkingPoductType: FieldData = {
        source: InputName.BenchMarkingProductType,
        config: {
            help: help.benchMarkingProductType,
            mask: '{}'
        },
        tabName: Menu.BenchMarkingInputs,
        fieldType: FieldType.Output,
        inputType: InputType.None,
        relatedInputs: [],
        value: null
    };
    // BenchMarking Intl Industries
    public benchMarkingIntlIndustries: FieldData = {
        source: InputName.IntlIndustries,
        fieldType: FieldType.Suggestion,
        value: [],
        inputType: InputType.None,
        relatedInputs: []
    };
    // BenchMarking Intl Industries
    public benchMarkingRegions: FieldData = {
        source: InputName.BenchMarkingRegions,
        fieldType: FieldType.Suggestion,
        value: [],
        inputType: InputType.None,
        relatedInputs: []
    };
    // BenchMarking Intl Industries
    public benchMarkingCurrencies: FieldData = {
        source: InputName.BenchMarkingCurrencies,
        fieldType: FieldType.Suggestion,
        value: [],
        inputType: InputType.None,
        relatedInputs: []
    };

    public intlBenchMarkingValuationDate: FieldData = {
        source: InputName.IntlBenchMarkingValuationDate,
        config: {
            help: help.benchMarkingValuationDate
        },
        tabName: Menu.BenchMarkingInputs,
        fieldType: FieldType.DatePicker,
        inputType: InputType.None,
        relatedInputs: []
    };
    public intlBenchMarkingRegion: FieldData = {
        source: InputName.IntlBenchMarkingRegion,
        config: {
            help: help.benchMarkingRegion,
            mask: '{}',
            placeholder:'Select a Region',
            hideInfoIcon:true,
        },
        tabName: Menu.BenchMarkingInputs,
        fieldType: FieldType.ComboBox,
        selectionType: SelectionType.IntlBenchmarkingRegion,
        valueType: ValueType.Region,
        inputType: InputType.None,
        relatedInputs: []
    };
    public intlBenchMarkingCurrency: FieldData = {
        source: InputName.IntlBenchMarkingCurrency,
        config: {
            help: help.benchMarkingCurrency,
            mask: '{}',
            placeholder:'Select a Currency',
            hideInfoIcon:true,
        },
        tabName: Menu.BenchMarkingInputs,
        fieldType: FieldType.ComboBox,
        selectionType: SelectionType.IntlBenchmarkingCurrency,
        valueType: ValueType.Currency,
        inputType: InputType.None,
        relatedInputs: []
    };

    public intlBenchMarkingIndustry: FieldData = {
        source: InputName.IntlBenchMarkingIndustry,
        config: {
            help: help.benchMarkingIndustry,
            disabled: true
        },
        tabName: Menu.BenchMarkingInputs,
        fieldType: FieldType.TypeAhead,
        valueType: ValueType.IntlBenchmarkingIndustry,
        inputType: InputType.None,
        relatedInputs: []
    };
    // BenchMarking Intl Industries
    public intlBenchMarkingIntlIndustries: FieldData = {
        source: InputName.IntlBenchMarkingIndustries,
        fieldType: FieldType.Suggestion,
        value: [],
        inputType: InputType.None,
        relatedInputs: []
    };
    // BenchMarking Intl Industries
    public intlBenchMarkingRegions: FieldData = {
        source: InputName.IntlBenchMarkingRegions,
        fieldType: FieldType.Suggestion,
        value: [],
        inputType: InputType.None,
        relatedInputs: []
    };
    // BenchMarking Intl Industries
    public intlBenchMarkingCurrencies: FieldData = {
        source: InputName.IntlBenchMarkingCurrencies,
        fieldType: FieldType.Suggestion,
        value: [],
        inputType: InputType.None,
        relatedInputs: []
    };
    public isDataSummaryExported: FieldData = {
        source: InputName.ISDataSummaryExported,
        fieldType: FieldType.Suggestion,
        value: '',
        inputType: InputType.None,
        relatedInputs: []
    };
    public fullInformationBeta0: FieldData = {
        source: InputName.FullInformationBeta0,
        config: {
            disabled: true,
            help: help.fullInformationBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.CrspDecilesBeta,
        suggestionType: SuggestionType.FullInformationBeta,
        inputType: InputType.None,
        valueType: ValueType.FullInformationBeta,
        relatedInputs: []
    };

    public vasicekAdjustedBeta0: FieldData = {
        source: InputName.VasicekAdjustedBeta0,
        config: {
            disabled: true,
            help: help.vasicekAdjusted
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.VasicekAdjustedBeta,
        inputType: InputType.None,
        valueType: ValueType.VasicekAdjustedBeta,
        relatedInputs: []
    };

    public medianSumLeveredBeta0: FieldData = {
        source: InputName.MedianSumLeveredBeta0,
        config: {
            disabled: true,
            help: help.sum
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.MedianSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.MedianSumLeveredBeta,
        relatedInputs: []
    };

    public highFinancialRiskSumLeveredBeta0: FieldData = {
        source: InputName.HighFinancialRiskSumLeveredBeta0,
        config: {
            disabled: true,
            help: help.HFRSumBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.HighFinancialRiskBeta,
        suggestionType: SuggestionType.HighFinancialRiskSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.HighFinancialRiskBeta,
        relatedInputs: []
    };

    public fullInformationBeta1: FieldData = {
        source: InputName.FullInformationBeta1,
        config: {
            disabled: true,
            help: help.fullInformationBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.CrspDecilesBeta,
        suggestionType: SuggestionType.FullInformationBeta,
        inputType: InputType.None,
        valueType: ValueType.FullInformationBeta,
        relatedInputs: []
    };

    public vasicekAdjustedBeta1: FieldData = {
        source: InputName.VasicekAdjustedBeta1,
        config: {
            disabled: true,
            help: help.vasicekAdjusted
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.VasicekAdjustedBeta,
        inputType: InputType.None,
        valueType: ValueType.VasicekAdjustedBeta,
        relatedInputs: []
    };

    public medianSumLeveredBeta1: FieldData = {
        source: InputName.MedianSumLeveredBeta1,
        config: {
            disabled: true,
            help: help.sum
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.MedianSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.MedianSumLeveredBeta,
        relatedInputs: []
    };

    public highFinancialRiskSumLeveredBeta1: FieldData = {
        source: InputName.HighFinancialRiskSumLeveredBeta1,
        config: {
            disabled: true,
            help: help.HFRSumBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.HighFinancialRiskBeta,
        suggestionType: SuggestionType.HighFinancialRiskSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.HighFinancialRiskBeta,
        relatedInputs: []
    };

    public fullInformationBeta2: FieldData = {
        source: InputName.FullInformationBeta2,
        config: {
            disabled: true,
            help: help.fullInformationBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.CrspDecilesBeta,
        suggestionType: SuggestionType.FullInformationBeta,
        inputType: InputType.None,
        valueType: ValueType.FullInformationBeta,
        relatedInputs: []
    };

    public vasicekAdjustedBeta2: FieldData = {
        source: InputName.VasicekAdjustedBeta2,
        config: {
            disabled: true,
            help: help.vasicekAdjusted
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.VasicekAdjustedBeta,
        inputType: InputType.None,
        valueType: ValueType.VasicekAdjustedBeta,
        relatedInputs: []
    };

    public medianSumLeveredBeta2: FieldData = {
        source: InputName.MedianSumLeveredBeta2,
        config: {
            disabled: true,
            help: help.sum
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.MedianSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.MedianSumLeveredBeta,
        relatedInputs: []
    };

    public highFinancialRiskSumLeveredBeta2: FieldData = {
        source: InputName.HighFinancialRiskSumLeveredBeta2,
        config: {
            disabled: true,
            help: help.HFRSumBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.HighFinancialRiskBeta,
        suggestionType: SuggestionType.HighFinancialRiskSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.HighFinancialRiskBeta,
        relatedInputs: []
    };

    public fullInformationBeta3: FieldData = {
        source: InputName.FullInformationBeta3,
        config: {
            disabled: true,
            help: help.fullInformationBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.CrspDecilesBeta,
        suggestionType: SuggestionType.FullInformationBeta,
        inputType: InputType.None,
        valueType: ValueType.FullInformationBeta,
        relatedInputs: []
    };

    public vasicekAdjustedBeta3: FieldData = {
        source: InputName.VasicekAdjustedBeta3,
        config: {
            disabled: true,
            help: help.vasicekAdjusted
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.VasicekAdjustedBeta,
        inputType: InputType.None,
        valueType: ValueType.VasicekAdjustedBeta,
        relatedInputs: []
    };

    public medianSumLeveredBeta3: FieldData = {
        source: InputName.MedianSumLeveredBeta3,
        config: {
            disabled: true,
            help: help.sum
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.MedianSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.MedianSumLeveredBeta,
        relatedInputs: []
    };

    public highFinancialRiskSumLeveredBeta3: FieldData = {
        source: InputName.HighFinancialRiskSumLeveredBeta3,
        config: {
            disabled: true,
            help: help.HFRSumBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.HighFinancialRiskBeta,
        suggestionType: SuggestionType.HighFinancialRiskSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.HighFinancialRiskBeta,
        relatedInputs: []
    };

    public fullInformationBeta4: FieldData = {
        source: InputName.FullInformationBeta4,
        config: {
            disabled: true,
            help: help.fullInformationBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.CrspDecilesBeta,
        suggestionType: SuggestionType.FullInformationBeta,
        inputType: InputType.None,
        valueType: ValueType.FullInformationBeta,
        relatedInputs: []
    };

    public vasicekAdjustedBeta4: FieldData = {
        source: InputName.VasicekAdjustedBeta4,
        config: {
            disabled: true,
            help: help.vasicekAdjusted
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.VasicekAdjustedBeta,
        inputType: InputType.None,
        valueType: ValueType.VasicekAdjustedBeta,
        relatedInputs: []
    };

    public medianSumLeveredBeta4: FieldData = {
        source: InputName.MedianSumLeveredBeta4,
        config: {
            disabled: true,
            help: help.sum
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.RiskPremiumBeta,
        suggestionType: SuggestionType.MedianSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.MedianSumLeveredBeta,
        relatedInputs: []
    };

    public highFinancialRiskSumLeveredBeta4: FieldData = {
        source: InputName.HighFinancialRiskSumLeveredBeta4,
        config: {
            disabled: true,
            help: help.HFRSumBeta
        },
        tabName: Menu.Betas,
        fieldType: FieldType.Output,
        betaGroup: 0,
        selectionType: SelectionType.HighFinancialRiskBeta,
        suggestionType: SuggestionType.HighFinancialRiskSumLeveredBeta,
        inputType: InputType.None,
        valueType: ValueType.HighFinancialRiskBeta,
        relatedInputs: []
    };
   
    public intlInvestorCountry: FieldData = {
        source: InputName.IntlInvestorCountry,
        config: {
            help: help.InternationalInvestorCountry,
            mask: '{}',
            placeholder:'',
        },
        fieldType: FieldType.IntlCombobox,
        selectionType: SelectionType.IntlInvestorCountry,
        valueType: ValueType.IntlInvestorCountry,
        inputType: InputType.None,
        relatedInputs: [],
        
    };
    public intlInvestorCurrency: FieldData = {
        source: InputName.IntlInvestorCurrency,
        config: {
            help: help.InternationalInvestorCurrency,
            mask: '{}',
            placeholder:'',
        },
        fieldType: FieldType.ComboBox,
        selectionType: SelectionType.IntlInvestorCurrency,
        valueType: ValueType.IntlInvestorCurrency,
        inputType: InputType.None,
        relatedInputs: []
    };

    public intlIndustry: FieldData = {
        source: InputName.IntlIndustry,
        config: {
            help: help.InternationalIndustry,
            disabled: true
        },
        fieldType: FieldType.TypeAhead,
        valueType: ValueType.IntlIndustry,
        inputType: InputType.None,
        relatedInputs: []
    };
}
