import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class RegionClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(): Observable<string[]> {
        const request = 'Region';
        return this.get(request);
    }
}
