export class Categories {
    public static readonly BetasLevered = 'Betas Levered';
    public static readonly BetasUnlevered = 'Betas Unlevered';
    public static readonly CostOfDebt = 'Cost of Debt (%)';
    public static readonly CostOfEquityCapital = 'Cost of Equity Capital (%)';
    public static readonly EnterpriseValuationMultiples = 'Enterprise Valuation (EV) Multiples';
    public static readonly EquityValuationMultiples = 'Equity Valuation Multiples';
    public static readonly FamaFrench3FactorModel = 'Fama-French (F-F) 3-Factor Model';
    public static readonly FamaFrench5FactorModel = 'Fama-French (F-F) 5-Factor Model';
    public static readonly GrowthRates = 'Growth Rates (%)';
    public static readonly Industry = 'Industry';
    public static readonly LeverageRatios = 'Leverage Ratios (%)';
    public static readonly LiquidityRatio = 'Liquidity Ratio';
    public static readonly ProfitabilityRatio = 'Profitability Ratio (%)';
    public static readonly ReturnRatios = 'Return Ratios (%)';
    public static readonly Sp500Index = 'S&P 500 Index';
    public static readonly MarketBenchmark = 'Market Benchmark';
    public static readonly Wacc = 'Weighted Average Cost of Capital (WACC) (%)';
    public static readonly AnnualizedMonthlyPerformanceStatistics = 'Annualized Monthly Performance Statistics (%)';
    public static readonly CapitalStructure = 'Capital Structure';
}
