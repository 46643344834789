import { Injectable } from '@angular/core';
import { Auth0ApiClient } from '../api.client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Industry } from '../responses/us-industry.response';

@Injectable()
export class USIndustryClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(dataAsOf: string): Observable<Industry[]> {
        return this.get(`usindustry?dataAsOf=${dataAsOf}`);
    }

    public getUsiIndustries(dataAsOf: string): Observable<Industry[]> {
        return this.get(`usindustry/Usi?dataAsOf=${dataAsOf}`);
    }

}
