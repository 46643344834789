import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsCompanyData } from '../responses/us-company.response';
import { Auth0ApiClient } from '../api.client';

@Injectable()
export class USCompanyClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(dataAsOf: string, sicId: number): Observable<UsCompanyData> {
        return this.get(`UsCompanyData?dataAsOf=${dataAsOf}&sicId=${sicId}`);
    }

    public getFibCompanies(dataAsOf: string, sicId: number): Observable<any> {
        return this.get(`UsIndustryData/GetFibCompanies?dataAsOf=${dataAsOf}&sicId=${sicId}`);
    }

    public getGICCompanies(dataAsOf: string, gicId: number, area: string, currency: string): Observable<any> {
        return this.get(`UsIndustryData/GetGICCompanies?dataAsOf=${dataAsOf}&gicId=${gicId}&area=${area}&currency=${currency}`);
    }
}
