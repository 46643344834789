import { Component, Input, OnChanges } from "@angular/core";
import { Observable } from "rxjs";
import { CommonUserInputComponent } from "src/app/_add-in/common/inputs/common-user-input.component";
import { FieldService } from "src/app/_add-in/data/field.service";
import { ExcelStorageService } from "src/app/_add-in/excel/excel-storage.service";
import { FieldType } from "../../data/enums/field-type";

@Component({
    selector: "common-typeahead",
    templateUrl: "./common-typeahead.component.html",
})
export class CommonTypeaheadComponent
    extends CommonUserInputComponent
    implements OnChanges
{
    @Input() public typeahead!: () => Observable<string>;
    @Input() public typeaheadValue!: string;
    @Input() public typeaheadFormatter!: () => string;
    public fieldTypeInput = FieldType.TypeAhead;

    constructor(
        private fieldService: FieldService,
        private excelStorageService: ExcelStorageService
    ) {
        super(excelStorageService);
    }

    public emitChange(isValid: boolean): void {
        // eslint-disable-next-line
        const hasTypeaheadResult =
            this.typeahead !== null &&
            this.model.value &&
            this.model.value[this.typeaheadValue];
        const hasStandardInput = this.typeahead == null && this.model.value;

        if (hasStandardInput || hasTypeaheadResult) {
            super.emitChange(isValid);
            if (isValid) {
                this.fieldService.updateModel(
                    this.model,
                    this.excelStorageService
                );
            }
        }
    }
    public emitFocus(event: FocusEvent): void {
        super.emitFocus(event);
    }

    public onImportCellValue(): void {
        this.excelStorageService.getCellValue(this.model);
    }
}
