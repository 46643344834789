<div class="common-input-reference" *ngIf="model.reference">
    <div class="common-input-reference-container">
        <span class="cell-reference" (click)="onFocusToCell()">
            ({{model.reference}})
        </span>
        <button *ngIf="model.fieldType == fieldTypeInput || model.fieldType == fieldTypeOutput || model.fieldType == fieldTypeDatePicker || model.fieldType==fieldTypeAhead || model.fieldType === fieldTypeIntlCombobox"
            (click)="onRemoveBinding()" class="information-btn unlink-button" title="Remove binding">
            <span *ngIf="model.fieldType == fieldTypeInput || model.fieldType == fieldTypeDatePicker || model.fieldType==fieldTypeAhead || model.fieldType==fieldTypeIntlCombobox; else undoIcon">
                <i class="fas fa-unlink"></i>
            </span>
            <ng-template #undoIcon>
                <i class="fas fa-undo"></i>
            </ng-template>
        </button>
    </div>
</div>