<input-container [(model)]="model">
    <div class="common-typeahead">
        <!-- <form #form="ngForm" *ngIf="model != null">
            
        </form> -->
        <input
            *ngIf="
                model.source !== 'benchMarkingIndustry' &&
                    model.source !== 'intlBenchMarkingIndustry';
                else otherThanModule1
            "
            class="common-input"
            [(ngModel)]="model.value"
            (ngModelChange)="emitChange(true)"
            [attr.disabled]="model.config.disabled ? true : null"
            [ngbTypeahead]="typeahead"
            (focus)="emitFocus($event)"
            [inputFormatter]="typeaheadFormatter"
            [resultFormatter]="typeaheadFormatter"
        />
        <span
            *ngIf="
                model.fieldType == fieldTypeInput &&
                model.source !== 'benchMarkingIndustry' &&
                model.source !== 'intlBenchMarkingIndustry'
            "
        >
            <button
                *ngIf="model.fieldType == fieldTypeInput"
                (click)="onImportCellValue()"
                class="link-to-cell"
                title="Link the selected cell in your model to this field"
            >
                <i class="fas fa-link"></i>
            </button>
        </span>
        <ng-template #otherThanModule1>
            <input
                class="common-input"
                [(ngModel)]="model.value"
                (ngModelChange)="emitChange(true)"
                [attr.disabled]="model.config.disabled ? true : null"
                [ngbTypeahead]="typeahead"
                (focus)="emitFocus($event)"
                [inputFormatter]="typeaheadFormatter"
                [resultFormatter]="typeaheadFormatter"
            />
        </ng-template>
        <!-- <input class="common-input" [(ngModel)]="model.value" (ngModelChange)="emitChange(true)"
            [attr.disabled]="model.config.disabled ? true : null" [ngbTypeahead]="typeahead" (focus)="emitFocus($event)"
            [inputFormatter]="typeaheadFormatter" [resultFormatter]="typeaheadFormatter" />
            <span *ngIf="model.fieldType == fieldTypeInput && model.source!=='benchMarkingIndustry' && model.source!=='intlBenchMarkingIndustry'">
                <button *ngIf="model.fieldType == fieldTypeInput" (click)="onImportCellValue()" class="link-to-cell"
                    title="Link the selected cell in your model to this field">
                    <i class="fas fa-link"></i>
                </button>
            </span> -->
    </div>
</input-container>
