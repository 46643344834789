import { Component, Input, OnInit } from '@angular/core';
import { ModalManager, Spinner } from '@concurrency/angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AccordionTextService } from '../_add-in/accordion/accordion-text.service';
import { CommonDate, DateStruct } from '../_add-in/common/date-struct';
import { FieldData } from '../_add-in/data/field-data';
import { InputBuilder } from '../_add-in/data/input.builder';
import { SuggestedInput } from '../_add-in/data/suggested-input.model';
import { ExcelDialogType } from '../_add-in/excel/excel-dialog-type';
import { ExcelStorageService } from '../_add-in/excel/excel-storage.service';
import { IntlCOCValuationDate } from '../_add-in/help/help-text';
import { MenuItem } from '../_add-in/menu/menu-item.model';
import { MenuUtility } from '../_add-in/menu/menu.utility';
import { PrimeManager } from '../_add-in/modal/pmodal.manager';
import { ProductType } from '../_add-in/user/enum/product-type';
import { SubscriptionData } from '../_add-in/user/model/subscription.model';
import { UserStore } from '../_add-in/user/store/user.store';
import { UserUtil } from '../_add-in/user/util/user.util';
import { CountryClient } from '../_api/clients/country.client';
import { IntlBetaClient } from '../_api/clients/intlBeta.client';
import { IntlErpClient } from '../_api/clients/intlErp.client';
import { IntlIndustryClient } from '../_api/clients/intlIndustry.client';
import { IntlRfClient } from '../_api/clients/intlRf.client';
import { Country } from '../_api/responses/country.response';
import { Gic } from '../_api/responses/gic.response';
import { IntlData } from '../_api/responses/intl-data.response';
import { MinimumDate } from '../_api/responses/minimum-date.response';
import { IntlCOCSheetService } from '../benchmarking/tear-sheet/data/intl-costofcapital-sheet.service';
import { GenericTabComponent } from '../estimate/generic-tab.component';
// import { Excel } from '@microsoft/office-js/dist/office';


@Component({
  selector: 'app-intl-cost-of-capital',
  templateUrl: './intl-cost-of-capital.component.html',
 // styleUrls: ['./intl-cost-of-capital.component.scss']
})
export class IntlCostOfCapitalComponent extends GenericTabComponent implements OnInit{
  public isTrial = false;
  public menuItems: MenuItem[] = MenuUtility.intlCoCMenu;
  public currentRoute: string;
  private _header = '';
  public industryCode!: number;
  public isIndustryDisplay: boolean = false;
  public investorCountriesComboOptions: SuggestedInput[] = [];
  public investorCountries: Country[] = [];
  public investorCurrencyComboOptions: SuggestedInput[] = [];
  public intlIndustryComboOptions: SuggestedInput[] = [];
  public intlIndustryOptions: Gic[] = [];
  public isIndustryNotAvailable = false;
  public lstIndustries: Gic[] = [];
  public listIntlBetas: IntlData[] = [];
  public listIntlErpClients: IntlData[] = [];
  public listIntlRfClients: IntlData[] = [];
  public tempIndustries: Gic[] = [];
  public lstSelectedIndustries: Gic[] = [];
  public lstFinalSelectedIndustry: Gic[] = [];
  public searchValue: any;
  public isAddClicked: any;
  private _check: string = '';
  public maximumNumberOfIndustries = 1;
  public preserveIntlValuationDate: DateStruct | undefined;
  private ineSubData: SubscriptionData | undefined;
  public selectedIndustry: Gic[] = [];
  public krollExcelTemplate = '';
  public excelData: any[] = [];
 

   
  public get header(): string {
        return this._header;
    }
  public set header(value: string) {
        this._header = value;
    }
  @Input() public model!: FieldData;

  constructor(
    public excelStorageService: ExcelStorageService,
    public userStore: UserStore,
    public builder: InputBuilder,
    public headers: AccordionTextService,
    public primeManager: PrimeManager,
    public modalManager: ModalManager,
    private spinner: Spinner,
    public intlCountries: CountryClient,
    public intlErpClients: IntlErpClient,
    public intlRfClients: IntlRfClient,
    public intlIndustries: IntlIndustryClient,
    public intlBetas: IntlBetaClient,
    public intlCOCSheetService: IntlCOCSheetService,    
    ) {
      
      super(excelStorageService, userStore, builder);
      // if(this.builder.intlValuationDate && this.builder.intlValuationDate.value
      //  && this.builder.intlInvestorCountry && this.builder.intlInvestorCountry.value
      //  && this.builder.intlInvestorCurrency && this.builder.intlInvestorCurrency.value){
      //   this.excelStorageService.isRefreshDataEnable = true;
      //  }
      // this.getPreviousDataSelected();
       this.currentRoute = this.getCurrentRouteName();
      this.header = headers.getAccordionText('ineCOCHeader');
      this.userStore.user.onceDefined((profile) => {
            this.excelStorageService.getuserprofile(profile);
            this.spinner.begin();
            this.userStore.valuesClient.getBasicMinimumDate().onceDefined((value: MinimumDate | undefined) => {
                const ngbDate = this.builder.intlCOCValuationDate.value as NgbDateStruct;
                const date = this.builder.intlCOCValuationDate.value ?
                    new CommonDate(ngbDate.year, ngbDate.month, ngbDate.day) : CommonDate.fromDate(new Date());
                  if(!this.builder.intlCOCValuationDate.value){
                    this.builder.intlCOCValuationDate.value = date;
                    }
                    // if(this.builder.intlCOCValuationDate.reference === ""){
                    //   this.builder.intlCOCValuationDate.config = {
                    //     disabled: false
                    //   };
                    // }
                if (value) {
                    const config = UserUtil.getIntValuationDateInputConfig(date, ProductType.Ine,
                        value.InternationalEstimates, profile.subscriptionDetails);
                    this.builder.intlCOCValuationDate.config = {
                        readonly: true,
                        required: true,
                        minimumValue: config.minimumValue,
                        maximumValue: config.maximumValue,
                        help: IntlCOCValuationDate,
                        disabled: this.builder.intlCOCValuationDate.config && this.builder.intlCOCValuationDate.config.disabled && this.builder.intlCOCValuationDate.reference !== ""
                            ? this.builder.intlCOCValuationDate.config.disabled : false
                    };
                }
                localStorage.setItem('IntlCOCValuationDate', JSON.stringify(this.builder.intlCOCValuationDate.value));
                this.excelStorageService.getInvestors();

            }, () => { }, () => { this.spinner.end(); });
        });
        this.excelStorageService.updateAvailbleExcels('KrollCountryRiskExport');
     
   }

  // public ngOnInit(): void {
  //   // super.ngOnInit();
  //   // this.getExistingDataIfExists();
    
  // }

  public logoutUser(): void {
      this.userStore.logoutCustomerOrEmployee();
  }

  public getCurrentRouteName(): string {
      const currentRoute = this.menuItems.find((x) => x.route === this.userStore.getCurrentRoute());

      if (!currentRoute) {
          return 'intlCOC';
      }

      return currentRoute.name;
  }
  public updateValuationDate(valuationDate: DateStruct){
    this.excelStorageService.updateValuationDateIntl(valuationDate);
  }
  

  public async onExportToExcel(): Promise<void> {
        if (!(this.builder.intlCOCValuationDate.value && this.builder.intlInvestorCurrency.value)) {
          this.excelStorageService.displayDialog(ExcelDialogType.IntlRequiredInputs);
            return;
        }
       await this.intlCOCSheetService.save();

    }
    public async onExportToExcelTemplate(): Promise<void> {
      this.intlCOCSheetService.saveKrollTemplate();
  }

//  public async getPreviousDataSelected(){
//   const previousSelectedDate = localStorage.getItem('IntlCOCValuationDate');
//   const previousSelectedCountry = localStorage.getItem("IntlInvestorCountry");
//   const previousSelectedCurrency = localStorage.getItem("IntlInvestorCurrency");
//   const selectedDate: NgbDateStruct = previousSelectedDate? JSON.parse(previousSelectedDate) : this.builder.intlCOCValuationDate.value;
//   const selectedCountry: FieldData = previousSelectedCountry? JSON.parse(previousSelectedCountry) : this.builder.intlInvestorCountry;
//   const selectedCurrency: FieldData = previousSelectedCurrency? JSON.parse(previousSelectedCurrency) : this.builder.intlInvestorCurrency;

//   this.builder.intlCOCValuationDate.value = selectedDate;
//   this.builder.intlInvestorCountry = selectedCountry;
//   this.builder.intlInvestorCurrency = selectedCurrency;
//   if(previousSelectedDate && previousSelectedCountry && previousSelectedCurrency){
//     await this.excelStorageService.getInvestors();
//    await this.excelStorageService.reflectInvestor(this.builder.intlInvestorCountry);
//     // this.excelStorageService.getErpClients();
//     this.excelStorageService.isRefreshDataEnable = true;
//   }
//  }

}