import { Injectable } from '@angular/core';
import { Util } from '@concurrency/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { InputBuilder } from 'src/app/_add-in/data/input.builder';
import { Industry } from 'src/app/_api/responses/industry.response';
import { FieldData } from '../data/field-data';

@Injectable()
export class IndustryService {
    public noResults = false;
    // TODO: remove inputBuilder from the constructor
    constructor(private inputBuilder: InputBuilder) { }

    // TODO: change this to a function that takes usIndustries as a parameter
    public searchIndustries = (text: Observable<string>, fieldData?: FieldData) => {
        const termMapper = (term: string) => {
            if (term === '') {
                return [];
            }

            const cleanTerm = term.toLowerCase().trim();

            let results: Industry[] = [];
            if (!fieldData) {
                results = this.baseFilterTypeAhead(this.inputBuilder.intlIndustries.value, cleanTerm).filter((industry) => {
                    const matchesUSZone = this.MatchesUSZoneNullValues(industry) || this.MatchesUSZone(industry);

                    return matchesUSZone;
                }).sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.SicIndustryCode) ||
                    parseFloat(a.GicIndustryCode) - parseFloat(b.GicIndustryCode))
                    .sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.GicIndustryCode));
            } else {
                results = this.baseFilterTypeAhead(fieldData.value, cleanTerm).filter((industry) => {
                    const matchesZone = this.MatchesZone(industry);
                    const matchesUSZone = this.MatchesBenchmarkingUSZoneNullValues(industry) || this.MatchesBenchmarkingUSZone(industry);
                    // const matchesUSZone = this.MatchesBenchmarkingUSZone(industry) || this.MatchesZone(industry);

                    return matchesUSZone || matchesZone;
                }).sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.SicIndustryCode) ||
                    parseFloat(a.GicIndustryCode) - parseFloat(b.GicIndustryCode))
                    .sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.GicIndustryCode));

                // slice(0, 10).sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.SicIndustryCode) ||
                //     parseFloat(a.GicIndustryCode) - parseFloat(b.GicIndustryCode))
                //     .sort((a, b) => parseFloat(a.SicIndustryCode) - parseFloat(b.GicIndustryCode))
            }

            this.noResults = !results.length ? true : false;
            return Util.sortBy(results, (x) => {
                const code = (!x.GicIndustryCode) ? x.SicIndustryCode : x.GicIndustryCode;
                parseInt(code, 10);
            });
        };

        return text.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(termMapper)
        );
    }

    public industryTypeaheadFormatter(result: Industry): string {
        if (result && result.Sector) {
            const code = (result.SicIndustryCode != null && result.SicIndustryCode !== '')
                ? `SIC ${result.SicIndustryCode}`
                : `GICS ${result.GicIndustryCode}`;

            return `${code} - ${result.Sector.toUpperCase()}`;
        } else if (result && typeof result === 'string' && result !== 'N/A') {
            return result;

        } else {
            return 'N/A';
        }
    }

    private MatchesUSZoneNullValues(industry: Industry): boolean {
        return industry.CurrencyCode === null && industry.Area === null;
    }

    private MatchesUSZone(industry: Industry): boolean {
        return industry.CurrencyCode === 'USD' && industry.Area === 'United States';
    }
    private MatchesBenchmarkingUSZone(industry: Industry): boolean {
        return industry.CurrencyCode === 'USD' && industry.Area === 'United States' &&
            this.inputBuilder.benchMarkingCurrency.value === 'USD' && this.inputBuilder.benchMarkingRegion.value === 'United States';
    }
    private MatchesBenchmarkingUSZoneNullValues(industry: Industry): boolean {
        return industry.CurrencyCode === null && industry.Area === null &&
            this.inputBuilder.benchMarkingCurrency.value === 'USD' && this.inputBuilder.benchMarkingRegion.value === 'United States';
    }
    private baseFilterTypeAhead(industries: Industry[], cleanTerm: string): Industry[] {
        return industries.filter((industryValue) => {
            if (Util.isNullOrEmpty(industryValue.Sector)) {
                return false;
            }
            const industryCode = (!industryValue.GicIndustryCode) ? industryValue.SicIndustryCode : industryValue.GicIndustryCode;
            const displayName = `${industryCode} - ${industryValue.Sector}`.toLowerCase();
            const matchesDisplayName = displayName.includes(cleanTerm);
            const hasDash = cleanTerm.includes('-');
            const matchesCode = industryCode.startsWith(cleanTerm);
            const matchesName = industryValue.Sector.toLowerCase().includes(cleanTerm);
            return matchesCode || matchesName || (hasDash && matchesDisplayName);
        });
    }

    private MatchesZone(industry: Industry): boolean {
        return industry.Area !== null && industry.CurrencyCode !== null &&
            industry.Area === this.inputBuilder.benchMarkingRegion.value &&
            industry.CurrencyCode === this.inputBuilder.benchMarkingCurrency.value;
    }

    public searchBenchmarkingIndustries = (text: Observable<string>) => {
        return this.searchIndustries(text, this.inputBuilder.benchMarkingIntlIndustries);
    }
}
