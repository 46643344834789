export enum ValueType {
    Industry,
    Beta,
    EquityRiskPremium,
    IndustryRiskPremium,
    BetaSuggestion,
    Currency,
    Region,
    BenchmarkingIndustry,
    IntlBenchmarkingIndustry,
    FullInformationBeta,
    MedianSumLeveredBeta,
    VasicekAdjustedBeta,
    HighFinancialRiskBeta,
    IntlInvestorCountry,
    IntlInvestorCurrency,
    IntlIndustry
}
