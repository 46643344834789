import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "common-dialog-info",
    templateUrl: "./common-dialog-info.component.html",
})
export class CommonDialogInfoComponent {
    constructor(
        public dialogRef: MatDialogRef<CommonDialogInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public message: string
    ) {}

    public onClose(): void {
        this.dialogRef.close();
    }
}
