import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserStore } from './user/store/user.store';
@Injectable()
export class IsSubscriberExpiredGuard implements CanActivate {
    constructor(
        private userStore: UserStore
    ) { }

    // TODO: Why does this only work with a promise and not an Observable?
    // see: https://stackoverflow.com/questions/41131476/emptyerror-no-elements-in-sequence
    public canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            resolve(true);
            this.userStore.user.once((user) => {
                if (!(user == null || user.hasExpired() === false)) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            });
        });
    }
}
