import { Component, OnInit } from '@angular/core';
import { JwtToken } from '@concurrency/authentication';
import { UserStore } from '../_add-in/user/store/user.store';

@Component({ template: '' })
export class EmployeeLoginComponent implements OnInit {
    constructor(
        private userStore: UserStore
    ) { }

    public ngOnInit(): void {
        let storage: any;
        if (navigator.userAgent.indexOf('Edge') > -1) { // TODO: This should not be necessary and using localStorage is not wise.
            storage = localStorage;
        } else {
            storage = sessionStorage;
        }
        const raw = storage.getItem('adal.idtoken');
        const token = raw ? new JwtToken(raw) : undefined;
        if (!token) {
            this.userStore.loginEmployee();
        } else {
            this.userStore.reDirectModuleURL();
        }
    }
}
