export enum AssetsTypes {
    EUR = 'Assets ([EUR] in millions)',
    USD = 'Assets ([USD] in millions)',
    GBP = 'Assets ([GBP] in millions)'
}

export enum SalesTypes {
    EUR = 'Sales ([EUR] in millions)',
    USD = 'Sales ([USD] in millions)',
    GBP = 'Sales ([GBP] in millions)',
}

export enum CurrencyTypes {
    EUR = 'eur',
    USD = 'usd',
    GBP = 'gbp'
}
