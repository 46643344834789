import * as moment from 'moment';
import { Moment } from 'moment';
import { UserResponse, UserSubscription } from 'src/app/_api/responses/user.response';
import { environment } from 'src/environments/environment';
import { ProductType } from '../enum/product-type';
import { SubscriptionLevel } from '../enum/subscription-level';
import { FastSpringUrl } from './fast-spring-url.model';
import { SubscriptionData } from './subscription.model';

export class User implements UserResponse {
    public ProfileId: string;
    public IdentityId: string;
    public FullName: string;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public PhoneNumber: string;
    public JobTitle: string;
    public CountryCode: string;
    public AcceptTerms: boolean;
    public ProductNewsId: string;
    public ProductNewsRead: boolean;
    public BannerMessageId: string;
    public ReadBannerMessage: boolean;
    public MarketingOptIn: boolean;
    public PartnerId: string;
    public CompanyId: string;
    public CompanyName: string;
    public PartnerName: string;
    public FeatureName: string[];
    public LicenseExpiration: string;
    public IsActive: boolean;
    public IsAdmin: boolean;
    public Roles: string[];
    public Subscriptions: UserSubscription[] = [];
    public CreatedOn: string;
    public UpdatedOn: string;
    public CreatedBy: string;
    public UpdatedBy: string;
    public externalRenewalLink: string;
    public mayPurchase: boolean;
    public subscriptionDetails: SubscriptionData[] = [];    
    public FeaturesWithExpiration:any[];
    public CustomerName:string;
    public Country:string;
    

    constructor(data: UserResponse) {
        this.ProfileId = data.ProfileId;
        this.IdentityId = data.IdentityId;
        this.FullName = data.FullName;
        this.FirstName = data.FirstName;
        this.LastName = data.LastName;
        this.Email = data.Email;
        this.PhoneNumber = data.PhoneNumber;
        this.JobTitle = data.JobTitle;
        this.CountryCode = data.CountryCode;
        this.PartnerId = data.PartnerId;
        this.CompanyId = data.CompanyId;
        this.CompanyName = data.CompanyName;
        this.PartnerName = data.PartnerName;
        this.FeatureName = data.FeatureName;
        this.LicenseExpiration = data.LicenseExpiration;
        this.IsAdmin = data.IsAdmin;
        this.IsActive = data.IsActive;
        this.Roles = data.Roles;
        this.AcceptTerms = data.AcceptTerms;
        this.ProductNewsId = data.ProductNewsId;
        this.ProductNewsRead = data.ProductNewsRead;
        this.BannerMessageId = data.BannerMessageId;
        this.ReadBannerMessage = data.ReadBannerMessage;
        this.MarketingOptIn = data.MarketingOptIn;
        this.Subscriptions = data.Subscriptions;
        this.CreatedOn = data.CreatedOn;
        this.UpdatedOn = data.UpdatedOn;
        this.CreatedBy = data.CreatedBy;
        this.UpdatedBy = data.UpdatedBy;
        this.FeaturesWithExpiration=data.FeaturesWithExpiration;
        this.CustomerName=data.CustomerName;
        this.Country=data.Country;
        this.mayPurchase = this.Subscriptions[0] == null;
        this.subscriptionDetails = this.Subscriptions.map((sub) => new SubscriptionData(sub));
        if (this.PartnerName === 'ValuSource') {
            this.externalRenewalLink = this.getValuSourceRenewUrl();
        } else {
            this.externalRenewalLink = `${environment.urls.fastSpring}/all`;
        }
    }

    public showRenew(): boolean {
        const expirations: Moment[] = [];
        this.subscriptionDetails.forEach((x) => expirations.push(x.expiration.asMoment()));
        const maxExpirationDate = moment.max(expirations);
        const now = moment();
        let feature = false;
        if (this.FeatureName != null) {
            this.FeatureName.forEach((x) => {
                if (x.toLowerCase().endsWith('basic') || x.toLowerCase().endsWith('pro')) {
                    feature = true;
                }
            });
        }
        return maxExpirationDate.clone().subtract(90, 'days').isBefore(now)
            && feature
            && !this.IsAdmin;
    }

    public hasTrial(): boolean {
        return this.subscriptionDetails.some((sub) => sub.level === SubscriptionLevel.Trial);
    }

    public hasExpired(): boolean {
        return this.subscriptionDetails.some((sub) => sub.isExpired === false);
    }

    public hasOfferRenewal(): boolean {
        return this.Subscriptions.some((sub) => sub.OfferRenewal);
    }

    public hasProOrEnterprise(): boolean {
        return this.subscriptionDetails.some((sub) => sub.level === SubscriptionLevel.Pro || sub.level === SubscriptionLevel.Enterprise);
    }

    public getProRatedLink(type: ProductType, fastSpringUrl: FastSpringUrl): string {
        const subscriptions = this.subscriptionDetails.filter((x) => x.productType !== type);
        const expirations: Moment[] = [];
        subscriptions.forEach((x) => expirations.push(x.expiration.asMoment()));
        const maxExpirationDate = moment.max(expirations);

        if (expirations.length > 0 && maxExpirationDate != null) {
            const now = moment();
            return `${environment.urls.fastSpring}/${fastSpringUrl.base}`;
            if (maxExpirationDate.clone().subtract(90, 'days').isBefore(now)) {
                return `${environment.urls.fastSpring}/uscofc`;
            } else if (maxExpirationDate.clone().subtract(180, 'days').isBefore(now)) {
                return `${environment.urls.fastSpring}/${fastSpringUrl.half}`;
            } else if (maxExpirationDate.clone().subtract(270, 'days').isBefore(now)) {
                return `${environment.urls.fastSpring}/${fastSpringUrl.quarter}`;
            }
        }

        return `${environment.urls.fastSpring}/${fastSpringUrl.base}`;
    }

    private getValuSourceRenewUrl(): string {
        const cocSub = this.subscriptionDetails.find((x) => x.productType === ProductType.Coc);

        if (cocSub != null) {
            const expiration = cocSub.expiration.asMoment();
            const now = moment();

            if (expiration.clone().subtract(90, 'days').isAfter(now)) {
                return 'https://www.valusource.com/duff-phelps-cost-of-capital-navigator-u-s-industry-benchmarking-module/';
            }
        }

        return 'https://www.valusource.com/navigator-renew/';
    }

}
