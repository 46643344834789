export class Columns {
    public static readonly Latest = 'Latest';
    public static readonly FiveYrAvg = '5-Yr Avg';
    public static readonly AnalystEstimates = 'Analyst Estimates';
    public static readonly FamaFrenchBeta = 'F-F Beta';
    public static readonly SmbPremium = 'SMB Premium';
    public static readonly HmlPremium = 'HML Premium';
    public static readonly CmaPremium = 'CMA Premium';
    public static readonly Capm = 'CAPM';
    public static readonly CapmSizePrem = 'CAPM + Size Prem';
    public static readonly BuildUp = 'Build-Up';
    public static readonly OneStage = '1-Stage';
    public static readonly ThreeStage = '3-Stage';
    public static readonly FiveFactorModel = '5-Factor Model';
}
