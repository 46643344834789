import { Util } from '@concurrency/core';

export class NumberFormatUtil {

    public static numberWithCommas(value: number, fractionDigits: number = 2, showNA: boolean = false): string {
        if (Util.isNumber(value)) {
            const parts = value.toFixed(fractionDigits).toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        } else if (value) {
            return value.toString();
        } else {
            return showNA ? 'N/A' : '';
        }
    }
}
