import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager } from '@concurrency/angular';
import { environment } from 'src/environments/environment';
import { ExpirationModalComponent } from './_modals/expiration-modal.component';

@Component({ template: '' })
export class ValidateSubscriptionComponent implements OnInit {
    constructor(
        private modalManager: ModalManager,
        private router: Router
    ) { }

    public ngOnInit(): void {
        this.displayExpiration();
    }

    private displayExpiration(): void {
        const modal = this.modalManager.open<string, ExpirationModalComponent>(ExpirationModalComponent, { mayDismiss: false });
        modal.once(() => {
            window.open(`${environment.urls.fastSpring}/uscofc`, '_blank');
            this.router.navigate(['landing']);
        });
    }

}
