import { InputType } from './input.response';

export interface Selection {
    SelectionType: SelectionType;
    InputType: InputType;
    Value?: number;
    Context?: string | null;
    SourceTable?: number | null;
    SourceDataAsOf?: string | null;
    Created?: Date;
    Updated?: Date;
}

export enum SelectionType {
    // Betas & Industry Risk Premiums
    CrspDecilesBeta = 'CrspDecilesBeta',
    RiskPremiumBeta = 'RiskPremiumBeta',
    HighFinancialRiskBeta = 'HighFinancialRiskBeta',
    CrspIndustryRiskPremium = 'CrspIndustryRiskPremium',
    RprIndustryRiskPremium = 'RprIndustryRiskPremium',

    // Miscellaneous (?)
    RiskFreeRate = 'RiskFreeRate',
    EquityRiskPremium = 'EquityRiskPremium',
    EquityRiskPremiumAdjustment = 'EquityRiskPremiumAdjustment',

    // Crsp Size Premiums
    CrspSizePremium = 'CrspSizePremium',
    CrspBuildupSizePremium = 'CrspBuildupSizePremium',

    // Risk Premiums
    RiskPremiumOverCapm = 'RiskPremiumOverCapm',
    RiskPremiumOverRiskFreeRateLevered = 'RiskPremiumOverRiskFreeRateLevered',
    RiskPremiumOverRiskFreeRateLeveredDebtEquityRatio = 'RiskPremiumOverRiskFreeRateLeveredDebtEquityRatio',
    RiskPremiumOverRiskFreeRateUnlevered = 'RiskPremiumOverRiskFreeRateUnlevered',
    RiskPremiumOverRiskFreeRateRelevered = 'RiskPremiumOverRiskFreeRateRelevered',
    Buildup2RiskPremiumOverTheRiskFreeRate = 'Buildup2RiskPremiumOverTheRiskFreeRate',
    Buildup3RiskPremiumOverTheRiskFreeRate = 'Buildup3RiskPremiumOverTheRiskFreeRate',

    // HFR Premiums
    HighFinancialRiskPremiumOverCapM = 'HighFinancialRiskPremiumOverCapM',
    HighFinancialRiskPremiumOverRiskFreeRate = 'HighFinancialRiskPremiumOverRiskFreeRate',

    // Region
    BenchmarkingRegion = 'BenchmarkingRegion',
    BenchmarkingCurrency = 'BenchmarkingCurrency',
    IntlBenchmarkingRegion = 'IntlBenchmarkingRegion',
    IntlBenchmarkingCurrency = 'IntlBenchmarkingCurrency',

    // Internation Cost of Capital
    IntlInvestorCountry = 'IntlInvestorCountry',
    IntlInvestorCurrency = 'IntlInvestorCurrency'
}
