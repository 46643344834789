import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { SubscriberEntity } from "@concurrency/angular";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { FieldData } from "src/app/_add-in/data/field-data";
import { Error } from "src/app/_add-in/error/error.model";
import { ExcelStorageService } from "src/app/_add-in/excel/excel-storage.service";
import { Help } from "../../help/help.model";

export interface DebounceConfig {
    callback: () => void;
    delay?: number;
}

// TODO: this shouldn't be here
export interface CommonInputConfig {
    name?: string;
    autofocus?: boolean; // TODO: Re-implement to be false by default
    readonly?: boolean;
    disabled?: boolean;
    required?: boolean;
    placeholder?: string;
    tabindex?: string;
    debounce?: DebounceConfig;
    minimumValue?: number | NgbDateStruct;
    maximumValue?: number | NgbDateStruct;
    help?: Help;
    error?: Error;
    mask?: string;
    label?: string;
    pattern?: string;
    inputType?: string; // TODO: Make this an enum
    edit?: boolean;
    checked?: boolean;
    hideInfoIcon?:boolean;
}

@Directive()
export abstract class CommonUserInputComponent
    extends SubscriberEntity
    implements OnChanges
{
    @Output() public modelChange = new EventEmitter();
    @Output() public submit = new EventEmitter();
    @Output() public isValid = new EventEmitter<boolean>();
    @Output() public focus = new EventEmitter<FocusEvent>();
    @Input() public config!: CommonInputConfig;
    @Input() public model!: FieldData;
    @ViewChild("input") public input!: ElementRef;

    constructor(public excel: ExcelStorageService) {
        super();
    }

    public invalidValue?: string;
    public count = 0;
    public address!: FieldData;
    public cellname!: string;
    public sample = 0;

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            changes != null &&
            changes.model != null &&
            changes.model.firstChange
        ) {
            const sto = setTimeout(() => {
                if (this.input == null) {
                    return;
                }

                if (this.config != null && this.config.autofocus === false) {
                    return;
                }

                this.input.nativeElement.focus();
                clearTimeout(sto);
            });
        }
    }
    // public async getCellValuewhenoffline(model: FieldData): Promise<void> {
    //     const fields = this.excel.getExcelEstimate();
    //     if (fields == null) {
    //         return;
    //     }
    //     Excel.run({ delayForCellEdit: true }, async (context: Excel.RequestContext) => {
    //         const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
    //         const range = activeWorksheet.getRange('A1:z60');
    //         range.load(['address', 'cellCount', 'values', 'text']);
    //         activeWorksheet.load(['name', 'id', 'protection/protected']);
    //         return context.sync().then(() => {
    //             const cell = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    //                 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    //             for (let i = 1; i < 60; i++) {
    //                 for (let j = 0; j < 24; j++) {
    //                     if (range.values[i][j] !== '' && model.value === range.values[i][j]) {
    //                         i = i + 1;
    //                         this.cellname = cell[j] + i;
    //                         const sheetname = activeWorksheet.name;
    //                         model.reference = sheetname + '!' + this.cellname;
    //                         // this.formatcell(model.reference);
    //                         this.excel.saveToEstimate(model);
    //                     }
    //                 }
    //             }
    //             return true;
    //         });
    //     }).then(() => {
    //         this.excel.saveToEstimate(model);
    //     }).catch(this.excel.handleExcelErrors);
    // }

    public emitChange(isValid: boolean): void {
        if (isValid) {
            this.model.context = this.model.context;
            this.modelChange.emit(this.model);
            this.invalidValue = undefined;
            // if (this.model.reference != null && this.model.reference !== undefined) {
            //     this.getCellValuewhenoffline(this.model);
            // }
        } else {
            this.invalidValue = this.model.value;
        }
        this.isValid.emit(isValid);
    }
    public emitFocus(event: FocusEvent): void {
        this.focus.emit(event);
    }
}
