import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { NgbDatepickerConfig, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CommonUserInputComponent } from 'src/app/_add-in/common/inputs/common-user-input.component';
import { FieldService } from 'src/app/_add-in/data/field.service';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';

@Component({
    selector: 'common-datepicker',
    templateUrl: './common-datepicker.component.html'
})
export class CommonDatepickerComponent extends CommonUserInputComponent implements OnChanges {
    @ViewChild('d') public d!: NgbInputDatepicker;
    @Input() public hideInfoIcon = false;
    @Input() public disabled = false;

    constructor(
        private datepickerConfig: NgbDatepickerConfig,
        private fieldService: FieldService,
        private excelStorageService: ExcelStorageService
    ) {
        super(excelStorageService);
    }

    public ngOnChanges(): void {
        if (this.config == null) {
            return;
        }

        if (this.config.maximumValue != null && typeof this.config.maximumValue !== 'number') {
            this.datepickerConfig.maxDate = this.config.maximumValue;
        }

        if (this.config.minimumValue != null && typeof this.config.minimumValue !== 'number') {
            this.datepickerConfig.minDate = this.config.minimumValue;
        }
    }

    public closeDatepicker(datepicker: NgbInputDatepicker): void {
        datepicker.close();
    }

    public emitChange(isValid: boolean): void {
        super.emitChange(isValid);
        if (isValid) {
            this.fieldService.updateModel(this.model, this.excelStorageService);
        }
    }

    public onImportCellValue(): void {
        this.excelStorageService.getCellValue(this.model);
    }

}
