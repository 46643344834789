<nav class="fixed-top navbar-expand-lg navbar-dark">
    <!-- <span></span> -->
    <div class="navbar-toggler float-container display-flex">
        <span class="nav-title">{{currentRoute}}</span>
        <div class="navbarlogout float-child">
            <button class="btn btn-info " (click)="logoutUser()">Logout</button>
        </div>
    </div>
</nav>
<div class="benchmarking-container">
    <router-outlet></router-outlet>
</div>