import { Injectable } from '@angular/core';
import { ExcelDialogType } from './excel-dialog-type';

@Injectable()
export class ExcelDialogTypesBuilder {
    private typeNames: { [index: number]: string };

    public constructor() {
        this.typeNames = {};
        this.typeNames[ExcelDialogType.ValueAlreadyBound] = 'already-bound';
        this.typeNames[ExcelDialogType.CellNotEmpty] = 'cell-not-empty';
        this.typeNames[ExcelDialogType.NaN] = 'nan';
        this.typeNames[ExcelDialogType.ProtectedSheet] = 'protected-sheet';
        this.typeNames[ExcelDialogType.ValueChanged] = 'value-changed';
        this.typeNames[ExcelDialogType.MultipleCellsSelected] = 'multiple-cells-selected';
        this.typeNames[ExcelDialogType.valuationDateunavailablemod1] = 'valuationdateunavailablemod1';
        this.typeNames[ExcelDialogType.valuationDateunavailablemod2] = 'valuationdateunavailablemod2';
        this.typeNames[ExcelDialogType.valuationDateunavailablemod3] = 'valuationdateunavailablemod3';
        this.typeNames[ExcelDialogType.valuationdateunavilablebasic] = 'valuationdateunavailablebasic';
        this.typeNames[ExcelDialogType.valuationfuturedate] = 'valuationfuturedate';
        this.typeNames[ExcelDialogType.valuationinvaliddate] = 'valuationinvaliddate';
        this.typeNames[ExcelDialogType.Trialdate] = 'Trialdate';
        this.typeNames[ExcelDialogType.IntlInvestorCountryNotExists] = 'IntlInvestorCountryNotExists';
        this.typeNames[ExcelDialogType.IntlRequiredInputs] = 'IntlRequiredInputs';
    }

    public getTypeName(excelDialogType: ExcelDialogType): string {
        return this.typeNames[excelDialogType];
    }

}
