import { NgModule } from "@angular/core";
import { ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { AccordionTextService } from "src/app/_add-in/accordion/accordion-text.service";
import { BetaService } from "src/app/_add-in/beta/beta.service";
import { CalculationsService } from "src/app/_add-in/calculations/calculations.service";
import { FieldService } from "src/app/_add-in/data/field.service";
import { InputBuilder } from "src/app/_add-in/data/input.builder";
import { ExcelDialogTypesBuilder } from "src/app/_add-in/excel/excel-dialog-types.builder";
import { ExcelStorageService } from "src/app/_add-in/excel/excel-storage.service";
import { IndustryService } from "src/app/_add-in/industry/industry.service";
import { IsSubscriberGuard } from "src/app/_add-in/is-subscriber.guard";
import { PremiumsBuilder } from "src/app/_add-in/risk/premiums.builder";
import { RiskService } from "src/app/_add-in/risk/risk.service";
import { SummaryTableHeaderService } from "src/app/_add-in/summary/summary-table-header.service";
import { SummaryService } from "src/app/_add-in/summary/summary.service";
import { BetasTabComponent } from "src/app/estimate/betas-tab.component";
import { RfTabComponent } from "src/app/estimate/rf-tab.component";
import { SizeTabComponent } from "src/app/estimate/size-tab.component";
import { InputsTabComponent } from "../estimate/inputs-tab.component";
import { IsUserHaveIniSubscriptionGuard } from "./Is-user-have-ini-subscription.guard";
import { IsUserHaveUsiSubscriptionGuard } from "./Is-user-have-usi-subscription.guard";
import { IntlIndustryService } from "./industry/intlIndustry.service";
import { IsSubscriberExpiredGuard } from "./is-subscription-expired.guard";
import { IsUserHaveCocSubscriptionGuard } from "./is-user-have-coc-subscription.guard";
import { IsUserHaveIneSubscriptionGuard } from "./is-user-have-ine-subscription.guard";
import { DataPoint } from "./summary/summary-datapoints";
import { UserStore } from "./user/store/user.store";
@NgModule({
    providers: [
        AccordionTextService,
        CalculationsService,
        ExcelStorageService,
        FieldService,
        InputBuilder,
        IsSubscriberGuard,
        SummaryTableHeaderService,
        UserStore,
        IndustryService,
        IntlIndustryService,
        BetaService,
        RiskService,
        SummaryService,
        PremiumsBuilder,
        ExcelDialogTypesBuilder,
        IsUserHaveCocSubscriptionGuard,
        IsUserHaveUsiSubscriptionGuard,
        IsUserHaveIneSubscriptionGuard,
        IsUserHaveIniSubscriptionGuard,
        IsSubscriberExpiredGuard,
        BetasTabComponent,
        SizeTabComponent,
        RfTabComponent,
        InputsTabComponent,
        DataPoint,
        ExcelExportComponent

    ],
    declarations: [
    ]
})
export class NavigatorModule {}
