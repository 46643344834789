export enum MetricGroup {
    ReturnOnAssets = 1,
    ReturnOnEquity = 2,
    DividendYield = 3,
    CurrentRatio = 4,
    OperatingMargin = 5,
    LongTermEps = 6,
    BetasLeveredRaw = 7,
    BetasLeveredBlumeAdjusted = 8,
    BetasLeveredPeerGroup = 9,
    BetasLeveredVasicekAdjusted = 10,
    BetasLeveredSum = 11,
    BetasLeveredDownside = 12,
    BetasUnleveredRaw = 13,
    BetasUnleveredBlumeAdjusted = 14,
    BetasUnleveredPeerGroup = 15,
    BetasUnleveredVasicekAdjusted = 16,
    BetasUnleveredSum = 17,
    BetasUnleveredDownside = 18,
    PriceSales = 19,
    PriceEarnings = 20,
    MarketBook = 21,
    EvSales = 22,
    EvEbitda = 23,
    EnterpriseValuation = 24,
    FamaFrenchComponents = 25,
    DebtMvEquity = 26,
    DebtTotalCapital = 27,
    CostOfDebt = 28,
    SicComposite = 29,
    CostOfEquityCapitalCapm = 30,
    CostOfEquityCapitalCrspDeciles = 31,
    CostOfEquityCapitalRiskPremiumReport = 32,
    CostOfEquityCapitalDiscountedCashFlow = 33,
    CostOfEquityCapitalFamaFrench = 34,
    CostOfEquityCapital = 35,
    WaccCapm = 36,
    WaccCrspDeciles = 37,
    WaccRiskPremiumReport = 38,
    WaccDiscountedCashFlow = 39,
    WaccFamaFrench = 40,
    Wacc = 41,
    PriceTbv = 42,
    PriceEbt = 43,
    ThreeStageCashFlow = 44
}
