import { Component, Input, OnInit } from '@angular/core';
import { ModalManager, Spinner } from '@concurrency/angular';
import { AccordionTextService } from 'src/app/_add-in/accordion/accordion-text.service';
import { InputBuilder } from 'src/app/_add-in/data/input.builder';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';
import { IndustryService } from 'src/app/_add-in/industry/industry.service';
import { PrimeManager } from 'src/app/_add-in/modal/pmodal.manager';
import { GenericTabComponent } from 'src/app/estimate/generic-tab.component';
import { CommonDate } from '../_add-in/common/date-struct';
import { FieldData } from '../_add-in/data/field-data';
import { UserStore } from '../_add-in/user/store/user.store';
import { USCompanyClient } from '../_api/clients/uscompany.client';
import { USIndustryClient } from '../_api/clients/usindustry.client';
import { ViewIndustriesComponent } from '../benchmarking/components/view-industries/view-industries.component';
import { CompanyListNoIndustryModalComponent } from '../benchmarking/modals/company-list-no-industry-modal.component';
import { BetaCompaniesListComponent } from './beta-company-list.component';

@Component({
    selector: 'betas-tab',
    templateUrl: './betas-tab.component.html'
})
export class BetasTabComponent extends GenericTabComponent implements OnInit {
    @Input() public model!: FieldData;
    public cellname!: string;
    public industryCode!: number;
    constructor(
        public excelStorageService: ExcelStorageService,
        public userStore: UserStore,
        public builder: InputBuilder,
        public headers: AccordionTextService,
        public industryService: IndustryService,
        public spinner: Spinner,
        public primeManager: PrimeManager,
        public modalManager: ModalManager,
        private usIndustryClient: USIndustryClient,
        private usCompanyClient: USCompanyClient
    ) {
        super(excelStorageService, userStore, builder);
    }

    public determineIndustryCode(): string | undefined {
        const valuationDate = this.builder.valuationDate.value;

        if (valuationDate) {
            const dateUSGICS = new Date('2020-09-30');

            if (new Date(`${valuationDate.year}-${valuationDate.month}-${valuationDate.day}`).getTime() >= dateUSGICS.getTime()) {
                return 'GicIndustryCode';
            } else {
                return 'SicIndustryCode';
            }
        }
        return undefined;
    }

    public showIndustries(): void {
        const date = CommonDate.fromStruct(this.builder.valuationDate.value)?.asString();
        const request = this.usIndustryClient.read(date);
        this.spinner.while(request);
        request.once((industries) => {
            this.primeManager.openDialog(ViewIndustriesComponent, {
                valuationDate: date,
                moduleName: 'USCOC',
                industries
            });
        });
    }

    public showCompanies(industry: FieldData): void {
       
        if (!this.builder.valuationDate.value||industry.value==='N/A'||!industry.value) {
            this.modalManager.open<string, CompanyListNoIndustryModalComponent>(CompanyListNoIndustryModalComponent, {
                mayDismiss: false
            });
            return;
        }
        const date = CommonDate.fromStruct(this.builder.valuationDate.value)?.asString();
        this.industryCode = industry.value.CodeId;
        const request = this.usCompanyClient.getFibCompanies(date, this.industryCode);
        this.spinner.while(request);
        request.once((data) => {
            this.primeManager.openDialog(BetaCompaniesListComponent, {
                companies: data
            });
        });
    }
    // Removed the logic to assign the reference to a cell based on the value
    // public ngOnInit(): void {
    //     const fields = this.excelStorageService.getExcelEstimate();
    //     if (fields == null) {
    //         return;
    //     }
    //     Excel.run({ delayForCellEdit: true }, async (context: Excel.RequestContext) => {
    //         const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
    //         const range = activeWorksheet.getRange('A1:z60');
    //         range.load(['address', 'cellCount', 'values', 'text']);
    //         activeWorksheet.load(['name', 'id', 'protection/protected']);
    //         return context.sync().then(() => {
    //             //  const temp=activeWorksheet.name+'!'.length;
    //             const cell = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    //                 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    //             for (let i = 0; i < 60; i++) {
    //                 for (let j = 0; j < 24; j++) {
    //                     if (range.values[i][j] !== '' && this.builder.beta0.value === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.beta0.source, reference, this.builder.beta0.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.beta0.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.industryRiskPremium0.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.industryRiskPremium0.source, reference,
    //                             this.builder.industryRiskPremium0.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.industryRiskPremium0.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.beta1.value === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.beta1.source, reference, this.builder.beta1.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.beta1.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.industryRiskPremium1.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.industryRiskPremium1.source,
    //                             reference, this.builder.industryRiskPremium1.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.industryRiskPremium1.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.beta2.value === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.beta2.source, reference, this.builder.beta2.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.beta2.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.industryRiskPremium2.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.industryRiskPremium2.source, reference,
    //                             this.builder.industryRiskPremium2.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.industryRiskPremium2.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.beta3.value === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.beta3.source, reference, this.builder.beta3.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.beta3.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.industryRiskPremium3.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.industryRiskPremium3.source, reference,
    //                             this.builder.industryRiskPremium3.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.industryRiskPremium3.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.beta4.value === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.beta4.source, reference, this.builder.beta4.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.beta4.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                     if (range.values[i][j] !== '' && this.builder.industryRiskPremium4.decimalValue === range.values[i][j]) {
    //                         const temp = i + 1;
    //                         this.cellname = cell[j] + temp;
    //                         const sheetname = activeWorksheet.name;
    //                         const reference = sheetname + '!' + this.cellname;
    //                         this.excelStorageService.setCellBinding(this.builder.industryRiskPremium4.source, reference,
    //                             this.builder.industryRiskPremium4.fieldType);
    //                         const estimateField: FieldData = fields[this.builder.industryRiskPremium4.source];
    //                         estimateField.reference = this.excelStorageService.removeSheetWithSpace(reference);
    //                         estimateField.worksheetId = activeWorksheet.id;
    //                         this.excelStorageService.Save();
    //                     }
    //                 }
    //             }
    //             return true;
    //         });
    //     }).then(() => {
    //     }).catch(this.excelStorageService.handleExcelErrors);
    // }
}
