import { Injectable } from '@angular/core';
import { Util } from '@concurrency/core';
import { Measure } from 'src/app/_add-in/data/enums/measure';
import { Premium } from 'src/app/_add-in/data/enums/premium';
import { RiskParameterType } from 'src/app/_add-in/data/enums/risk-parameter-type';
import { PortfolioResult } from 'src/app/_add-in/risk/portfolio-result.model';
import { ZScoreBreakpoint } from 'src/app/_add-in/risk/zscore-breakpoint';
import { ZScoreResult } from 'src/app/_add-in/risk/zscore-result.model';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { CompanyType, InputType } from 'src/app/_api/responses/input.response';
import { Portfolio } from 'src/app/_api/responses/portfolio.response';
import { SelectionType } from 'src/app/_api/responses/selection.response';
import { ZScore } from 'src/app/_api/responses/zscore.response';
import { PremiumsBuilder } from './premiums.builder';
import { PercentPipe, formatPercent } from '@angular/common';

@Injectable()
export class RiskService {

    public constructor(private premiumsBuilder: PremiumsBuilder) { }

    public calculateZScore(options: {
        marketValueOfEquity: number,
        bookValueOfEquity: number,
        totalAssets: number,
        currentYearEBIT: number,
        currentYearSales: number,
        currentAssets: number,
        currentLiabilities: number,
        retainedEarnings: number,
        companyType: CompanyType
    }): number {
        const netWorkingCapital = options.currentAssets - options.currentLiabilities;
        const A = netWorkingCapital / options.totalAssets;
        const B = options.retainedEarnings / options.totalAssets;
        const C = options.currentYearEBIT / options.totalAssets;
        const E = options.currentYearSales / options.totalAssets;

        if (options.companyType === CompanyType.Manufacturing) {
            const D = options.marketValueOfEquity / (options.totalAssets - options.bookValueOfEquity);

            return (1.2 * A) + (1.4 * B) + (3.3 * C) + (0.6 * D) + (0.999 * E);
        } else {
            const D = options.bookValueOfEquity / (options.totalAssets - options.bookValueOfEquity);

            return (6.56 * A) + (3.26 * B) + (6.72 * C) + (1.05 * D);
        }
    }

    public getZScoreResult(zscore: ZScore, input: number, companyType: CompanyType, selectionType: SelectionType): ZScoreResult {
        const isBuildup = selectionType === SelectionType.HighFinancialRiskPremiumOverRiskFreeRate;
        const zScoreMeasure = companyType === CompanyType.Manufacturing ? Measure.ManufacturingZScore : Measure.ServiceZScore;
        const isBuildupVerbose = isBuildup ? 'Risk Premium Over the Risk-free Rate' : 'Size Premium';

        let result: number;
        let zScoreBreakpoint: ZScoreBreakpoint;
        let zScoreDataPoint: string;

        if (companyType === CompanyType.Manufacturing) {
            if (input < 1.8) {
                if (isBuildup) {
                    result = zscore.Manufacturing.Buildup.Distress;
                } else {
                    result = zscore.Manufacturing.Capm.Distress;
                }
                zScoreBreakpoint = ZScoreBreakpoint.ManufacturingDistress;
                zScoreDataPoint = `Distress Zone ${isBuildupVerbose}`;
            } else {
                result = 0;
                zScoreBreakpoint = ZScoreBreakpoint.ManufacturingNotDistress;
                zScoreDataPoint = `Not Distressed ${isBuildupVerbose}`;
            }
        } else {
            if (input < 1.1) {
                if (isBuildup) {
                    result = zscore.Service.Buildup.Distress;
                } else {
                    result = zscore.Service.Capm.Distress;
                }
                zScoreBreakpoint = ZScoreBreakpoint.ServiceDistress;
                zScoreDataPoint = `Distress Zone ${isBuildupVerbose}`;
            } else {
                result = 0;
                zScoreBreakpoint = ZScoreBreakpoint.ServiceNotDistress;
                zScoreDataPoint = `Not Distressed ${isBuildupVerbose}`;
            }
        }

        return {
            value: Util.round(result * 100),
            measure: zScoreMeasure,
            breakpoint: zScoreBreakpoint,
            dataAsOf: zscore.DataAsOf,
            dataPoint: zScoreDataPoint
        };
    }

    // TODO: find another place for this
    public removeTimeStampFromDate(date: string): string {
        if (date && date.includes('T')) {
            return date.split('T')[0];
        }
        return date;
    }

    public getPortfolioResult(
        portfolios: Portfolio[],
        input: number,
        inputType: InputType,
        debt: number,
        historicRprErp: number
    ): PortfolioResult[] {

        const portfolioResults: PortfolioResult[] = [];
        const portfolio = portfolios.filter((x) =>
            x.InputType === inputType &&
            x.Lower < input &&
            x.Upper >= input
        );

        for (const item of portfolio) {
            const lowerBreakpoint = item.Lower < 0 ? 0 : item.Lower;
            const boundedBreakpoints = `$${Util.round(lowerBreakpoint)}m-$${Util.round(item.Upper)}m`;
            const unboundedBreakpoints = `$${Util.round(lowerBreakpoint)}m and up`;
            const premium: Premium = this.premiumsBuilder.getPremiumItem(item.EquationType);

            portfolioResults.push({
                value: Util.round(item.Result * 100),
                description: item.Description,
                dataAsOf: this.removeTimeStampFromDate(item.DataAsOf),
                riskParameterType: RiskParameterType.Portfolio,
                inputType: item.InputType,
                equationType: item.EquationType,
                breakpoints: item.Description.includes('and up') ? unboundedBreakpoints : boundedBreakpoints,
                dataPoint: `${item.Name} ${premium}`
            });
        }
        const factor = Math.log(input) / Math.log(10);
        if (debt && historicRprErp) {
            const unleveredPortfolio = portfolio.filter((x) => x.EquationType === EquationType.RprsBuildup1Unlevered)[0];
            const lowerBreakpoint = unleveredPortfolio.Lower < 0 ? 0 : unleveredPortfolio.Lower;

            const averageDebt = debt / 100;
            const difference = unleveredPortfolio.UnleveredBeta - 0.1;
            const releveredValue = unleveredPortfolio.Result + averageDebt * difference * historicRprErp;

            portfolioResults.push({
                value: Util.round(releveredValue * 100),
                description: unleveredPortfolio.Description,
                dataAsOf: this.removeTimeStampFromDate(unleveredPortfolio.DataAsOf),
                riskParameterType: RiskParameterType.Portfolio,
                inputType: unleveredPortfolio.InputType,
                equationType: EquationType.RprsBuildup1Relevered,
                breakpoints: `$${Util.round(lowerBreakpoint)}m-$${Util.round(unleveredPortfolio.Upper)}m`,
                dataPoint: `${unleveredPortfolio.Name} ${Premium.RiskPremiumOverTheRiskFreeRateRelevered}`
            });

            const constant = unleveredPortfolio.ConstantPercentage;
            const coefficient = unleveredPortfolio.CoefficientPercentage;
            const UnleveredCoefficient = unleveredPortfolio.UnleveredBetaCoefficient;
            const UnleveredConstant = unleveredPortfolio.UnleveredBetaConstant;

            const debtratio = debt / 100;
            const erp = historicRprErp;
            const regressionValue = ((constant + coefficient * factor) +
                ((UnleveredConstant + UnleveredCoefficient * factor) - 0.1) * debtratio * erp) * 100;
            const breakpoint = 'Unlevered Premium+ {{' + UnleveredConstant.toFixed(3).toString()
                + ' + ' + UnleveredCoefficient.toFixed(3).toString() + ' * Log(' + Math.abs(input) + ')} -0.1}* ' + debtratio + ' * '
                + erp.toFixed(3).toString();
            const description = 'Regression Method = ' + breakpoint;
            portfolioResults.push({
                value: Util.round(regressionValue),
                description,
                dataAsOf: this.removeTimeStampFromDate(unleveredPortfolio.DataAsOf),
                riskParameterType: RiskParameterType.Regression,
                inputType: unleveredPortfolio.InputType,
                equationType: EquationType.RprsBuildup1Relevered,
                breakpoints: breakpoint,
                dataPoint: `Regression Method ${Premium.RiskPremiumOverTheRiskFreeRateRelevered}`
            });
        }

        const regressionAvailablePortfolio = portfolio;
       
        for (const item of regressionAvailablePortfolio) {
          
            const constant = formatPercent(item.ConstantPercentage, 'en-US', '1.3-3');
            const coefficient = formatPercent(Math.abs(item.CoefficientPercentage), 'en-US', '1.3-3');
           
            // const constant = Util.roundToFixed(item.ConstantPercentage * 100, 3);
            // const coefficient = Util.roundToFixed(Math.abs(item.CoefficientPercentage) * 100, 3);

            if (item.InputType === 'OperatingMargin') {
                portfolioResults.push({
                    value: Util.round((item.ConstantPercentage + (item.CoefficientPercentage * factor)) * 100),
                    description: `Regression Method = ${constant} - ${coefficient} * Log(${Util.round(input, 3)})`,
                    dataAsOf: this.removeTimeStampFromDate(item.DataAsOf),
                    riskParameterType: RiskParameterType.Regression,
                    inputType: item.InputType,
                    equationType: item.EquationType,
                    breakpoints: `${constant} - ${coefficient} * Log(${Util.round(input, 3)})`,
                    dataPoint: `Regression Method ${Premium.RiskPremiumOverTheRiskFreeRateRelevered}`
                });

            } else {
                portfolioResults.push({
                    value: Util.round((item.ConstantPercentage + (item.CoefficientPercentage * factor)) * 100),
                    description:item.EquationType==EquationType.RprsBuildup3 ?`Regression Method = ${constant} + ${coefficient} * Log(${Util.round(input, 3)})`:`Regression Method = ${constant} - ${coefficient} * Log(${Util.round(input, 3)})`,
                    dataAsOf: this.removeTimeStampFromDate(item.DataAsOf),
                    riskParameterType: RiskParameterType.Regression,
                    inputType: item.InputType,
                    equationType: item.EquationType,
                    breakpoints: `${constant} - ${coefficient} * Log(${Util.round(input, 3)})`,
                    dataPoint: `Regression Method ${Premium.RiskPremiumOverTheRiskFreeRateRelevered}`
                });
            }
        }

       


        return portfolioResults;
    }

    // public replaceEquation(value:string):string{
    //    const replacedValue=value.replace(/%–/g,"% + ");
    //    return replacedValue;
    // }
}
