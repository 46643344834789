// Angular Modules
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ConcurrencyModule } from "@concurrency/angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NavigatorModule } from "src/app/_add-in/add-in.module";
import { AddInCommonModule } from "src/app/_add-in/common/common.module";

// TODO: Should these actually all be in here? e.g. PDF or Excel
export const CommonModules = [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    ConcurrencyModule,
    NavigatorModule,
    AddInCommonModule, // TODO: Move all this code into Concurrency or Navigator
];
