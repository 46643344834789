import { Injectable } from '@angular/core';
import { Util } from '@concurrency/core';
import { CustomNumber } from 'src/app/_add-in/calculations/custom-number.model';

@Injectable()
export class CalculationsService {

    private calculateMargin(netSales: number[], operatingIncome: number[]): number[] {
        const margin: number[] = [];

        for (const i in netSales) {
            if (netSales[i]) {
                margin[i] = (operatingIncome[i] / netSales[i]) || 0;
            } else {
                margin[i] = 0;
            }
        }

        return margin;
    }

    private sampleSizeStandardDeviation(values: number[]): number {
        let M = 0.0;
        let S = 0.0;
        let k = 1;

        for (const value of values) {
            const tempM: number = M;
            M += (value - tempM) / k;
            S += (value - tempM) * (value - M);
            k++;
        }

        return Math.sqrt(S / (k - 2));
    }

    private calculateReturnOnEquity(netIncome: number[], bookValueOfEquity: number[]): number[] {
        const returnOnEquity: number[] = [];

        for (const i in bookValueOfEquity) {
            if (bookValueOfEquity[i]) {
                returnOnEquity[i] = (netIncome[i] / bookValueOfEquity[i]) || 0;
            } else {
                returnOnEquity[i] = 0;
            }
        }

        return returnOnEquity;
    }

    private convertToCustomNumber(value: number): CustomNumber {
        return {
            decimal: value,
            percentage: Util.round(value * 100)
        };
    }

    public calculateOperatingMargin(netSales: number[], operatingIncome: number[]): CustomNumber {
        const margin: number[] = this.calculateMargin(netSales, operatingIncome);
        const filteredMargin: number[] = margin.filter((x) => x != null && isNaN(x) === false && x !== Infinity && x !== 0);
        const operatingMargin = Util.average(filteredMargin, 4) || 0;

        return this.convertToCustomNumber(operatingMargin);
    }

    public calculateCVOfOperatingMargin(netSales: number[], operatingIncome: number[]): CustomNumber {
        const margin: number[] = this.calculateMargin(netSales, operatingIncome);
        const filteredMargin: number[] = margin.filter((x) => x != null && isNaN(x) === false && x !== Infinity && x !== 0);
        const std = this.sampleSizeStandardDeviation(filteredMargin);

        const stdMargin: number = std > 0 ? std : 0;
        const operatingMargin: number = Util.average(filteredMargin, 4) || 0;
        const CVOfOperatingMargin: number = (stdMargin / operatingMargin) || 0;

        return this.convertToCustomNumber(CVOfOperatingMargin);
    }

    public calculateAverageReturnOnEquity(netIncome: number[], bookValueOfEquity: number[]): CustomNumber {
        const returnOnEquity: number[] = this.calculateReturnOnEquity(netIncome, bookValueOfEquity);
        const filteredRoE: number[] = returnOnEquity.filter((x) => x != null && isNaN(x) === false && x !== Infinity && x !== 0);
        const averageReturnOnEquity: number = Util.average(filteredRoE, 4) || 0;

        return this.convertToCustomNumber(averageReturnOnEquity);
    }

    public calculateCVOfReturnOnEquity(netIncome: number[], bookValueOfEquity: number[]): CustomNumber {
        const returnOnEquity: number[] = this.calculateReturnOnEquity(netIncome, bookValueOfEquity);
        const filteredRoE: number[] = returnOnEquity.filter((x) => x != null && isNaN(x) === false && x !== Infinity && x !== 0);
        const averageReturnOnEquity: number = Util.average(filteredRoE, 4) || 0;

        let stdRoe: number = this.sampleSizeStandardDeviation(filteredRoE);
        stdRoe = stdRoe > 0 ? stdRoe : 0;
        const CVOfReturnOnEquity: number = (stdRoe / averageReturnOnEquity) || 0;

        return this.convertToCustomNumber(CVOfReturnOnEquity);
    }

}
