export enum ExcelDialogType {
    ValueAlreadyBound = 1,
    CellNotEmpty = 2,
    NaN = 3,
    ProtectedSheet = 4,
    ValueChanged = 5,
    MultipleCellsSelected = 6,
    valuationDateunavailablemod1= 7,
    valuationDateunavailablemod2= 8,
    valuationDateunavailablemod3= 9,
    valuationdateunavilablebasic= 10,
    valuationfuturedate= 11,
    valuationinvaliddate= 12,
    Trialdate= 13,
    IntlInvestorCountryNotExists= 14,
    IntlRequiredInputs=15
}
