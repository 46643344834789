import { CommonDate } from '../../common/date-struct';
import { ProductType } from '../enum/product-type';
import { SubscriptionLevel } from '../enum/subscription-level';

export class SubscriptionUtil {
    public static getMinimumDate(basicMinimumYear: number, level: SubscriptionLevel, productType: ProductType): CommonDate {
        if (level === SubscriptionLevel.Trial) {
            return new CommonDate(2016, 3, 31);
        }

        const minYear = productType === ProductType.Coc ? 1998 : 2014;
        const minMonth = productType === ProductType.Coc ? 12 : 3;

        let minimumYear = minYear;
        let minimumMonth = minMonth;

        if (level === SubscriptionLevel.Basic) {
            minimumYear = Math.max(basicMinimumYear, minYear);
            minimumMonth = 12;
            return new CommonDate(minimumYear, minimumMonth, 31);
        }

        if (productType === ProductType.Ini) {
            minimumYear = 2015;
            return new CommonDate(minimumYear, minimumMonth, 31);
        }

        return new CommonDate(minimumYear, minimumMonth, 31);
    }

    public static getMaximumDate(endDate: string, level: SubscriptionLevel): CommonDate {
        if (level === SubscriptionLevel.Trial) {
            return new CommonDate(2016, 7, 31);
        }

        const subscriptionEndDate = new Date(endDate);
        const today = new Date();
        const maximumTime = Math.min(subscriptionEndDate.getTime(), today.getTime());
        const maximumDate = new Date(maximumTime);

        return CommonDate.fromDate(maximumDate);
    }
}
