import { OnInit, Directive } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { InputBuilder } from 'src/app/_add-in/data/input.builder';
import { ExcelStorageService } from 'src/app/_add-in/excel/excel-storage.service';
import { ExcelEstimate } from 'src/app/_add-in/excel/excel.estimate';
import { UserStore } from '../_add-in/user/store/user.store';

@Directive()
export class GenericTabComponent extends SubscriberEntity implements OnInit {

    public excelEstimate: ExcelEstimate = {} as ExcelEstimate;

    constructor(
        public excelStorageService: ExcelStorageService,
        public userStore: UserStore,
        public builder: InputBuilder
    ) {
        super();
    }

    public ngOnInit(): void {
        this.excelStorageService.init(this.excelEstimate);
        this.excelStorageService.loadExcelStorageService().then(() => {
            const fields = this.excelStorageService.getExcelEstimate();
            if (fields == null) {
                return;
            }

            const estimate = Object.keys(this.builder).filter(
                (x) => fields[x]
            );
            for (const obj of estimate) {
                this.builder[obj] = fields[obj];
            }
        });
    }

}
