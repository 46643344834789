import { Component, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { UserStore } from '../_add-in/user/store/user.store';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    templateUrl: './landing.component.html'
})
export class LandingComponent extends SubscriberEntity implements OnInit {
    constructor(
        private userStore: UserStore,
        private auth:AuthService
    ) { super(); }

    public ngOnInit(): void { }
   
    public loginUser(): void {
        const navigateLocation = sessionStorage.getItem('navigateLocation');
        if (!Util.isNullOrEmpty(navigateLocation)) {
            if (navigateLocation && navigateLocation.search('#employee') >= 0) {
                this.userStore.loginEmployee();
            } else {
                this.userStore.loginCustomer();
            }
        } else {
            this.userStore.loginCustomer();
        }

    }
}
