import { SubscriptionType, UserSubscription } from 'src/app/_api/responses/user.response';
import { CommonDate } from '../../common/date-struct';
import { ProductType } from '../enum/product-type';
import { SubscriptionLevel } from '../enum/subscription-level';
import { SubscriptionUtil } from '../util/subscription.util';

export class SubscriptionData {
    public isExpired: boolean;
    public level: SubscriptionLevel;
    public productType: ProductType;
    public maximumDate: CommonDate;
    public expiration: CommonDate;

    constructor(data: UserSubscription) {
        this.isExpired = data != null && new Date(data.End) < new Date();
        const subName: string = SubscriptionType[data.Type];
        const productName: string = subName.substring(0, 3);   // First three letters are the product type
        const levelName: string = subName.substring(3);        // From here to the end is the product level
        this.level = levelName as SubscriptionLevel;
        this.productType = productName as ProductType;
        this.maximumDate = SubscriptionUtil.getMaximumDate(data.End, this.level);
        this.expiration = CommonDate.fromString(data.End);
    }
}
