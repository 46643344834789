import { Component, Input } from "@angular/core";
import { BetaService } from "../../beta/beta.service";
import { FieldType } from "../../data/enums/field-type";
import { FieldData } from "../../data/field-data";
import { FieldService } from "../../data/field.service";
import { SuggestedInput } from "../../data/suggested-input.model";
import { ExcelStorageService } from "../../excel/excel-storage.service";
import { CommonUserInputComponent } from "./common-user-input.component";

@Component({
    selector: 'common-intlcombobox',
    templateUrl: './common-intlcombobox.component.html'
})

export class CommonIntlComboboxComponent extends CommonUserInputComponent{
     @Input() public comboboxData: SuggestedInput[] = [];
    @Input() public model!: FieldData;
    industryValue: string = '';

    public isDropdownActive = false;
    public fieldTypeCombobox = FieldType.IntlCombobox;
     constructor(
        private fieldService: FieldService,
        private excelStorageService: ExcelStorageService,
        private betaService: BetaService
    ) {
        super(excelStorageService);
    }
      
     public selectIndustrty(): void {
        this.betaService.bindIndustry(this.model);
    }
    // public emitChange(isValid: boolean): void {
    //     super.emitChange(isValid);
    //     if (isValid) {
    //         this.fieldService.updateModel(this.model, this.excelStorageService  );
    //     }
    // }
    public onBindToCell(): void {
        this.betaService.bindIndustry(this.model);
    }

    public selectItem(item: SuggestedInput): void {
        // if (this.model.betaGroup == null) {
        //     return;
        // }

        this.betaService.saveModel(this.model, item.value, item.source, item.dataPoint, item.dataAsOf);
        this.modelChange.emit(this.model);
        this.input.nativeElement.focus();
        // debugger;
        // if (this.model.betaGroup !== null && typeof (this.model.betaGroup) === 'number') {
        //     this.betaService.updateIndustryRiskPremium(false, this.model.betaGroup);
        // }
    }
    public emitChange(isValid: boolean): void {
        super.emitChange(isValid);
        if (isValid) {
            this.fieldService.updateModel(this.model, this.excelStorageService);
        }
    }
    public onChange(){
        console.log(this.model)
    }
    public clearSource(): void {
        this.model.context = '';
    }
   

}   