<div class="subscription-redirect-component">
    <div class="nav-main">
        <div class="nav-header">
            <img src="assets/images/kroll-logo.png" alt="Kroll" />
        </div>
        <div class="nav-description">
            <p>
                To access the Cost of Capital Navigator Excel Add-in, you must have an active subscription to the
                Cost of Capital Navigator.
            </p>
        </div>
        <div class="nav-description">
            <p>
                To subscribe, click <a class="purchase-link" target="_blank"
                    href="https://dpcostofcapital.com">here</a>.
            </p>
        </div>
        <div class="login-btn">
            <button (click)="goToLandingPage()">Go back to Landing page</button>
        </div>
        <div class="nav-description">
            <div class="nav-footer">
                <div class="nav-footer-section">
                    <a class="nav-footer-link" target="_blank" href="http://www.kroll.com">
                        About Kroll
                    </a>
                </div>
                <div class="nav-footer-section">
                    <div>
                        <a class="nav-footer-link" target="_blank" href="https://www.kroll.com/en/terms-of-use">
                            Legal
                        </a>
                        <span>|</span>
                        <a class="nav-footer-link" target="_blank" href="https://www.kroll.com/en/privacy-policy">
                            Privacy
                        </a>
                        <span>|</span>
                        <a class="nav-footer-link" target="_blank" href="https://www.kroll.com/en/cookies-policy">
                            Cookies
                        </a>
                        <span>|</span>
                        <a class="nav-footer-link" target="_blank" href="https://www.kroll.com/en/disclosure">
                            Disclosures
                        </a>
                        <span>|</span>
                        <a class="nav-footer-link" target="_blank"
                            href="https://vasdc8grscoc.blob.core.windows.net/files/terms.v042020.html">
                            Terms &amp; Conditions
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>