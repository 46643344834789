import { NgModule } from "@angular/core";
// Components
import { EmployeeLoginComponent } from "src/app/home/employee-login.component";
import { HomeComponent } from "src/app/home/home.component";
import { LandingComponent } from "src/app/home/landing.component";
import { SubscriptionRedirectComponent } from "src/app/home/subscription-redirect.component";
import { CommonModules } from "src/app/_config/common-modules";

@NgModule({
    declarations: [
        EmployeeLoginComponent,
        HomeComponent,
        LandingComponent,
        SubscriptionRedirectComponent,
    ],
    imports: [...CommonModules],
})
export class HomeModule {}
