export enum MenuRoutes {
    RouteInputs = '/home/inputs-tab',
    RouteRfErp = '/home/rf-tab',
    RouteSizeRiskPremia = '/home/size-tab',
    RouteBetas = '/home/betas-tab',
    RouteDataSummary = '/home/data-summary-tab',
    RouteBenckmarking = '/benchmarking',
    RouteIntlBenchMarking = '/intlBenchmarking',
    RouteIntlCoC = '/intlCoC'
}
